import React from 'react';
import './ArchiveYearForm.scss';
import { connect } from 'react-redux';
import { putWorkPlanCurrentYear } from '../../service/actions/faculty';
import { showLoadingModal, hideModal } from '../../service/actions/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import { PUT_WORKPLAN_CURRYEAR } from '../../service/types';

class ArchiveYearForm extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.updating !== this.props.updating) {
      if (!this.props.updating && !this.props.errorMessage) {
        this.props.cancelFunc(document.createEvent('Event'));
      }
    }
  }

  render() {
    const { errorMessage } = this.props;
    return (
      <form id="archive-year-form">
        <fieldset>
          <legend className="radio-legend">
            {'Would you like to archive this academic year?'}
          </legend>
          <div className="form-end-wrapper">
            <div className="buttons">
              <button
                onClick={event => this.handleCancelClick(event)}
                id="cancelStatusChange"
              >
                Cancel
              </button>
              <button
                onClick={event => this.handleSubmitClick(event)}
                id="confirmStatusChange"
              >
                Confirm
                <FontAwesomeIcon icon={faCheckCircle} />
              </button>
            </div>
          </div>
        </fieldset>

        {errorMessage && (
          <div className="error-message archive-year-error-message">
            <FontAwesomeIcon icon={faExclamationCircle} />
            {errorMessage}
          </div>
        )}
      </form>
    );
  }

  handleSubmitClick(event) {
    event.preventDefault();
    const { facultyMember, workPlan } = this.props;
    if (workPlan && facultyMember && workPlan.academicYear) {
      this.props.putWorkPlanCurrentYear(
        facultyMember.id,
        workPlan.academicYear + 1
      );
    }
  }

  handleCancelClick(event) {
    event.preventDefault();
    this.props.cancelFunc(event);
  }
}

const updatingSelector = createLoadingSelector([PUT_WORKPLAN_CURRYEAR]);
const errorSelector = createErrorMessageSelector([PUT_WORKPLAN_CURRYEAR]);
const mapStateToProps = state => {
  const { faculty } = state;
  return {
    workPlan: faculty.viewWorkPlan,
    facultyMember: faculty.viewIndividual,
    updating: updatingSelector(state),
    errorMessage: errorSelector(state)
  };
};

export default connect(
  mapStateToProps,
  { putWorkPlanCurrentYear, showLoadingModal, hideModal }
)(ArchiveYearForm);
