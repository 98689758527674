import React from 'react';
import './LocationsList.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import {
  showSidePanel,
  hideSidePanel,
  updateTrapFocus
} from '../../service/actions/sidePanel';

import { connect } from 'react-redux';
import { showLoadingModal, hideModal } from '../../service/actions/modal';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import { GET_LOCATIONS, GET_CONFIG } from '../../service/types';
import AddLocation from './AddLocation';
import UpdateLocation from './UpdateLocation';
import { mainViewComponent } from '../common/HOCs';

class LocationsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: ''
    };
    this.updateLocationButton = React.createRef();
    this.locationRefs = {};
  }

  closeSidePanel(shouldSendFocus) {
    this.props.hideSidePanel({
      sendFocus: !!shouldSendFocus
    });
  }

  openSidePanel(keyActivated, location) {
    this.props.showSidePanel(
      <UpdateLocation
        locations={this.props.locations}
        location={location}
        closeSidePanel={() => this.closeSidePanel(location)}
      />,
      this.locationRefs[location.id],
      keyActivated
    );
  }

  render() {
    const { loading, errorMessage, locations, scopes } = this.props;

    if (!locations) {
      return null;
    }

    if (!scopes.includes('location.read')) {
      return 'Unauthorized';
    }

    return (
      <div className="locations-list">
        <h1>Locations</h1>
        {loading ? null : (
          <>
            {errorMessage && (
              <div className="error-message">
                <FontAwesomeIcon icon={faExclamationCircle} />
                {errorMessage}
              </div>
            )}
            {!errorMessage && this.renderLocationsList()}
          </>
        )}
      </div>
    );
  }

  handleInputChange(event) {
    this.setState({
      filter: event.target.value
    });
  }

  renderLocationsList() {
    const { locations } = this.props;
    const { filter } = this.state;

    if (locations && locations.length) {
      let filteredLocations = this.filterLocations();

      return (
        <div className="location-results">
          {this.props.scopes.includes('location.write') ? (
            <AddLocation />
          ) : null}

          <label htmlFor="location-search-input">Search</label>
          <input
            id="location-search-input"
            className="search-bar"
            type="text"
            value={filter}
            onChange={this.handleInputChange.bind(this)}
          />

          {filteredLocations.length ? (
            <table className="location-items">
              <colgroup>
                <col span="1"></col>
                <col span="1"></col>
                <col span="1"></col>
                <col span="1"></col>
              </colgroup>
              <thead>
                <tr>
                  <th></th>
                  <th>Building and Unit</th>
                  <th>Capacity</th>
                  <th>Type</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredLocations.map((item, i) => {
                  const type =
                    this.props.typeList &&
                    this.props.typeList.find(tp => tp.id === item.type).name;
                  return (
                    <tr key={item.id}>
                      <td
                        className={`${
                          item.isNew || item.updated ? 'new ' : ''
                        }`}
                      ></td>
                      <td>{item.name}</td>
                      <td>{item.capacity}</td>
                      <td className="location-type">{type}</td>
                      <td>
                        {this.props.scopes.includes('location.write') ? (
                          <button
                            className="circle small"
                            ref={ref => {
                              this.locationRefs[item.id] = ref;
                            }}
                            onClick={() => this.openSidePanel(false, item)}
                            onKeyDown={e => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                this.openSidePanel(true, item);
                              }
                            }}
                            title="Update Location"
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </button>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <p>No locations match the criteria</p>
          )}
        </div>
      );
    } else {
      return <p className="empty">There have been no locations added.</p>;
    }
  }

  filterLocations() {
    const { locations } = this.props;
    const { filter } = this.state;

    return [].concat(locations).filter(val => {
      if (filter) {
        return this.containsFilter(val);
      }
      return true;
    });
  }

  containsFilter(valueIn) {
    let returnVal = false;
    let modifiedFilter = this.state.filter.replace(/\s+/g, '').toUpperCase();

    if (
      valueIn.type
        .replace(/\s+/g, '')
        .toUpperCase()
        .indexOf(modifiedFilter) >= 0
    ) {
      returnVal = true;
    } else if (
      valueIn.name
        .replace(/\s+/g, '')
        .toUpperCase()
        .indexOf(modifiedFilter) >= 0
    ) {
      returnVal = true;
    }

    return returnVal;
  }
}

const loadingSelector = createLoadingSelector([GET_LOCATIONS, GET_CONFIG]);
const errorSelector = createErrorMessageSelector([GET_LOCATIONS]);
const mapStateToProps = state => {
  const { auth, locations, config } = state;

  return {
    scopes: auth.scopes,
    loading: loadingSelector(state),
    errorMessage: errorSelector(state),
    locations:
      locations.list && locations.list.filter(val => val.archived !== true),
    typeList: config && config.config && config.config.locationTypes
  };
};

const mainViewConfig = {
  pageTitle: 'Locations'
};

export default connect(mapStateToProps, {
  showLoadingModal,
  hideModal,
  showSidePanel,
  hideSidePanel,
  updateTrapFocus
})(mainViewComponent({ component: LocationsList, mainViewConfig }));
