import React from 'react';
import PropTypes from 'prop-types';
import './SchedulePicker.scss';
import ConnectedSelector from '../../common/ConnectedSelector';
import Select from '../../common/Select';

const hourOptions = [
  '12',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11'
];
const minuteOptions = [
  '00',
  '05',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55'
];

class SchedulePicker extends React.Component {
  constructor(props) {
    super(props);

    this.formValues = {
      startTimeHour: {
        name: 'start-time-hour',
        valueId: 'startTimeHour',
        labelTitle: 'HR',
        defaultValue: '\u2014',
        validatorFunc: value => ({
          isValid: value && value !== '',
          errorMessage: 'Invalid'
        })
      },
      startTimeMinute: {
        name: 'start-time-minute',
        valueId: 'startTimeMinute',
        labelTitle: 'MIN',
        defaultValue: '\u2014',
        validatorFunc: value => ({
          isValid: value && value !== '',
          errorMessage: 'Invalid'
        })
      },
      startTimeAmPm: {
        name: 'start-time-ampm',
        valueId: 'startTimeAmPm',
        labelTitle: 'AM/PM',
        defaultValue: ''
      },
      endTimeHour: {
        name: 'end-time-hour',
        valueId: 'endTimeHour',
        labelTitle: 'HR',
        defaultValue: '\u2014',
        validatorFunc: value => ({
          isValid: value && value !== '',
          errorMessage: 'Invalid'
        })
      },
      endTimeMinute: {
        name: 'end-time-minute',
        valueId: 'endTimeMinute',
        labelTitle: 'MIN',
        defaultValue: '\u2014',
        validatorFunc: value => ({
          isValid: value && value !== '',
          errorMessage: 'Invalid'
        })
      },
      endTimeAmPm: {
        name: 'end-time-ampm',
        valueId: 'endTimeAmPm',
        labelTitle: 'AM/PM',
        defaultValue: ''
      }
    };

    this.state = {
      daysOfWeek: props.daysOfWeekValue
    };

    if (props.startTimeValue && props.endTimeValue) {
      const startObj = this.getTimePiecesFromString(props.startTimeValue);
      const endObj = this.getTimePiecesFromString(props.endTimeValue);
      this.state = {
        ...this.state,
        startTimeHour: startObj.hour,
        startTimeMinute: startObj.minute,
        startTimeAmPm: startObj.amPm,
        endTimeHour: endObj.hour,
        endTimeMinute: endObj.minute,
        endTimeAmPm: endObj.amPm
      };
    }
  }

  componentDidUpdate(_, prevState) {
    if (prevState !== this.state) {
      this.callControlFunction();
    }
  }

  render() {
    const {
      daysOfWeekFormValue,
      startTimeFormValue,
      endTimeFormValue,
      daysOfWeekValue,
      startTimeValue,
      endTimeValue,
      disabled
    } = this.props;

    const noDaysOfWeek =
      !this.state.daysOfWeek || this.state.daysOfWeek.length === 0;

    let startTimePieces = {};
    let endTimePieces = {};
    if (startTimeValue && endTimeValue) {
      startTimePieces = this.getTimePiecesFromString(startTimeValue);
      endTimePieces = this.getTimePiecesFromString(endTimeValue);
    }

    return (
      <div className="schedule-picker">
        <h4>{daysOfWeekFormValue.labelTitle}</h4>
        <ConnectedSelector
          options={['M', 'T', 'W', 'Th', 'F', 'S', 'Su']}
          preselectedOptions={
            daysOfWeekValue || daysOfWeekFormValue.defaultValue
          }
          controlFunc={event =>
            this.handleValueChange({ valueId: 'daysOfWeek', value: event })
          }
          disabled={disabled}
          actionable={true}
        />

        <h4>{startTimeFormValue.labelTitle}</h4>
        <div className="row time">
          <div className="row">
            <div>
              <Select
                formValue={this.formValues.startTimeHour}
                startingValue={startTimePieces.hour}
                defaultOption={this.formValues.startTimeHour.defaultValue}
                controlFunc={event => this.handleValueChange(event)}
                optionList={hourOptions}
                containerStyle={{ zIndex: 102 }}
                disabled={disabled || noDaysOfWeek}
                emptyValueOption={'\u2014'}
                actionable={disabled || !noDaysOfWeek}
              />
            </div>
            <span className="colon">:</span>
            <div>
              <Select
                formValue={this.formValues.startTimeMinute}
                startingValue={startTimePieces.minute}
                defaultOption={this.formValues.startTimeMinute.defaultValue}
                controlFunc={event => this.handleValueChange(event)}
                optionList={minuteOptions}
                containerStyle={{ zIndex: 102 }}
                disabled={disabled || noDaysOfWeek}
                emptyValueOption={'\u2014'}
                actionable={disabled || !noDaysOfWeek}
              />
            </div>
          </div>

          <ConnectedSelector
            options={['AM', 'PM']}
            preselectedOptions={[
              startTimePieces.amPm || this.formValues.startTimeAmPm.defaultValue
            ]}
            controlFunc={event =>
              this.handleValueChange({
                valueId: this.formValues.startTimeAmPm.valueId,
                value: event
              })
            }
            multiSelect={false}
            disabled={disabled || noDaysOfWeek}
            actionable={disabled || !noDaysOfWeek}
          />
        </div>

        <h4>{endTimeFormValue.labelTitle}</h4>
        <div className="row time">
          <div className="row">
            <div>
              <Select
                formValue={this.formValues.endTimeHour}
                startingValue={endTimePieces.hour}
                defaultOption={this.formValues.endTimeHour.defaultValue}
                controlFunc={event => this.handleValueChange(event)}
                optionList={hourOptions}
                containerStyle={{ zIndex: 101 }}
                disabled={disabled || noDaysOfWeek}
                emptyValueOption={'\u2014'}
                actionable={disabled || !noDaysOfWeek}
              />
            </div>
            <span className="colon">:</span>
            <div>
              <Select
                formValue={this.formValues.endTimeMinute}
                startingValue={endTimePieces.minute}
                defaultOption={this.formValues.endTimeMinute.defaultValue}
                controlFunc={event => this.handleValueChange(event)}
                optionList={minuteOptions}
                containerStyle={{ zIndex: 101 }}
                disabled={disabled || noDaysOfWeek}
                emptyValueOption={'\u2014'}
                actionable={disabled || !noDaysOfWeek}
              />
            </div>
          </div>

          <ConnectedSelector
            options={['AM', 'PM']}
            preselectedOptions={[
              endTimePieces.amPm || this.formValues.endTimeAmPm.defaultValue
            ]}
            controlFunc={event =>
              this.handleValueChange({
                valueId: this.formValues.endTimeAmPm.valueId,
                value: event
              })
            }
            multiSelect={false}
            disabled={disabled || noDaysOfWeek}
            actionable={disabled || !noDaysOfWeek}
          />
        </div>
      </div>
    );
  }

  handleValueChange(formValue) {
    this.setState({
      [formValue.valueId]: formValue.value
    });
  }

  callControlFunction() {
    const {
      daysOfWeekFormValue,
      startTimeFormValue,
      endTimeFormValue
    } = this.props;
    const {
      daysOfWeek,
      startTimeHour,
      startTimeMinute,
      startTimeAmPm,
      endTimeHour,
      endTimeMinute,
      endTimeAmPm
    } = this.state;

    let partialSchedule = false;
    if (
      daysOfWeek ||
      startTimeHour ||
      startTimeMinute ||
      startTimeAmPm ||
      endTimeHour ||
      endTimeMinute ||
      endTimeAmPm
    ) {
      partialSchedule = true;
    }

    let validSchedule = true;
    if (
      !daysOfWeek ||
      !startTimeHour ||
      !startTimeMinute ||
      !startTimeAmPm ||
      !endTimeHour ||
      !endTimeMinute ||
      !endTimeAmPm
    ) {
      validSchedule = false;
    } else {
      partialSchedule = false;
    }

    const scheduleStateUpdate = {
      [daysOfWeekFormValue.valueId]: validSchedule ? daysOfWeek : null,
      [startTimeFormValue.valueId]: validSchedule
        ? this.createTimeString(startTimeHour, startTimeMinute, startTimeAmPm)
        : null,
      [endTimeFormValue.valueId]: validSchedule
        ? this.createTimeString(endTimeHour, endTimeMinute, endTimeAmPm)
        : null,
      partialSchedule
    };

    this.props.controlFunc(scheduleStateUpdate);
  }

  createTimeString(hour, minute, amPm) {
    const formattedHour =
      amPm.indexOf('PM') >= 0
        ? '' + (parseInt(hour) === 12 ? hour : parseInt(hour) + 12)
        : parseInt(hour) < 10
        ? `0${hour}`
        : parseInt(hour) === 12
        ? '00'
        : hour;
    return formattedHour + ':' + minute;
  }

  getTimePiecesFromString(timestring) {
    const pieces = timestring.split(':');

    let hour = parseInt(pieces[0]);
    let minute = pieces[1];
    let amPm = '';

    if (hour === 0) {
      hour = '12';
      amPm = 'AM';
    } else if (hour < 12) {
      amPm = 'AM';
    } else if (hour === 12) {
      amPm = 'PM';
    } else {
      amPm = 'PM';
      hour -= 12;
    }
    hour += '';

    return {
      hour,
      minute,
      amPm
    };
  }
}

SchedulePicker.propTypes = {
  daysOfWeekFormValue: PropTypes.object.isRequired,
  startTimeFormValue: PropTypes.object.isRequired,
  endTimeFormValue: PropTypes.object.isRequired,
  daysOfWeekValue: PropTypes.array,
  startTimeValue: PropTypes.string,
  endTimeValue: PropTypes.string,
  controlFunc: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  actionable: PropTypes.bool
};

SchedulePicker.defaultProps = {
  disabled: false,
  actionable: false
};

export default SchedulePicker;
