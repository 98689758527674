import React from 'react';
import { withRouter, Route } from 'react-router-dom';

import PlanOfStudyListView from './PlanOfStudyListView';
import PlanOfStudyView from './PlanOfStudyView';

const PlansOfStudyPage = props => {
  const { match } = props;
  return (
    <>
      <Route exact path={match.path} component={PlanOfStudyListView} />
      <Route
        path={match.path + '/:planOfStudyId'}
        component={PlanOfStudyView}
      />
    </>
  );
};

export default withRouter(PlansOfStudyPage);
