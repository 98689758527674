import { GET_USER } from '../types';
import Auth from '@aws-amplify/auth';
import config from '../../config';

export const validateUserSession = () => async dispatch => {
  Auth.currentAuthenticatedUser()
    .then(currentAuthUser => {
      Auth.userSession(currentAuthUser).then(session => {
        if (session.isValid()) {
          dispatch({
            type: GET_USER,
            payload: session
          });
        }
      });
    })
    .catch(error => {
      return Auth.currentCredentials()
        .then(credentials => {
          if (credentials) {
            console.log(credentials);
          }
        })
        .catch(err => {
          console.log({ message: 'refreshing error informaton', error: err });
        });
    });
};

export const signIn = () => {
  const path = document.location.pathname;
  if (path !== '/') {
    localStorage.setItem('deeplink', path);
  }
  Auth.federatedSignIn(
    config.AWS_COGNITO_DEFINE_IDP
      ? { provider: config.AWS_COGNITO_IDP_NAME }
      : undefined
  );
};

export const refreshToken = () => {
  Auth.currentAuthenticatedUser()
    .then(currentAuthUser => {
      Auth.userSession(currentAuthUser)
        .then(session => {
          try {
            currentAuthUser.refreshSession(session.refreshToken, () => null);
          } catch (err) {
            console.log('error in refreshSession()', err);
          }
        })
        .catch(err => {
          console.log(err);
        });
    })
    .catch(err => {
      console.log('currentAuthenticatedUser error', err);
      Auth.currentSession()
        .then(sesh => {
          console.log('called currentSession()', sesh);
        })
        .catch(err => {
          console.log('error in currentSession()', err);
        });
    });
};

export const signOut = () => {
  localStorage.clear();
  const clientId = config.AWS_COGNITO_CLIENT_ID;
  const domain = config.AWS_COGNITO_CLIENT_DOMAIN_NAME;
  const redirectSignIn = config.AWS_COGNITO_IDP_SIGNOUT_URL;
  let url = `https://${domain}/logout?redirect_uri=${redirectSignIn}&response_type=code&client_id=${clientId}`;
  if (config.AWS_COGNITO_DEFINE_IDP) {
    url += `&idp_identifier=${config.AWS_COGNITO_IDP_NAME}`;
  }
  window.location.assign(url);
};
