import React from 'react';
import './UpdatePlanOfStudy.scss';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faArchive } from '@fortawesome/free-solid-svg-icons/faArchive';
import Select from '../common/Select';
import SelectProgram from '../common/SelectProgram';
import {
  hideSidePanel,
  updateTrapFocus
} from '../../service/actions/sidePanel';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import {
  updatePlanOfStudy,
  archivePlanOfStudy,
  clearUpdatePlanOfStudyError
} from '../../service/actions/plansOfStudy';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import {
  UPDATE_PLAN_OF_STUDY,
  ARCHIVE_PLAN_OF_STUDY
} from '../../service/types';
import { generateYearList } from './helpers';
import { withRouter } from 'react-router-dom';

class UpdatePlanOfStudy extends React.Component {
  constructor(props) {
    super(props);

    if (!props.planOfStudy && !props.planOfStudyList) {
      console.error('no plan of study provided to the side panel');
    }
    const { planOfStudy } = props;

    this.state = {
      isFormInputValid: true,
      duplicateErrorMessage: '',
      resetting: false,
      programId: planOfStudy.program.id,
      concentrationId:
        (planOfStudy.concentration && planOfStudy.concentration.id) || '',
      track: planOfStudy.track,
      startTerm: planOfStudy.startTerm,
      startYear: planOfStudy.startYear,
      archiving: false
    };

    this.baseState = { ...this.state };
    this.handleValueChange = this.handleValueChange.bind(this);
    this.formValues = {
      programId: {
        name: 'program-concentration-select',
        valueId: 'programId',
        defaultValue: this.state.programId,
        labelTitle: 'Programs and Concentrations',
        validatorFunc: value => ({
          isValid: value && value !== '' && value !== 'Select',
          errorMessage: 'Invalid'
        })
      },
      concentrationId: {
        valueId: 'concentrationId',
        defaultValue: this.state.concentrationId,
        validatorFunc: value => ({
          isValid: true,
          errorMessage: 'Invalid'
        })
      },
      track: {
        name: 'track-type-select',
        valueId: 'track',
        labelTitle: 'Track Type',
        defaultValue: this.state.track,
        validatorFunc: value => ({
          isValid: value && value !== '' && value !== 'Select',
          errorMessage: 'Invalid'
        })
      },
      startTerm: {
        name: 'starting-term-select',
        valueId: 'startTerm',
        labelTitle: 'Starting Term',
        defaultValue: this.state.startTerm,
        validatorFunc: value => ({
          isValid: value && value !== '' && value !== 'Select',
          errorMessage: 'Invalid'
        })
      },
      startYear: {
        name: 'year-select',
        valueId: 'startYear',
        labelTitle: 'Year',
        defaultValue: this.state.startYear,
        validatorFunc: value => ({
          isValid: value && value !== '' && value !== 'Select',
          errorMessage: 'Invalid'
        })
      }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.updating !== prevProps.updating) {
      if (!this.props.updating && !this.props.errorMessage) {
        this.closePanel(true);
      }
    }
    if (this.props.archiving !== prevProps.archiving) {
      if (!this.props.archiving && !this.props.errorMessage) {
        this.closePanel(true);
      }
    }
    if (this.state.isFormInputValid !== prevState.isFormInputValid) {
      this.props.updateTrapFocus();
    }
  }

  render() {
    const {
      errorMessage,
      programList,
      trackList,
      termList,
      planOfStudy
    } = this.props;
    const { duplicateError, termChangeWarning } = this.state;
    const yearList = generateYearList();

    if (!programList || !trackList || !termList) {
      return null;
    }

    const editDisabled = !planOfStudy.canEdit;
    const saveButtonDisabled = !this.state.isFormInputValid || editDisabled;
    return (
      <section id="update-pos-wrapper">
        <div className="top first-focus">
          <h1>Update Plan of Study</h1>
          <button
            className="circle"
            ref={this.closeButton}
            tabIndex="0"
            title="Close Edit Panel"
            onClick={event => {
              event.preventDefault();
              this.closePanel(false);
            }}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                this.closePanel(true);
              }
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        {editDisabled && (
          <div className="form-message info-message">
            <FontAwesomeIcon icon={faInfoCircle} />
            <span>
              Plan of study is utilized by one or more student groups and can
              only be archived.
            </span>
          </div>
        )}

        <form id="pos-form-wrapper">
          <div id="pos-entry-form">
            <div>
              <SelectProgram
                programFormValue={this.formValues.programId}
                concentrationFormValue={this.formValues.concentrationId}
                controlFunc={this.handleValueChange}
                programList={programList}
                disabled={editDisabled}
              />
            </div>
            <div>
              <Select
                formValue={this.formValues.track}
                startingValue={this.state[this.formValues.track.valueId]}
                controlFunc={this.handleValueChange}
                optionList={trackList}
                disabled={editDisabled}
              />
            </div>
            <div>
              <Select
                formValue={this.formValues.startTerm}
                startingValue={this.state[this.formValues.startTerm.valueId]}
                controlFunc={this.handleValueChange}
                optionList={termList}
                disabled={editDisabled}
              />
            </div>
            <div>
              <Select
                formValue={this.formValues.startYear}
                startingValue={this.state[this.formValues.startYear.valueId]}
                controlFunc={this.handleValueChange}
                optionList={yearList}
                disabled={editDisabled}
              />
            </div>
          </div>

          <div className="form-end-wrapper">
            {errorMessage && (
              <div className="form-message unexpected-error-message error-message">
                <FontAwesomeIcon icon={faExclamationCircle} />
                An unexpected error occurred. Please try again.
              </div>
            )}

            {duplicateError && (
              <div className="form-message duplicate-error-message warning-message">
                <FontAwesomeIcon icon={faExclamationTriangle} />
                This plan of study already exists. Please validate your entries.
              </div>
            )}

            {termChangeWarning && (
              <div className="form-message term-change-warning-message warning-message">
                <FontAwesomeIcon icon={faExclamationTriangle} />
                <span>{termChangeWarning}</span>
              </div>
            )}
            <div className="bottom row">
              <div className="buttons">
                <button
                  id="cancel-pos-entry"
                  onClick={event => {
                    event.preventDefault();
                    this.closePanel(false);
                  }}
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      this.closePanel(true);
                    }
                  }}
                >
                  Cancel
                </button>
                <button
                  id="submit-pos-entry"
                  onClick={
                    saveButtonDisabled
                      ? undefined
                      : event => {
                          event.preventDefault();
                          this.updatePlanOfStudy();
                        }
                  }
                  onKeyDown={
                    saveButtonDisabled
                      ? undefined
                      : event => {
                          if (event.key === 'Enter') {
                            event.preventDefault();
                            this.updatePlanOfStudy();
                          }
                        }
                  }
                  disabled={saveButtonDisabled}
                >
                  Save Plan of Study <FontAwesomeIcon icon={faCheckCircle} />
                </button>
              </div>

              <div className="archive-wrapper">
                {this.renderArchiveButton()}
              </div>
            </div>
          </div>
        </form>
      </section>
    );
  }

  handleSubmitClick(event) {
    event.preventDefault();
    this.updatePlanOfStudy();
  }

  renderArchiveButton() {
    const { archiving } = this.state;

    if (archiving) {
      return (
        <div className="archival-buttons">
          <span className="confirmation-message">Archive Plan of Study?</span>
          <button
            className="circle"
            title="Cancel archival"
            tabIndex="0"
            onClick={event => {
              event.preventDefault();
              this.setState({
                archiving: false
              });
            }}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                this.setState({
                  archiving: false
                });
              }
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <button
            className="circle"
            title="Confirm archival"
            tabIndex="0"
            onClick={event => {
              event.preventDefault();
              this.archivePlanOfStuy();
            }}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                this.archivePlanOfStuy();
              }
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </button>
        </div>
      );
    }
    return (
      <div className="archival-buttons">
        <button
          className="circle"
          title="Archive Plan of Study"
          tabIndex="0"
          onClick={event => {
            event.preventDefault();
            this.setState({
              archiving: true
            });
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              event.preventDefault();
              this.setState({
                archiving: true
              });
            }
          }}
        >
          <FontAwesomeIcon icon={faArchive} />
        </button>
      </div>
    );
  }

  archivePlanOfStuy() {
    const {
      programId,
      concentrationId,
      track,
      startTerm,
      startYear
    } = this.state;
    const { planOfStudy } = this.props;

    let planOfStudyUpdate = {
      programId,
      concentrationId: concentrationId || undefined,
      track,
      startTerm,
      startYear,
      archived: true
    };
    this.props.archivePlanOfStudy(planOfStudy.id, planOfStudyUpdate);
  }

  updatePlanOfStudy() {
    this.validateFormInput();
    const {
      programId,
      concentrationId,
      track,
      startTerm,
      startYear
    } = this.state;
    const { planOfStudy } = this.props;

    let planOfStudyUpdate = {
      programId,
      concentrationId: concentrationId || undefined,
      track,
      startTerm,
      startYear,
      archived: false
    };

    this.props.updatePlanOfStudy(planOfStudy.id, planOfStudyUpdate);
  }

  handleValueChange(formValue, formValue2 = {}) {
    let currentFormState = Object.keys(this.formValues).reduce(
      (result, next) => {
        switch (next) {
          case formValue.valueId:
            result[formValue.valueId] = formValue.value;
            break;
          case formValue2.valueId:
            result[formValue2.valueId] = formValue2.value;
            break;
          default:
            result[next] = this.state[next];
        }
        return result;
      },
      {}
    );

    const isFormInputValid = Object.keys(this.formValues).reduce(
      (result, next) => {
        return (
          result &&
          this.formValues[next].validatorFunc(currentFormState[next]).isValid
        );
      },
      true
    );

    const { planOfStudy } = this.props;
    const isDuplicate = this.props.plansOfStudy.reduce((result, next) => {
      if (next.id === planOfStudy.id) {
        return result || false;
      }
      if (
        next.program.id === currentFormState.programId &&
        next.track === currentFormState.track &&
        next.startYear === currentFormState.startYear &&
        next.startTerm === currentFormState.startTerm
      ) {
        if (!next.concentration) {
          return result || !currentFormState.concentrationId === 'undefined';
        } else {
          return (
            result || next.concentration.id === currentFormState.concentrationId
          );
        }
      }
      return result || false;
    }, false);

    const termChangeWarning = this.generateTermChangeWarningString(
      currentFormState.startTerm
    );

    this.setState({
      [formValue.valueId]: formValue.value,
      [formValue2.valueId]: formValue2.value,
      isFormInputValid: isFormInputValid && !isDuplicate,
      duplicateError: isDuplicate,
      termChangeWarning
    });
  }

  generateTermChangeWarningString(newTerm) {
    const { planOfStudy, termList } = this.props;

    const newTermIndex = termList.indexOf(newTerm);
    const oldTermIndex = termList.indexOf(planOfStudy.startTerm);
    const termDifference = newTermIndex - oldTermIndex;
    let termChangeWarning = '';

    if (newTermIndex === -1) {
      return termChangeWarning;
    }

    if (termDifference !== 0) {
      if (termDifference > 0) {
        // starting term changed to later in the year
        for (let i = oldTermIndex; i < newTermIndex; i++) {
          if (i > oldTermIndex) {
            if (i === newTermIndex - 1) {
              if (i > oldTermIndex + 2) {
                termChangeWarning += ', and ';
              } else {
                termChangeWarning += ' and ';
              }
            } else {
              termChangeWarning += ', ';
            }
          }
          termChangeWarning += `${termList[i]}`;
        }
        termChangeWarning +=
          ' in academic year 1 will be removed along with any courses.';
      } else {
        for (let i = newTermIndex; i < oldTermIndex; i++) {
          if (i > newTermIndex) {
            if (i === oldTermIndex - 1) {
              if (i > newTermIndex + 2) {
                termChangeWarning += ', and ';
              } else {
                termChangeWarning += ' and ';
              }
            } else {
              termChangeWarning += ', ';
            }
          }
          termChangeWarning += `${termList[i]}`;
        }
        termChangeWarning += ` will be added before ${termList[oldTermIndex]} in academic year 1.`;
      }
    }

    return termChangeWarning;
  }

  validateFormInput() {
    this.setState({
      isFormInputValid: Object.keys(this.formValues).reduce((result, next) => {
        let theResult =
          result &&
          this.formValues[next].validatorFunc(this.state[next]).isValid;
        return theResult;
      }, true)
    });
  }

  closePanel(shouldSendFocus) {
    this.props.hideSidePanel({
      sendFocus: !!shouldSendFocus
    });
    this.props.clearUpdatePlanOfStudyError();
  }
}

const updatingSelector = createLoadingSelector([UPDATE_PLAN_OF_STUDY]);
const archivingSelector = createLoadingSelector([ARCHIVE_PLAN_OF_STUDY]);
const errorMessageSelector = createErrorMessageSelector([
  UPDATE_PLAN_OF_STUDY,
  ARCHIVE_PLAN_OF_STUDY
]);
const mapStateToProps = state => {
  const { config, programs } = state;
  return {
    updating: updatingSelector(state),
    archiving: archivingSelector(state),
    errorMessage: errorMessageSelector(state),
    programList: programs.list,
    trackList: config.config && config.config.tracks,
    termList: config.termsByAcademicYear
  };
};

export default connect(mapStateToProps, {
  updatePlanOfStudy,
  archivePlanOfStudy,
  hideSidePanel,
  updateTrapFocus,
  clearUpdatePlanOfStudyError
})(withRouter(UpdatePlanOfStudy));
