import { GET_CONFIG } from '../types';
import { getConfigEndpoint } from '../../utils/endpoints';
import requestWrapper from '../requestWrapper';

export const getConfig = () => async dispatch => {
  requestWrapper(dispatch, {
    actionType: GET_CONFIG,
    requestObject: {
      url: getConfigEndpoint()
    },
    onlyUrlRequest: true,
    errorMessage: 'Error retrieving config'
  });
};
