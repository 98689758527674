import React from 'react';
import { withRouter, Route } from 'react-router-dom';
import StudentGroupListView from './StudentGroupListView';
import StudentGroupView from './StudentGroupView';

const StudentGroupPage = props => {
  const { match } = props;
  return (
    <>
      <Route exact path={match.path} component={StudentGroupListView} />
      <Route
        path={match.path + '/:studentGroupId'}
        component={StudentGroupView}
      />
    </>
  );
};

export default withRouter(StudentGroupPage);
