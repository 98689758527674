import {
  SHOW_SIDE_PANEL,
  HIDE_SIDE_PANEL,
  CLEAR_SIDE_PANEL_CONTENT,
  UPDATE_TRAP_FOCUS_PANEL
} from '../types';

export const showSidePanel = (
  content,
  activatingElement,
  keyActivated
) => dispatch => {
  dispatch({
    type: SHOW_SIDE_PANEL,
    payload: {
      content,
      activatingElement,
      keyActivated
    }
  });
};

export const hideSidePanel = options => dispatch => {
  dispatch({
    type: HIDE_SIDE_PANEL,
    payload: {
      sendFocus: options.sendFocus
    }
  });

  setTimeout(() => {
    dispatch({
      type: CLEAR_SIDE_PANEL_CONTENT
    });
  }, 500);
};

export const updateTrapFocus = () => dispatch => {
  dispatch({
    type: UPDATE_TRAP_FOCUS_PANEL
  });
};
