import {
  GET_FACULTY_MEMBERS,
  ADD_FACULTY_MEMBER,
  GET_FACULTY_MEMBER,
  UPDATE_FACULTY_MEMBER,
  GET_FACULTY_MEMBER_WORKPLAN,
  PUT_WORKPLAN_CURRYEAR,
  GET_WORKPLAN_SCHEDULES,
  SET_WORKPLAN_SECTION_CONFIRMATION_STATUS,
  UPDATE_ASSIGNED_COURSE_SECTION,
  DELETE_SECTION_PLACEHOLDER,
  ADD_SECTION_PLACEHOLDER,
  SET_TEACHING_EFFORT_TOTALS,
  DELETE_PROSPECTIVE_COURSE,
  ADD_PROSPECTIVE_COURSE,
  SET_DEFAULT_TEACHING_EFFORT,
  GET_DEFAULT_TEACHING_EFFORT,
  PUT_WORKPLAN_ALLOCATION,
  DELETE_WORK_ACTIVITY,
  UPDATE_WORK_ACTIVITY,
  ADD_WORK_ACTIVITY,
  ADD_OTHER_TEACHING_ITEM,
  DELETE_OTHER_TEACHING_ITEM,
  GET_TEACHING_ASSIGNMENTS,
  GET_WORKPLAN_SCHEDULE_ENROLLMENT,
  GET_PROPOSED_TEACHING,
  GET_FACULTY_MEMBER_NOTES,
  SET_FACULTY_MEMBER_NOTES
} from '../types';
import {
  generateRequestActionTypeName,
  generateSuccessActionTypeName,
  generateFailureActionTypeName,
  generateClearItemActionTypeName
} from '../actionNamingMethods';

const initialState = { plannedTeachingEffort: 0, assignedTeachingEffort: 0 };

export default (state = initialState, action) => {
  switch (action.type) {
    case generateSuccessActionTypeName(GET_FACULTY_MEMBERS):
      return {
        ...state,
        list: action.payload.facultyMembers,
        workPlanSummary: action.payload.workPlanSummary
      };

    case generateRequestActionTypeName(GET_FACULTY_MEMBER):
      return {
        ...state,
        viewIndividual: undefined
      };

    case generateSuccessActionTypeName(GET_FACULTY_MEMBER):
      return {
        ...state,
        viewIndividual: action.payload
      };

    case generateClearItemActionTypeName(GET_FACULTY_MEMBER):
      return {
        ...state,
        viewIndividual: undefined
      };

    case generateSuccessActionTypeName(ADD_FACULTY_MEMBER):
      action.payload.isNew = true;
      return {
        ...state,
        adding: false,
        list: [action.payload].concat(state.list),
        addError: '',
        newest: action.payload
      };

    case generateRequestActionTypeName(GET_FACULTY_MEMBER_NOTES):
      return {
        ...state,
        facultyMemberNotes: undefined
      };

    case generateSuccessActionTypeName(GET_FACULTY_MEMBER_NOTES):
      return {
        ...state,
        facultyMemberNotes: action.payload
      };

    case generateSuccessActionTypeName(SET_FACULTY_MEMBER_NOTES):
      return {
        ...state,
        updating: false,
        facultyMemberNotes: action.payload
      };

    case generateSuccessActionTypeName(UPDATE_FACULTY_MEMBER):
      action.payload.isNew = true;
      return {
        ...state,
        updating: false,
        updateError: '',
        viewIndividual: {
          ...state.viewIndividual,
          ...action.payload
        },
        list: state.list.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              ...action.payload
            };
          }
          return item;
        })
      };

    case generateRequestActionTypeName(GET_FACULTY_MEMBER_WORKPLAN):
      return {
        ...state,
        viewWorkPlan: undefined
      };

    case generateSuccessActionTypeName(GET_FACULTY_MEMBER_WORKPLAN):
      return {
        ...state,
        viewWorkPlan: action.payload
      };

    case generateSuccessActionTypeName(PUT_WORKPLAN_CURRYEAR):
      const { workPlans } = action.payload;
      return {
        ...state,
        viewIndividual: { ...state.viewIndividual, workPlans }
      };

    case generateRequestActionTypeName(GET_WORKPLAN_SCHEDULES):
      return {
        ...state,
        workPlanSchedules: null
      };

    case generateSuccessActionTypeName(GET_WORKPLAN_SCHEDULES):
      return {
        ...state,
        workPlanSchedules: action.payload
      };

    case generateRequestActionTypeName(GET_WORKPLAN_SCHEDULE_ENROLLMENT):
      return {
        ...state,
        workPlanProjectedEnrollment: undefined
      };

    case generateSuccessActionTypeName(GET_WORKPLAN_SCHEDULE_ENROLLMENT):
      return {
        ...state,
        workPlanProjectedEnrollment: action.payload
      };

    case generateRequestActionTypeName(GET_TEACHING_ASSIGNMENTS):
      return {
        ...state,
        teachingAssignments: undefined
      };

    case generateSuccessActionTypeName(GET_TEACHING_ASSIGNMENTS):
      return {
        ...state,
        teachingAssignments: action.payload,
        errorMessage: ''
      };

    case generateRequestActionTypeName(ADD_WORK_ACTIVITY):
      return {
        ...state,
        newActivityAdded: false,
        addWorkActivityError: ''
      };

    case generateSuccessActionTypeName(ADD_WORK_ACTIVITY):
      action.payload.isNew = true;
      let workItems = state.viewWorkPlan.workItems.concat(action.payload);
      return {
        ...state,
        loading: false,
        addingWorkActivity: false,
        newActivityAdded: true,
        viewWorkPlan: { ...state.viewWorkPlan, workItems },
        newWorkActivity: action.payload,
        addWorkActivityError: ''
      };

    case generateFailureActionTypeName(ADD_WORK_ACTIVITY):
      return {
        ...state,
        loading: false,
        newActivityAdded: false,
        addingWorkActivity: false,
        addWorkActivityError: {
          ...state.addWorkActivityError,
          [action.payload]: 'Error adding new work activity'
        }
      };

    case generateRequestActionTypeName(UPDATE_WORK_ACTIVITY):
      return {
        ...state,
        updatingWorkItem: true
      };

    case generateSuccessActionTypeName(UPDATE_WORK_ACTIVITY):
      return {
        ...state,
        viewWorkPlan: {
          ...state.viewWorkPlan,
          workItems: state.viewWorkPlan.workItems.map(item => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                ...action.payload,
                isNew: true
              };
            }
            return item;
          })
        },
        updatingWorkItem: false,
        updateWorkItemError: ''
      };

    case generateSuccessActionTypeName(DELETE_WORK_ACTIVITY):
      return {
        ...state,
        viewWorkPlan: {
          ...state.viewWorkPlan,
          workItems: state.viewWorkPlan.workItems.filter(
            item => item.id !== action.payload.id
          )
        },
        updatingWorkItem: false,
        updateWorkItemError: ''
      };

    case generateRequestActionTypeName(ADD_OTHER_TEACHING_ITEM):
      return {
        ...state,
        newOtherTeachingItemAdded: false,
        addOtherTeachingItemError: ''
      };

    case generateSuccessActionTypeName(ADD_OTHER_TEACHING_ITEM):
      action.payload.isNew = true;
      let otherTeaching = state.viewWorkPlan.otherTeaching.concat(
        action.payload
      );
      return {
        ...state,
        loading: false,
        addingOtherTeachingItem: false,
        newOtherTeachingItemAdded: true,
        viewWorkPlan: { ...state.viewWorkPlan, otherTeaching },
        newOtherTeachingItem: action.payload,
        addOtherTeachingItemError: ''
      };

    case generateFailureActionTypeName(ADD_OTHER_TEACHING_ITEM):
      return {
        ...state,
        loading: false,
        newOtherTeachingItem: false,
        addingOtherTeachingItem: false,
        addOtherTeachingItemError: {
          ...state.addOtherTeachingItemError,
          [action.payload]: 'Error adding new other teaching item'
        }
      };

    case generateSuccessActionTypeName(DELETE_OTHER_TEACHING_ITEM):
      return {
        ...state,
        viewWorkPlan: {
          ...state.viewWorkPlan,
          otherTeaching: state.viewWorkPlan.otherTeaching.filter(
            item => item.id !== action.payload.id
          )
        },
        updatingOtherTeachingItems: false,
        updateOtherTeachingItemError: ''
      };

    case generateSuccessActionTypeName(DELETE_WORK_ACTIVITY):
      return {
        ...state,
        viewWorkPlan: {
          ...state.viewWorkPlan,
          workItems: state.viewWorkPlan.workItems.filter(
            item => item.id !== action.payload.id
          )
        },
        updatingWorkItem: false,
        updateWorkItemError: ''
      };

    case generateSuccessActionTypeName(PUT_WORKPLAN_ALLOCATION):
      let newAllocation = action.payload.allocation;
      return {
        ...state,
        viewWorkPlan: { ...state.viewWorkPlan, allocation: newAllocation }
      };

    case generateSuccessActionTypeName(GET_DEFAULT_TEACHING_EFFORT):
      return {
        ...state,
        defaultTeachingEffort: action.payload
      };

    case generateSuccessActionTypeName(
      SET_WORKPLAN_SECTION_CONFIRMATION_STATUS
    ):
      let updatedTeachingAssignments = [
        ...(state.teachingAssignments || [])
      ].map(assignment => ({
        ...assignment,
        courses: [...(assignment.courses || [])].map(course => ({
          ...course,
          components: [...(course.components || [])].map(component => ({
            ...component,
            sections: [...(component.sections || [])].map(section => {
              if (section.id !== action.payload.sectionId) {
                return section;
              } else {
                return {
                  ...section,
                  confirmed: action.payload.confirmed
                };
              }
            })
          }))
        }))
      }));

      return {
        ...state,
        loading: false,
        updatingAssignedSection: false,
        teachingAssignments: updatedTeachingAssignments
      };

    case generateSuccessActionTypeName(SET_DEFAULT_TEACHING_EFFORT):
      return {
        ...state,
        defaultTeachingEffort: action.payload
      };

    case generateRequestActionTypeName(ADD_PROSPECTIVE_COURSE):
      return {
        ...state,
        newProspectiveCourse: {}
      };

    case generateSuccessActionTypeName(ADD_PROSPECTIVE_COURSE):
      let proposedTeaching = state.viewWorkPlan.proposedTeaching.concat(
        action.payload
      );
      return {
        ...state,
        newProspectiveCourse: action.payload,
        viewWorkPlan: { ...state.viewWorkPlan, proposedTeaching }
      };

    case generateFailureActionTypeName(ADD_PROSPECTIVE_COURSE):
      return {
        ...state,
        newProposedCourse: {}
      };

    case generateRequestActionTypeName(DELETE_PROSPECTIVE_COURSE):
      let removedProposedCoursesArr = state.removedProposedCourses
        ? [...state.removedProposedCourses]
        : [];
      return {
        ...state,
        removedProposedCourses: removedProposedCoursesArr
      };

    case generateSuccessActionTypeName(DELETE_PROSPECTIVE_COURSE):
      let trimProposedCourses = [...state.viewWorkPlan.proposedTeaching];
      trimProposedCourses.splice(
        trimProposedCourses.findIndex(
          x =>
            x.courseId === action.payload.courseId &&
            x.term === action.payload.term
        ),
        1
      );
      return {
        ...state,
        removedCourses: [action.payload].concat(state.removedCourses),
        removedProposedCourses: [action.payload].concat(
          state.removedProposedCourses
        ),
        viewWorkPlan: {
          ...state.viewWorkPlan,
          proposedTeaching: trimProposedCourses
        }
      };

    case generateFailureActionTypeName(DELETE_PROSPECTIVE_COURSE):
      return {
        ...state,
        removedProposedCourses: [...state.removedProposedCourses],
        deleteProposedCourseError: action.payload
      };

    case generateRequestActionTypeName(SET_TEACHING_EFFORT_TOTALS):
      return {
        ...state,
        plannedTeachingEffort: 0,
        assignedTeachingEffort: 0
      };

    case generateSuccessActionTypeName(SET_TEACHING_EFFORT_TOTALS):
      let assigned = action.payload.reduce(
        (result, next) => parseFloat(result) + parseFloat(next.assigned),
        0
      );
      let planned = action.payload.reduce(
        (result, next) => parseFloat(result) + parseFloat(next.planned),
        0
      );

      return {
        ...state,
        plannedTeachingEffort: planned,
        assignedTeachingEffort: assigned
      };

    case generateFailureActionTypeName(SET_TEACHING_EFFORT_TOTALS):
      return {
        ...state,
        plannedTeachingEffort: state.plannedTeachingEffort,
        assignedTeachingEffort: state.assignedTeachingEffort
      };

    case generateSuccessActionTypeName(ADD_SECTION_PLACEHOLDER):
      let proposedTeachingCourses = [...state.viewWorkPlan.proposedTeaching];
      let changedProposedTeaching;
      if (
        proposedTeachingCourses.some(
          c => c.courseId === action.payload.courseId
        )
      ) {
        changedProposedTeaching = proposedTeachingCourses.map(course => {
          if (
            course.courseId === action.payload.courseId &&
            course.term === action.payload.term
          ) {
            return { ...course, sections: action.payload.sections };
          } else {
            return course;
          }
        });
      } else {
        changedProposedTeaching = proposedTeachingCourses.concat(
          action.payload
        );
      }

      return {
        ...state,
        viewWorkPlan: {
          ...state.viewWorkPlan,
          proposedTeaching: changedProposedTeaching
        }
      };

    case generateRequestActionTypeName(DELETE_SECTION_PLACEHOLDER):
      return {
        ...state,
        removedSection: ''
      };

    case generateSuccessActionTypeName(DELETE_SECTION_PLACEHOLDER):
      let proposedCourses = [...state.viewWorkPlan.proposedTeaching];
      let removedProposedTeaching = proposedCourses.map(course => {
        if (
          course.courseId === action.payload.courseId &&
          course.term === action.payload.term
        ) {
          return { ...course, sections: action.payload.sections };
        } else {
          return course;
        }
      });
      return {
        ...state,
        viewWorkPlan: {
          ...state.viewWorkPlan,
          proposedTeaching: removedProposedTeaching
        }
      };

    case generateFailureActionTypeName(DELETE_SECTION_PLACEHOLDER):
      return {
        ...state,
        removedSection: ''
      };

    case generateRequestActionTypeName(UPDATE_ASSIGNED_COURSE_SECTION):
      return {
        ...state,
        updatedSection: undefined
      };

    case generateSuccessActionTypeName(UPDATE_ASSIGNED_COURSE_SECTION):
      let assignedTeachingSections = [
        ...state.viewWorkPlan.assignedCourseSections
      ];
      let changedAssignedSections = [];
      let sectionExists = assignedTeachingSections.some(
        section =>
          section.scheduleSectionId === action.payload.scheduleSectionId
      );

      if (sectionExists) {
        changedAssignedSections = assignedTeachingSections.map(section => {
          if (section.scheduleSectionId === action.payload.scheduleSectionId) {
            return { ...section, actualEffort: action.payload.actualEffort };
          } else {
            return section;
          }
        });
      } else {
        changedAssignedSections = assignedTeachingSections.concat({
          scheduleSectionId: action.payload.scheduleSectionId,
          actualEffort: action.payload.actualEffort
        });
      }

      return {
        ...state,
        updatedSection: action.payload.scheduleSectionId,
        viewWorkPlan: {
          ...state.viewWorkPlan,
          assignedCourseSections: changedAssignedSections
        }
      };

    case generateFailureActionTypeName(UPDATE_ASSIGNED_COURSE_SECTION):
      return {
        ...state,
        updatedSection: undefined
      };

    case generateRequestActionTypeName(GET_PROPOSED_TEACHING):
      return {
        ...state,
        proposedTeaching: undefined
      };

    case generateSuccessActionTypeName(GET_PROPOSED_TEACHING):
      return {
        ...state,
        proposedTeaching: action.payload
      };

    default:
      return state;
  }
};
