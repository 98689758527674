import React from 'react';
import './UpdateTermCourses.scss';
import { connect } from 'react-redux';
import { putPlanOfStudyTerms } from '../../service/actions/plansOfStudy';
import { showLoadingModal, hideModal } from '../../service/actions/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import Checkbox from '../common/Checkbox';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import { PUT_PLAN_OF_STUDY_TERMS } from '../../service/types';

class UpdateTermCourses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCourses: []
    };
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  componentDidMount() {
    this.populateLocalTermCourseSelections();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updating !== this.props.updating) {
      if (this.props.updating) {
        this.props.showLoadingModal();
      } else {
        this.props.hideModal();

        if (!this.props.errorMessage) {
          this.props.hideFormFunc();
        }
      }
    } else if (prevProps.planOfStudy !== this.props.planOfStudy) {
      if (this.props.planOfStudy) {
        this.populateLocalTermCourseSelections();
      }
    }
  }

  populateLocalTermCourseSelections() {
    const { term } = this.props;
    let courseIds = [];
    if (term.courseIds && term.courseIds.length) {
      courseIds = [].concat(term.courseIds);
    }
    this.setState({
      selectedCourses: courseIds
    });
  }

  render() {
    const { errorMessage } = this.props;
    return (
      <form id="update-term-courses-form">
        <fieldset>{this.renderCourseList()}</fieldset>

        {errorMessage && (
          <div className="error-message plans-of-study-error-message">
            <FontAwesomeIcon icon={faExclamationCircle} />
            {errorMessage}
          </div>
        )}

        <div className="buttons">
          <button
            className="cancel-save-courses-button"
            onClick={() => this.cancelUpdate()}
          >
            Cancel
          </button>
          <button
            className="save-courses-button"
            onClick={event => this.updateTermCourses(event)}
          >
            Save Courses
            <FontAwesomeIcon icon={faCheckCircle} />
          </button>
        </div>
      </form>
    );
  }

  renderCourseList() {
    const { term, courseList } = this.props;

    if (!term || !courseList) {
      return null;
    }
    const noArchivedCourses = courseList.filter(
      c => term.courseIds.includes(c.id) || !c.archived
    );

    return (
      <ul className="none interactive course-list">
        {[]
          .concat(noArchivedCourses)
          .sort((a, b) => {
            if (a.isNew > b.isNew) return 1;
            if (a.isNew < b.isNew) return -1;
            return ('' + a.courseNumber).localeCompare(b.courseNumber);
          })
          .map((course, index) => {
            const courseId = course.id;
            return (
              <Checkbox
                key={index}
                domId={courseId + '_' + index}
                label={courseId}
                id={{ courseId: courseId }}
                value={this.isSelected(courseId)}
                controlFunc={this.handleCheckboxChange}
                hiddenLabel={true}
                liClassName="course"
              >
                <div className="content">
                  <span className="course-number">
                    {course.courseNumber.replace(/(\d)/, ' $1')}
                  </span>
                  <span className="course-name">
                    {course.name}
                    <span className="credit-hours">
                      {`(${
                        course.variableCredit ? 'Variable' : course.credits
                      }\u00A0CR)`}
                    </span>
                  </span>
                </div>
              </Checkbox>
            );
          })}
      </ul>
    );
  }

  updateTermCourses(event) {
    event.preventDefault();

    const { term, planOfStudy, putPlanOfStudyTerms } = this.props;
    const { selectedCourses } = this.state;

    let termIndex = planOfStudy.terms.findIndex(element => {
      return term.year === element.year && term.term === element.term;
    });
    let arrayCopy = [...planOfStudy.terms];
    arrayCopy.splice(termIndex, 1);
    arrayCopy.push({
      ...term,
      courseIds: selectedCourses
    });

    putPlanOfStudyTerms(planOfStudy.id, arrayCopy);
  }

  cancelUpdate() {
    this.props.hideFormFunc();
  }

  handleCheckboxChange(identifier) {
    const { selectedCourses } = this.state;
    let courseIndex = selectedCourses.findIndex(element => {
      return identifier.courseId === element;
    });
    if (courseIndex >= 0) {
      // unselect
      let arrayCopy = [...selectedCourses];
      arrayCopy.splice(courseIndex, 1);
      this.setState({
        selectedCourses: arrayCopy
      });
    } else {
      // select
      this.setState(state => ({
        selectedCourses: state.selectedCourses.concat([identifier.courseId])
      }));
    }
  }

  isSelected(courseId) {
    const elementIndex = this.state.selectedCourses.findIndex(element => {
      return element === courseId;
    });

    return elementIndex >= 0 ? 'checked' : '';
  }
}

const updatingSelector = createLoadingSelector([PUT_PLAN_OF_STUDY_TERMS]);
const errorMessageSelector = createErrorMessageSelector([
  PUT_PLAN_OF_STUDY_TERMS
]);
const mapStateToProps = state => {
  const { courses, plansOfStudy } = state;
  return {
    updating: updatingSelector(state),
    errorMessage: errorMessageSelector(state),
    planOfStudy: plansOfStudy.viewIndividual,
    courseList: courses.list
  };
};

export default connect(
  mapStateToProps,
  {
    putPlanOfStudyTerms,
    showLoadingModal,
    hideModal
  }
)(UpdateTermCourses);
