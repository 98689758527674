import React from 'react';
import PropTypes from 'prop-types';
import './ToggleSlider.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';

const ToggleSlider = props => {
  const returnClassName = () => {
    if (props.value) {
      if (props.confirmToggle) {
        return 'toggle confirmed';
      } else {
        return 'toggle on';
      }
    } else {
      if (props.confirmToggle) {
        return 'toggle unconfirmed';
      } else {
        return 'toggle off';
      }
    }
  };

  return (
    <div className={'toggle-slider ' + (props.disabled ? 'disabled ' : '')}>
      {props.leftLabel && (
        <span className="toggle-label">{props.leftLabel}</span>
      )}
      <div
        className={returnClassName()}
        tabIndex={props.disabled ? '-1' : '0'}
        onClick={
          props.disabled ? undefined : () => props.controlFunc(!props.value)
        }
        onKeyDown={
          props.disabled
            ? undefined
            : event => {
                if (event.key === 'Enter' || event.key === ' ') {
                  event.preventDefault();
                  props.controlFunc(!props.value);
                }
              }
        }
      >
        <div className="circle">
          {props.value ? (
            <FontAwesomeIcon icon={faCheck} />
          ) : (
            <FontAwesomeIcon
              icon={props.confirmToggle ? faExclamationTriangle : faTimes}
            />
          )}
        </div>
      </div>
      {props.rightLabel && (
        <span className="toggle-label">{props.rightLabel}</span>
      )}
    </div>
  );
};

ToggleSlider.propTypes = {
  controlFunc: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  disabled: PropTypes.bool
};

ToggleSlider.defaultProps = {
  disabled: false
};

export default ToggleSlider;
