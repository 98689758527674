import React from 'react';
import PropTypes from 'prop-types';
import Input from '../common/Input';
import { effortValidatorFunc } from './helpers';

const CourseEffort = props => {
  const { course } = props;

  const handleValueChange = (componentType, defaultEffort) => {
    props.controlFunc({
      courseId: props.course.id,
      componentType,
      defaultEffort
    });
  };

  const effortFormValue = {
    name: 'effort-input',
    idSuffix: course.id,
    valueId: 'defaultEffort',
    labelTitle: 'Default Effort',
    validatorFunc: value => {
      const isValid = effortValidatorFunc(value);
      return {
        isValid,
        errorMessage: 'Invalid effort'
      };
    }
  };

  return (
    <li className="course-effort course">
      <div className="course-details content">
        <span className="course-number">
          {course.courseNumber.replace(/(\d)/, ' $1')}
        </span>
        <span className="course-name">
          {course.name}
          <span className="credit-hours">
            ({course.variableCredit ? 'Variable' : course.credits}
            {'\u00A0'}CR)
          </span>
        </span>
      </div>
      <div className="components">
        {course.components.map(componentType => {
          const { component, defaultEffort } = componentType;
          return (
            <div className="component" key={component.type}>
              <div className={`label component-${component.index}-bg`}>
                {component.name}
              </div>
              <Input
                formValue={{
                  ...effortFormValue,
                  defaultValue: defaultEffort || undefined
                }}
                idSuffix={effortFormValue.idSuffix + `-${component.type}`}
                controlFunc={obj =>
                  handleValueChange(component.type, obj.value)
                }
                immediatelyValidate={true}
                hideLabel={true}
              />
            </div>
          );
        })}
      </div>
    </li>
  );
};

CourseEffort.propTypes = {
  course: PropTypes.object.isRequired,
  controlFunc: PropTypes.func.isRequired
};

export default CourseEffort;
