import React from 'react';
import { SHOW_MODAL, HIDE_MODAL, RESET_ACTIVITY_COUNTER } from '../types';
import loadingSpinner from '../../assets/images/spinner.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserClock } from '@fortawesome/free-solid-svg-icons/faUserClock';
import { faDoorClosed } from '@fortawesome/free-solid-svg-icons/faDoorClosed';
import { signOut, refreshToken } from '../../service/actions/auth';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import updatePageTitle from '../../components/common/helpers/updatePageTitle';

export const showLoadingModal = () => dispatch => {
  dispatch({
    type: SHOW_MODAL,
    payload: (
      <>
        <img alt="Loading..." src={loadingSpinner} />
        Loading...
      </>
    )
  });
};

export const showCustomLoadingModal = text => dispatch => {
  dispatch({
    type: SHOW_MODAL,
    payload: (
      <>
        <img alt={text} src={loadingSpinner} />
        {text}
      </>
    )
  });
};

export const showTimeoutModal = () => dispatch => {
  document.body.classList.add('noscroll');

  let startingTitle = document.title;
  let secondsToShowModal = window.Cypress ? 3 : 600;

  let localTimer = setTimeout(() => {
    logout(document.createEvent('Event'));
  }, secondsToShowModal * 1000);

  let pageTitleInterval = setInterval(() => {
    if (secondsToShowModal < 120) {
      if (secondsToShowModal % 2 === 0) {
        updatePageTitle(`${secondsToShowModal} seconds`);
      } else {
        updatePageTitle('!! TIMEOUT !!');
      }
    } else {
      if (secondsToShowModal % 4 === 0) {
        updatePageTitle('... ... ');
      } else {
        updatePageTitle('Logging out soon');
      }
    }
    secondsToShowModal--;
  }, 1000);

  const logout = event => {
    event.preventDefault();
    signOut();
  };

  const stayLoggedIn = event => {
    event.preventDefault();
    clearTimeout(localTimer);
    clearInterval(pageTitleInterval);
    document.title = startingTitle;
    refreshToken();
    document.body.classList.remove('noscroll');
    dispatch({
      type: HIDE_MODAL
    });
    dispatch({
      type: RESET_ACTIVITY_COUNTER
    });
  };

  dispatch({
    type: SHOW_MODAL,
    options: {
      trapFocus: true
    },
    payload: (
      <div className="timeout-modal">
        <div className="top">
          <FontAwesomeIcon icon={faUserClock} />
        </div>
        <p>Your session is about to expire. You may lose any unsaved work.</p>
        <p>Do you want to stay logged in?</p>
        <div className="row">
          <button
            onClick={event => logout(event)}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                logout(event);
              }
            }}
          >
            Log Out
            <FontAwesomeIcon icon={faDoorClosed} />
          </button>
          <button
            onClick={event => stayLoggedIn(event)}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                stayLoggedIn(event);
              }
            }}
          >
            Stay Logged In
            <FontAwesomeIcon icon={faCheckCircle} />
          </button>
        </div>
      </div>
    )
  });
};

export const showValidatingModal = () => dispatch => {
  dispatch({
    type: SHOW_MODAL,
    payload: (
      <>
        <img alt="Loading..." src={loadingSpinner} />
        <span id="validation-message">Validating...</span>
      </>
    )
  });
};

export const showArchivingModal = () => dispatch => {
  dispatch({
    type: SHOW_MODAL,
    payload: (
      <>
        <img alt="Archiving..." src={loadingSpinner} />
        <span id="validation-message">Archiving...</span>
      </>
    )
  });
};

export const showCustomJSXModal = customJSX => dispatch => {
  dispatch({
    type: SHOW_MODAL,
    payload: customJSX
  });
};

export const hideModal = () => dispatch => {
  dispatch({
    type: HIDE_MODAL
  });
};
