import React from 'react';
import './FacultyMemberList.scss';
import { connect } from 'react-redux';
import {
  getFacultyMembers,
  getFacultyColor
} from '../../service/actions/faculty';
import { showLoadingModal, hideModal } from '../../service/actions/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons/faFileDownload';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons/faChevronCircleRight';
import { withRouter } from 'react-router-dom';
import AddFacultyMember from './AddFacultyMember';
import ExportWorkAssignments from './FacultyMemberExport';
import RolloverForm from './RolloverForm';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import FormReveal from '../common/FormReveal';
import {
  GET_CONFIG,
  GET_FACULTY_MEMBERS,
  ADD_FACULTY_MEMBER,
  EXPORT_WORK_ASSIGNMENTS,
  PUT_ALL_WORKPLANS_CURRYEAR
} from '../../service/types';
import ViewSelector from '../common/ViewSelector';
import { ValidationMessage } from '../common/Validation/validationFunctionComponent';
import { mainViewComponent } from '../common/HOCs';

const VIEW_STORAGE_FACULTY_ROOT = 'VIEW_FACULTY_LIST';

class FacultyMemberListView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleForm: 'AddFacultyForm',
      resetting: false,
      showAddFacultyForm: false,
      filter: ''
    };
    this.baseState = { ...this.state };
    this.showExportFormRevealRef = React.createRef();
    this.showAddFacultyFormRevealRef = React.createRef();
    this.showRolloverFormRevealRef = React.createRef();

    if (!localStorage.getItem(VIEW_STORAGE_FACULTY_ROOT)) {
      localStorage.setItem(VIEW_STORAGE_FACULTY_ROOT, 'active');
    }
  }

  componentDidMount() {
    this.props.getFacultyMembers();

    if (this.props.facultyMemberList) {
      this.augmentFacultyList();
    }

    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.facultyMemberList !== this.props.facultyMemberList) {
      if (this.props.facultyMemberList) {
        this.augmentFacultyList();
      }
    }
    if (prevProps.ranks !== this.props.ranks) {
      if (this.props.ranks) {
        this.augmentFacultyList();
      }
    }
    if (this.state.visibleForm !== prevState.visibleForm) {
      if (this.state.visibleForm) {
        this[
          'show' + this.state.visibleForm + 'RevealRef'
        ].current.handleOpenClick(document.createEvent('Event'));
      }
    }
    if (prevProps.addingFacultyMember !== this.props.addingFacultyMember) {
      if (!this.props.addingFacultyMember) {
        if (!this.props.addingError) {
          this.showAddFacultyFormRevealRef.current.handleSubmitClick(
            document.createEvent('Event')
          );
          this.showAddFacultyFormRevealRef.current.handleSubmitClose(
            document.createEvent('Event')
          );
          this.props.getFacultyMembers();
          if (this.props.facultyMemberList) {
            this.augmentFacultyList();
          }
        }
      }
    }
    if (prevProps.exporting !== this.props.exporting) {
      if (!this.props.exporting) {
        if (!this.props.exportingError) {
          this.showExportFormRevealRef.current.handleSubmitClick(
            document.createEvent('Event')
          );
          this.showExportFormRevealRef.current.handleSubmitClose(
            document.createEvent('Event')
          );
        }
      }
    }
    if (prevProps.rollingOverWorkPlans !== this.props.rollingOverWorkPlans) {
      if (!this.props.rollingOverWorkPlans) {
        if (!this.props.rolloverError) {
          setTimeout(() => {
            this.props.hideModal();
            this.showRolloverFormRevealRef.current.handleSubmitClick(
              document.createEvent('Event')
            );
            this.props.getFacultyMembers();
            if (this.props.facultyMemberList) {
              this.augmentFacultyList();
            }
          }, 1800);
        }
      }
    }
  }

  render() {
    const {
      loading,
      errorMessage,
      scopes,
      currentAcademicYear,
      facultyMembersAreAligned,
      facultyMemberList
    } = this.props;

    return (
      <div>
        <div className="faculty-header">
          <div>
            <h1 className="no-bar">Faculty Members</h1>
            {currentAcademicYear && (
              <p>
                Current Work Plan Academic Year:{' '}
                {`${currentAcademicYear}-${currentAcademicYear + 1}`}
              </p>
            )}
          </div>
        </div>
        {!facultyMembersAreAligned &&
          facultyMemberList &&
          facultyMemberList.length !== 0 &&
          ValidationMessage({
            message: `One or more faculty workplans are in different academic years than current: ${currentAcademicYear}-${currentAcademicYear +
              1}.`,
            type: 'error'
          })}
        {loading ? null : (
          <>
            {errorMessage && (
              <div className="error-message faculty-members-error-message">
                <FontAwesomeIcon icon={faExclamationCircle} />
                {errorMessage}
              </div>
            )}
            <div className="faculty-list-actions-wrapper">
              {!errorMessage && this.renderFacultyForms()}
            </div>
            {!errorMessage &&
              scopes.includes('faculty-member.read') &&
              this.renderFacultyMemberList()}
          </>
        )}
      </div>
    );
  }

  renderExportForm() {
    return (
      <ExportWorkAssignments
        handleCancelClick={(name, event) => this.handleCancelClick(name, event)}
      />
    );
  }

  renderAddFacultyForm() {
    return (
      <AddFacultyMember
        handleCancelClick={(name, event) => this.handleCancelClick(name, event)}
      />
    );
  }

  renderRolloverForm() {
    return (
      <RolloverForm
        handleCancelClick={(name, event) => this.handleCancelClick(name, event)}
      />
    );
  }

  renderFacultyForms() {
    const { visibleForm } = this.state;
    let form =
      visibleForm === 'AddFacultyForm' ? (
        this.renderAddFacultyForm()
      ) : visibleForm === 'ExportForm' ? (
        this.renderExportForm()
      ) : visibleForm === 'RolloverForm' ? (
        this.renderRolloverForm()
      ) : (
        <></>
      );
    const { scopes } = this.props;

    let formJSX = (
      <div className="faculty-form-buttons-wrapper">
        {scopes.includes('faculty-member.write') && (
          <button
            id="add-faculty-member-entry"
            onClick={event => this.showForm('AddFacultyForm', event)}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                this.showForm('AddFacultyForm', event);
              }
            }}
          >
            Add a Faculty Member <FontAwesomeIcon icon={faPlusCircle} />
          </button>
        )}
        {scopes.includes('faculty-member.work-plan.read') && (
          <button
            id="show-faculty-member-export"
            onClick={event => this.showForm('ExportForm', event)}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                this.showForm('ExportForm', event);
              }
            }}
          >
            Export Work Assignments
            <FontAwesomeIcon icon={faFileDownload} />
          </button>
        )}
        {scopes.includes('faculty-member.work-plan.write') && (
          <button
            id="show-faculty-member-rollover"
            onClick={event => this.showForm('RolloverForm', event)}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                this.showForm('RolloverForm', event);
              }
            }}
          >
            Rollover Work Plans
            <FontAwesomeIcon icon={faChevronCircleRight} />
          </button>
        )}
      </div>
    );

    return (
      <FormReveal
        hideButton
        parentShowForm={this.state[`show${visibleForm}`]}
        parentShowFormSendFocus={this.state[`show${visibleForm}SendFocus`]}
        form={form}
        hideRevealJsx={formJSX}
        submitFunc={() => this.resetForm()}
        cancelFunc={() => this.resetForm()}
        ref={this[`show${visibleForm}RevealRef`]}
      />
    );
  }

  renderFacultyMemberList() {
    const { filter, augmentedFacultyList } = this.state;
    if (augmentedFacultyList && augmentedFacultyList.length) {
      let filteredFacultyMemberList = this.filterFacultyMembers();

      const viewTuples = [
        {
          label: 'Active',
          id: 'active'
        },
        {
          label: 'Inactive',
          id: 'inactive'
        }
      ];

      return (
        <div className="faculty-members-results">
          <label htmlFor="faculty-member-search-input">Search</label>
          <input
            id="faculty-member-search-input"
            className="search-bar"
            type="text"
            value={filter}
            onChange={event => this.handleInputChange(event)}
          />
          <ViewSelector
            viewTuples={viewTuples}
            useLocalStorage={true}
            storageRoot={VIEW_STORAGE_FACULTY_ROOT}
            callbackFunction={() =>
              this.setState({
                viewUpdated: ((this.state && this.state.viewUpdated) || 0) + 1
              })
            }
          />
          {filteredFacultyMemberList.length ? (
            this.buildFacultyMemberListJsx(filteredFacultyMemberList)
          ) : (
            <p id="no-faculty-message">No faculty members match the criteria</p>
          )}
        </div>
      );
    } else {
      return (
        <p className="empty no-faculty-members-message">
          No faculty members have been added.
        </p>
      );
    }
  }

  showForm(formName, event) {
    let formSendFocus = false;
    if (event) {
      formSendFocus = true;
    }
    this.setState(
      {
        visibleForm: formName,
        [`show${formName}`]: true,
        [`show${formName}SendFocus`]: formSendFocus
      },
      () =>
        this[`show${formName}RevealRef`].current.handleOpenClick(
          document.createEvent('Event')
        )
    );
  }

  handleCancelClick(formName, event) {
    event.preventDefault();
    this[`show${formName}RevealRef`].current.handleCancelClick(event);
  }

  resetForm() {
    let { visibleForm } = this.state;
    let newState = {
      visibleForm: undefined,
      [`show${visibleForm}`]: false,
      [`show${visibleForm}SendFocus`]: false,
      resetting: true
    };
    this.setState(newState);
  }

  handleInputChange(event) {
    this.setState({
      filter: event.target.value
    });
  }

  buildFacultyMemberListJsx(filteredFacultyMemberList) {
    const { currentAcademicYear } = this.props;
    return (
      <ul className="interactive none">
        {filteredFacultyMemberList
          .sort((a, b) => {
            return ('' + a.lastName).localeCompare(b.lastName);
          })
          .map(faculty => {
            const facultyColor = getFacultyColor(faculty);

            let rankDeptText = faculty.rankName;
            if (rankDeptText && faculty.departmentName) {
              rankDeptText += `, ${faculty.departmentName}`;
            } else if (faculty.departmentName) {
              rankDeptText = faculty.departmentName;
            }

            return (
              <li key={faculty.id} className="with-anchor faculty-member">
                <a
                  className={faculty.isNew ? 'new ' : ''}
                  href={this.props.match.path + '/' + faculty.id}
                  onClick={event =>
                    this.facultyClicked(event, faculty.id, false)
                  }
                  onKeyDown={event => this.facultyKeyDowned(event, faculty.id)}
                >
                  <div className="content">
                    {faculty.currentWorkPlanYear &&
                      faculty.currentWorkPlanYear !== currentAcademicYear && (
                        <div className={'error flag'}>
                          <FontAwesomeIcon icon={faExclamationCircle} />
                        </div>
                      )}
                    <div
                      className={'initials circle-badge ' + facultyColor}
                      aria-hidden={true}
                    >
                      {faculty.firstName.charAt(0) + faculty.lastName.charAt(0)}
                    </div>
                    <div className={'name'}>
                      {faculty.lastName + ', ' + faculty.firstName}
                    </div>
                    <div className={'appointment'}>
                      {faculty.appointmentId && faculty.appointmentName}
                    </div>
                    <div className={'rank-dept'}>{rankDeptText}</div>
                    <div className={'employee-id'}>{faculty.employeeId}</div>
                  </div>
                  <FontAwesomeIcon icon={faChevronCircleRight} />
                </a>
              </li>
            );
          })}
      </ul>
    );
  }

  augmentFacultyList() {
    const { facultyMemberList, ranks, departments, appointments } = this.props;
    if (!ranks || !departments || !appointments || !facultyMemberList) {
      return;
    }

    this.setState({
      augmentedFacultyList: facultyMemberList.map(faculty => {
        let augmentedFaculty = { ...faculty };

        let matchingRank = ranks.find(rank => {
          return rank.id === faculty.rankId;
        });
        augmentedFaculty.rankName = matchingRank ? matchingRank.name : '';

        let matchingDepartment = departments.find(dept => {
          return dept.id === faculty.departmentId;
        });
        augmentedFaculty.departmentName = matchingDepartment
          ? matchingDepartment.name
          : '';

        let matchingAppointment = appointments.find(appt => {
          return appt.id === faculty.appointmentId;
        });
        augmentedFaculty.appointmentName = matchingAppointment
          ? matchingAppointment.name
          : '';

        return augmentedFaculty;
      })
    });
  }

  facultyKeyDowned(event, id) {
    if (event.key === 'Enter') {
      this.facultyClicked(event, id, true);
    }
  }

  facultyClicked(event, id, keyed) {
    event.preventDefault();
    const pathname = this.props.match.path + '/' + id;
    if (event.ctrlKey || event.metaKey) {
      window.open(this.props.history.createHref({ pathname }));
    } else {
      this.props.history.push({
        pathname,
        state: {
          keyed
        }
      });
    }
  }

  filterFacultyMembers() {
    const { filter, augmentedFacultyList } = this.state;

    const showOnlyInactive =
      localStorage.getItem(VIEW_STORAGE_FACULTY_ROOT) === 'inactive';
    const showOnlyActive =
      localStorage.getItem(VIEW_STORAGE_FACULTY_ROOT) === 'active';

    return [].concat(augmentedFacultyList).filter(val => {
      if (showOnlyActive && val.active === false) {
        return false;
      }
      if (showOnlyInactive && val.active === true) {
        return false;
      }
      if (filter) {
        return this.containsFilter(val);
      }
      return true;
    });
  }

  containsFilter(valueIn) {
    const filterValues = this.state.filter.split(' ');
    let availableTokens = Object.keys(valueIn).reduce((result, next) => {
      if (
        typeof valueIn[next] !== 'string' ||
        (next.indexOf('Id') >= 0 && next !== 'employeeId')
      ) {
        return result;
      }
      return result.concat(valueIn[next].split(' '));
    }, []);

    return filterValues.reduce((result, next) => {
      let modifiedFilterValue = next.replace(/\s+/g, '').toUpperCase();
      let exists = false;
      availableTokens.forEach((token, index) => {
        if (
          ('' + token)
            .replace(/\s+/g, '')
            .toUpperCase()
            .indexOf(modifiedFilterValue) >= 0
        ) {
          exists = true;
          availableTokens.splice(index, 1);
        }
      });
      return result && exists;
    }, true);
  }
}

const loadingSelector = createLoadingSelector([
  GET_FACULTY_MEMBERS,
  GET_CONFIG
]);
const addingSelector = createLoadingSelector([ADD_FACULTY_MEMBER]);
const addingErrorSelector = createErrorMessageSelector([ADD_FACULTY_MEMBER]);
const rolloverSelector = createLoadingSelector([PUT_ALL_WORKPLANS_CURRYEAR]);
const rolloverErrorSelector = createErrorMessageSelector([
  PUT_ALL_WORKPLANS_CURRYEAR
]);
const errorSelector = createErrorMessageSelector([GET_FACULTY_MEMBERS]);
const exportingSelector = createLoadingSelector([EXPORT_WORK_ASSIGNMENTS]);
const exportingErrorSelector = createErrorMessageSelector([
  EXPORT_WORK_ASSIGNMENTS
]);
const mapStateToProps = state => {
  const { auth, config, faculty } = state;
  return {
    scopes: auth.scopes,
    loading: loadingSelector(state),
    addingFacultyMember: addingSelector(state),
    addingError: addingErrorSelector(state),
    rollingOverWorkPlans: rolloverSelector(state),
    rolloverError: rolloverErrorSelector(state),
    facultyMembersAreAligned: faculty.workPlanSummary?.facultyMembersAreAligned,
    exporting: exportingSelector(state),
    exportingError: exportingErrorSelector(state),
    errorMessage: errorSelector(state),
    departments: config.config && config.config.departments,
    appointments: config.config && config.config.facultyMemberAppointments,
    ranks: config.config && config.config.facultyMemberRanks,
    facultyMemberList: faculty?.list,
    currentAcademicYear: faculty.workPlanSummary?.currentYear
  };
};

const mainViewConfig = {
  pageTitle: 'Faculty Members'
};

export default connect(mapStateToProps, {
  getFacultyMembers,
  showLoadingModal,
  hideModal
})(
  withRouter(
    mainViewComponent({ component: FacultyMemberListView, mainViewConfig })
  )
);
