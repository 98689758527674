import React from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';
import { connect } from 'react-redux';

class SelectFaculty extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valueMappings: new Map(),
      reverseValueMappings: new Map(),
      strippedOptionList: []
    };

    this.controlFunc = this.controlFunc.bind(this);
  }

  componentDidMount() {
    if (this.props.facultyList) {
      this.buildValueMappings();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.facultyList !== this.props.facultyList) {
      this.buildValueMappings();
    }
  }

  render() {
    const {
      containerStyle,
      facultyFormValue,
      hideLabel,
      loading,
      value,
      valueLabel,
      disabled,
      actionable
    } = this.props;
    const {
      strippedOptionList,
      strippedTopList,
      reverseValueMappings
    } = this.state;

    let defaultText = facultyFormValue.defaultValue || 'Select';
    if (loading) {
      defaultText = 'Loading...';
    }

    let mappedValue = reverseValueMappings.get(value);
    return (
      <Select
        formValue={facultyFormValue}
        startingValue={valueLabel ? valueLabel : mappedValue}
        controlFunc={this.controlFunc}
        optionList={strippedOptionList}
        topList={strippedTopList}
        topListHeading="Planned Faculty"
        defaultOption={defaultText}
        hideLabel={hideLabel}
        containerStyle={containerStyle}
        disabled={disabled}
        actionable={actionable}
      />
    );
  }

  controlFunc(formValue) {
    const option = this.state.valueMappings.get(formValue.value);
    this.props.controlFunc({
      valueId: this.props.facultyFormValue.valueId,
      value: (option && option.facultyId) || '',
      valueLabel: formValue.value
    });
  }

  buildValueMappings() {
    let valueMappings = new Map();
    let reverseValueMappings = new Map();
    let strippedOptionList = [];
    let strippedTopList = [];
    if (!this.props.facultyList || this.props.proposedTeaching) {
      return null;
    }

    this.props.facultyList.forEach(faculty => {
      if (faculty.id !== this.props.value && faculty.active === false) {
        return;
      }

      const employeeId = faculty.employeeId ? `| ${faculty.employeeId}` : '';
      const facultyName = `${faculty.lastName}, ${faculty.firstName} ${employeeId}`;

      const assignedSections = this.props.courseComponent.sections.filter(
        s => s.facultyMember && s.facultyMember.id === faculty.id
      );

      let [proposedSections] = this.props.proposedTeachingList
        .filter(pt => pt.facultyMemberId === faculty.id)
        .map(c =>
          c.proposedCourses.map(pc => {
            let proposedSection;
            if (pc.courseId === this.props.courseComponent.courseId) {
              proposedSection = pc.sections.find(
                s => s.componentType === this.props.courseComponent.type
              );
            }
            return proposedSection;
          })
        )
        .flat()
        .filter(s => s !== undefined);

      valueMappings.set(facultyName, {
        facultyId: faculty.id
      });
      reverseValueMappings.set(faculty.id, facultyName);
      strippedOptionList.push(facultyName);

      if (proposedSections) {
        const facultyLabel = `${facultyName} | ${
          assignedSections ? assignedSections.length : 0
        } of ${proposedSections.sectionCount} assigned`;
        valueMappings.set(facultyLabel, {
          facultyId: faculty.id
        });
        reverseValueMappings.set(faculty.id, facultyLabel);
        strippedTopList.push(facultyLabel);
      }
    });

    this.setState({
      valueMappings,
      reverseValueMappings,
      strippedOptionList: strippedTopList.concat(strippedOptionList),
      strippedTopList
    });
  }
}

SelectFaculty.propTypes = {
  facultyFormValue: PropTypes.object.isRequired,
  controlFunc: PropTypes.func.isRequired,
  facultyList: PropTypes.array.isRequired,
  proposedTeachingList: PropTypes.array.isRequired,
  courseComponent: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueLabel: PropTypes.string,
  hideLabel: PropTypes.bool,
  containerStyle: PropTypes.object,
  disabled: PropTypes.bool,
  actionable: PropTypes.bool
};

SelectFaculty.defaultProps = {
  disabled: false,
  actionable: false
};

const mapStateToProps = ({ faculty }) => {
  return {
    loading: faculty.loading
  };
};

export default connect(
  mapStateToProps,
  null
)(SelectFaculty);
