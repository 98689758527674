import { GET_CONFIG } from '../types';
import { generateSuccessActionTypeName } from '../actionNamingMethods';

export default (state = {}, action) => {
  switch (action.type) {
    case generateSuccessActionTypeName(GET_CONFIG): {
      const termsByAcademicYear = []
        .concat(action.payload.terms)
        .sort((a, b) => {
          return a.academicYearOrder - b.academicYearOrder;
        })
        .map(term => {
          return term.name;
        });

      const termsByCalendarYear = []
        .concat(action.payload.terms)
        .sort((a, b) => {
          return a.calendarYearOrder - b.calendarYearOrder;
        })
        .map(term => {
          return term.name;
        });
      return {
        ...state,
        loading: false,
        config: action.payload,
        termsByAcademicYear: termsByAcademicYear,
        termsByCalendarYear: termsByCalendarYear,
        errorMessage: ''
      };
    }

    default:
      return state;
  }
};
