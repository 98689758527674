import React from 'react';
import { withRouter, Route } from 'react-router-dom';
import NoMatchPage from '../App/404';
import LocationsList from './LocationsList';

const LocationsPage = props => {
  const { match } = props;
  return (
    <>
      <Route exact path={match.path} component={LocationsList} />
      <Route path={match.path + '/*'} component={NoMatchPage} />
    </>
  );
};

export default withRouter(LocationsPage);
