import React from 'react';
import './PlanOfStudyView.scss';
import { connect } from 'react-redux';
import { getCourses } from '../../service/actions/courses';
import { getPlanOfStudy } from '../../service/actions/plansOfStudy';
import { showSidePanel } from '../../service/actions/sidePanel';
import {
  showLoadingModal,
  hideModal,
  showCustomJSXModal,
  showCustomLoadingModal
} from '../../service/actions/modal';
import { withRouter } from 'react-router-dom';
import UpdateTerms from './UpdateTerms';
import ViewTerms from './ViewTerms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import {
  GET_COURSES,
  GET_PLANS_OF_STUDY,
  GET_PLAN_OF_STUDY,
  GET_CONFIG,
  PUT_PLAN_OF_STUDY_TERMS,
  UPDATE_PLAN_OF_STUDY,
  ARCHIVE_PLAN_OF_STUDY
} from '../../service/types';
import { mainViewComponent } from '../common/HOCs';
import updatePageTitle from '../common/helpers/updatePageTitle';
import UpdatePlanOfStudy from './UpdatePlanOfStudy';

class PlanOfStudyView extends React.Component {
  constructor(props) {
    super(props);
    this.updatePlanOfStudyRef = React.createRef();

    this.state = {
      updatingTerms: false
    };
  }

  componentDidMount() {
    updatePageTitle(`Plan of Study: ${this.props.match.params.planOfStudyId}`);
    this.props.getPlanOfStudy(this.props.match.params.planOfStudyId);
    this.props.getCourses();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updating !== this.props.updating) {
      if (!this.props.updating) {
        this.props.hideModal();
      } else {
        this.props.showCustomLoadingModal('Updating...');
      }
    }

    if (prevProps.archiving !== this.props.archiving) {
      if (this.props.archiving) {
        this.props.showCustomLoadingModal('Archiving...');
      } else {
        if (this.props.updatingErrorMessage) {
          this.props.hideModal();
        } else {
          this.props.showCustomJSXModal(
            <div id="success-message" style={{ textAlign: 'center' }}>
              <FontAwesomeIcon icon={faCheckCircle} />
              Plan of study archived
            </div>
          );

          setTimeout(() => {
            this.props.hideModal();
            this.props.history.push('/plans-of-study');
          }, 1800);
        }
      }
    }
  }

  render() {
    const { planOfStudy, errorMessage } = this.props;
    if (errorMessage) {
      return (
        <div className="plan-of-study-error-message error-message">
          {errorMessage}
        </div>
      );
    }

    if (!planOfStudy) {
      return null;
    }

    return (
      <div className="main plan-of-study-container">
        <div className="top-container">
          <div className="left-bar first-focus">
            <h1 id="program-name" className="no-bar">
              {planOfStudy.program.name}
            </h1>
            {planOfStudy.concentration && (
              <h3 id="concentration-name">{planOfStudy.concentration.name}</h3>
            )}
            <p id="plan-of-study-meta">
              {planOfStudy.track} | Effective {planOfStudy.startYear} | Starts{' '}
              {planOfStudy.startTerm}
            </p>
          </div>
          {this.props.planOfStudyList && this.renderUpdatePlanOfStudy()}
        </div>

        {this.renderTerms()}
      </div>
    );
  }

  renderTerms() {
    const { scopes, planOfStudy } = this.props;
    const { updatingTerms } = this.state;
    const showUpdateTermsForm =
      updatingTerms || !(planOfStudy.terms && planOfStudy.terms.length);
    const totalNumberOfCourses = planOfStudy.terms.reduce((result, next) => {
      if (!next.courseIds) {
        return result;
      }
      return result + next.courseIds.length;
    }, 0);
    return (
      <section>
        <div className="top-container">
          <h1>Plan of Study</h1>
          {!showUpdateTermsForm && scopes.includes('plan-of-study.write') && (
            <button onClick={() => this.setUpdateTermsFormVisibility(true)}>
              Update Terms
              <FontAwesomeIcon icon={faEdit} />
            </button>
          )}
        </div>
        <span className="subheading">{totalNumberOfCourses} Total Courses</span>

        {showUpdateTermsForm
          ? this.renderUpdateTerms()
          : this.renderViewTerms()}
      </section>
    );
  }

  renderUpdateTerms() {
    const { scopes } = this.props;

    if (!scopes.includes('plan-of-study.write')) {
      return <p>You are not authorized to add terms to plans of study.</p>;
    }

    return (
      <UpdateTerms
        hideFormFunc={() => this.setUpdateTermsFormVisibility(false)}
      />
    );
  }

  renderUpdatePlanOfStudy() {
    const { scopes } = this.props;
    if (!scopes.includes('plan-of-study.write')) {
      return null;
    }

    const buttonDisabled = false;
    return (
      <button
        ref={this.updatePlanOfStudyRef}
        onClick={buttonDisabled ? undefined : () => this.openSidePanel(false)}
        onKeyDown={
          buttonDisabled
            ? undefined
            : event => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  this.openSidePanel(true);
                }
              }
        }
        title="Update Plan of Study"
        disabled={buttonDisabled}
      >
        Update Plan of Study
        <FontAwesomeIcon icon={faEdit} />
      </button>
    );
  }

  openSidePanel(keyActivated) {
    this.props.showSidePanel(
      <UpdatePlanOfStudy
        planOfStudy={this.props.planOfStudy}
        plansOfStudy={this.props.planOfStudyList}
      />,
      this.updatePlanOfStudyRef,
      keyActivated
    );
  }

  renderViewTerms() {
    const { planOfStudy } = this.props;
    if (!planOfStudy.terms) {
      return <p>No terms assigned.</p>;
    }

    return <ViewTerms />;
  }

  setUpdateTermsFormVisibility(value) {
    this.setState({
      updatingTerms: value
    });
  }
}

const loadingSelector = createLoadingSelector([
  GET_COURSES,
  GET_PLANS_OF_STUDY,
  GET_PLAN_OF_STUDY,
  GET_CONFIG
]);
const updatingSelector = createLoadingSelector([
  PUT_PLAN_OF_STUDY_TERMS,
  UPDATE_PLAN_OF_STUDY
]);
const archivingSelector = createLoadingSelector([ARCHIVE_PLAN_OF_STUDY]);
const errorMessageSelector = createErrorMessageSelector([GET_PLAN_OF_STUDY]);
const updatingErrorMessageSelector = createErrorMessageSelector([
  ARCHIVE_PLAN_OF_STUDY,
  UPDATE_PLAN_OF_STUDY
]);
const mapStateToProps = state => {
  const { auth, plansOfStudy } = state;
  return {
    scopes: auth.scopes,
    loading: loadingSelector(state),
    updating: updatingSelector(state),
    archiving: archivingSelector(state),
    errorMessage: errorMessageSelector(state),
    updatingErrorMessage: updatingErrorMessageSelector(state),
    planOfStudy: plansOfStudy.viewIndividual,
    planOfStudyList: plansOfStudy.list
  };
};

const mainViewConfig = {
  pageTitle: 'View Plan of Study'
};

export default connect(mapStateToProps, {
  getPlanOfStudy,
  getCourses,
  showLoadingModal,
  hideModal,
  showSidePanel,
  showCustomJSXModal,
  showCustomLoadingModal
})(
  withRouter(mainViewComponent({ component: PlanOfStudyView, mainViewConfig }))
);
