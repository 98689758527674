import {
  GET_STUDENT_GROUPS,
  ADD_STUDENT_GROUP,
  PUT_STUDENT_GROUP,
  PUT_STUDENT_GROUP_ENROLLMENT,
  PUT_STUDENT_GROUP_TERM_COURSES,
  GET_STUDENT_GROUP,
  DELETE_STUDENT_GROUP
} from '../types';
import {
  generateRequestActionTypeName,
  generateSuccessActionTypeName
} from '../actionNamingMethods';

export default (state = {}, action) => {
  switch (action.type) {
    case generateSuccessActionTypeName(GET_STUDENT_GROUPS):
      return {
        ...state,
        list: action.payload.studentGroups
      };

    case generateSuccessActionTypeName(ADD_STUDENT_GROUP):
      return {
        ...state,
        list: [action.payload].concat(state.list),
        newest: action.payload
      };

    case generateSuccessActionTypeName(PUT_STUDENT_GROUP):
      return {
        ...state,
        newest: action.payload
      };

    case generateSuccessActionTypeName(PUT_STUDENT_GROUP_ENROLLMENT):
      return {
        ...state,
        viewIndividual: {
          ...state.viewIndividual,
          enrollment: action.payload
        }
      };

    case generateSuccessActionTypeName(PUT_STUDENT_GROUP_TERM_COURSES):
      return {
        ...state,
        viewIndividual: { ...state.viewIndividual, planOfStudy: action.payload }
      };

    case generateRequestActionTypeName(GET_STUDENT_GROUP):
      return {
        ...state,
        viewIndividual: null
      };

    case generateSuccessActionTypeName(GET_STUDENT_GROUP):
      return {
        ...state,
        viewIndividual: action.payload.studentGroup
      };

    case generateRequestActionTypeName(DELETE_STUDENT_GROUP):
      return {
        ...state,
        deletingStudentGroup: true
      };

    case generateSuccessActionTypeName(DELETE_STUDENT_GROUP):
      return {
        ...state,
        deletingStudentGroup: false
      };

    default:
      return state;
  }
};
