import React from 'react';
import { showSidePanel, hideSidePanel } from '../../service/actions/sidePanel';
import { setSectionConfirmationStatus } from '../../service/actions/schedules';
import fieldValueMappings from '../common/FieldMappings/FieldMappings';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { ValidationMessage } from '../common/Validation/validationFunctionComponent';
import ToggleSlider from '../common/ToggleSlider';
import SectionPanel from './SectionPanel';
import { showLoadingModal, hideModal } from '../../service/actions/modal';
import { SET_SECTION_CONFIRMATION_STATUS } from '../../service/types';

class ScheduleViewCourseSection extends React.Component {
  constructor(props) {
    super(props);

    this.editSectionButton = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.updatingConfirmedToggle !== prevProps.updatingConfirmedToggle
    ) {
      if (!this.props.toggleError) {
        if (this.props.updatingConfirmedToggle) {
          this.props.showLoadingModal();
        } else {
          this.props.hideModal();
        }
      }
    }
  }

  render() {
    const {
      section,
      component,
      courseComponentTypes,
      validationList
    } = this.props;

    if (!section || !component) {
      return null;
    }

    const fieldRequirements = courseComponentTypes.find(type => {
      return type.id === component.type;
    }).fieldRequirements;

    let sectionValidationList;
    if (validationList) {
      sectionValidationList = validationList
        .flat()
        .filter(
          x => x.validationLevel === 'section' && x.sectionId === section.id
        );
    }

    return (
      <div className="section-details-wrapper">
        {sectionValidationList && (
          <div className="validation-message-wrapper">
            {sectionValidationList.map((s, i) => {
              return (
                <ValidationMessage message={s.message} type={s.type} key={i} />
              );
            })}
          </div>
        )}
        <div className="section-details">
          {this.renderField('number', fieldRequirements)}
          {this.renderField('schedule', fieldRequirements)}
          <div className="faculty-column">
            {this.renderField('facultyMember', fieldRequirements)}
            {this.renderField('employeeId', fieldRequirements)}
          </div>
          <div className="column">
            {this.renderField('capacity', fieldRequirements)}
            {this.renderField('location', fieldRequirements)}
          </div>
          {this.renderEditButton()}
        </div>
      </div>
    );
  }

  renderEditButton() {
    const { scopes, schedule } = this.props;
    if (
      !scopes.includes('schedule.section.write') &&
      !scopes.includes('schedule.section.faculty-member.write')
    ) {
      return null;
    }

    if (schedule.state !== 'planning') {
      return null;
    }

    return (
      <button
        className="circle small edit"
        ref={this.editSectionButton}
        title="Edit Section"
        tabIndex="0"
        onClick={event => {
          event.preventDefault();
          this.openSectionPanel(false);
        }}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            event.preventDefault();
            this.openSectionPanel(true);
          }
        }}
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>
    );
  }

  openSectionPanel(keyActivated) {
    const { scheduleCourse, component, section } = this.props;

    const courseObject = {
      ...scheduleCourse.courseInfo,
      projectedEnrollment: scheduleCourse.projectedEnrollment
    };
    this.props.showSidePanel(
      <SectionPanel
        course={courseObject}
        component={component}
        section={section}
      />,
      this.editSectionButton,
      keyActivated
    );
  }

  renderField(field, fieldReqs) {
    const { section, schedule, scopes } = this.props;

    const currentFieldMapping = fieldValueMappings[field];
    let sectionValue;
    let className = field + ' field ';
    if (fieldReqs[field] === 'n/a') {
      sectionValue = 'Not Applicable';
      className += 'na';
    } else {
      if (section[field + 'Tba'] === true) {
        sectionValue = 'TBA';
      } else if (!section[field]) {
        if (field === 'capacity') {
          sectionValue = 'No capacity set';
        } else if (field === 'employeeId') {
          sectionValue = currentFieldMapping.renderFunc(
            section.facultyMember?.employeeId
          );
        } else {
          sectionValue = '\u2014';
        }
      } else {
        sectionValue = currentFieldMapping.renderFunc(section[field]);
      }
    }

    if (field === 'number' && section.isNew) {
      className += ' new';
    }

    return (
      <div className={className}>
        {currentFieldMapping.icon && (
          <div className="icon-wrapper">
            <FontAwesomeIcon icon={currentFieldMapping.icon} />
          </div>
        )}
        {sectionValue}
        <div className="toggle-container">
          {schedule.state !== 'completed' &&
            !section.facultyMemberTba &&
            section.facultyMember &&
            field === 'facultyMember' && (
              <ToggleSlider
                controlFunc={() =>
                  this.props.setSectionConfirmationStatus(section.id)
                }
                value={section.confirmed}
                confirmToggle={true}
                disabled={!scopes.includes('schedule.section.confirm')}
              />
            )}
        </div>
      </div>
    );
  }
}

const toggleSelector = createLoadingSelector([SET_SECTION_CONFIRMATION_STATUS]);
const toggleErrorSelector = createErrorMessageSelector([
  SET_SECTION_CONFIRMATION_STATUS
]);

const mapStateToProps = state => {
  const { config, schedules, auth } = state;

  return {
    updatingConfirmedToggle: toggleSelector(state),
    toggleError: toggleErrorSelector(state),
    scopes: auth.scopes,
    courseComponentTypes:
      config && config.config && config.config.courseComponentTypes,
    schedule: schedules.viewIndividual,
    validationList: schedules.validationResults
  };
};

export default connect(mapStateToProps, {
  showSidePanel,
  hideSidePanel,
  setSectionConfirmationStatus,
  showLoadingModal,
  hideModal
})(ScheduleViewCourseSection);
