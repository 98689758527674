import React from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import Header from '../Header';
import Sidebar from '../Sidebar';
import LandingPage from '../LandingPage';
import Courses from '../Courses';
import PlansOfStudy from '../PlansOfStudy';
import StudentGroups from '../StudentGroups';
import Schedules from '../Schedules';
import Locations from '../Locations';
import FacultyMembers from '../FacultyMembers';
import DefaultEffort from '../DefaultEffort';
import Modal from './Modal';
import SidePanel from './SidePanel';
import SkipLink from './SkipLink';
import { connect } from 'react-redux';
import { getConfig } from '../../service/actions/config';
import { getCourses } from '../../service/actions/courses';
import { getLocations } from '../../service/actions/locations';
import { getPrograms } from '../../service/actions/programs';
import { getPlanOfStudyList } from '../../service/actions/plansOfStudy';
import { getStudentGroupList } from '../../service/actions/studentGroups';
import {
  getDefaultTeachingEffort,
  getFacultyMembers
} from '../../service/actions/faculty';
import { showTimeoutModal } from '../../service/actions/modal';
import { resetActivityCounter } from '../../service/actions/inactivityTimeout';
import NoMatchPage from '../App/404';
import UnauthorizedPage from '../App/Unauthorized';
import config from '../../config';

class App extends React.Component {
  componentDidMount() {
    if (this.props.token) {
      this.props.getConfig();
      this.getInitialData();
    }

    let timer;
    document.addEventListener(
      'wheel',
      () => {
        if (!timer) {
          this.props.resetActivityCounter();
          timer = setTimeout(() => {
            timer = null;
          }, 10000);
        }
      },
      {
        capture: true,
        passive: true
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) {
      this.props.getConfig();
      this.getInitialData();
    }

    if (prevProps.inactivityTime !== this.props.inactivityTime) {
      const SECONDS_INACTIVE = 3000;
      if (this.state && this.state.inactivityTimer) {
        clearTimeout(this.state.inactivityTimer);
      }
      if (
        window.Cypress &&
        window.Cypress.spec.name === 'login/61-ManageTimeouts.feature'
      ) {
        this.setState({
          inactivityTimer: setTimeout(() => {
            this.props.showTimeoutModal();
          }, SECONDS_INACTIVE)
        });
      } else {
        this.setState({
          inactivityTimer: setTimeout(() => {
            this.props.showTimeoutModal();
          }, SECONDS_INACTIVE * 1000)
        });
      }
    }
  }

  getInitialData() {
    const {
      token,
      getCourses,
      getFacultyMembers,
      getPrograms,
      getPlanOfStudyList,
      getStudentGroupList,
      getLocations,
      getDefaultTeachingEffort
    } = this.props;
    if (token) {
      getCourses();
      getLocations();
      getPrograms();
      getFacultyMembers();
      getPlanOfStudyList();
      getStudentGroupList();
      getDefaultTeachingEffort();
    }
  }

  render() {
    const {
      signedIn,
      scopes,
      token,
      authorized,
      displayName,
      modalVisible,
      modalContent,
      modalTrapFocus
    } = this.props;
    const isEdge = window.navigator.userAgent.indexOf('Edge') !== -1;
    const isIE =
      window.navigator.userAgent.indexOf('Trident') !== -1 && !isEdge;

    if (!signedIn) {
      return null;
    } else if (!authorized) {
      return (
        <div>
          You are not authorized to use {config.APP_NAME} application. Please
          contact your system administrator
        </div>
      );
    } else {
      if (!token) {
        return null;
      }
      return (
        <Router>
          <div id="App" className={isIE ? 'is-ie' : ''}>
            <SkipLink />
            <Header displayName={displayName} />
            <div className="content">
              <Sidebar />
              <main id="main-content" className={modalVisible ? 'loading' : ''}>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={props => <LandingPage {...props} />}
                  />
                  <Route
                    exact
                    path="/redirect"
                    render={() => {
                      const redirectTo = localStorage.getItem('deeplink');
                      if (redirectTo) {
                        localStorage.removeItem('deeplink');
                        return (
                          <Redirect
                            to={{
                              pathname: redirectTo
                            }}
                          />
                        );
                      } else {
                        window.location.assign(window.location.origin);
                      }
                    }}
                  />
                  <PrivateRoute
                    path="/courses"
                    hasScope={scopes.includes('course.read')}
                    component={Courses}
                  />
                  <PrivateRoute
                    path="/plans-of-study"
                    hasScope={scopes.includes('plan-of-study.read')}
                    component={PlansOfStudy}
                  />
                  <PrivateRoute
                    path="/student-groups"
                    hasScope={scopes.includes('student-group.read')}
                    component={StudentGroups}
                  />
                  <PrivateRoute
                    path="/schedules"
                    hasScope={scopes.includes('schedule.read')}
                    component={Schedules}
                  />
                  <PrivateRoute
                    path="/faculty-members"
                    hasScope={scopes.includes('faculty-member.read')}
                    component={FacultyMembers}
                  />
                  <PrivateRoute
                    path="/default-course-effort"
                    hasScope={scopes.includes(
                      'teaching-default-work-effort.write'
                    )}
                    component={DefaultEffort}
                  />
                  <PrivateRoute
                    path="/locations"
                    hasScope={scopes.includes('location.read')}
                    component={Locations}
                  />
                  <Route
                    path="/unauthorized"
                    render={props => <UnauthorizedPage {...props} />}
                  />
                  <Route
                    path="*"
                    render={props => <NoMatchPage {...props} />}
                  />
                </Switch>
                <Modal modalVisible={modalVisible} trapFocus={modalTrapFocus}>
                  {modalContent}
                </Modal>
                <SidePanel />
              </main>
            </div>
          </div>
        </Router>
      );
    }
  }
}

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return React.createElement(component, finalProps);
};

const PrivateRoute = ({ component, hasScope, ...rest }) => {
  return (
    <Route
      {...rest}
      render={routeProps =>
        hasScope ? (
          renderMergedProps(component, routeProps, rest)
        ) : (
          <Redirect
            to={{
              pathname: '/unauthorized',
              state: { from: routeProps.location }
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = state => {
  const { auth, modal, inactivityTimeoutReducer } = state;
  return {
    token: auth.token,
    scopes: auth.scopes,
    modalVisible: modal.modalVisible,
    modalContent: modal.content,
    modalTrapFocus: modal.trapFocus,
    inactivityTime: inactivityTimeoutReducer.time
  };
};

export default connect(
  mapStateToProps,
  {
    getConfig,
    getCourses,
    getPrograms,
    getPlanOfStudyList,
    getFacultyMembers,
    getLocations,
    getStudentGroupList,
    getDefaultTeachingEffort,
    showTimeoutModal,
    resetActivityCounter
  }
)(App);
