import React from 'react';
import Input from '../common/Input';
import Select from '../common/Select';
import FormReveal from '../common/FormReveal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';

class AddWorkActivity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormInputValid: false,
      resetting: false
    };
    this.formRevealRef = React.createRef();
    this.baseState = { ...this.state };
    this.handleValueChange = this.handleValueChange.bind(this);

    this.clearSelectCommand = { invoke: () => {} };
    this.clearNameCommand = { invoke: () => {} };
    this.clearEffortCommand = { invoke: () => {} };

    this.formValues = {
      effort: {
        name: props.workType.id + 'effort',
        valueId: 'effort',
        labelTitle:
          this.props.effortMode === '%'
            ? `${this.props.effortUnit} Effort`
            : 'Effort',
        defaultValue: '',
        validatorFunc:
          this.props.effortMode === '%'
            ? value => ({
                isValid:
                  /^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/.test(value) &&
                  value &&
                  parseFloat(value) >= 0 &&
                  parseFloat(value) <= 100,
                errorMessage: 'Invalid effort'
              })
            : value => ({
                isValid:
                  !value ||
                  (/^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/.test(value) &&
                    value &&
                    parseFloat(value) >= 0 &&
                    parseFloat(value) <= 100) ||
                  value.trim() === '',
                errorMessage: 'Invalid effort'
              })
      },
      activityType: {
        name: this.props.workType.id + '-activity-type',
        valueId: 'activityType',
        labelTitle: 'Type',
        validatorFunc: value => ({
          // If not non-teaching division, must be a category type -- no need for validation
          isValid:
            this.props.workType.id !== 'non-teaching' ||
            (value && value !== '' && value !== 'Select'),
          errorMessage: 'Invalid'
        })
      },
      activityName: {
        name: props.workType.id + '-activity-name',
        valueId: 'activityName',
        labelTitle: 'Name',
        defaultValue: '',
        validatorFunc: value => {
          return {
            isValid:
              /^.{1,50}$/.test(value) && value && value.trim().length > 0,
            errorMessage: 'Invalid activity name'
          };
        }
      },
      activityDescription: {
        name: props.workType.id + '-activity-description',
        valueId: 'activityDescription',
        labelTitle: 'Activity Description',
        defaultValue: '',
        validatorFunc: value => {
          return {
            isValid: /^.{0,250}$/.test(value),
            errorMessage: 'Invalid activity description'
          };
        }
      }
    };
  }

  render() {
    const { workType } = this.props;
    const form = this.renderAddActivityForm();
    if (!form) return null;
    return (
      <FormReveal
        buttonText={`Add ${workType.name} Activity`}
        buttonId="add-work-activity-btn"
        form={form}
        submitFunc={() => this.resetForm(workType.id)}
        cancelFunc={() => this.resetForm(workType.id)}
        ref={this.formRevealRef}
      />
    );
  }

  renderAddActivityForm() {
    const { workType, workTypes, effortMode } = this.props;
    return (
      <form id={`add-${workType.id}-activity`} className="work-activity-form">
        <div
          className={effortMode === '%' ? 'work-activity' : 'work-activity-tu'}
        >
          {workType.id === 'non-teaching' && (
            <Select
              clearCommand={this.clearSelectCommand}
              formValue={this.formValues.activityType}
              controlFunc={value => this.handleValueChange(value, workType)}
              optionList={workTypes
                .filter(x => x.division === 'non-teaching')
                .map(x => x.name)}
            />
          )}
          <Input
            clearCommand={this.clearNameCommand}
            formValue={this.formValues.activityName}
            controlFunc={value => this.handleValueChange(value, workType)}
          />
          <Input
            formValue={this.formValues.activityDescription}
            controlFunc={value => this.handleValueChange(value, workType)}
            optional={true}
          />
          <Input
            clearCommand={this.clearEffortCommand}
            formValue={this.formValues.effort}
            controlFunc={value => this.handleValueChange(value, workType)}
            trimWhitespaces={true}
            optional={this.props.effortMode === '%' ? false : true}
          />
        </div>
        <div id="form-end-wrapper">
          <div className="buttons">
            <button
              type="button"
              id={`${workType.id}-cancel-work-activity`}
              onClick={event => this.handleCancelClick(event, workType)}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  this.handleCancelClick(event, workType);
                }
              }}
            >
              Cancel
            </button>
            <button
              id={`${workType.id}-submit-work-activity`}
              onClick={event =>
                this.handleSubmitClick(event, workType, workTypes)
              }
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  this.handleSubmitClick(event, workType, workTypes);
                }
              }}
              disabled={!this.state[workType.id + 'isFormInputValid']}
            >
              Add {workType.name} Activity
              <FontAwesomeIcon icon={faPlusCircle} />
            </button>
          </div>
        </div>
      </form>
    );
  }

  handleCancelClick(event) {
    event.preventDefault();
    this.formRevealRef.current.handleCancelClick(event);
  }

  handleSubmitClick(event, workType, workTypes) {
    event.preventDefault();

    const workTypeId =
      workType.id !== 'non-teaching'
        ? workType.id
        : workTypes.find(
            x => x.name === this.state['non-teaching'].activityType
          ).id;

    let activityObject = {
      workType: workTypeId,
      name: this.state[workType.id].activityName,
      description: this.state[workType.id].activityDescription,
      actualEffort: (() => {
        if (parseFloat(this.state[workType.id].effort) >= 0) {
          return parseFloat(this.state[workType.id].effort);
        }

        return undefined;
      })()
    };

    this.props.addNewWorkplanActivity(activityObject);
    this.formRevealRef.current.handleCancelClick(event);
  }

  resetForm(workType) {
    let newState = { ...this.baseState, resetting: true };

    document.getElementById(`add-${workType}-activity`).reset();
    this.clearSelectCommand.invoke();
    this.clearNameCommand.invoke();
    this.clearEffortCommand.invoke();

    Object.keys(this.formValues).forEach(key => {
      newState[workType + key] = undefined;
      newState[workType + 'isFormInputValid'] = false;
      newState[workType] = undefined;
    });
    this.setState(newState);
  }

  handleValueChange = (formValue, workType) => {
    this.setState(prevState => ({
      [workType.id]: {
        ...prevState[workType.id],
        [formValue.valueId]: formValue.value
      },
      [workType.id + formValue.valueId]: formValue.value,
      [workType.id + 'isFormInputValid']: Object.keys(this.formValues).reduce(
        (result, next) => {
          let theResult;
          if (next === formValue.valueId) {
            theResult =
              result &&
              this.formValues[next].validatorFunc(formValue.value).isValid;
          } else {
            theResult =
              result &&
              this.formValues[next].validatorFunc(
                this.state[workType.id + next]
              ).isValid;
          }
          return theResult;
        },
        true
      )
    }));
  };
}

export default AddWorkActivity;
