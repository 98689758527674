import React from 'react';
import './ViewTerms.scss';
import { connect } from 'react-redux';
import ViewTermCourses from './ViewTermCourses';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import { GET_PLANS_OF_STUDY, GET_COURSES } from '../../service/types';

class ViewTerms extends React.Component {
  render() {
    const { planOfStudy, coursesError, termList } = this.props;

    if (!planOfStudy.terms || !termList) {
      return null;
    }

    if (coursesError) {
      return (
        <div class="plan-of-study-error-message error-message">
          An unexpected error occurred. Please try again
        </div>
      );
    }

    const termsByYear = planOfStudy.terms.reduce((aggregator, next) => {
      aggregator[next.year] = aggregator[next.year] || [];
      aggregator[next.year] = aggregator[next.year].concat([next]);
      return aggregator;
    }, {});

    return Object.keys(termsByYear).map(termYear => {
      return (
        <div key={termYear} className="term-year">
          <h3 className="alternative">Academic Year {termYear}</h3>
          {termsByYear[termYear]
            .sort((a, b) => {
              if (a.year === b.year) {
                return termList.indexOf(a.term) - termList.indexOf(b.term);
              }
              return a.year - b.year;
            })
            .map(term => {
              return <ViewTermCourses key={term.term} term={term} />;
            })}
        </div>
      );
    });
  }
}

const loadingSelector = createLoadingSelector([
  GET_PLANS_OF_STUDY,
  GET_COURSES
]);
const errorSelector = createErrorMessageSelector([GET_COURSES]);
const mapStateToProps = state => {
  const { config, plansOfStudy } = state;
  return {
    loading: loadingSelector(state),
    coursesError: errorSelector(state),
    planOfStudy: plansOfStudy.viewIndividual,
    termList: config.termsByAcademicYear
  };
};

export default connect(
  mapStateToProps,
  null
)(ViewTerms);
