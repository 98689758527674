import React from 'react';
import FacultyViewCourseSection from './FacultyViewCourseSection';
import { assignSectionPlaceholder } from '../../service/actions/faculty';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';

class FacultyCourseComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editingSection: false
    };

    this.editSectionButton = React.createRef();
  }
  render() {
    const {
      courseComponents,
      component,
      schedule,
      selectedYear,
      scheduleCourse,
      defaultTeachingEfforts,
      workPlan,
      facultyMember,
      effortUnit
    } = this.props;

    if (!workPlan) {
      return null;
    }

    const componentType = courseComponents.find(type => {
      return type.id === component.type;
    });

    let componentDefaultEffort = defaultTeachingEfforts.find(d => {
      return (
        d.courseId === scheduleCourse.id && d.componentType === component.type
      );
    });
    let defaultEffort = componentDefaultEffort
      ? componentDefaultEffort.defaultEffort
      : '\u2014';

    const componentTypeIndex =
      courseComponents.findIndex(configComponent => {
        return configComponent.id === component.type;
      }) + 1;

    if (componentType) {
      return (
        <div key={component.type} className="instructional-component-wrapper">
          <div className="instructional-component-header">
            <div
              className={`instructional-component component-${componentTypeIndex}-bg-light`}
            >
              <div className={`label component-${componentTypeIndex}-bg`}>
                {component.type}
              </div>
            </div>
            <div>
              <div className="default-effort">
                {workPlan &&
                  workPlan.state !== 'past' &&
                  !Number.isNaN(parseFloat(defaultEffort)) && (
                    <>
                      {defaultEffort}
                      {effortUnit} Default Effort
                    </>
                  )}
              </div>
              {workPlan &&
                workPlan.state !== 'past' &&
                schedule.state !== 'completed' &&
                facultyMember.active === true &&
                this.renderAddSectionPlaceholder()}
            </div>
          </div>

          {component.sections
            .sort((a, b) => {
              if (a.isAssigned && !b.isAssigned) {
                return -1;
              } else if (!a.isAssigned && b.isAssigned) {
                return 1;
              } else {
                return ('' + a.number).localeCompare(b.number);
              }
            })
            .filter(section => {
              if (workPlan.state === 'past') {
                return section.isAssigned;
              } else {
                return true;
              }
            })
            .map((section, i) => {
              return (
                <FacultyViewCourseSection
                  key={i}
                  section={section}
                  component={component}
                  scheduleCourse={scheduleCourse}
                  schedule={schedule}
                  selectedYear={selectedYear}
                  effortUnit={effortUnit}
                />
              );
            })}
        </div>
      );
    } else {
      return null;
    }
  }

  renderAddSectionPlaceholder() {
    const { scopes } = this.props;
    if (!scopes.includes('faculty-member.work-plan.write')) {
      return null;
    }
    return (
      <>
        <button
          className="small no-print"
          ref={this.addSectionPlaceholderButton}
          onClick={() => this.addSectionPlaceholder()}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              event.preventDefault();
              this.addSectionPlaceholder();
            }
          }}
          title="Add Section Placeholder"
        >
          Add Section Placeholder
          <FontAwesomeIcon icon={faPlusCircle} />
        </button>
      </>
    );
  }

  addSectionPlaceholder() {
    const {
      component,
      assignSectionPlaceholder,
      schedule,
      scheduleCourse,
      selectedYear
    } = this.props;
    let facultyMemberId = this.props.match.params.facultyMemberId;

    let sectionsArr = [];
    scheduleCourse.components.forEach(c => {
      if (component.type === c.type) {
        sectionsArr.push({
          componentType: c.type,
          sectionCount: parseInt(component.sectionCount) + 1
        });
      } else {
        if (c.sectionCount > 0) {
          sectionsArr.push({
            componentType: c.type,
            sectionCount: c.sectionCount
          });
        }
      }
    });
    let sectionsObj = { sections: sectionsArr };

    assignSectionPlaceholder(
      facultyMemberId,
      selectedYear,
      schedule.term,
      scheduleCourse.id,
      sectionsObj
    );
  }
}

const mapStateToProps = ({ auth, config, courses, faculty }) => {
  return {
    scopes: auth.scopes,
    defaultTeachingEfforts: faculty.defaultTeachingEffort,
    courseComponents:
      config && config.config && config.config.courseComponentTypes,
    courseList: courses.list,
    termList: config.termsByCalendarYear,
    workPlan: faculty.viewWorkPlan,
    facultyMember: faculty.viewIndividual
  };
};

export default connect(mapStateToProps, { assignSectionPlaceholder })(
  withRouter(FacultyCourseComponent)
);
