import {
  GET_COURSES,
  GET_COURSE,
  GET_COURSE_SCHEDULES,
  ADD_COURSE,
  UPDATE_COURSE,
  UPDATE_COURSE_COMPONENTS,
  CLEAR_COURSE_ERRORS,
  ARCHIVE_COURSE
} from '../types';
import {
  generateRequestActionTypeName,
  generateSuccessActionTypeName
} from '../actionNamingMethods';

export default (
  state = {
    list: undefined,
    viewIndividual: undefined,
    newest: undefined
  },
  action
) => {
  switch (action.type) {
    case generateSuccessActionTypeName(GET_COURSES): {
      return {
        ...state,
        list: action.payload
      };
    }

    case generateRequestActionTypeName(GET_COURSE): {
      return {
        ...state,
        viewIndividual: undefined
      };
    }

    case generateSuccessActionTypeName(GET_COURSE): {
      return {
        ...state,
        viewIndividual: action.payload
      };
    }

    case generateSuccessActionTypeName(GET_COURSE_SCHEDULES): {
      return {
        ...state,
        viewIndividualSchedules: action.payload
      };
    }

    case generateSuccessActionTypeName(ADD_COURSE): {
      action.payload.isNew = true;
      return {
        ...state,
        list: [action.payload].concat(state.list),
        newest: action.payload
      };
    }

    case generateSuccessActionTypeName(UPDATE_COURSE): {
      action.payload.isNew = true;
      return {
        ...state,
        viewIndividual: {
          ...state.viewIndividual,
          ...action.payload
        },
        list: state.list.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              ...action.payload,
              credits: action.payload.variableCredit
                ? undefined
                : action.payload.credits
            };
          }
          return item;
        })
      };
    }

    case generateSuccessActionTypeName(UPDATE_COURSE_COMPONENTS): {
      return {
        ...state,
        viewIndividual: {
          ...state.viewIndividual,
          components: [...action.payload.components]
        },
        list: state.list.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              components: action.payload.components
            };
          }
          return item;
        })
      };
    }

    case generateSuccessActionTypeName(ARCHIVE_COURSE):
      const individual = state.viewIndividual;
      Object.assign(individual, { archived: true });

      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === action.payload) {
            return {
              ...item,
              archived: true
            };
          }
          return item;
        }),
        viewIndividual: individual
      };

    case CLEAR_COURSE_ERRORS:
      return {
        ...state,
        errorMessage: '',
        addCourseError: '',
        updateError: ''
      };

    default:
      return state;
  }
};
