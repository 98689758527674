import React from 'react';
import './CourseView.scss';
import { connect } from 'react-redux';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import { getCourse, getCourseSchedules } from '../../service/actions/courses';
import {
  showLoadingModal,
  hideModal,
  showArchivingModal
} from '../../service/actions/modal';
import { showSidePanel } from '../../service/actions/sidePanel';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import updatePageTitle from '../common/helpers/updatePageTitle';
import { sendFocusToFirstH1 } from '../App/SkipLink';
import UpdateCourse from './UpdateCourse';
import {
  GET_CONFIG,
  GET_COURSE,
  GET_COURSE_SCHEDULES,
  UPDATE_COURSE,
  UPDATE_COURSE_COMPONENTS,
  GET_COURSES,
  ARCHIVE_COURSE
} from '../../service/types';
import { mainViewComponent } from '../common/HOCs';

class CourseView extends React.Component {
  constructor(props) {
    super(props);
    this.updateCourseButton = React.createRef();
  }

  componentDidMount() {
    updatePageTitle('Course ID: ' + this.props.match.params.courseId);

    if (this.props.scopes.includes('course.read')) {
      this.props.getCourse(this.props.match.params.courseId);
    }
    if (this.props.scopes.includes('schedule.read')) {
      this.props.getCourseSchedules(this.props.match.params.courseId);
    }
    if (this.props.loading) {
      this.props.showLoadingModal();
    } else {
      this.props.hideModal();
      if (
        this.props.location.state &&
        this.props.location.state.keyed === true
      ) {
        sendFocusToFirstH1();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      if (this.props.loading) {
        this.props.showLoadingModal();
      } else {
        this.props.hideModal();
        if (
          this.props.location.state &&
          this.props.location.state.keyed === true
        ) {
          sendFocusToFirstH1();
        }
      }
    }

    if (prevProps.archiving !== this.props.archiving) {
      if (this.props.archiving) {
        if (this.props.errorMessage) {
          this.props.hideModal();
        }
        this.props.showArchivingModal();
      } else {
        this.props.hideModal();
      }
    }

    if (prevProps.course !== this.props.course) {
      if (this.props.course) {
        updatePageTitle(this.props.course.courseNumber);
      }
    }
  }

  render() {
    const {
      course,
      schedules,
      courseComponents,
      errorMessage,
      schedulesErrorMessage,
      scopes,
      termList
    } = this.props;
    if (errorMessage) {
      return (
        <div className="course-error-message error-message">
          <FontAwesomeIcon icon={faExclamationCircle} />
          {errorMessage}
        </div>
      );
    }

    if (!course || !courseComponents || !scopes || !termList) {
      return null;
    }

    if (!scopes.includes('course.read')) {
      return 'Unauthorized';
    }

    let courseNumber = course.courseNumber.replace(/(\d)/, ' $1');

    const MAX_YEARS_PAST = 3;
    const MAX_COMPLETED_TERMS = 6;
    const CURRENT_YEAR = new Date().getFullYear();
    let sortedSchedules = [];
    let filteredSchedules = [];
    if (schedules) {
      sortedSchedules = []
        .concat(schedules)
        .sort((a, b) => {
          if (a.year === b.year) {
            return termList.indexOf(a.term) - termList.indexOf(b.term);
          }
          return a.year - b.year;
        })
        .filter(schedule => schedule.year >= CURRENT_YEAR - MAX_YEARS_PAST);

      let completedCount = 0;
      filteredSchedules = sortedSchedules.reduce((result, schedule) => {
        if (schedule.state === 'completed') {
          if (completedCount >= MAX_COMPLETED_TERMS) {
            result.splice(0, 1);
          }
          completedCount++;
        }
        result.push(schedule);
        return result;
      }, []);
    }

    return (
      <div className="course-container">
        {course.archived ? (
          <div className="label archived">
            <span>Archived</span>
          </div>
        ) : null}
        <div className="top-container">
          <div className="left-bar first-focus">
            <h3 id="course-number" className="no-bar">
              {courseNumber}
            </h3>
            <h1 id="course-name" className="no-bar">
              {course.name}
            </h1>
            <p id="course-meta">
              {course.variableCredit ? (
                'Variable Credit'
              ) : (
                <>
                  {course.credits} credit{course.credits === 1 ? '' : 's'}
                </>
              )}
            </p>
          </div>
          {this.renderUpdateCourse()}
        </div>
        <section className="instructional-components">
          <h1>Instructional Components</h1>
          {course.components
            ? []
                .concat(course.components)
                .sort((a, b) =>
                  ('' + a.componentType).localeCompare(b.componentType)
                )
                .map(component => {
                  const componentTypeIndex =
                    courseComponents.findIndex(configComponent => {
                      return configComponent.id === component.componentType;
                    }) + 1;
                  return (
                    <div key={component.componentType} className="component">
                      <div
                        className={`label component-${componentTypeIndex}-bg`}
                      >
                        {component.componentType}
                      </div>
                      <span>
                        {component.maxCapacity
                          ? `${component.maxCapacity} Student Capacity`
                          : 'No Capacity Set'}
                      </span>
                    </div>
                  );
                })
            : 'Error: No components in this course'}
        </section>
        {scopes.includes('schedule.read') ? (
          <section className="schedules">
            <h1>Schedules with {courseNumber}</h1>
            {schedulesErrorMessage ? (
              <div class="course-error-message error-message">
                <FontAwesomeIcon icon={faExclamationCircle} />
                {schedulesErrorMessage}
              </div>
            ) : filteredSchedules.length > 0 ? (
              <table>
                <tbody>
                  {filteredSchedules.map(schedule => {
                    const projectedEnrollmentText = schedule.projectedEnrollment
                      ? schedule.projectedEnrollment +
                        ' Student' +
                        (schedule.projectedEnrollment === 1 ? '' : 's')
                      : 'No projected enrollment';

                    return (
                      <tr
                        className="schedule"
                        key={`${schedule.term} ${schedule.year}`}
                      >
                        <td className="title">{`${schedule.term} ${schedule.year}`}</td>
                        <td className="state">
                          <div
                            className={
                              schedule.state === 'completed'
                                ? 'completed '
                                : `label status-${schedule.state}-bg`
                            }
                          >
                            {schedule.state}
                          </div>
                        </td>
                        <td className="enrollment">
                          {projectedEnrollmentText}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              'This course does not appear on any schedules.'
            )}
          </section>
        ) : null}
      </div>
    );
  }

  renderUpdateCourse() {
    const { scopes, course } = this.props;
    if (!scopes.includes('course.write') || !!course.archived === true) {
      return null;
    }
    return (
      <button
        ref={this.updateCourseButton}
        onClick={() => this.openSidePanel(false)}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            event.preventDefault();
            this.openSidePanel(true);
          }
        }}
        title="Update Course"
      >
        Update Course
        <FontAwesomeIcon icon={faEdit} />
      </button>
    );
  }

  openSidePanel(keyActivated) {
    this.props.showSidePanel(
      <UpdateCourse course={this.props.course} />,
      this.updateCourseButton,
      keyActivated
    );
  }
}

const loadingSelector = createLoadingSelector([
  GET_CONFIG,
  GET_COURSE,
  GET_COURSES,
  GET_COURSE_SCHEDULES,
  UPDATE_COURSE,
  UPDATE_COURSE_COMPONENTS
]);
const archiveSelector = createLoadingSelector([ARCHIVE_COURSE]);
const errorMessageSelector = createErrorMessageSelector([GET_COURSE]);
const mapStateToProps = state => {
  const { auth, config, courses } = state;
  return {
    token: auth.token,
    scopes: auth.scopes,
    loading: loadingSelector(state),
    archiving: archiveSelector(state),
    courseComponents:
      config && config.config && config.config.courseComponentTypes,
    course: courses.viewIndividual,
    schedules: courses.viewIndividualSchedules,
    errorMessage: errorMessageSelector(state),
    schedulesErrorMessage: courses.schedulesErrorMessage,
    termList: config.termsByCalendarYear
  };
};

const mainViewConfig = {
  pageTitle: 'View Course'
};

export default connect(
  mapStateToProps,
  {
    getCourse,
    getCourseSchedules,
    showLoadingModal,
    hideModal,
    showSidePanel,
    showArchivingModal
  }
)(withRouter(mainViewComponent({ component: CourseView, mainViewConfig })));
