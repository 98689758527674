const convertAppointmentDateToObj = appointmentDate => {
  const DAY_OF_MONTH = '02';
  const date = new Date(`${appointmentDate}-${DAY_OF_MONTH}`);
  return {
    year: date.getFullYear(),
    month: date.toLocaleString('default', { month: 'long' })
  };
};

export default convertAppointmentDateToObj;
