import { combineReducers } from 'redux';

import auth from './authReducer';
import config from './configReducer';
import courses from './coursesReducer';
import faculty from './facultyReducer';
import locations from './locationsReducer';
import modal from './modalReducer';
import plansOfStudy from './plansOfStudyReducer';
import programs from './programsReducer';
import schedules from './schedulesReducer';
import sidePanel from './sidePanelReducer';
import studentGroups from './studentGroupsReducer';
import accordion from './accordionReducer';
import inactivityTimeoutReducer from './inactivityTimeoutReducer';
import loadingReducer from '../loadingReducer';
import errorReducer from '../errorReducer';

export default combineReducers({
  errorReducer,
  loadingReducer,
  accordion,
  auth,
  config,
  courses,
  faculty,
  inactivityTimeoutReducer,
  locations,
  modal,
  plansOfStudy,
  programs,
  schedules,
  sidePanel,
  studentGroups
});
