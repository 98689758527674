import React from 'react';
import { connect } from 'react-redux';
import sortStudentGroups from '../common/helpers/sortStudentGroups';
import reduceStudentGroupsToProgramAndTracks from '../common/helpers/reduceStudentGroupsToProgramTrack';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import {
  GET_SCHEDULES,
  GET_COURSES,
  GET_STUDENT_GROUPS,
  GET_CONFIG
} from '../../service/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';

class ScheduleViewStudentGroups extends React.Component {
  render() {
    const {
      courseComponents,
      schedule,
      courseList,
      studentGroupList,
      termList,
      errorMessage
    } = this.props;

    if (errorMessage) {
      return (
        <div className="form-message unexpected-error-message error-message">
          <FontAwesomeIcon icon={faExclamationCircle} />
          {errorMessage}
        </div>
      );
    }

    if (!schedule || !courseList || !studentGroupList || !courseComponents) {
      return null;
    }

    const studentGroups = schedule.studentGroups
      .map(studentGroup => {
        return {
          ...studentGroup,
          studentGroupInfo: studentGroupList.find(elem => {
            return elem.id === studentGroup.id;
          })
        };
      })
      .map(elem => {
        if (!elem.courseIds) {
          return elem;
        }
        return {
          ...elem,
          courses: elem.courseIds.map(courseId => {
            return courseList.find(course => {
              return course.id === courseId;
            });
          })
        };
      });

    const displayGroupings = reduceStudentGroupsToProgramAndTracks(
      studentGroups
    );

    return Object.keys(displayGroupings)
      .sort()
      .map(programLabel => {
        return (
          <div className="program-grouping" key={programLabel}>
            <h2>{programLabel}</h2>
            {Object.keys(displayGroupings[programLabel])
              .sort()
              .map(trackLabel => {
                return (
                  <div className="track-grouping" key={trackLabel}>
                    <h3>{trackLabel}</h3>
                    {displayGroupings[programLabel][trackLabel]
                      .sort(sortStudentGroups(termList))
                      .map(currentStudentGroup => {
                        const sgInfo = currentStudentGroup.studentGroupInfo;
                        return (
                          <div
                            className="student-group"
                            key={currentStudentGroup.id}
                          >
                            <div className="details">
                              <p className="start-end-terms">{`${sgInfo.startTerm}\u00A0${sgInfo.startYear}\u2013${sgInfo.endTerm}\u00A0${sgInfo.endYear}`}</p>
                              <div className="label">
                                {currentStudentGroup.enrollment
                                  ? `${currentStudentGroup.enrollment} student` +
                                    (currentStudentGroup.enrollment !== 1
                                      ? 's'
                                      : '')
                                  : 'No enrollment set'}
                              </div>
                              <span className="sg-name">{sgInfo.name}</span>
                            </div>
                            <ol className="group-list">
                              {[]
                                .concat(currentStudentGroup.courses)
                                .sort((a, b) => {
                                  return ('' + a.courseNumber).localeCompare(
                                    b.courseNumber
                                  );
                                })
                                .map(course => {
                                  return (
                                    <li
                                      key={course.id}
                                      className="course-content"
                                    >
                                      <span className="course-number">
                                        {course.courseNumber.replace(
                                          /(\d)/,
                                          ' $1'
                                        )}
                                      </span>
                                      <span className="course-name">
                                        {course.name}
                                        <span className="credit-hours">
                                          (
                                          {course.variableCredit
                                            ? 'Variable'
                                            : course.credits}{' '}
                                          CR)
                                        </span>
                                      </span>
                                    </li>
                                  );
                                })}
                            </ol>
                          </div>
                        );
                      })}
                  </div>
                );
              })}
          </div>
        );
      });
  }
}

const loadingSelector = createLoadingSelector([
  GET_SCHEDULES,
  GET_COURSES,
  GET_STUDENT_GROUPS,
  GET_CONFIG
]);
const errorSelector = createErrorMessageSelector([GET_STUDENT_GROUPS]);
const mapStateToProps = state => {
  const { config, courses, schedules, studentGroups } = state;
  return {
    loading: loadingSelector(state),
    errorMessage: errorSelector(state),
    courseComponents:
      config && config.config && config.config.courseComponentTypes,
    schedule: schedules.viewIndividual,
    courseList: courses.list,
    termList: config.termsByCalendarYear,
    studentGroupList: studentGroups.list
  };
};

export default connect(
  mapStateToProps,
  null
)(ScheduleViewStudentGroups);
