import React from 'react';
import './FacultyTeachingAssignments.scss';
import '../common/ViewSelector/ViewSelector.scss';
import { addProspectiveCourse } from '../../service/actions/faculty';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getCourses } from '../../service/actions/courses';
import { addNewOtherTeachingItem } from '../../service/actions/faculty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import SelectCourse from '../common/SelectCourse';
import Input from '../common/Input';
import FormReveal from '../common/FormReveal';
import FacultyCourse from './FacultyCourse';
import FacultyOtherTeachingItem from './FacultyOtherTeachingItem';
import {
  showLoadingModal,
  showCustomJSXModal,
  showValidatingModal,
  hideModal
} from '../../service/actions/modal';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import {
  ADD_PROSPECTIVE_COURSE,
  ADD_OTHER_TEACHING_ITEM,
  DELETE_OTHER_TEACHING_ITEM
} from '../../service/types';

class FacultyTeachingAssignments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFormInputValid: false,
      selectedCourse: null,
      visibleForm: 'AddProspectiveCourse',
      showAddProspectiveCourseForm: false
    };
    this.baseState = { ...this.state };
    this.formRevealRef = React.createRef();
    this.showAddProspectiveCourseRevealRef = React.createRef();
    this.showAddOtherTeachingItemRevealRef = React.createRef();
    this.handleCourseOptionsChange = this.handleCourseOptionsChange.bind(this);

    this.formValues = {
      courseId: {
        name: 'courses-select',
        valueId: 'courseId',
        defaultValue: 'Select',
        labelTitle: 'Available Courses',
        validatorFunc: () => ({
          isValid: true,
          errorMessage: 'Invalid course selected'
        })
      },
      effort: {
        name: 'effort',
        valueId: 'effort',
        labelTitle: 'Effort',
        defaultValue: '',
        validatorFunc: value => ({
          isValid:
            /^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/.test(value) &&
            value &&
            parseFloat(value) >= 0 &&
            parseFloat(value) <= 100,
          errorMessage: 'Invalid effort'
        })
      },
      description: {
        name: 'other-teaching-item-description',
        valueId: 'description',
        labelTitle: 'Description',
        defaultValue: '',
        validatorFunc: value => {
          return {
            isValid: value && /^.{0,250}$/.test(value) && value?.trim() !== '',
            errorMessage: 'Invalid activity description'
          };
        }
      }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.resetting !== this.state.resetting && this.state.resetting) {
      this.setState({
        resetting: false
      });
    }
    if (
      this.props.addingProspectiveCourse !== prevProps.addingProspectiveCourse
    ) {
      if (!this.props.addingProspectiveCourse) {
        if (this.props.addProspectiveCourseError) {
          this.props.hideModal();
        } else {
          this.props.showCustomJSXModal(
            <div id="success-message" style={{ textAlign: 'center' }}>
              <FontAwesomeIcon icon={faCheckCircle} />
              New Prospective Course Added
            </div>
          );
          this.showAddProspectiveCourseRevealRef.current.handleCancelClick(
            document.createEvent('Event')
          );

          setTimeout(() => {
            this.props.hideModal();
          }, 1800);
        }
      }
    }

    if (prevProps.loading !== this.props.loading) {
      if (!this.props.addOtherTeachingItemError) {
        if (this.props.loading) {
          this.props.showLoadingModal();
        } else {
          this.props.hideModal();
        }
      } else {
        this.props.hideModal();
      }
    }
  }

  render() {
    const {
      teachingAssignments,
      workPlan,
      selectedYear,
      schedule,
      facultyMember,
      effortUnit,
      effortMode
    } = this.props;
    const { resetting } = this.state;
    if (resetting) {
      return null;
    }

    if (!teachingAssignments) {
      return null;
    }

    return (
      <div className="faculty-courses">
        {workPlan &&
          workPlan.state !== 'past' &&
          schedule.state !== 'completed' &&
          facultyMember.active === true &&
          this.renderProspectiveCourseAndOtherTeaching()}

        {teachingAssignments.courses
          ? []
              .concat(teachingAssignments.courses)
              .sort((a, b) => {
                return ('' + a.courseInfo.courseNumber).localeCompare(
                  b.courseInfo.courseNumber
                );
              })
              .map((scheduleCourse, i) => {
                return (
                  <div
                    className={effortMode === 'TU' ? 'course-wrapper-tu' : ''}
                    key={i}
                  >
                    <FacultyCourse
                      key={scheduleCourse.id || scheduleCourse.courseId}
                      selectedYear={selectedYear}
                      scheduleCourse={scheduleCourse}
                      schedule={schedule}
                      isProposedCourse={scheduleCourse.isProposed}
                      effortUnit={effortUnit}
                    />
                  </div>
                );
              })
          : null}
        {effortMode === 'TU' && workPlan?.otherTeaching.length
          ? workPlan.otherTeaching
              .filter(oti => oti.term === schedule.term)
              .map(oti => {
                return (
                  <FacultyOtherTeachingItem
                    isNew={oti?.isNew}
                    key={oti.id}
                    id={oti.id}
                    facultyMemberId={facultyMember.id}
                    year={selectedYear}
                    description={oti.description}
                    effort={oti.effort}
                  />
                );
              })
          : null}
      </div>
    );
  }

  renderProspectiveCourseAndOtherTeaching() {
    const { visibleForm } = this.state;
    const { scopes, effortMode } = this.props;
    let form =
      visibleForm === 'AddProspectiveCourse'
        ? this.renderAddProspectiveCourseForm()
        : this.renderAddOtherTeachingItemForm();

    let formJSX = (
      <div className="prospective-course-and-other-teaching-buttons-wrapper">
        {scopes.includes('faculty-member.write') && (
          <>
            <button
              id="add-prospective-courses-btn"
              onClick={event => this.showForm('AddProspectiveCourse', event)}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  this.showForm('AddProspectiveCourse', event);
                }
              }}
            >
              Assign Prospective Course <FontAwesomeIcon icon={faPlusCircle} />
            </button>
            {effortMode === 'TU' ? (
              <button
                id="add-other-teaching-btn"
                onClick={event => this.showForm('AddOtherTeachingItem', event)}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    this.showForm('AddOtherTeachingItem', event);
                  }
                }}
              >
                Add Other Teaching Activity{' '}
                <FontAwesomeIcon icon={faPlusCircle} />
              </button>
            ) : null}
          </>
        )}
      </div>
    );

    return (
      <FormReveal
        hideButton
        parentShowForm={this.state[`show${visibleForm}`]}
        parentShowFormSendFocus={this.state[`show${visibleForm}SendFocus`]}
        form={form}
        hideRevealJsx={formJSX}
        submitFunc={() => this.resetForm()}
        cancelFunc={() => this.resetForm()}
        ref={this[`show${visibleForm}RevealRef`]}
      />
    );
  }

  renderAddProspectiveCourseForm() {
    const {
      addProspectiveCourseError,
      courseList,
      scheduleProjectedEnrollment,
      schedule,
      order,
      teachingAssignments
    } = this.props;

    const { visibleForm } = this.state;

    if (!courseList || !teachingAssignments || !scheduleProjectedEnrollment) {
      return null;
    }

    let filteredCourses = courseList
      .filter(c => {
        return (
          c.archived !== true &&
          !teachingAssignments.courses.some(
            d => d.id === c.id || d.courseId === c.id
          )
        );
      })
      .map(course => {
        let projectedEnrollment = scheduleProjectedEnrollment.courses.find(
          c => {
            return course.id === c.id;
          }
        );
        return {
          ...course,
          projectedEnrollment: projectedEnrollment
            ? projectedEnrollment.projectedEnrollment
            : 0
        };
      });

    return (
      <div className="add-proposed-course-wrapper">
        <h1>Assign a Prospective Course to Faculty</h1>
        <form className="course-add-wrapper">
          <div className="course-add-form">
            <div>
              <SelectCourse
                order={order}
                courseFormValue={this.formValues.courseId}
                controlFunc={this.handleCourseOptionsChange}
                courseList={filteredCourses}
                includeEnrollment
              />
            </div>
          </div>

          <div className="form-end-wrapper">
            {addProspectiveCourseError && (
              <div className="form-message unexpected-error-message error-message">
                <FontAwesomeIcon icon={faExclamationCircle} />
                An unexpected error occurred. Please try again.
              </div>
            )}
            <div className="buttons">
              <button
                id={
                  schedule.term +
                  schedule.year +
                  'cancel-add-prospective-course'
                }
                onClick={event => this.handleCancelClick(visibleForm, event)}
                onKeyUp={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    this.handleCancelClick(event);
                  }
                }}
              >
                Cancel
              </button>
              <button
                id={
                  schedule.term +
                  schedule.year +
                  'submit-add-prospective-course'
                }
                onClick={event => {
                  event.preventDefault();
                  this.addProspectiveCourse();
                }}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    this.addProspectiveCourse();
                  }
                }}
                disabled={!this.state.isFormInputValid}
              >
                Assign Prospective Course{' '}
                <FontAwesomeIcon icon={faPlusCircle} />
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }

  renderAddOtherTeachingItemForm() {
    const {
      schedule: { term },
      facultyMember: { id },
      selectedYear
    } = this.props;
    const { visibleForm } = this.state;

    return (
      <div className="add-proposed-course-wrapper">
        <h1>Add Other Teaching Effort</h1>
        <form
          id={`add-other-teaching-item`}
          className="other-teaching-item-form"
        >
          <div className="other-teaching-item-inputs">
            <Input
              formValue={this.formValues.description}
              controlFunc={value => this.handleValueChange(value)}
            />
            <Input
              formValue={this.formValues.effort}
              controlFunc={value => this.handleValueChange(value)}
              trimWhitespaces={true}
            />
          </div>
          <div id="form-end-wrapper">
            <div className="buttons">
              <button
                type="button"
                id={`cancel-other-teaching-item`}
                onClick={event => this.handleCancelClick(visibleForm, event)}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    this.handleCancelClick(event);
                  }
                }}
              >
                Cancel
              </button>
              <button
                id={`submit-other-teaching-activity`}
                onClick={event => {
                  event.preventDefault();
                  this.handleSubmitClick(event, id, term, selectedYear);
                }}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    this.handleSubmitClick(event, id, term, selectedYear);
                  }
                }}
                disabled={
                  !this.state.descriptionInputIsValid ||
                  !this.state.effortInputIsValid
                }
              >
                Add Other Teaching Activity
                <FontAwesomeIcon icon={faPlusCircle} />
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }

  showForm(formName, event) {
    let formSendFocus = false;
    if (event) {
      formSendFocus = true;
    }
    this.setState(
      {
        visibleForm: formName,
        [`show${formName}`]: true,
        [`show${formName}SendFocus`]: formSendFocus
      },
      () =>
        this[`show${formName}RevealRef`].current.handleOpenClick(
          document.createEvent('Event')
        )
    );
  }

  addProspectiveCourse() {
    const { schedule, addProspectiveCourse, selectedYear } = this.props;
    const { selectedCourse } = this.state;
    let facultyMemberId = this.props.match.params.facultyMemberId;
    addProspectiveCourse(
      facultyMemberId,
      selectedYear,
      schedule.term,
      selectedCourse
    );
  }

  handleCourseOptionsChange(selectedCourse) {
    this.setState({
      selectedCourse: selectedCourse.value,
      isFormInputValid: this.formValues[selectedCourse.valueId].validatorFunc()
        .isValid
    });
  }

  validateFormInput() {
    this.setState(state => ({
      isFormInputValid: this.formValues[state.selectedCourse].validatorFunc()
        .isValid
    }));
  }

  handleCancelClick(formName, event) {
    event.preventDefault();
    this[`show${formName}RevealRef`].current.handleCancelClick(event);
  }

  handleSubmitClick(event, facultyMemberId, term, academicYear) {
    event.preventDefault();

    let otherTeachingObject = {
      term,
      description: this.state.description,
      effort: parseFloat(this.state.effort)
    };

    this.props.addNewOtherTeachingItem(
      facultyMemberId,
      academicYear,
      otherTeachingObject
    );
    this.showAddOtherTeachingItemRevealRef.current.handleCancelClick(event);
  }

  handleValueChange = formValue => {
    this.setState(prevState => ({
      ...prevState,
      [formValue.valueId]: formValue.value,
      [formValue.valueId + 'InputIsValid']: this.formValues[
        formValue.valueId
      ].validatorFunc(formValue.value).isValid
    }));
  };

  resetForm() {
    let newState = { ...this.baseState, resetting: true, visibleForm: '' };
    Object.keys(this.formValues).forEach(key => {
      newState[key] = undefined;
    });

    this.setState(newState);
  }
}

const loadingSelector = createLoadingSelector([
  ADD_PROSPECTIVE_COURSE,
  ADD_OTHER_TEACHING_ITEM,
  DELETE_OTHER_TEACHING_ITEM
]);
const errorSelector = createErrorMessageSelector([
  ADD_PROSPECTIVE_COURSE,
  ADD_OTHER_TEACHING_ITEM,
  DELETE_OTHER_TEACHING_ITEM
]);
const mapStateToProps = state => {
  const { auth, config, courses, faculty, accordion } = state;
  return {
    scopes: auth.scopes,
    loading: loadingSelector(state),
    addProspectiveCourseError: errorSelector(state),
    addOtherTeachingItemError: errorSelector(state),
    courseComponents:
      config && config.config && config.config.courseComponentTypes,
    courseList: courses.list,
    workPlan: faculty.viewWorkPlan,
    defaultTeachingEffort: faculty.defaultTeachingEffort,
    expandAll: accordion.expandAll,
    facultyMember: faculty.viewIndividual,
    effortMode: config?.config?.facultyWorkPlanEffortUnit
  };
};

export default connect(mapStateToProps, {
  getCourses,
  addNewOtherTeachingItem,
  addProspectiveCourse,
  showLoadingModal,
  showCustomJSXModal,
  showValidatingModal,
  hideModal
})(withRouter(FacultyTeachingAssignments));
