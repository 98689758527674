import React from 'react';
import Accordion from '../common/Accordion';
import FacultyCourseComponent from './FacultyCourseComponent';
import { deleteProspectiveCourse } from '../../service/actions/faculty';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { createErrorMessageSelector } from '../../service/selectors';
import {
  DELETE_PROSPECTIVE_COURSE,
  ADD_SECTION_PLACEHOLDER,
  DELETE_SECTION_PLACEHOLDER,
  UPDATE_ASSIGNED_COURSE_SECTION
} from '../../service/types';

const FacultyCourse = props => {
  const {
    deleteProspectiveCourse,
    schedule,
    selectedYear,
    scheduleCourse,
    courseError,
    isProposedCourse,
    removedProposedCourses,
    newProspectiveCourse,
    expandAll,
    expandedItems,
    workPlan,
    scopes,
    facultyMember,
    effortUnit
  } = props;

  const deleteFunction = function() {
    let facultyMemberId = props.match.params.facultyMemberId;
    deleteProspectiveCourse(
      facultyMemberId,
      selectedYear,
      schedule.term,
      scheduleCourse.courseId
    );
  };

  let courseEnrollmentText;
  if (scheduleCourse.projectedEnrollment) {
    courseEnrollmentText =
      scheduleCourse.projectedEnrollment +
      ' student' +
      (scheduleCourse.projectedEnrollment > 1 ? 's' : '');
  } else {
    courseEnrollmentText = 'No projected enrollment';
  }

  const courseInfo = scheduleCourse.courseInfo;

  const heading = (
    <div className="course-content">
      <span className="course-number">
        {courseInfo.courseNumber.replace(/(\d)/, ' $1')}
      </span>
      <span className="course-name">
        {courseInfo.name}
        <span className="credit-hours">{`(${
          courseInfo.variableCredit ? 'Variable' : courseInfo.credits
        }\u00A0CR)`}</span>
      </span>
      <div className="enrollment">
        <FontAwesomeIcon icon={faUsers} />
        <span>{courseEnrollmentText}</span>
      </div>
    </div>
  );
  const headingAlt = `${courseInfo.courseNumber} ${courseInfo.name}`;

  const content = (
    <div className="faculty-course-content">
      {scheduleCourse.components.map((component, i) => {
        return (
          <FacultyCourseComponent
            key={i}
            scheduleCourse={scheduleCourse}
            component={component}
            schedule={schedule}
            selectedYear={selectedYear}
            effortUnit={effortUnit}
          />
        );
      })}
    </div>
  );

  return (
    <Accordion
      errorMessage={
        courseError && courseError.id === scheduleCourse.id
          ? courseError.error
          : false
      }
      isRemoved={
        removedProposedCourses &&
        removedProposedCourses.includes(scheduleCourse.id)
      }
      key={scheduleCourse.id}
      accordionId={scheduleCourse.id}
      heading={heading}
      headingAlt={headingAlt}
      content={content}
      enableDeletion={
        workPlan &&
        workPlan.state !== 'past' &&
        isProposedCourse &&
        facultyMember.active === true &&
        scopes.includes('faculty-member.work-plan.write')
      }
      deleteFunction={deleteFunction}
      deleteLabel="Delete Course?"
      startExpanded={
        (expandAll && !expandedItems.hasOwnProperty(scheduleCourse.id)) ||
        expandedItems[scheduleCourse.id] ||
        (newProspectiveCourse &&
          newProspectiveCourse.courseId === scheduleCourse.id)
      }
    />
  );
};

const errorSelector = createErrorMessageSelector([
  DELETE_PROSPECTIVE_COURSE,
  ADD_SECTION_PLACEHOLDER,
  DELETE_SECTION_PLACEHOLDER,
  UPDATE_ASSIGNED_COURSE_SECTION
]);
const mapStateToProps = state => {
  const { auth, config, courses, faculty, accordion } = state;
  return {
    scopes: auth.scopes,
    courseComponents:
      config && config.config && config.config.courseComponentTypes,
    courseList: courses.list,
    removedProposedCourses: faculty.removedProposedCourses,
    newProspectiveCourse: faculty.newProspectiveCourse,
    termList: config.termsByCalendarYear,
    expandAll: accordion.expandAll,
    expandedItems: accordion.expandedItems,
    workPlan: faculty.viewWorkPlan,
    facultyMember: faculty.viewIndividual,
    courseError: errorSelector(state)
  };
};

export default connect(mapStateToProps, {
  deleteProspectiveCourse
})(withRouter(FacultyCourse));
