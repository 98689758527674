import React from 'react';
import './UpdateCourse.scss';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faArchive } from '@fortawesome/free-solid-svg-icons/faArchive';
import Select from '../common/Select';
import Input from '../common/Input';
import ToggleSlider from '../common/ToggleSlider';
import CheckboxInput from '../common/CheckboxInput';
import {
  showCustomJSXModal,
  showArchivingModal,
  hideModal
} from '../../service/actions/modal';
import {
  updateCourse,
  updateCourseComponents,
  clearErrors,
  archiveCourse
} from '../../service/actions/courses';
import {
  hideSidePanel,
  updateTrapFocus
} from '../../service/actions/sidePanel';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import {
  UPDATE_COURSE,
  UPDATE_COURSE_COMPONENTS,
  ARCHIVE_COURSE
} from '../../service/types';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';

class UpdateCourse extends React.Component {
  constructor(props) {
    super(props);

    if (!props.course) {
      console.error('no course provided to the side panel');
    }
    const { course } = props;

    this.state = {
      isFormInputValid: true,
      archiving: false,
      duplicateErrorMessage: '',
      resetting: false,
      courseNumberPrefix: course.courseNumber.substring(
        0,
        course.courseNumber.search(/\d/)
      ),
      courseNumber: course.courseNumber.substring(
        course.courseNumber.search(/\d/)
      ),
      courseCredits: course.variableCredit ? undefined : course.credits,
      variableCredit: course.variableCredit,
      courseName: course.name,
      components: course.components,
      sectionComponent: course.components[0].componentType
    };
    this.baseState = { ...this.state };

    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleCheckboxInputChange = this.handleCheckboxInputChange.bind(this);

    this.formValues = {
      courseNumberPrefix: {
        name: 'course-number-select',
        valueId: 'courseNumberPrefix',
        defaultValue: '',
        labelTitle: 'Course Number Prefix',
        validatorFunc: () => ({
          isValid: true,
          errorMessage: 'Invalid'
        })
      },
      courseNumber: {
        name: 'course-number',
        labelTitle: 'Course Number',
        valueId: 'courseNumber',
        defaultValue: this.state.courseNumber,
        validatorFunc: value => ({
          isValid: /^[0-9]{3,4}([A-Z]{1,3})?$/.test(value),
          errorMessage: 'Course number is invalid'
        })
      },
      courseCredits: {
        name: 'course-credits',
        valueId: 'courseCredits',
        labelTitle: 'Credits',
        defaultValue: this.state.courseCredits,
        validatorFunc: value => ({
          isValid:
            /^(\d{1,2})+(\.\d{1,2})?$/.test(value) &&
            parseFloat(value) > 0 && parseFloat(value) <= 20,
          errorMessage: 'Invalid credit hours'
        })
      },
      variableCredit: {
        name: 'variable-credit',
        valueId: 'variableCredit',
        labelTitle: 'Variable Credit',
        defaultValue: this.state.variableCredit,
        validatorFunc: () => ({
          isValid: true,
          errorMessage: 'Invalid credit hours'
        })
      },
      courseName: {
        name: 'course-name',
        valueId: 'courseName',
        labelTitle: 'Course Name',
        defaultValue: this.state.courseName,
        validatorFunc: value => {
          return {
            isValid:
              /^.{1,100}$/.test(value) && value && value.trim().length > 0,
            errorMessage: 'Invalid course name'
          };
        }
      },
      maxPerSectionCapacity: {
        name: 'max-per-section-capacity',
        valueId: 'maxPerSectionCapacity',
        labelTitle: 'Section Capacity',
        defaultValue: '',
        validatorFunc: value => ({
          isValid:
            (/^\d+$/.test(value) &&
              parseFloat(value) > 0 &&
              parseFloat(value) <= 9999) ||
            !value,
          errorMessage: 'Invalid max per-section capacity'
        })
      },
      sectionComponent: {
        name: 'section-component',
        valueId: 'sectionComponent',
        labelTitle: 'Instructional Components',
        defaultValue: '',
        validatorFunc: value => {
          return {
            isValid:
              /^.{1,20}$/.test(value) && value && value.trim().length > 0,
            errorMessage: 'Invalid section component'
          };
        }
      }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      updatingCourse,
      archivingCourse,
      errorMessage,
      updateTrapFocus
    } = this.props;
    if (
      updatingCourse !== prevProps.updatingCourse ||
      archivingCourse !== prevProps.archivingCourse
    ) {
      if (!updatingCourse && !archivingCourse && !errorMessage) {
        this.closePanel(false);
      }
    }
    if (this.state.isFormInputValid !== prevState.isFormInputValid) {
      updateTrapFocus();
    }
  }

  render() {
    const {
      courseNumberPrefix,
      courseNumber,
      duplicateErrorMessage,
      resetting
    } = this.state;
    const { coursePrefixOptions, courseComponentTypes, course } = this.props;

    const editDetailsDisabled = !course.editable;

    if (resetting || !coursePrefixOptions) return null;

    let defaultOption = coursePrefixOptions[0];
    let sortedOptions = [].concat(coursePrefixOptions).sort((a, b) => {
      return ('' + a).localeCompare(b);
    });

    let populatedPrefix;
    if (!courseNumberPrefix) {
      populatedPrefix = defaultOption;
    } else {
      populatedPrefix = courseNumberPrefix;
    }

    const { errorMessage } = this.props;

    return (
      <section>
        <div className="top first-focus">
          <h1>Update Course</h1>
          <button
            className="circle"
            ref={this.closeButton}
            tabIndex="0"
            title="Close Section Panel"
            onClick={event => {
              event.preventDefault();
              this.closePanel(false);
            }}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                this.closePanel(true);
              }
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        <form id="course-form-wrapper">
          <div id="course-entry-form">
            <div role="group" aria-labelledby="course-number-label">
              <label
                id="course-number-label"
                className={editDetailsDisabled ? 'disabled ' : ''}
              >
                Course Number
              </label>
              <div className="select-input-combo">
                <Select
                  controlFunc={this.handleValueChange}
                  optionList={sortedOptions}
                  defaultOption={populatedPrefix}
                  formValue={this.formValues.courseNumberPrefix}
                  hideLabel={true}
                  disabled={editDetailsDisabled}
                  showClearButton={false}
                />

                <Input
                  formValue={this.formValues.courseNumber}
                  controlFunc={this.handleValueChange}
                  hideLabel={true}
                  toUppercase={true}
                  trimWhitespaces={true}
                  disabled={editDetailsDisabled}
                  errorStyle={{ left: '-86px' }}
                />
              </div>
            </div>
            <div
              onClick={
                this.state.variableCredit && !editDetailsDisabled
                  ? () => {
                      this.handleValueChange({
                        valueId: 'variableCredit',
                        value: false
                      });
                    }
                  : undefined
              }
            >
              <Input
                formValue={this.formValues.courseCredits}
                controlFunc={this.handleValueChange}
                immediatelyValidate={false}
                trimWhitespaces={true}
                hideError={this.state.variableCredit}
                hideErrorMessage={this.state.variableCredit}
                disabled={this.state.variableCredit || editDetailsDisabled}
                actionable={this.state.variableCredit && !editDetailsDisabled}
              />
            </div>
            <div>
              <label
                id="variable-credit-label"
                className={editDetailsDisabled ? 'disabled ' : ''}
              >
                Variable Credit
              </label>
              {this.renderVariableCreditSlider({
                disabled: editDetailsDisabled
              })}
            </div>
            <div>
              <Input
                formValue={this.formValues.courseName}
                controlFunc={this.handleValueChange}
                immediatelyValidate={true}
                disabled={editDetailsDisabled}
              />
            </div>
            <div>
              <CheckboxInput
                formRows={[]
                  .concat(courseComponentTypes)
                  .sort((a, b) => ('' + a.name).localeCompare(b.name))}
                controlFunc={this.handleCheckboxInputChange}
                checkboxFormValues={this.formValues.sectionComponent}
                inputFormValues={this.formValues.maxPerSectionCapacity}
                startingValues={this.state.components.map(component => {
                  return {
                    checkboxId: component.componentType,
                    inputValue: component.maxCapacity || undefined
                  };
                })}
                checkboxLabel="Instructional Components"
                inputLabel="Section Capacity"
                inputOptional={true}
              />
            </div>
          </div>

          <div className="form-end-wrapper">
            {errorMessage && (
              <div className="form-message unexpected-error-message error-message">
                <FontAwesomeIcon icon={faExclamationCircle} />
                An unknown error occurred. Please try again.
              </div>
            )}

            {duplicateErrorMessage && (
              <div className="form-message duplicate-error-message warning-message">
                <FontAwesomeIcon icon={faExclamationTriangle} />
                {populatedPrefix + courseNumber} already exists in the course
                list.
              </div>
            )}
            <div className="button-wrapper">
              <div className="buttons">
                <button
                  id="cancel-course-entry"
                  onClick={event => {
                    event.preventDefault();
                    this.closePanel(false);
                  }}
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      this.closePanel(true);
                    }
                  }}
                >
                  Cancel
                </button>
                <button
                  id="submit-course-entry"
                  onClick={event => this.handleSubmitClick(event)}
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      this.handleSubmitClick(event);
                    }
                  }}
                  disabled={!this.state.isFormInputValid}
                >
                  Save Course <FontAwesomeIcon icon={faCheckCircle} />
                </button>
              </div>
              {this.renderArchiveButton()}
            </div>
          </div>
        </form>
      </section>
    );
  }

  renderArchiveButton() {
    const { archiving } = this.state;

    if (archiving) {
      return (
        <div className="Archive-buttons">
          <div>
            <span className="confirmation-message">Archive Course?</span>
          </div>

          <div className="archive-buttons-wrapper">
            <button
              className="circle"
              title="Cancel Archive"
              tabIndex="0"
              onClick={e => {
                e.preventDefault();
                this.setState({ archiving: false });
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  this.setState({ archiving: false });
                }
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <button
              className="circle"
              title="Confirm Archive"
              tabIndex="0"
              onClick={e => {
                this.handleSubmitClick(e);
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  this.handleSubmitClick(e);
                }
              }}
            >
              <FontAwesomeIcon icon={faCheck} />
            </button>
          </div>
        </div>
      );
    }
    return (
      <button
        id="archive-course-btn"
        className="circle"
        onClick={e => {
          e.preventDefault();
          this.setState({ archiving: true });
        }}
        onKeyDown={e => {
          e.preventDefault();
          if (e.key === 'Enter') {
            this.setState({ archiving: true });
          }
        }}
      >
        <FontAwesomeIcon icon={faArchive} style={{ color: 'black' }} />
      </button>
    );
  }

  renderVariableCreditSlider(options) {
    const toggleFunction = value => {
      if (value) {
        this.setState({
          courseCredits: undefined
        });
      }
      this.handleValueChange({
        valueId: 'variableCredit',
        value: value || false
      });
    };

    const value = this.state.variableCredit || false;
    return (
      <ToggleSlider
        controlFunc={value => toggleFunction(value)}
        value={value}
        disabled={!!options.disabled}
      />
    );
  }

  handleSubmitClick(event) {
    event.preventDefault();
    this.updateCourse();
  }

  updateCourse() {
    this.validateFormInput();
    const {
      courseNumber,
      courseCredits,
      courseName,
      components,
      variableCredit
    } = this.state;
    const { course, archiveCourse } = this.props;

    if (this.state.archiving) {
      const defaultCourseData = {
        courseNumber: course.courseNumber,
        name: course.name,
        variableCredit: course.variableCredit
      };
      if (defaultCourseData.variableCredit === false) {
        Object.assign(defaultCourseData, { credits: courseCredits });
      }
      return archiveCourse(course.id, defaultCourseData);
    }
    if (course.editable) {
      let courseNumberPrefix = this.state.courseNumberPrefix;

      let courseUpdate = {
        courseNumber: courseNumberPrefix + courseNumber,
        name: courseName,
        variableCredit: variableCredit
      };
      if (!variableCredit) {
        courseUpdate.credits = parseFloat(courseCredits);
      }

      this.props.updateCourse(course.id, courseUpdate);
    }

    this.props.updateCourseComponents(
      course.id,
      components.map(component => {
        let updatedComponent = {
          componentType: component.componentType
        };
        if (component.maxCapacity) {
          updatedComponent.maxCapacity = component.maxCapacity;
        }
        return updatedComponent;
      })
    );
  }

  handleValueChange(formValue) {
    const { coursePrefixOptions } = this.props;

    let currentCourseNumber;
    if (formValue.valueId === 'courseNumberPrefix') {
      currentCourseNumber = formValue.value;
    } else {
      currentCourseNumber =
        this.state.courseNumberPrefix || coursePrefixOptions[0];
    }

    if (formValue.valueId === 'courseNumber') {
      currentCourseNumber += formValue.value;
    } else {
      currentCourseNumber += this.state.courseNumber;
    }
    this.setState({
      [formValue.valueId]: formValue.value,
      isFormInputValid: Object.keys(this.formValues).reduce((result, next) => {
        let theResult;
        if (next === formValue.valueId) {
          theResult =
            result &&
            this.formValues[next].validatorFunc(formValue.value).isValid;
        } else {
          theResult =
            result &&
            this.formValues[next].validatorFunc(this.state[next]).isValid;
        }

        if (next.toString() === 'courseCredits') {
          theResult =
            theResult ||
            (formValue.valueId === 'variableCredit'
              ? formValue.value
              : this.state.variableCredit);
        }
        return theResult;
      }, true),
      duplicateErrorMessage: this.props.courses.reduce((aggregator, item) => {
        return (
          aggregator ||
          (item.courseNumber === currentCourseNumber &&
            currentCourseNumber !== this.props.course.courseNumber)
        );
      }, false)
    });
  }

  handleCheckboxInputChange(values) {
    let components;
    components = values.map(c => {
      let newComponent = {};
      newComponent.componentType = c.checkboxValue;
      if (c.inputValue) newComponent.maxCapacity = parseInt(c.inputValue);
      return newComponent;
    });

    let isCheckboxInputValid;
    if (values.length > 0) {
      isCheckboxInputValid = values.reduce((result, next) => {
        let theResult =
          result &&
          this.formValues.sectionComponent.validatorFunc(next.checkboxValue)
            .isValid &&
          this.formValues.maxPerSectionCapacity.validatorFunc(next.inputValue)
            .isValid;
        return theResult;
      }, true);
    } else {
      isCheckboxInputValid = false;
    }

    this.setState({
      sectionComponent: isCheckboxInputValid
        ? values[0].checkboxValue
        : isCheckboxInputValid,
      maxPerSectionCapacity: isCheckboxInputValid
        ? values[0].inputValue
        : isCheckboxInputValid,
      isFormInputValid: Object.keys(this.formValues).reduce((result, next) => {
        let theResult;
        if (
          next.toString() === 'sectionComponent' ||
          next.toString() === 'maxPerSectionCapacity'
        ) {
          theResult = result && isCheckboxInputValid;
        } else if (next.toString() === 'courseCredits') {
          theResult =
            result &&
            (this.formValues[next].validatorFunc(this.state[next]).isValid ||
              this.state.variableCredit);
        } else {
          theResult =
            result &&
            this.formValues[next].validatorFunc(this.state[next]).isValid;
        }
        return theResult;
      }, true),
      components: components
    });
  }

  validateFormInput() {
    const fieldsValid = Object.keys(this.formValues).reduce((result, next) => {
      let theResult = this.formValues[next].validatorFunc(this.state[next])
        .isValid;
      if (next.toString() === 'courseCredits') {
        theResult = theResult || this.state.variableCredit;
      }

      return result && theResult;
    }, true);

    this.setState({
      isFormInputValid: fieldsValid
    });
  }

  closePanel(shouldSendFocus) {
    this.props.hideSidePanel({
      sendFocus: !!shouldSendFocus
    });
  }
}

const loadingSelector = createLoadingSelector([
  UPDATE_COURSE,
  UPDATE_COURSE_COMPONENTS,
  ARCHIVE_COURSE
]);
const errorMessageSelector = createErrorMessageSelector([
  UPDATE_COURSE,
  UPDATE_COURSE_COMPONENTS,
  ARCHIVE_COURSE
]);
const mapStateToProps = state => {
  const { config, courses } = state;
  return {
    updatingCourse: loadingSelector(state),
    archivingCourse: loadingSelector(state),
    errorMessage: errorMessageSelector(state),
    courses: courses.list,
    coursePrefixOptions: config.config && config.config.coursePrefixes,
    courseComponentTypes: config.config && config.config.courseComponentTypes
  };
};

export default connect(mapStateToProps, {
  updateCourse,
  updateCourseComponents,
  clearErrors,
  hideSidePanel,
  updateTrapFocus,
  archiveCourse,
  showCustomJSXModal,
  showArchivingModal,
  hideModal
})(UpdateCourse);
