import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './LandingPage.scss';
import imgSchedule from './img/schedule.svg';
import imgFaculty from './img/faculty.svg';
import imgCourses from './img/courses.svg';
import imgPlanOfStudy from './img/plan-of-study.svg';
import imgStudents from './img/students.svg';
import imgLocations from './img/locations.svg';
import updatePageTitle from '../common/helpers/updatePageTitle';
import { sendFocusToFirstH1 } from '../App/SkipLink';

const LandingPage = props => {
  useEffect(() => {
    updatePageTitle('Home');
  }, []);
  useEffect(() => {
    if (props.location.state && props.location.state.keyed === true) {
      sendFocusToFirstH1();
    }
  }, [props.location]);

  const scopes = useSelector(state => {
    return state.auth && state.auth.scopes;
  });

  if (!scopes) {
    return null;
  }

  const buttons = [
    {
      path: '/schedules',
      permission: 'schedule.read',
      image: imgSchedule,
      heading: 'Schedule of Classes',
      additionalText:
        'View the list of schedules, plan course sections, and change schedule status.',
      isLarge: true
    },
    {
      path: '/faculty-members',
      permission: 'faculty-member.read',
      image: imgFaculty,
      heading: 'Faculty Work Management',
      additionalText:
        'View the list of faculty members, add new faculty, and assign work assignments.',
      isLarge: true
    },
    {
      path: '/courses',
      permission: 'course.read',
      image: imgCourses,
      heading: 'Courses'
    },
    {
      path: '/plans-of-study',
      permission: 'plan-of-study.read',
      image: imgPlanOfStudy,
      heading: 'Plans of Study'
    },
    {
      path: '/student-groups',
      permission: 'student-group.read',
      image: imgStudents,
      heading: 'Student Groups'
    },
    {
      path: '/locations',
      permission: 'location.read',
      image: imgLocations,
      heading: 'Locations'
    }
  ];

  return (
    <div id="landing-page">
      {buttons.map((buttonInfo, i) => (
        <LandingPageButton
          firstFocus={i === 0 ? true : false}
          key={buttonInfo.path}
          path={buttonInfo.path}
          unauthorized={!scopes.includes(buttonInfo.permission)}
          image={buttonInfo.image}
          heading={buttonInfo.heading}
          additionalText={buttonInfo.additionalText || ''}
          isLarge={buttonInfo.isLarge || false}
        />
      ))}
    </div>
  );
};

const LandingPageButton = withRouter(props => {
  return (
    <a
      className={
        'landing-page-tile ' +
        (props.isLarge ? 'large ' : '') +
        (props.firstFocus ? 'first-focus ' : '') +
        (props.unauthorized ? 'unauthorized ' : '')
      }
      href={props.unauthorized ? '/' : props.path}
      title={props.heading}
      tabIndex={props.unauthorized ? '-1' : '0'}
      onClick={event => {
        event.preventDefault();

        if (props.unauthorized) {
          return;
        }

        if (event.ctrlKey || event.metaKey) {
          window.open(props.history.createHref({ pathname: props.path }));
        } else {
          props.history.push({
            pathname: props.path
          });
        }
      }}
      onKeyDown={event => {
        if (event.key === 'Enter') {
          event.preventDefault();

          if (props.unauthorized) {
            return true;
          }

          if (event.ctrlKey || event.metaKey) {
            window.open(props.history.createHref({ pathname: props.path }));
          } else {
            props.history.push({
              pathname: props.path,
              state: {
                keyed: true
              }
            });
          }
        }
      }}
    >
      <div className="text">
        <h2>{props.heading}</h2>
        {props.isLarge ? <p>{props.additionalText}</p> : null}
      </div>
      <div className="image">
        <img alt={props.heading} src={props.image} />
      </div>
    </a>
  );
});

export default LandingPage;
