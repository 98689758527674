import React from 'react';
import './ChangeState.scss';
import { connect } from 'react-redux';
import { showLoadingModal, hideModal } from '../../service/actions/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import Radio from '../common/Radio';
import { POST_SCHEDULE_STATE_CHANGE } from '../../service/types';
import {
  createErrorMessageSelector,
  createLoadingSelector
} from '../../service/selectors';

class ChangeState extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormInputValid: false,
      mergePriorYear: false,
      direction: 'next',
      selectedOption: null
    };
    this.baseState = { ...this.state };
    this.changeState = this.changeState.bind(this);
    this.formReset = this.formReset.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.formValues = {
      copyPreviousYear: {
        name: 'copy-previous-year',
        valueId: 'copyPreviousYear',
        labelTitle: 'Copy Previous Year',
        cy: 'copyPreviousYear',
        validatorFunc: () => ({
          isValid: true,
          errorMessage: 'Invalid'
        })
      },
      startNewProjected: {
        name: 'start-new-with-projected',
        labelTitle: 'Start New with Projected',
        valueId: 'startNewProjected',
        cy: 'startNewProject',
        validatorFunc: () => ({
          isValid: true,
          errorMessage: 'Invalid'
        })
      },
      revertToPlanning: {
        name: 'revert-to-planning',
        valueId: 'revertToPlanning',
        labelTitle: 'Back to "Planning"',
        cy: 'revertToPlanning',
        validatorFunc: () => ({
          isValid: true,
          errorMessage: 'Invalid'
        })
      },
      markAsComplete: {
        name: 'mark-as-complete',
        valueId: 'markAsComplete',
        labelTitle: 'Mark as "Complete"',
        cy: 'markAsComplete',
        validatorFunc: () => ({
          isValid: true,
          errorMessage: 'Invalid'
        })
      }
    };
  }

  componentDidMount() {
    this.setState({
      mergePriorYear: this.props.hasPriorYear || false
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.hasPriorYear !== this.props.hasPriorYear) {
      this.setState({
        mergePriorYear: this.props.hasPriorYear || false,
        selectedOption: null
      });
    }
  }

  render() {
    const { schedule } = this.props;
    return (
      <form id="change-state-form">
        {schedule.state === 'projected' && (
          <fieldset>{this.renderChangeProjected()}</fieldset>
        )}
        {schedule.state === 'planning' && this.renderChangePlanning()}
        {schedule.state === 'ready' && (
          <fieldset>{this.renderChangeReady()}</fieldset>
        )}
        {this.props.errorMessage && (
          <div className="error-message schedules-error-message">
            <FontAwesomeIcon icon={faExclamationCircle} />
            {this.props.errorMessage}
          </div>
        )}
      </form>
    );
  }

  renderChangePlanning() {
    return (
      <>
        <legend className="radio-legend">
          {
            'Are you sure you want to move this schedule from "Planning" to "Ready"?'
          }
        </legend>
        <div className="form-end-wrapper">
          <div className="buttons">
            <button
              onClick={event => this.handleCancelClick(event)}
              id="cancelStatusChange"
            >
              Cancel
            </button>
            <button onClick={this.changeState} id="confirmStatusChange">
              Confirm
              <FontAwesomeIcon icon={faCheckCircle} />
            </button>
          </div>
        </div>
      </>
    );
  }

  renderChangeReady() {
    return (
      <>
        <legend className="radio-legend">
          {
            'Would you like to move this schedule back to "Planning" or mark as "Complete"?'
          }
        </legend>
        <div className="radios interactive">
          <Radio
            cy={this.formValues.revertToPlanning.cy}
            domId={this.formValues.revertToPlanning.valueId}
            label={this.formValues.revertToPlanning.labelTitle}
            id={this.formValues.revertToPlanning.valueId}
            selected={
              this.state.selectedOption ===
                this.formValues.revertToPlanning.valueId || false
            }
            controlFunc={this.handleRadioChange}
          />
          <Radio
            cy={this.formValues.markAsComplete.cy}
            domId={this.formValues.markAsComplete.valueId}
            label={this.formValues.markAsComplete.labelTitle}
            id={this.formValues.markAsComplete.valueId}
            selected={
              this.state.selectedOption ===
                this.formValues.markAsComplete.valueId ||
              !this.state.selectedOption
            }
            controlFunc={this.handleRadioChange}
          />
        </div>
        <div className="form-end-wrapper">
          <div className="buttons">
            <button
              onClick={event => this.handleCancelClick(event)}
              id="cancelStatusChange"
            >
              Cancel
            </button>
            <button onClick={this.changeState} id="confirmStatusChange">
              Confirm
              <FontAwesomeIcon icon={faCheckCircle} />
            </button>
          </div>
        </div>
      </>
    );
  }

  renderChangeProjected() {
    const { hasPriorYear } = this.props;

    return (
      <>
        <legend className="radio-legend">
          {
            "Would you like to copy last year's schedule details or start a new schedule from projected?"
          }
        </legend>
        <div className="radios interactive">
          <Radio
            cy={this.formValues.copyPreviousYear.cy}
            domId={this.formValues.copyPreviousYear.valueId}
            label={this.formValues.copyPreviousYear.labelTitle}
            id={this.formValues.copyPreviousYear.valueId}
            selected={
              (this.state.selectedOption &&
                this.state.selectedOption ===
                  this.formValues.copyPreviousYear.valueId) ||
              (!this.state.selectedOption && hasPriorYear) ||
              false
            }
            disabled={!hasPriorYear}
            controlFunc={this.handleRadioChange}
          />
          <Radio
            cy={this.formValues.startNewProjected.cy}
            domId={this.formValues.startNewProjected.valueId}
            label={this.formValues.startNewProjected.labelTitle}
            id={this.formValues.startNewProjected.valueId}
            selected={
              this.state.selectedOption ===
                this.formValues.startNewProjected.valueId ||
              (!this.state.selectedOption && !hasPriorYear)
            }
            controlFunc={this.handleRadioChange}
          />
        </div>
        <div className="form-end-wrapper">
          <div className="buttons">
            <button onClick={event => this.handleCancelClick(event)}>
              Cancel
            </button>
            <button onClick={this.changeState} id="statusChangeSubmit">
              {'Move to Planning'}
              <FontAwesomeIcon icon={faCheckCircle} />
            </button>
          </div>
        </div>
      </>
    );
  }

  changeState(event) {
    event.preventDefault();
    let { direction, mergePriorYear } = this.state;
    this.props.handleSubmitClick(direction, mergePriorYear);
  }

  validateFormInput() {
    this.setState(state => ({
      isFormInputValid: this.formValues[state.selectedOption].validatorFunc()
        .isValid
    }));
  }

  handleCancelClick(event) {
    event.preventDefault();
    this.formReset();
    this.props.cancelFunc(event);
  }

  formReset() {
    let newState = { ...this.baseState };
    this.setState(newState);
  }

  handleRadioChange(id) {
    let direction;
    if (id === 'revertToPlanning') {
      direction = 'prev';
    } else {
      direction = 'next';
    }
    this.setState({
      selectedOption: id,
      mergePriorYear: id === 'copyPreviousYear',
      direction: direction,
      isFormInputValid: this.formValues[id].validatorFunc().isValid
    });
  }
}

const updatingSelector = createLoadingSelector([POST_SCHEDULE_STATE_CHANGE]);
const errorSelector = createErrorMessageSelector([POST_SCHEDULE_STATE_CHANGE]);
const mapStateToProps = state => {
  const { schedules } = state;
  return {
    schedule: schedules.viewIndividual,
    updating: updatingSelector(state),
    errorMessage: errorSelector(state)
  };
};

export default connect(
  mapStateToProps,
  { showLoadingModal, hideModal }
)(ChangeState);
