const filterPlanOfStudyList = (currentFormState, planOfStudyList) => {
  const {
    programId,
    concentrationId,
    track,
    startTerm,
    startYear
  } = currentFormState;
  return [].concat(planOfStudyList).filter(planOfStudy => {
    let result = true;
    if (programId !== planOfStudy.program.id) {
      result = false;
    }

    if (concentrationId) {
      if (!planOfStudy.concentration) {
        result = false;
      } else if (concentrationId !== planOfStudy.concentration.id) {
        result = false;
      }
    } else {
      if (planOfStudy.concentration) {
        result = false;
      }
    }

    if (track !== planOfStudy.track) {
      result = false;
    }

    if (startTerm !== planOfStudy.startTerm) {
      result = false;
    }

    if (!startYear || startYear < planOfStudy.startYear) {
      result = false;
    }

    if (!planOfStudy.assignableToStudentGroup) {
      result = false;
    }

    return result;
  });
};

export default filterPlanOfStudyList;
