import React from 'react';
import updatePageTitle from '../helpers/updatePageTitle';
import { sendFocusToFirstH1 } from '../../App/SkipLink';

function mainViewComponent({ component, mainViewConfig }) {
  return class PP extends React.Component {
    constructor(props) {
      super(props);

      const { pageTitle } = mainViewConfig;
      updatePageTitle(pageTitle);
    }

    componentDidMount() {
      if (
        this.props.location.state &&
        this.props.location.state.keyed === true
      ) {
        sendFocusToFirstH1();
      }

      const offsetTop = document.getElementById('main-content').offsetTop;
      if (window.scrollY > offsetTop) {
        window.scrollTo(0, offsetTop);
      }

      if (this.props.loading) {
        this.props.showLoadingModal();
      } else {
        this.props.hideModal();
        if (
          this.props.location.state &&
          this.props.location.state.keyed === true
        ) {
          sendFocusToFirstH1();
        }
      }
    }

    componentDidUpdate(prevProps) {
      if (prevProps.loading !== this.props.loading) {
        if (this.props.loading) {
          this.props.showLoadingModal();
        } else {
          this.props.hideModal();
          if (
            this.props.location.state &&
            this.props.location.state.keyed === true
          ) {
            sendFocusToFirstH1();
          }
        }
      }
    }

    render() {
      const WrappedComponent = component;
      return <WrappedComponent {...this.props} />;
    }
  };
}

export { mainViewComponent };
