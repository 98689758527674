import React from 'react';
import './Header.scss';
import factsLogo from '../../assets/images/factslogo-v2.svg';
import vcuLogo from '../../assets/images/vcu-son-logo.svg';
import A11YLink from '../common/A11YLink/A11YLink';

import { signOut } from '../../service/actions/auth';

class Header extends React.Component {
  render() {
    return (
      <header>
        <div>
          <A11YLink path="/" title="Link to Landing Page for FACTS">
            <img
              alt="coursACTS — Faculty and Curricula Tracking System Logo"
              src={factsLogo}
            />
          </A11YLink>
        </div>
        <div>
          <div id="vcu-logo">
            <img alt="VCU School of Nursing Logo" src={vcuLogo} />
          </div>
          <div id="right-info">
            {this.props.displayName ? (
              <div id="profile" className="greeting">
                Welcome, {this.props.displayName}!
              </div>
            ) : null}
            <a
              href="/logout"
              id="logout-link"
              onClick={event => this.logout(event)}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  this.logout(event);
                }
              }}
            >
              Log Out
            </a>
          </div>
        </div>
      </header>
    );
  }

  logout(event) {
    event.preventDefault();
    signOut();
  }
}

export default Header;
