import React from 'react';
import './PlanOfStudyList.scss';
import { connect } from 'react-redux';
import { getPlanOfStudyList } from '../../service/actions/plansOfStudy';
import { showLoadingModal, hideModal } from '../../service/actions/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons/faChevronCircleRight';
import AddPlanOfStudy from './AddPlanOfStudy';
import { withRouter } from 'react-router-dom';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import { GET_PLANS_OF_STUDY, GET_CONFIG } from '../../service/types';
import { mainViewComponent } from '../common/HOCs';

const NO_CONCENTRATION = 'NO_CONCENTRATION';

class PlanOfStudyListView extends React.Component {
  componentDidMount() {
    this.props.getPlanOfStudyList();
  }

  render() {
    const { loading, errorMessage, scopes } = this.props;

    return (
      <div>
        <h1>Plans of Study</h1>
        {loading ? null : (
          <>
            {errorMessage && (
              <div className="error-message plans-of-study-error-message">
                <FontAwesomeIcon icon={faExclamationCircle} />
                {errorMessage}
              </div>
            )}

            {!errorMessage && scopes.includes('plan-of-study.write') && (
              <AddPlanOfStudy />
            )}
            {!errorMessage && this.renderPlanOfStudyList()}
          </>
        )}
      </div>
    );
  }

  renderPlanOfStudyList() {
    const { planOfStudyList } = this.props;

    if (planOfStudyList && planOfStudyList.length) {
      let sortedPlanOfStudyList = this.buildPlanOfStudyListJsx();

      return (
        <div className="plans-of-study-results">
          <ul className="none">{sortedPlanOfStudyList}</ul>
        </div>
      );
    } else {
      return (
        <p className="empty no-plans-of-study-message">
          No plans of study have been added.
        </p>
      );
    }
  }

  buildPlanOfStudyListJsx() {
    const { planOfStudyList, termList } = this.props;
    let programs = planOfStudyList.reduce(function(res, obj) {
      const programName = obj.program.name;
      let concentrationName = obj.concentration && obj.concentration.name;
      res[programName] = res[programName] || {};
      res[programName].concentrations = res[programName].concentrations || {};
      if (!obj.concentration) concentrationName = NO_CONCENTRATION;

      res[programName].concentrations[concentrationName] = (
        res[programName].concentrations[concentrationName] || []
      ).concat(obj);
      return res;
    }, {});

    return Object.keys(programs)
      .sort((a, b) => {
        return ('' + a).localeCompare(b);
      })
      .map(programKey => (
        <li className="program" key={programKey}>
          <h2>{programKey}</h2>
          <ul className="none">
            {Object.keys(programs[programKey].concentrations)
              .sort((a, b) => {
                if (a === NO_CONCENTRATION) return -1;
                if (b === NO_CONCENTRATION) return 1;
                return ('' + a).localeCompare(b);
              })
              .map(concentration => (
                <li key={concentration}>
                  {concentration !== NO_CONCENTRATION && (
                    <h3>{concentration}</h3>
                  )}
                  <ul className="none interactive pos-list">
                    {programs[programKey].concentrations[concentration]
                      .sort((a, b) => {
                        if (('' + a.track).localeCompare(b.track) === 0) {
                          if (a.startYear - b.startYear === 0) {
                            return (
                              termList.indexOf(a.startTerm) -
                              termList.indexOf(b.startTerm)
                            );
                          }
                          return a.startYear - b.startYear;
                        }
                        return ('' + a.track).localeCompare(b.track);
                      })
                      .map((item, index) => (
                        <li className="with-anchor" key={index}>
                          <a
                            href={this.props.match.path + '/' + item.id}
                            onClick={event =>
                              this.planOfStudyClicked(event, item.id, false)
                            }
                            onKeyUp={event => {
                              this.planOfStudyKeyDowned(event, item.id);
                            }}
                          >
                            <div className="content">
                              <span>{item.track}</span>
                              <span>
                                {item.studentGroupsCount || 0} student group
                                {item.studentGroupsCount !== 1 && 's'}
                              </span>
                              <span>Effective {item.startYear}</span>
                              <span>Starts {item.startTerm}</span>
                            </div>
                            <FontAwesomeIcon icon={faChevronCircleRight} />
                          </a>
                        </li>
                      ))}
                  </ul>
                </li>
              ))}
          </ul>
        </li>
      ));
  }

  planOfStudyKeyDowned(event, id) {
    if (event.key === 'Enter') {
      this.planOfStudyClicked(event, id, true);
    }
  }

  planOfStudyClicked(event, id, keyed) {
    event.preventDefault();

    const pathname = this.props.match.path + '/' + id;
    if (event.ctrlKey || event.metaKey) {
      window.open(this.props.history.createHref({ pathname }));
    } else {
      this.props.history.push({
        pathname,
        state: {
          keyed
        }
      });
    }
  }
}

const loadingSelector = createLoadingSelector([GET_PLANS_OF_STUDY, GET_CONFIG]);
const errorMessageSelector = createErrorMessageSelector([GET_PLANS_OF_STUDY]);
const mapStateToProps = state => {
  const { auth, config, plansOfStudy } = state;
  return {
    scopes: auth.scopes,
    loading: loadingSelector(state),
    errorMessage: errorMessageSelector(state),
    planOfStudyList: plansOfStudy.list,
    termList: config.termsByCalendarYear
  };
};

const mainViewConfig = {
  pageTitle: 'Plans of Study'
};

export default connect(
  mapStateToProps,
  { getPlanOfStudyList, showLoadingModal, hideModal }
)(
  withRouter(
    mainViewComponent({ component: PlanOfStudyListView, mainViewConfig })
  )
);
