import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import {
  updateLocation,
  archiveLocation
} from '../../service/actions/locations';
import {
  showCustomJSXModal,
  showValidatingModal,
  showCustomLoadingModal,
  hideModal
} from '../../service/actions/modal';
import { UPDATE_LOCATION, ARCHIVE_LOCATION } from '../../service/types';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import { updateTrapFocus } from '../../service/actions/sidePanel';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import LocationForm from './LocationForm';

class UpdateLocation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormInputValid: true,
      resetting: false,
      building: undefined,
      capacity: undefined,
      id: undefined,
      type: undefined,
      reRender: 0
    };
    this.formRevealRef = React.createRef();
    this.baseState = { ...this.state };

    this.formValues = {
      building: {
        name: 'building',
        valueId: 'building',
        labelTitle: 'Building And Unit',
        defaultValue: this.props.location.name,
        validatorFunc: value => {
          const isDuplicate =
            value &&
            value !== '' &&
            value.length < 51 &&
            this.props.locations.reduce((aggregator, item) => {
              return (
                aggregator ||
                (item.archived !== true &&
                  (item.name || '').toUpperCase() ===
                    value.toUpperCase().trimEnd())
              );
            }, false) &&
            value !== this.props.location.name;

          if (isDuplicate) {
            return {
              isValid: false,
              errorMessage:
                'Duplicate Location Name found. Please validate your entry.'
            };
          }
          return {
            isValid:
              /^.{1,50}$/.test(value) && value && value.trim().length > 0,
            errorMessage: 'Invalid Building Name'
          };
        }
      },
      capacity: {
        name: 'capacity',
        valueId: 'capacity',
        labelTitle: 'Capacity',
        defaultValue: this.props.location.capacity || '',
        validatorFunc: value => ({
          isValid:
            (/^\d{1,4}$/.test(value) && value > 0) ||
            value === undefined ||
            value === '',
          errorMessage: 'Invalid Capacity'
        })
      },
      type: {
        name: 'type',
        valueId: 'type',
        defaultValue: this.props.typeList.find(
          tp => tp.id === this.props.location.type
        ).name,
        labelTitle: 'Type',
        validatorFunc: value => ({
          isValid: !!value,
          errorMessage: 'Invalid'
        })
      }
    };
  }

  componentDidMount() {
    const { name, capacity, type, id } = this.props.location;
    this.setState({
      building: name,
      capacity: capacity || undefined,
      type,
      id
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.updating !== this.props.updating) {
      if (!this.props.updating) {
        if (this.props.errorMessage) {
          this.setState(prevProps => {
            return { reRender: prevProps.reRender + 1 };
          });
          this.props.hideModal();
        } else {
          this.props.showCustomJSXModal(
            <div id="success-message" style={{ textAlign: 'center' }}>
              <FontAwesomeIcon icon={faCheckCircle} />
              Location Updated
              <br />
              {`${this.props.newlyUpdatedLocation.name}`}
            </div>
          );
          this.props.closeSidePanel(false, this.props.location);

          setTimeout(() => {
            this.props.hideModal();
          }, 1800);
        }
      } else {
        this.props.showValidatingModal();
      }
    } else if (prevProps.archiving !== this.props.archiving) {
      if (!this.props.archiving) {
        if (this.props.errorMessage) {
          this.setState(prevProps => {
            return { reRender: prevProps.reRender + 1 };
          });
          this.props.hideModal();
        } else {
          this.props.showCustomJSXModal(
            <div id="success-message" style={{ textAlign: 'center' }}>
              <FontAwesomeIcon icon={faCheckCircle} />
              Location archived
              <br />
              {`${this.props.recentlyArchivedLocationName}`}
            </div>
          );
          this.props.closeSidePanel(false, this.props.location);

          setTimeout(() => {
            this.props.hideModal();
          }, 1800);
        }
      } else {
        this.props.showCustomLoadingModal('Archiving...');
      }
    } else if (
      prevState.resetting !== this.state.resetting &&
      this.state.resetting
    ) {
      this.setState({
        resetting: false
      });
    }
    if (this.state.isFormInputValid !== prevState.isFormInputValid) {
      this.props.updateTrapFocus();
    }
  }

  handleValueChange = formValue => {
    this.setState({
      [formValue.valueId]: formValue.value,
      isFormInputValid: Object.keys(this.formValues).reduce((result, next) => {
        let theResult;
        if (next === formValue.valueId) {
          theResult =
            result &&
            this.formValues[next].validatorFunc(formValue.value).isValid;
        } else {
          theResult =
            result &&
            this.formValues[next].validatorFunc(this.state[next]).isValid;
        }
        if (theResult === false) {
          this.setState(prevProps => {
            return { reRender: prevProps.reRender + 1 };
          });
        }
        return theResult;
      }, true)
    });
  };

  resetForm = () => {
    const newState = {
      ...this.baseState,
      resetting: true
    };
    Object.values(this.formValues).forEach(key => {
      newState[key.valueId] = undefined;
    });
    this.setState(newState);
  };

  validateFormInput = () => {
    this.setState({
      isFormInputValid: Object.keys(this.formValues).reduce((result, next) => {
        let theResult =
          result &&
          this.formValues[next].validatorFunc(this.state[next]).isValid;
        return theResult;
      }, true)
    });
  };

  handleSubmitClick = e => {
    e.preventDefault();
    this.validateFormInput();
    const { building, capacity, type, id } = this.state;
    const typeId = this.props.typeList.find(
      tp => tp.name === type || tp.id === type
    ).id;
    const changes = {
      name: building,
      capacity: parseInt(capacity) || undefined,
      type: typeId
    };

    this.props.updateLocation(changes, id);
  };

  handleArchiveClick = e => {
    e.preventDefault();
    this.validateFormInput();
    const { building, capacity, type, id } = this.state;
    const typeId = this.props.typeList.find(
      tp => tp.name === type || tp.id === type
    ).id;
    const changes = {
      name: building,
      capacity: parseInt(capacity) || undefined,
      type: typeId,
      archived: true
    };

    this.props.archiveLocation(changes, id);
  };

  handleCancelClick = e => {
    e.preventDefault();
    this.props.closeSidePanel();
  };

  keyHandler = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (e.target.id === 'cancel-update-location') {
        this.handleCancelClick(e);
      } else {
        this.handleSubmitClick(e);
      }
    }
  };

  renderUpdateLocationForm = () => {
    const { resetting, reRender, isFormInputValid } = this.state;
    const { typeList, errorMessage } = this.props;

    // check for active types
    // also checks if an inactive type is the default type and keeps it
    const optionNames = typeList
      .flatMap(type => {
        if (this.props.location.type === type.id && !type.active) {
          return type.name;
        } else if (type.active) {
          return type.name;
        }
      })
      .filter(Boolean);

    // deletes input values by unmounting this component
    if (resetting) return null;
    return (
      <LocationForm
        formValues={this.formValues}
        isFormInputValid={isFormInputValid}
        errorMessage={errorMessage}
        reRender={reRender}
        optionNames={optionNames}
        selectDefault={this.formValues.type.defaultValue}
        handleValueChange={e => this.handleValueChange(e)}
        handleCancelClick={e => this.handleCancelClick(e)}
        handleSubmitClick={e => this.handleSubmitClick(e)}
        handleArchiveClick={e => this.handleArchiveClick(e)}
        keyHandler={e => this.keyHandler(e)}
        cancelId="cancel-update-location"
        submitId="submit-update-location"
        action="updating"
      >
        Update Location
      </LocationForm>
    );
  };

  render() {
    const form = this.renderUpdateLocationForm();
    if (!form) return null;
    return form;
  }
}

const updatingSelector = createLoadingSelector([UPDATE_LOCATION]);
const archivingSelector = createLoadingSelector([ARCHIVE_LOCATION]);
const errorSelector = createErrorMessageSelector([
  UPDATE_LOCATION,
  ARCHIVE_LOCATION
]);
const mapStateToProps = state => {
  const {
    config: { config },
    locations: { list, updatedLocation, archivedName }
  } = state;
  return {
    errorMessage: errorSelector(state),
    typeList: config && config.locationTypes,
    newlyUpdatedLocation: updatedLocation,
    recentlyArchivedLocationName: archivedName,
    locations: list,
    updating: updatingSelector(state),
    archiving: archivingSelector(state)
  };
};

export default connect(mapStateToProps, {
  updateLocation,
  archiveLocation,
  showCustomJSXModal,
  showValidatingModal,
  showCustomLoadingModal,
  hideModal,
  updateTrapFocus
})(UpdateLocation);
