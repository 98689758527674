import React from 'react';
import { connect } from 'react-redux';
import './AddFacultyMember.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons/faChevronCircleRight';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { putAllWorkPlansCurrentYear } from '../../service/actions/faculty';
import {
  showCustomJSXModal,
  showValidatingModal,
  hideModal
} from '../../service/actions/modal';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import { PUT_ALL_WORKPLANS_CURRYEAR } from '../../service/types';

class RolloverForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showRolloverConfirmation: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rolloverWorkPlans !== this.props.rolloverWorkPlans) {
      if (!this.props.rolloverWorkPlans) {
        if (this.props.errorMessage) {
          this.props.hideModal();
        } else {
          this.props.showCustomJSXModal(
            <div id="success-message" style={{ textAlign: 'center' }}>
              <FontAwesomeIcon icon={faCheckCircle} />
              Advanced Work Plan Academic Year
            </div>
          );
        }
      } else {
        this.props.showValidatingModal();
      }
    }
  }

  render() {
    const {
      currentYearSchedules,
      massRolloverIsPossible,
      currentYear,
      terms
    } = this.props;

    const schedules = terms.map(term => {
      const termYear = currentYear + term.calendarYearOffset;
      const persistedSchedule = currentYearSchedules?.find(
        x => x.term === term.name && x.year === termYear
      );

      return {
        term: persistedSchedule?.term ?? term.name,
        year: persistedSchedule?.year ?? termYear,
        status: persistedSchedule?.status ?? 'projected'
      };
    });

    return (
      <div className="rollover-form">
        {schedules.map((schedule, index) => (
          <li className="with-anchor schedule" key={index}>
            <div className="content">
              <span>{`${schedule.term} ${schedule.year}`}</span>
              <div className={`label status-${schedule.status}-bg`}>
                {schedule.status}
              </div>
            </div>
          </li>
        ))}
        {this.state.showRolloverConfirmation && (
          <div>{this.showConfirmationMessage()}</div>
        )}

        <div className="buttons">
          <button
            id="cancel-faculty-member-export"
            onClick={event =>
              this.props.handleCancelClick('RolloverForm', event)
            }
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                this.props.handleCancelClick('RolloverForm', event);
              }
            }}
          >
            Cancel
          </button>
          <button
            onClick={e => this.setRolloverConfirmationOrSubmit(e)}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                this.handleSubmitClick(event);
              }
            }}
            disabled={!massRolloverIsPossible}
          >
            Rollover Work Plans <FontAwesomeIcon icon={faChevronCircleRight} />
          </button>
        </div>
      </div>
    );
  }

  setRolloverConfirmationOrSubmit(e) {
    const { currentYear } = this.props;
    if (this.state.showRolloverConfirmation) {
      e.stopPropagation();
      this.props.putAllWorkPlansCurrentYear(currentYear + 1);
    } else {
      e.stopPropagation();
      this.setState({ showRolloverConfirmation: true });
    }
  }

  showConfirmationMessage() {
    const { currentYear } = this.props;
    return (
      <p>{`Are you sure you want to roll over all faculty work plans to the next academic year, ${currentYear +
        1}-${currentYear + 2}?`}</p>
    );
  }
}

const rolloverSelector = createLoadingSelector([PUT_ALL_WORKPLANS_CURRYEAR]);
const rolloverErrorSelector = createErrorMessageSelector([
  PUT_ALL_WORKPLANS_CURRYEAR
]);

const mapStateToProps = state => {
  const { schedules, faculty, config } = state;
  return {
    schedules: schedules.list,
    currentYear: faculty.workPlanSummary.currentYear,
    massRolloverIsPossible: faculty.workPlanSummary.massRolloverIsPossible,
    currentYearSchedules: faculty.workPlanSummary.currentYearSchedules,
    terms: config && config.config && config.config.terms,
    rolloverWorkPlans: rolloverSelector(state),
    rolloverErrorSelector: rolloverErrorSelector(state)
  };
};

export default connect(mapStateToProps, {
  putAllWorkPlansCurrentYear,
  showCustomJSXModal,
  showValidatingModal,
  hideModal
})(RolloverForm);
