const reduceStudentGroupsToProgramTrack = studentGroupList => {
  return studentGroupList.reduce((result, augmentedStudentGroup) => {
    const sgInfo = augmentedStudentGroup.studentGroupInfo;
    let progLabel = sgInfo.program.name;
    if (sgInfo.concentration) {
      progLabel += `, ${sgInfo.concentration.name}`;
    }
    result[progLabel] = result[progLabel] || {};
    if (!result[progLabel][sgInfo.track]) {
      result[progLabel][sgInfo.track] = [augmentedStudentGroup];
    } else {
      result[progLabel][sgInfo.track].push(augmentedStudentGroup);
    }
    return result;
  }, {});
};
export default reduceStudentGroupsToProgramTrack;
