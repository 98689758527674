import { GET_USER } from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case GET_USER:
      let scopes = [];
      if (
        action.payload &&
        action.payload.idToken &&
        action.payload.idToken.payload.scope
      ) {
        scopes = action.payload.idToken.payload.scope.split(' ');
      }
      return {
        ...state,
        session: action.payload,
        token: action.payload && action.payload.idToken.jwtToken,
        scopes: scopes
      };
    default:
      return state;
  }
};
