export const GET_CONFIG = 'GET_CONFIG';
export const GET_COURSES = 'GET_COURSES';
export const GET_COURSE = 'GET_COURSE';
export const GET_COURSE_SCHEDULES = 'GET_COURSE_SCHEDULES';
export const ADD_COURSE = 'ADD_COURSE';
export const UPDATE_COURSE = 'UPDATE_COURSE';
export const UPDATE_COURSE_COMPONENTS = 'UPDATE_COURSE_COMPONENTS';
export const ARCHIVE_COURSE = 'ARCHIVE_COURSE';
export const GET_PLANS_OF_STUDY = 'GET_PLANS_OF_STUDY';
export const ADD_PLAN_OF_STUDY = 'ADD_PLAN_OF_STUDY';
export const GET_PLAN_OF_STUDY = 'GET_PLAN_OF_STUDY';
export const UPDATE_PLAN_OF_STUDY = 'UPDATE_PLAN_OF_STUDY';
export const ARCHIVE_PLAN_OF_STUDY = 'ARCHIVE_PLAN_OF_STUDY';
export const PUT_PLAN_OF_STUDY_TERMS = 'PUT_PLAN_OF_STUDY_TERMS';
export const GET_STUDENT_GROUPS = 'GET_STUDENT_GROUPS';
export const GET_STUDENT_GROUP = 'GET_STUDENT_GROUP';
export const ADD_STUDENT_GROUP = 'ADD_STUDENT_GROUP';
export const PUT_STUDENT_GROUP = 'PUT_STUDENT_GROUP';
export const DELETE_STUDENT_GROUP = 'DELETE_STUDENT_GROUP';
export const PUT_STUDENT_GROUP_ENROLLMENT = 'PUT_STUDENT_GROUP_ENROLLMENT';
export const PUT_STUDENT_GROUP_TERM_COURSES = 'PUT_STUDENT_GROUP_TERM_COURSES';
export const GET_PROGRAMS = 'GET_PROGRAMS';
export const GET_FACULTY_MEMBERS = 'GET_FACULTY_MEMBERS';
export const GET_FACULTY_MEMBER = 'GET_FACULTY_MEMBER';
export const GET_FACULTY_MEMBER_WORKPLAN = 'GET_FACULTY_MEMBER_WORKPLAN';
export const SET_WORKPLAN_SECTION_CONFIRMATION_STATUS =
  'SET_WORKPLAN_SECTION_CONFIRMATION_STATUS';
export const ADD_FACULTY_MEMBER = 'ADD_FACULTY_MEMBER';
export const EXPORT_WORK_ASSIGNMENTS = 'EXPORT_WORK_ASSIGNMENTS';
export const UPDATE_FACULTY_MEMBER = 'UPDATE_FACULTY_MEMBER';
export const PUT_WORKPLAN_CURRYEAR = 'PUT_WORKPLAN_CURRYEAR';
export const PUT_ALL_WORKPLANS_CURRYEAR = 'PUT_ALL_WORKPLANS_CURRYEAR';
export const PUT_WORKPLAN_ALLOCATION = 'PUT_WORKPLAN_ALLOCATION';
export const GET_DEFAULT_TEACHING_EFFORT = 'GET_DEFAULT_TEACHING_EFFORT';
export const GET_SCHEDULES = 'GET_SCHEDULES';
export const GET_SCHEDULE = 'GET_SCHEDULE';
export const GET_SCHEDULE_ENROLLMENT = 'GET_SCHEDULE_ENROLLMENT';
export const GET_SCHEDULE_NOTES = 'GET_SCHEDULE_NOTES';
export const SET_SCHEDULE_NOTES = 'SET_SCHEDULE_NOTES';
export const EXPORT_SCHEDULE = 'EXPORT_SCHEDULE';
export const POST_SCHEDULE_STATE_CHANGE = 'POST_SCHEDULE_STATE_CHANGE';
export const ADD_SCHEDULE_SECTION = 'ADD_SCHEDULE_SECTION';
export const ADD_COURSE_TO_SCHEDULE = 'ADD_COURSE_TO_SCHEDULE';
export const DELETE_SCHEDULE_COURSE = 'DELETE_SCHEDULE_COURSE';
export const PUT_SCHEDULE_SECTION = 'PUT_SCHEDULE_SECTION';
export const SET_SECTION_CONFIRMATION_STATUS = 'PUT_SECTION_ASSIGNMENT_STATUS';
export const DELETE_SCHEDULE_SECTION = 'DELETE_SCHEDULE_SECTION';
export const VALIDATE_SCHEDULE = 'VALIDATE_SCHEDULE';
export const SET_DEFAULT_TEACHING_EFFORT = 'SET_DEFAULT_TEACHING_EFFORT';
export const GET_WORKPLAN_SCHEDULES = 'GET_WORKPLAN_SCHEDULES';
export const SET_TEACHING_EFFORT_TOTALS = 'SET_TEACHING_EFFORT_TOTALS';
export const GET_TEACHING_ASSIGNMENTS = 'GET_TEACHING_ASSIGNMENTS';
export const ADD_WORK_ACTIVITY = 'ADD_WORK_ACTIVITY';
export const UPDATE_WORK_ACTIVITY = 'UPDATE_WORK_ACTIVITY';
export const DELETE_WORK_ACTIVITY = 'DELETE_WORK_ACTIVITY';
export const ADD_OTHER_TEACHING_ITEM = 'ADD_OTHER_TEACHING_ITEM';
export const DELETE_OTHER_TEACHING_ITEM = 'DELETE_OTHER_TEACHING_ITEM';
export const ADD_PROSPECTIVE_COURSE = 'ADD_PROSPECTIVE_COURSE';
export const DELETE_PROSPECTIVE_COURSE = 'DELETE_PROSPECTIVE_COURSE';
export const GET_WORKPLAN_SCHEDULE_ENROLLMENT =
  'GET_WORKPLAN_SCHEDULE_ENROLLMENT';
export const ADD_SECTION_PLACEHOLDER = 'ADD_SECTION_PLACEHOLDER';
export const DELETE_SECTION_PLACEHOLDER = 'DELETE_SECTION_PLACEHOLDER';
export const UPDATE_ASSIGNED_COURSE_SECTION = 'UPDATE_ASSIGNED_COURSE_SECTION';
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const ADD_LOCATION = 'ADD_LOCATION';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const ARCHIVE_LOCATION = 'ARCHIVE_LOCATION';
export const GET_PROPOSED_TEACHING = 'GET_PROPOSED_TEACHING';
export const GET_FACULTY_MEMBER_NOTES = 'GET_FACULTY_MEMBER_NOTES';
export const SET_FACULTY_MEMBER_NOTES = 'SET_FACULTY_MEMBER_NOTES';

export const CLEAR_COURSE_ERRORS = 'CLEAR_COURSE_ERRORS';
export const GET_USER = 'GET_USER';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_SIDE_PANEL = 'SHOW_SIDE_PANEL';
export const HIDE_SIDE_PANEL = 'HIDE_SIDE_PANEL';
export const CLEAR_SIDE_PANEL_CONTENT = 'CLEAR_SIDE_PANEL_CONTENT';
export const UPDATE_TRAP_FOCUS_PANEL = 'UPDATE_TRAP_FOCUS_PANEL';
export const CLEAR_SCHEDULE_UPDATING_ERROR = 'CLEAR_SCHEDULE_UPDATING_ERROR';
export const EXPAND_ALL = 'EXPAND_ALL';
export const SET_EXPANDED = 'SET_EXPANDED';
export const RESET_ACTIVITY_COUNTER = 'RESET_ACTIVITY_COUNTER';
