import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';

const SelectHTMLSelect = props => {
  const {
    handleOpenOptions,
    handleClearValue,
    openOptions,
    currentOption,
    selectRef,
    name,
    hasBeenChanged,
    disabled,
    actionable,
    emptyValueOption,
    showClearButton,
    optional
  } = props;
  return (
    <>
      <div
        id={name}
        tabIndex={disabled ? '-1' : '0'}
        role="button"
        onClick={disabled ? undefined : handleOpenOptions}
        onKeyDown={disabled ? undefined : handleOpenOptions}
        aria-pressed={openOptions}
        aria-expanded={openOptions}
        className={
          'select ' +
          (!hasBeenChanged ? 'unchanged ' : '') +
          (disabled ? 'disabled ' : '') +
          (actionable ? 'actionable ' : '')
        }
        ref={selectRef}
      >
        {currentOption === undefined ? 'Select' : <span>{currentOption}</span>}
        <FontAwesomeIcon
          className="dropdown-arrow"
          icon={faChevronDown}
          title="Clear value"
        />
      </div>
      {currentOption !== emptyValueOption && showClearButton && (
        <div
          className="clear-value"
          onClick={disabled ? undefined : () => handleClearValue()}
          onKeyDown={
            disabled
              ? undefined
              : event => {
                  if (event.key === ' ' || event.key === 'Enter') {
                    event.preventDefault();
                    handleClearValue();
                  }
                }
          }
          tabIndex={disabled ? '-1' : '0'}
        >
          <FontAwesomeIcon icon={faTimes} title="Clear value" />
        </div>
      )}
      <div className="optional-input-label">
        {optional ? <p>&#x28;OPTIONAL&#x29;</p> : null}
      </div>
    </>
  );
};

export default SelectHTMLSelect;
