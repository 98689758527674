import React from 'react';
import { connect } from 'react-redux';
import {
  addNewWorkItem,
  putWorkplanAllocation,
  getFacultyMemberWorkPlan,
  getWorkPlanSchedules,
  getWorkPlanProjectedEnrollment,
  getTeachingAssignments,
  setTeachingEffortTotals
} from '../../service/actions/faculty';
import { expandAll } from '../../service/actions/accordion';
import ArchiveYearForm from './ArchiveYearForm';
import './FacultyTeachingAssignments.scss';
import FormReveal from '../common/FormReveal';
import { showSidePanel } from '../../service/actions/sidePanel';
import ToggleExpandAll from '../common/Accordion/ToggleExpandAll';
import AddWorkActivity from './AddWorkActivity';
import SetWorkplanAllocation from './SetWorkplanAllocation';
import { showLoadingModal, hideModal } from '../../service/actions/modal';
import { ValidationMessage } from '../common/Validation/validationFunctionComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons/faExchangeAlt';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import FacultyTeachingAssignments from './FacultyTeachingAssignments';
import UpdateWorkItem from './UpdateWorkItem';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import {
  GET_DEFAULT_TEACHING_EFFORT,
  GET_CONFIG,
  ADD_PROSPECTIVE_COURSE,
  DELETE_PROSPECTIVE_COURSE,
  ADD_SECTION_PLACEHOLDER,
  DELETE_SECTION_PLACEHOLDER,
  UPDATE_ASSIGNED_COURSE_SECTION,
  PUT_WORKPLAN_ALLOCATION,
  GET_FACULTY_MEMBER_WORKPLAN,
  GET_WORKPLAN_SCHEDULE_ENROLLMENT,
  GET_WORKPLAN_SCHEDULES,
  GET_TEACHING_ASSIGNMENTS,
  GET_COURSES
} from '../../service/types';

class FacultyMemberWorkPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: '',
      expandAll: false
    };
    this.baseState = { ...this.state };
    this.formRevealRef = React.createRef();
    this.archiveFormRevealRef = React.createRef();
    this.workItemRefs = {};
  }

  componentDidMount() {
    if (this.props.workPlans) {
      if (!this.props.workPlans.length) {
        return;
      }
      this.setupData(false);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.workPlans !== prevProps.workPlans) {
      // A year has just been archived and the faculty's workplans have changed
      if (this.props.workPlans) {
        this.setupData(true);
      }
    }
    if (this.props.updatingWorkPlan !== prevProps.updatingWorkPlan) {
      if (!this.props.updatingWorkPlan) {
        this.props
          .getTeachingAssignments(this.props.facultyMember.id)
          .then(teachingAssignments => {
            this.props.setTeachingEffortTotals(teachingAssignments);
          });
      }
    }
  }

  setupData(keepSelectedYear) {
    const currentYearIndex = this.props.workPlans.findIndex(
      val => val.state === 'current'
    );
    const currentPlan = this.props.workPlans[currentYearIndex];
    const currentYear = currentPlan && currentPlan.academicYear;
    const selectedYear = keepSelectedYear
      ? this.state.selectedYear || currentYear
      : currentYear;
    this.setState({ selectedYear, currentYear, currentYearIndex });
    this.getWorkPlan(selectedYear);
  }

  render() {
    const {
      workPlans,
      scopes,
      loading,
      workPlan,
      currentAcademicYear,
      workPlanSchedules,
      facultyMember,
      effortMode
    } = this.props;
    const {
      showArchiveForm,
      showArchiveFormSendFocus,
      currentYearIndex
    } = this.state;
    const sortedPlans = workPlans
      .sort((a, b) => {
        return a.academicYear - b.academicYear;
      })
      .filter((_, index) => index >= currentYearIndex - 2);

    if (!(workPlans && workPlans.length)) {
      return null;
    }

    const isOnSameYear = workPlan?.academicYear >= currentAcademicYear;

    const archiveButtonVisible =
      !loading &&
      !isOnSameYear &&
      facultyMember.active === true &&
      scopes.includes('faculty-member.work-plan.write') &&
      workPlan &&
      workPlan.state === 'current';
    const allTermsCompleted =
      workPlanSchedules &&
      workPlanSchedules.reduce(
        (result, next) => result && next && next.state === 'completed',
        true
      );
    const archiveButtonDisabled = loading || !allTermsCompleted;

    return (
      <section id="work-plans">
        <div className="heading">
          <h1>Work Assignments</h1>
          <div>
            {archiveButtonVisible && !showArchiveForm && (
              <button
                disabled={archiveButtonDisabled || undefined}
                onClick={
                  archiveButtonDisabled
                    ? undefined
                    : event => this.setArchiveFormVisibility(true, false, event)
                }
                onKeyDown={
                  archiveButtonDisabled
                    ? undefined
                    : event => {
                        if (event.key === 'Enter') {
                          this.setArchiveFormVisibility(true, true, event);
                        }
                      }
                }
                tabIndex={archiveButtonDisabled ? -1 : 0}
                id="archiveYear"
                className="no-print"
              >
                {effortMode === 'TU'
                  ? 'Mark as Archived Year'
                  : 'Archive Year '}
                <FontAwesomeIcon
                  icon={effortMode === 'TU' ? faCheck : faExchangeAlt}
                />
              </button>
            )}
          </div>
        </div>

        {scopes.includes('faculty-member.work-plan.write') &&
          archiveButtonVisible && (
            <div>
              <FormReveal
                hideButton
                parentShowForm={showArchiveForm}
                parentShowFormSendFocus={showArchiveFormSendFocus}
                form={this.renderArchiveForm()}
                cancelFunc={() => this.setArchiveFormVisibility(false)}
                ref={this.archiveFormRevealRef}
              />
            </div>
          )}
        {this.renderYearSelector(sortedPlans)}
        {this.renderYearViewer(sortedPlans)}
      </section>
    );
  }

  renderYearSelector(years) {
    const { selectedYear } = this.state;

    return (
      <div className="hash-selector large">
        {years.map(year => (
          <div
            key={year.academicYear}
            tabIndex="0"
            onClick={() => this.setYear(year.academicYear)}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                this.setYear(year.academicYear);
              }
            }}
            className={
              selectedYear === year.academicYear ? 'active' : undefined
            }
          >
            {year.state === 'current' && (
              <span className="top-label">Current Year</span>
            )}
            <span>
              {year.academicYear}&ndash;{year.academicYear + 1}
            </span>
          </div>
        ))}
      </div>
    );
  }

  renderYearViewer() {
    const {
      workPlan,
      workTypes,
      divisions,
      viewError,
      scopes,
      addWorkActivityError,
      setAllocationError,
      workPlanSchedules,
      workPlanProjectedEnrollment,
      termsList,
      teachingAssignments,
      facultyMember,
      workPlanLoading,
      configEffortUnit
    } = this.props;

    const { selectedYear, currentYear, showSetAllocation } = this.state;

    // If the workplan is archived, it will have an effortUnit value, if not, the effort unit will come from the config value
    const effortMode = workPlan?.effortUnit || configEffortUnit;
    const effortUnit = effortMode === 'TU' ? ' TU' : '%';

    const configAllocationTypes = effortMode === 'TU' ? divisions : workTypes;

    if (viewError) {
      return (
        <div className="faculty-member-error-message error-message">
          <FontAwesomeIcon icon={faExclamationCircle} />
          {viewError.toString()}
        </div>
      );
    }

    if (
      workPlanLoading ||
      !workPlan ||
      !workTypes ||
      !divisions ||
      !workPlanSchedules ||
      !termsList ||
      !workPlanProjectedEnrollment ||
      !teachingAssignments
    ) {
      return (
        <>
          <div className="work-plan alternate"></div>
          <div className="work-plan"></div>
          <div className="work-plan alternate"></div>
          <div className="work-plan"></div>
          <div className="work-plan"></div>
          <div className="work-plan"></div>
          <div className="work-plan"></div>
        </>
      );
    }

    let isExceedingEffortAllocation = [];
    let totalEffortAggregator = 0;

    // Built the actual content for a work plan year
    const workPlanContent = workPlan.allocation
      .sort(
        (a, b) =>
          configAllocationTypes.findIndex(x => a.type === x.id) -
          configAllocationTypes.findIndex(x => b.type === x.id)
      )
      .reduce((result, allocation) => {
        const relevantDivisionOrCategory = allocation;
        const divisionOrWorkTypeInfo = configAllocationTypes.find(
          x => x.id === allocation.type
        );

        const relevantWorkItems = workPlan.workItems.filter(item => {
          if (effortMode === '%') {
            return item.workType === divisionOrWorkTypeInfo.id;
          } else if (allocation.type === 'teaching') {
            return false; //No work items apply to teaching
          } else {
            return true; //All work items apply to non-teaching
          }
        });

        const otherTeachingEffortTotal = workPlan.otherTeaching.reduce(
          (result, otherTeachingItem) => {
            return result + otherTeachingItem.effort;
          },
          0
        );

        if (
          relevantWorkItems.length ||
          relevantDivisionOrCategory ||
          divisionOrWorkTypeInfo.active === true
        ) {
          const expectedEffort = relevantDivisionOrCategory
            ? relevantDivisionOrCategory.expectedEffort
            : 0;

          let divisionOrCategoryTotalEffort;
          if (allocation.type === 'teaching') {
            divisionOrCategoryTotalEffort =
              parseFloat(this.props.plannedTeachingEffort) +
              parseFloat(this.props.assignedTeachingEffort) +
              otherTeachingEffortTotal;
          } else {
            if (effortMode === 'TU') {
              divisionOrCategoryTotalEffort = expectedEffort;
            } else {
              divisionOrCategoryTotalEffort = relevantWorkItems.reduce(
                (effortAggregator, next) => {
                  return effortAggregator + (next.actualEffort || 0);
                },
                0
              );
            }
          }

          if (divisionOrCategoryTotalEffort > expectedEffort) {
            isExceedingEffortAllocation.push(divisionOrCategoryTotalEffort);
          }

          totalEffortAggregator += divisionOrCategoryTotalEffort;

          const canEdit =
            scopes.includes('faculty-member.work-plan.write') &&
            workPlan &&
            workPlan.state !== 'past' &&
            facultyMember.active === true;

          const effortNumerator = this.parseToPrecision(
            effortMode === 'TU' ? '3.00' : expectedEffort,
            divisionOrCategoryTotalEffort,
            { removeTrailingZeros: effortMode === 'TU' }
          );

          const effortDescription = (() => {
            if (effortMode === '%') {
              return `${effortNumerator}/${expectedEffort}${effortUnit} Expected Effort`;
            } else if (allocation.type === 'non-teaching') {
              return `${effortNumerator} Non-Teaching Effort`;
            } else {
              return `${effortNumerator}/${expectedEffort} Teaching Effort`;
            }
          })();

          return result.concat([
            <div className="work-type" key={divisionOrWorkTypeInfo.name}>
              <div className="work-type-heading">
                <h2>{divisionOrWorkTypeInfo.name}</h2>
                <div className="effort-totals-container">
                  <span className="effort-heading">{effortDescription}</span>
                  {allocation.type === 'teaching' && (
                    <span className="teaching-effort-totals">
                      {this.parseToPrecision(
                        effortMode === 'TU' ? '3.00' : expectedEffort,
                        this.props.assignedTeachingEffort,
                        { removeTrailingZeros: effortMode === 'TU' }
                      )}
                      {effortUnit} Assigned +{' '}
                      {this.parseToPrecision(
                        effortMode === 'TU' ? '3.00' : expectedEffort,
                        this.props.plannedTeachingEffort,
                        { removeTrailingZeros: effortMode === 'TU' }
                      )}
                      {effortUnit} Planned
                      {otherTeachingEffortTotal > 0 && effortMode === 'TU'
                        ? ` + ${otherTeachingEffortTotal} ${effortUnit} Other`
                        : null}
                    </span>
                  )}
                </div>
              </div>

              {allocation.type === 'teaching' ? (
                <>
                  <ToggleExpandAll
                    startExpanded={this.props.expandAllState}
                    toggleExpandAll={expandState =>
                      this.toggleExpandAll(expandState)
                    }
                  />
                  {workPlanSchedules
                    .sort(
                      (a, b) =>
                        termsList.indexOf(a.term) - termsList.indexOf(b.term)
                    )
                    .map((schedule, i) => {
                      let teachingAssignmentCourses = teachingAssignments.find(
                        a =>
                          a.term === schedule.term && a.year === schedule.year
                      );
                      let scheduleProjectedEnrollment = workPlanProjectedEnrollment.find(
                        s =>
                          s.term === schedule.term && s.year === schedule.year
                      );
                      return (
                        <div
                          key={schedule.term}
                          className="schedule-course-list"
                        >
                          <div className="title">
                            <span>{`${schedule.year} ${schedule.term}`}</span>
                            {schedule.state === 'completed' && (
                              <div
                                className={`state`}
                              >{`${schedule.state} Term`}</div>
                            )}
                            {schedule.state !== 'completed' && (
                              <div
                                className={`label state status-${schedule.state}-bg`}
                              >
                                {schedule.state}
                              </div>
                            )}
                          </div>
                          <FacultyTeachingAssignments
                            order={i}
                            scheduleProjectedEnrollment={
                              scheduleProjectedEnrollment
                            }
                            schedule={schedule}
                            teachingAssignments={teachingAssignmentCourses}
                            expandAll={this.state.expandAll}
                            selectedYear={this.state.selectedYear}
                            effortUnit={effortUnit}
                          />
                        </div>
                      );
                    })}
                </>
              ) : (
                <>
                  {relevantWorkItems.length > 0 && (
                    <table className="work-items">
                      <thead>
                        <tr
                          className={
                            allocation.type === 'non-teaching'
                              ? 'four-items'
                              : 'three-items'
                          }
                        >
                          {allocation.type === 'non-teaching' && <th>Type</th>}
                          <th>Name</th>
                          <th>Description</th>
                          <th
                            className={'effort ' + (canEdit ? 'can-edit' : '')}
                          >
                            Effort
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {relevantWorkItems.map((item, i) => {
                          const workTypeInfo = workTypes.find(
                            wt => wt.id === item.workType
                          );
                          return (
                            <tr
                              key={i}
                              className={
                                'course ' +
                                (item.isNew ? 'new ' : '') +
                                (allocation.type === 'non-teaching'
                                  ? 'four-items'
                                  : 'three-items')
                              }
                            >
                              {allocation.type === 'non-teaching' && (
                                <td>{workTypeInfo.name}</td>
                              )}
                              <td>{item.name}</td>
                              <td>{item.description}</td>
                              <td className={canEdit ? 'can-edit ' : ''}>
                                {parseInt(item.actualEffort) >= 0
                                  ? item.actualEffort
                                  : '-'}
                                {effortMode === '%' ? effortUnit : null}
                                {canEdit ? (
                                  <div className="edit-work-item">
                                    <button
                                      className="circle small no-print"
                                      onClick={() =>
                                        this.openSidePanel(
                                          false,
                                          item,
                                          divisionOrWorkTypeInfo,
                                          workTypes,
                                          effortMode,
                                          effortUnit
                                        )
                                      }
                                      onKeyDown={event => {
                                        if (event.key === 'Enter') {
                                          event.preventDefault();
                                          this.openSidePanel(
                                            true,
                                            item,
                                            divisionOrWorkTypeInfo,
                                            workTypes,
                                            effortMode,
                                            effortUnit
                                          );
                                        }
                                      }}
                                      ref={ref => {
                                        this.workItemRefs[item.id] = ref;
                                      }}
                                      title="Edit work item"
                                    >
                                      <FontAwesomeIcon icon={faEdit} />
                                      <span className="visually-hidden">
                                        Edit work item
                                      </span>
                                    </button>
                                  </div>
                                ) : (
                                  undefined
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                  {selectedYear >= currentYear &&
                    scopes.includes('faculty-member.work-plan.write') &&
                    facultyMember.active === true &&
                    divisionOrWorkTypeInfo.id !== 'teaching' && (
                      <>
                        <AddWorkActivity
                          workType={divisionOrWorkTypeInfo}
                          workTypes={workTypes}
                          effortMode={effortMode}
                          effortUnit={effortUnit}
                          addNewWorkplanActivity={activityObject =>
                            this.addNewWorkplanActivity(activityObject)
                          }
                        />
                        {addWorkActivityError &&
                          addWorkActivityError[divisionOrWorkTypeInfo.id] && (
                            <div className="faculty-work-activity-error error-message">
                              <FontAwesomeIcon icon={faExclamationCircle} />
                              {addWorkActivityError[divisionOrWorkTypeInfo.id]}
                            </div>
                          )}
                      </>
                    )}
                </>
              )}
            </div>
          ]);
        } else {
          return result;
        }
      }, []);

    const totalExpectedEffort = workPlan.allocation.reduce((result, next) => {
      return result + next.expectedEffort;
    }, 0);

    // Get the max precision of all effort total floating-point numbers (ex: Precision of 1 for 5.5)
    const effortMaxPrecision = () => {
      if (effortMode === 'TU') {
        return '3.00';
      }

      let precision = workPlan.allocation.reduce(function(
        precisionAggregator,
        next
      ) {
        let nextStr = next.expectedEffort.toString().split('.');
        let nextPrecision = nextStr.length > 1 ? nextStr[1].length : 0;
        return Math.max(precisionAggregator, nextPrecision);
      },
      0);
      return Number.parseFloat(precision).toFixed(precision);
    };

    return (
      <div className="work-plan">
        {effortMode === 'TU' && (
          <>
            <h1>Faculty Allocation</h1>

            {!showSetAllocation && (
              <>
                <div className="allocation-container">
                  {configAllocationTypes.map(type => (
                    <div key={type.id} className="allocation">
                      <div className="effort-labels">{type.name} Effort</div>
                      <div className="expected-effort">
                        {
                          workPlan.allocation.find(a => a.type === type.id)
                            .expectedEffort
                        }
                      </div>
                    </div>
                  ))}
                </div>
                <div className="effort-total">
                  {`${this.parseToPrecision(
                    effortMaxPrecision(),
                    totalEffortAggregator,
                    { removeTrailingZeros: effortMode === 'TU' }
                  )}/${this.parseToPrecision(
                    effortMaxPrecision(),
                    totalExpectedEffort,
                    { removeTrailingZeros: effortMode === 'TU' }
                  )} Total Effort`}
                </div>
              </>
            )}
          </>
        )}

        <div
          className={
            showSetAllocation
              ? 'top-content allocation-form-open'
              : 'top-content'
          }
        >
          <div className="no-print">
            {selectedYear >= currentYear &&
              facultyMember.active === true &&
              scopes.includes('faculty-member.work-plan.write') && (
                <>
                  <SetWorkplanAllocation
                    showSetAllocation={val => this.showSetAllocation(val)}
                    setWorkplanAllocation={allocationObject =>
                      this.setWorkplanAllocation(allocationObject)
                    }
                    effortUnit={effortUnit}
                    effortMode={effortMode}
                    workTypes={configAllocationTypes}
                  />
                  {setAllocationError && (
                    <div className="set-allocation-error-message error-message">
                      <FontAwesomeIcon icon={faExclamationCircle} />
                      {setAllocationError}
                    </div>
                  )}
                </>
              )}
            {selectedYear < currentYear && (
              <span className="effort-heading">
                {`${selectedYear}\u2013${selectedYear + 1}`} Archived Academic
                Year
              </span>
            )}
          </div>
          {!showSetAllocation && effortMode !== 'TU' && (
            <span className="effort-heading">
              {`${this.parseToPrecision(
                effortMaxPrecision(),
                totalEffortAggregator,
                { removeTrailingZeros: effortMode === 'TU' }
              )}/${this.parseToPrecision(
                effortMaxPrecision(),
                totalExpectedEffort,
                { removeTrailingZeros: effortMode === 'TU' }
              )}${effortUnit} Total Expected Effort`}
            </span>
          )}
        </div>
        {(isExceedingEffortAllocation.length > 0 ||
          totalEffortAggregator > totalExpectedEffort) && (
          <ValidationMessage
            message={
              'The actual effort for one or more categories exceeds the expected effort.'
            }
            type={'warning'}
          />
        )}
        {workPlanContent}
      </div>
    );
  }

  renderArchiveForm() {
    return (
      <ArchiveYearForm cancelFunc={event => this.handleCancelClick(event)} />
    );
  }

  openSidePanel(
    keyActivated,
    workItem,
    divisionOrWorkTypeInfo,
    workTypes,
    effortMode,
    effortUnit
  ) {
    const { selectedYear } = this.state;
    const { facultyMember } = this.props;

    this.props.showSidePanel(
      <UpdateWorkItem
        workItem={workItem}
        workTypes={workTypes}
        workType={divisionOrWorkTypeInfo}
        effortMode={effortMode}
        effortUnit={effortUnit}
        facultyMemberId={facultyMember.id}
        academicYear={selectedYear}
      />,
      this.workItemRefs[workItem.id],
      keyActivated
    );
  }

  toggleExpandAll(expandState) {
    this.props.expandAll(expandState);
  }

  setArchiveFormVisibility(value, sendFocus, event) {
    if (event) {
      event.preventDefault();
    }

    const showArchiveFormSendFocus = !!sendFocus;

    this.setState({
      showArchiveForm: value,
      showArchiveFormSendFocus
    });
  }

  handleCancelClick(event) {
    if (this.archiveFormRevealRef.current) {
      this.archiveFormRevealRef.current.handleCancelClick(event);
    }
  }

  parseToPrecision(allocation, actual, { removeTrailingZeros } = {}) {
    let allocationStr = allocation.toString().split('.');
    let allocationPrecision =
      allocationStr.length > 1 ? allocationStr[1].length : 0;
    const rounded = Number.parseFloat(actual).toFixed(allocationPrecision);
    return removeTrailingZeros ? (+rounded).toString() : rounded;
  }

  setYear(year) {
    this.setState({
      selectedYear: year,
      showSetAllocation: false
    });
    this.setArchiveFormVisibility(false, false);
    this.handleCancelClick(document.createEvent('Event'));
    this.getWorkPlan(year);
  }

  showSetAllocation(val) {
    this.setState({ showSetAllocation: val });
  }

  async getWorkPlan(selectedYear) {
    const {
      viewError,
      facultyMember,
      getFacultyMemberWorkPlan,
      getWorkPlanProjectedEnrollment,
      getWorkPlanSchedules,
      getTeachingAssignments,
      setTeachingEffortTotals,
      termObjects
    } = this.props;
    if (!viewError) {
      let promises = [
        getWorkPlanSchedules(selectedYear, termObjects),
        getWorkPlanProjectedEnrollment(selectedYear, termObjects),
        getFacultyMemberWorkPlan(facultyMember.id, selectedYear)
      ];
      Promise.all(promises).then(results => {
        getTeachingAssignments(facultyMember.id).then(teachingAssignments => {
          setTeachingEffortTotals(teachingAssignments);
        });
      });
    }
  }

  addNewWorkplanActivity(activityObject) {
    const { facultyMember, addNewWorkItem } = this.props;
    addNewWorkItem(facultyMember.id, this.state.selectedYear, activityObject);
  }

  setWorkplanAllocation(allocationObject) {
    const { facultyMember, putWorkplanAllocation } = this.props;
    putWorkplanAllocation(
      facultyMember.id,
      this.state.selectedYear,
      allocationObject
    );
  }
}

const loadingSelector = createLoadingSelector([
  GET_FACULTY_MEMBER_WORKPLAN,
  GET_CONFIG,
  GET_COURSES,
  GET_WORKPLAN_SCHEDULES,
  GET_TEACHING_ASSIGNMENTS,
  GET_DEFAULT_TEACHING_EFFORT,
  GET_WORKPLAN_SCHEDULE_ENROLLMENT
]);
const updatingSelector = createLoadingSelector([
  GET_CONFIG,
  ADD_PROSPECTIVE_COURSE,
  DELETE_PROSPECTIVE_COURSE,
  ADD_SECTION_PLACEHOLDER,
  DELETE_SECTION_PLACEHOLDER,
  UPDATE_ASSIGNED_COURSE_SECTION,
  PUT_WORKPLAN_ALLOCATION,
  GET_DEFAULT_TEACHING_EFFORT,
  GET_WORKPLAN_SCHEDULES,
  GET_FACULTY_MEMBER_WORKPLAN,
  GET_WORKPLAN_SCHEDULE_ENROLLMENT
]);
const errorSelector = createErrorMessageSelector([
  GET_FACULTY_MEMBER_WORKPLAN,
  GET_WORKPLAN_SCHEDULE_ENROLLMENT,
  GET_WORKPLAN_SCHEDULES,
  GET_COURSES
]);
const mapStateToProps = state => {
  const { auth, config, faculty, accordion } = state;
  return {
    scopes: auth.scopes,
    workPlanLoading: loadingSelector(state),
    updatingWorkPlan: updatingSelector(state),
    viewError: errorSelector(state),
    workTypes: config && config.config && config.config.workTypes,
    divisions: config && config.config && config.config.divisions,
    configEffortUnit:
      config && config.config && config.config.facultyWorkPlanEffortUnit,
    defaultAllocations:
      config &&
      config.config &&
      config.config.facultyWorkPlanDefaultAllocations,
    termsList: config && config.termsByAcademicYear,
    termObjects: config && config.config && config.config.terms,
    workPlan: faculty.viewWorkPlan,
    currentAcademicYear: faculty.workPlanSummary?.currentYear,
    facultyMember: faculty.viewIndividual,
    addWorkActivityError: faculty.addWorkActivityError,
    setAllocationError: faculty.setAllocationError,
    workPlanSchedules: faculty.workPlanSchedules,
    workPlanProjectedEnrollment: faculty.workPlanProjectedEnrollment,
    teachingAssignments: faculty.teachingAssignments,
    plannedTeachingEffort: faculty.plannedTeachingEffort,
    assignedTeachingEffort: faculty.assignedTeachingEffort,
    expandAllState: accordion.expandAll,
    effortMode:
      config && config.config && config.config.facultyWorkPlanEffortUnit
  };
};

export default connect(mapStateToProps, {
  addNewWorkItem,
  putWorkplanAllocation,
  getFacultyMemberWorkPlan,
  getWorkPlanSchedules,
  getWorkPlanProjectedEnrollment,
  setTeachingEffortTotals,
  getTeachingAssignments,
  showLoadingModal,
  hideModal,
  expandAll,
  showSidePanel
})(FacultyMemberWorkPlan);
