const sortStudentGroups = termList => (a, b) => {
  if (a.studentGroupInfo) a = a.studentGroupInfo;
  if (b.studentGroupInfo) b = b.studentGroupInfo;
  let aText = a.program.name;
  if (a.concentration) aText += ', ' + a.concentration.name;
  let bText = b.program.name;
  if (b.concentration) bText += ', ' + b.concentration.name;

  if (aText !== bText) {
    return aText.localeCompare(bText);
  }

  if (a.track !== b.track) {
    return ('' + a.track).localeCompare(b.track);
  }

  if (!a.name) a.name = '';
  if (!b.name) b.name = '';

  if (a.startYear === b.startYear) {
    const termDiff =
      termList.indexOf(a.startTerm) - termList.indexOf(b.startTerm);

    const nameDiff = ('' + a.name).localeCompare(b.name);
    return termDiff === 0 ? nameDiff : termDiff;
  }
  return a.startYear - b.startYear;
};

export default sortStudentGroups;
