import React from 'react';

const SelectCourseOptions = props => {
  const {
    handleOptionsEvents,
    optionsWrapperRef,
    includeEnrollment,
    setOptionRef,
    focusedOption,
    optionList,
    currentOption,
    emptyMessage
  } = props;

  let setSize = Object.keys(optionList).length || 1;

  return (
    <div className="options" ref={optionsWrapperRef}>
      {optionList && optionList.length ? (
        optionList.map((optionValue, index) => {
          let courseEnrollmentText;
          if (optionValue.projectedEnrollment) {
            courseEnrollmentText =
              optionValue.projectedEnrollment +
              ' student' +
              (optionValue.projectedEnrollment > 1 ? 's' : '');
          } else {
            courseEnrollmentText = '';
          }

          return (
            <div
              className={
                currentOption === optionValue.name ? 'active option' : 'option'
              }
              role="option"
              tabIndex="0"
              id={optionValue.name}
              aria-selected={focusedOption === optionValue.name}
              key={index}
              onClick={e => handleOptionsEvents(optionValue, index, e)}
              onKeyDown={e => handleOptionsEvents(optionValue, index, e)}
              onBlur={e => handleOptionsEvents(optionValue, index, e)}
              ref={setOptionRef}
              aria-posinset={index}
              aria-setsize={setSize}
            >
              <div className="course-option-content">
                <span className="course-number">
                  {optionValue.courseNumber.replace(/(\d)/, ' $1')}
                </span>
                <span className="course-name">
                  {optionValue.name}
                  <span className="credit-hours">{` (${
                    optionValue.variableCredit
                      ? 'Variable'
                      : optionValue.credits
                  } CR)`}</span>
                  {includeEnrollment && (
                    <span className="course-enrollment">{` ${courseEnrollmentText}`}</span>
                  )}
                </span>
              </div>
            </div>
          );
        })
      ) : (
        <div
          className="option unselectable"
          ref={setOptionRef}
          id="empty-option"
        >
          <span>{emptyMessage}</span>
        </div>
      )}
    </div>
  );
};

export default SelectCourseOptions;
