import React from 'react';
import './UpdateTerms.scss';
import { connect } from 'react-redux';
import { putPlanOfStudyTerms } from '../../service/actions/plansOfStudy';
import { showLoadingModal, hideModal } from '../../service/actions/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import Checkbox from '../common/Checkbox';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import { PUT_PLAN_OF_STUDY_TERMS } from '../../service/types';

class UpdateTerms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      terms: []
    };

    this.updateTerms = this.updateTerms.bind(this);
    this.cancelUpdate = this.cancelUpdate.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  componentDidMount() {
    this.populateLocalTerms();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updating !== this.props.updating) {
      if (this.props.updating) {
        this.props.showLoadingModal();
      } else {
        this.props.hideModal();
        if (!this.props.errorMessage) {
          this.props.hideFormFunc();
        }
      }
    } else if (prevProps.planOfStudy !== this.props.planOfStudy) {
      if (this.props.planOfStudy) {
        this.populateLocalTerms();
      }
    }
  }

  populateLocalTerms() {
    const { planOfStudy } = this.props;
    const startingTerm = {
      year: 1,
      term: planOfStudy.startTerm,
      courseIds: []
    };
    let terms = [startingTerm];

    if (planOfStudy.terms && planOfStudy.terms.length) {
      terms = planOfStudy.terms;
    }

    let startingTermIndex = terms.findIndex(element => {
      return (
        startingTerm.term === element.term && startingTerm.year === element.year
      );
    });

    if (startingTermIndex < 0) {
      terms.push(startingTerm);
    }

    this.setState({
      terms: terms
    });
  }

  render() {
    const showCancelButton =
      this.props.planOfStudy &&
      this.props.planOfStudy.terms &&
      this.props.planOfStudy.terms.length > 0;
    return (
      <form id="update-terms-form">
        <fieldset>
          <legend>Please assign terms for this plan of study.</legend>
          {this.renderTermList()}
        </fieldset>

        {this.props.errorMessage && (
          <div className="error-message plans-of-study-error-message">
            <FontAwesomeIcon icon={faExclamationCircle} />
            {this.props.errorMessage}
          </div>
        )}

        <div className="buttons">
          {showCancelButton && (
            <button onClick={this.cancelUpdate}>Cancel</button>
          )}
          <button onClick={this.updateTerms}>
            Save Terms
            <FontAwesomeIcon icon={faCheckCircle} />
          </button>
        </div>
      </form>
    );
  }

  renderTermList() {
    const { planOfStudy, availableTerms } = this.props;

    if (!planOfStudy || !availableTerms) {
      return null;
    }

    const numberOfAcademicYears = 5;
    const startingTermIndex = availableTerms.indexOf(planOfStudy.startTerm);

    return [...Array(numberOfAcademicYears)].map((_, yearIndex) => {
      const academicYear = yearIndex + 1;
      return (
        <div key={academicYear}>
          <h3 className="alternative">Academic Year {academicYear}</h3>
          <ul className="checkboxes interactive">
            {availableTerms.map((term, termIndex) => {
              let readOnly = false;
              if (academicYear === 1) {
                if (termIndex < startingTermIndex) {
                  return null;
                } else if (termIndex === startingTermIndex) {
                  readOnly = true;
                }
              }
              return (
                <Checkbox
                  key={term}
                  domId={term + academicYear}
                  label={term}
                  id={{ term: term, year: academicYear }}
                  value={this.isSelected(term, academicYear)}
                  controlFunc={this.handleCheckboxChange}
                  readOnly={readOnly}
                />
              );
            })}
          </ul>
        </div>
      );
    });
  }

  updateTerms(event) {
    event.preventDefault();

    const { planOfStudy, putPlanOfStudyTerms } = this.props;
    let { terms } = this.state;

    terms.forEach(term => {
      if (!term.courseIds) {
        term.courseIds = [];
      }
    });

    putPlanOfStudyTerms(planOfStudy.id, terms);
  }

  cancelUpdate(event) {
    event.preventDefault();
    this.props.hideFormFunc();
  }

  handleCheckboxChange(id) {
    const { terms } = this.state;
    let termIndex = terms.findIndex(element => {
      return id.term === element.term && id.year === element.year;
    });
    if (termIndex >= 0) {
      let arrayCopy = [...terms];
      arrayCopy.splice(termIndex, 1);
      this.setState({
        terms: arrayCopy
      });
    } else {
      this.setState(state => ({
        terms: state.terms.concat([
          {
            year: id.year,
            term: id.term,
            courseIds: []
          }
        ])
      }));
    }
  }

  isSelected(term, year) {
    if (year === 1 && term === this.props.planOfStudy.startTerm) {
      return 'checked';
    }

    const elementIndex = this.state.terms.findIndex(element => {
      return term === element.term && year === element.year;
    });

    if (elementIndex >= 0) {
      return 'checked';
    }

    return '';
  }
}

const loadingSelector = createLoadingSelector([PUT_PLAN_OF_STUDY_TERMS]);
const errorMessageSelector = createErrorMessageSelector([
  PUT_PLAN_OF_STUDY_TERMS
]);
const mapStateToProps = state => {
  const { config, plansOfStudy } = state;
  return {
    updating: loadingSelector(state),
    errorMessage: errorMessageSelector(state),
    planOfStudy: plansOfStudy.viewIndividual,
    availableTerms: config.termsByAcademicYear
  };
};

export default connect(
  mapStateToProps,
  { putPlanOfStudyTerms, showLoadingModal, hideModal }
)(UpdateTerms);
