import React from 'react';
import './UpdateFacultyMember.scss';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import Select from '../common/Select';
import Input from '../common/Input';
import ConnectedSelector from '../common/ConnectedSelector';
import {
  hideSidePanel,
  updateTrapFocus
} from '../../service/actions/sidePanel';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import SelectRank from '../common/SelectRank';
import SelectAppointment from '../common/SelectAppointment';
import SelectDepartment from '../common/SelectDepartment';
import { updateFacultyMember } from '../../service/actions/faculty';
import {
  getMonthList,
  getYearList,
  getMonthNumberString,
  getMonthNameString
} from './dateHelpers';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import {
  UPDATE_FACULTY_MEMBER,
  PUT_WORKPLAN_CURRYEAR
} from '../../service/types';

class UpdateFacultyMember extends React.Component {
  constructor(props) {
    super(props);

    if (!props.facultyMember && !props.facultyList) {
      console.error('no faculty member provided to the side panel');
    }
    const { facultyMember } = props;

    this.state = {
      isFormInputValid: true,
      duplicateErrorMessage: '',
      resetting: false,
      appointmentId: facultyMember.appointmentId,
      rankId: facultyMember.rankId,
      departmentId: facultyMember.departmentId,
      startMonth: getMonthNameString(
        facultyMember.appointmentDate.split('-')[1]
      ),
      startYear: facultyMember.appointmentDate.split('-')[0],
      firstName: facultyMember.firstName,
      lastName: facultyMember.lastName,
      emailAddress: facultyMember.emailAddress || '',
      employeeId: facultyMember.employeeId || '',
      active: facultyMember.active || false
    };

    this.baseState = { ...this.state };
    this.handleValueChange = this.handleValueChange.bind(this);
    this.formValues = {
      appointmentId: {
        name: 'appointment',
        valueId: 'appointmentId',
        defaultValue: this.state.appointmentId,
        labelTitle: 'Appointment',
        validatorFunc: value => ({
          isValid: value && value !== '' && value !== 'Select',
          errorMessage: 'Invalid'
        })
      },
      rankId: {
        name: 'rank',
        valueId: 'rankId',
        defaultValue: this.state.rankId,
        labelTitle: 'Rank',
        validatorFunc: value => ({
          isValid: value && value !== '' && value !== 'Select',
          errorMessage: 'Invalid'
        })
      },
      departmentId: {
        name: 'department',
        valueId: 'departmentId',
        defaultValue: this.state.departmentId || '',
        labelTitle: 'Department',
        validatorFunc: () => ({
          isValid: true,
          errorMessage: 'Invalid'
        })
      },
      startMonth: {
        name: 'start-month',
        valueId: 'startMonth',
        defaultValue: this.state.startMonth,
        labelTitle: 'Month',
        validatorFunc: value => ({
          isValid: value && value !== '' && value !== 'Select',
          errorMessage: 'Invalid'
        })
      },
      startYear: {
        name: 'start-year',
        valueId: 'startYear',
        defaultValue: this.state.startYear,
        labelTitle: 'Year',
        validatorFunc: value => ({
          isValid: value && value !== '' && value !== 'Select',
          errorMessage: 'Invalid'
        })
      },
      firstName: {
        name: 'first-name',
        valueId: 'firstName',
        labelTitle: 'First Name',
        defaultValue: this.state.firstName,
        validatorFunc: value => {
          return {
            isValid:
              /^.{1,30}$/.test(value) && value && value.trim().length > 0,
            errorMessage: 'Invalid first name'
          };
        }
      },
      lastName: {
        name: 'last-name',
        valueId: 'lastName',
        labelTitle: 'Last Name',
        defaultValue: this.state.lastName,
        validatorFunc: value => {
          return {
            isValid:
              /^.{1,30}$/.test(value) && value && value.trim().length > 0,
            errorMessage: 'Invalid last name'
          };
        }
      },
      emailAddress: {
        name: 'email-address',
        valueId: 'emailAddress',
        labelTitle: 'Email',
        defaultValue: this.state.emailAddress,
        validatorFunc: value => {
          const isDuplicate =
            value &&
            value !== '' &&
            props.facultyList.reduce((aggregator, item) => {
              return (
                aggregator ||
                (item.id !== props.facultyMember.id &&
                  (item.emailAddress || '').toUpperCase() ===
                    value.toUpperCase() &&
                  'Duplicate email address')
              );
            }, false);

          if (isDuplicate) {
            return {
              isValid: false,
              errorMessage:
                'Duplicate Email Address Found. Please validate your entry.'
            };
          }

          return {
            isValid:
              (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
                value
              ) &&
                value.length <= 100) ||
              !value ||
              value.length === 0,
            errorMessage: 'Invalid email address'
          };
        }
      },
      employeeId: {
        name: 'employee-id',
        valueId: 'employeeId',
        labelTitle: 'Employee ID',
        defaultValue: this.state.employeeId,
        validatorFunc: value => {
          const isDuplicate =
            value &&
            value !== '' &&
            props.facultyList.reduce((aggregator, item) => {
              return (
                aggregator ||
                (item.id !== props.facultyMember.id &&
                  (item.employeeId || '').toUpperCase() ===
                    value.toUpperCase() &&
                  'Duplicate employee id')
              );
            }, false);

          if (isDuplicate) {
            return {
              isValid: false,
              errorMessage:
                'Duplicate Employee ID Found. Please validate your entry.'
            };
          }

          return {
            isValid:
              /^(\w|\.|-){1,20}$/.test(value) || !value || value.length === 0,
            errorMessage: 'Invalid Employee ID'
          };
        }
      },
      active: {
        name: 'active',
        valueId: 'active',
        labelTitle: 'Active / Inactive',
        defaultValue: this.state.active,
        validatorFunc: value => {
          return {
            isValid: value === false || value === true,
            errorMessage: 'Invalid active status'
          };
        }
      }
    };

    this.monthList = getMonthList();
    this.yearList = getYearList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.updating !== prevProps.updating) {
      if (!this.props.updating && !this.props.errorMessage) {
        this.closePanel(true);
      }
    }
    if (this.state.isFormInputValid !== prevState.isFormInputValid) {
      this.props.updateTrapFocus();
    }
  }

  render() {
    const { errorMessage, departmentsList } = this.props;

    return (
      <section>
        <div className="top first-focus">
          <h1>Update Faculty</h1>
          <button
            className="circle"
            ref={this.closeButton}
            tabIndex="0"
            title="Close Edit Panel"
            onClick={event => {
              event.preventDefault();
              this.closePanel(false);
            }}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                this.closePanel(true);
              }
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        <form id="faculty-member-form-wrapper">
          <div id="faculty-member-entry-form">
            <div className="first-name">
              <Input
                formValue={this.formValues.firstName}
                controlFunc={this.handleValueChange}
              />
            </div>
            <div className="last-name">
              <Input
                formValue={this.formValues.lastName}
                controlFunc={this.handleValueChange}
              />
            </div>
            <div className="email-address">
              <Input
                formValue={this.formValues.emailAddress}
                controlFunc={this.handleValueChange}
                immediatelyValidate={true}
                optional={true}
              />
            </div>
            <div className="employee-id">
              <Input
                formValue={this.formValues.employeeId}
                controlFunc={this.handleValueChange}
                immediatelyValidate={true}
                optional={true}
              />
            </div>
            <div className="appointment">
              <SelectAppointment
                formValue={this.formValues.appointmentId}
                controlFunc={this.handleValueChange}
                value={this.formValues.appointmentId.defaultValue}
              />
            </div>
            <div className="rank">
              <SelectRank
                formValue={this.formValues.rankId}
                controlFunc={this.handleValueChange}
                value={this.formValues.rankId.defaultValue}
              />
            </div>
            {departmentsList && departmentsList.length ? (
              <div className="department">
                <SelectDepartment
                  formValue={this.formValues.departmentId}
                  controlFunc={this.handleValueChange}
                  value={this.formValues.departmentId.defaultValue}
                  optional={true}
                />
              </div>
            ) : null}
            <div className="dates update">
              <div className="start-date">
                <h3>Start Date</h3>
                <div className="selects">
                  <div>
                    <Select
                      formValue={this.formValues.startMonth}
                      startingValue={this.formValues.startMonth.defaultValue}
                      controlFunc={this.handleValueChange}
                      optionList={this.monthList}
                    />
                  </div>
                  <div>
                    <Select
                      formValue={this.formValues.startYear}
                      startingValue={this.formValues.startYear.defaultValue}
                      controlFunc={this.handleValueChange}
                      optionList={this.yearList}
                    />
                  </div>
                </div>
              </div>
              <ConnectedSelector
                options={['Active', 'Inactive']}
                preselectedOptions={[
                  this.formValues.active.defaultValue === true
                    ? 'Active'
                    : 'Inactive'
                ]}
                controlFunc={event =>
                  this.handleValueChange({
                    valueId: this.formValues.active.valueId,
                    value: event[0] === 'Active' ? true : false
                  })
                }
                multiSelect={false}
                upperCase={false}
              />
            </div>
          </div>

          <div id="form-end-wrapper">
            {errorMessage && (
              <div className="form-message unexpected-error-message error-message">
                <FontAwesomeIcon icon={faExclamationCircle} />
                An unknown error occurred. Please try again.
              </div>
            )}

            <div className="buttons">
              <button
                id="cancel-course-entry"
                onClick={event => {
                  event.preventDefault();
                  this.closePanel(false);
                }}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    this.closePanel(true);
                  }
                }}
              >
                Cancel
              </button>
              <button
                id="submit-faculty-member-entry"
                onClick={event => this.handleSubmitClick(event)}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    this.handleSubmitClick(event);
                  }
                }}
                disabled={!this.state.isFormInputValid}
              >
                Save Faculty <FontAwesomeIcon icon={faCheckCircle} />
              </button>
            </div>
          </div>
        </form>
      </section>
    );
  }

  handleSubmitClick(event) {
    event.preventDefault();
    this.updateFacultyMember();
  }

  updateFacultyMember() {
    this.validateFormInput();
    const {
      firstName,
      lastName,
      emailAddress,
      employeeId,
      appointmentId,
      rankId,
      departmentId,
      startMonth,
      startYear,
      active
    } = this.state;
    const { facultyMember } = this.props;

    const appointmentDate = `${startYear}-${getMonthNumberString(startMonth)}`;
    const facultyMemberUpdate = {
      firstName,
      lastName,
      emailAddress: emailAddress || undefined,
      employeeId: employeeId || undefined,
      appointmentId,
      rankId,
      departmentId: departmentId || undefined,
      appointmentDate,
      active
    };

    this.props.updateFacultyMember(facultyMember.id, facultyMemberUpdate);
  }

  handleValueChange(formValue) {
    this.setState({
      [formValue.valueId]: formValue.value,
      isFormInputValid: Object.keys(this.formValues).reduce((result, next) => {
        let theResult;
        if (next === formValue.valueId) {
          theResult =
            result &&
            this.formValues[next].validatorFunc(formValue.value).isValid;
        } else {
          theResult =
            result &&
            this.formValues[next].validatorFunc(this.state[next]).isValid;
        }
        return theResult;
      }, true)
    });
  }

  validateFormInput() {
    this.setState({
      isFormInputValid: Object.keys(this.formValues).reduce((result, next) => {
        let theResult =
          result &&
          this.formValues[next].validatorFunc(this.state[next]).isValid;
        return theResult;
      }, true)
    });
  }

  closePanel(shouldSendFocus) {
    this.props.hideSidePanel({
      sendFocus: !!shouldSendFocus
    });
  }
}

const loadingSelector = createLoadingSelector([
  UPDATE_FACULTY_MEMBER,
  PUT_WORKPLAN_CURRYEAR
]);
const errorSelector = createErrorMessageSelector([UPDATE_FACULTY_MEMBER]);
const mapStateToProps = state => {
  const { config } = state;
  return {
    updating: loadingSelector(state),
    errorMessage: errorSelector(state),
    departmentsList: config && config.config && config.config.departments
  };
};

export default connect(mapStateToProps, {
  updateFacultyMember,
  hideSidePanel,
  updateTrapFocus
})(UpdateFacultyMember);
