import React from 'react';
import './ScheduleView.scss';
import { connect } from 'react-redux';
import ScheduleCourse from './ScheduleCourse';
import { ValidationMessage } from '../common/Validation/validationFunctionComponent';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import {
  GET_SCHEDULES,
  GET_COURSES,
  GET_STUDENT_GROUPS,
  GET_CONFIG
} from '../../service/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';

class ScheduleViewCourses extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedProgramGroup: 'all' };
  }

  componentDidMount() {
    if (this.props.selectedGroup) {
      this.setState({
        selectedProgramGroup: this.props.selectedGroup
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedGroup !== this.props.selectedGroup) {
      this.setState({
        selectedProgramGroup: this.props.selectedGroup
      });
    }
  }

  render() {
    const {
      courseComponents,
      schedule,
      courseList,
      studentGroupList,
      validationList,
      errorMessage,
      projectedEnrollmentList,
      programs
    } = this.props;

    if (errorMessage) {
      return (
        <div className="form-message unexpected-error-message error-message">
          <FontAwesomeIcon icon={faExclamationCircle} />
          {errorMessage}
        </div>
      );
    }

    if (
      !schedule ||
      !courseList ||
      !studentGroupList ||
      !courseComponents ||
      !projectedEnrollmentList ||
      !programs
    ) {
      return null;
    }

    const studentGroupCourses = schedule.studentGroups
      .map(sg => {
        // Find the program associated with the student group
        let { program } = studentGroupList.find(s => s.id === sg.id);
        // Find the group associated with the program
        let { group } = programs.find(p => p.id === program.id);
        // Return the student group courses with their program groups
        return sg.courseIds.map(c => {
          return {
            ...courseList.find(course => c === course.id),
            programGroup: group
          };
        });
      })
      .flat();

    const augmentedScheduleCourses = schedule.courses.map(scheduleCourse => {
      const courseInfo = courseList.find(course => {
        return course.id === scheduleCourse.id;
      });

      if (!courseInfo) {
        return null;
      }
      // Get the course if it exists in a student group
      let matchingCourses = studentGroupCourses.filter(
        c => c.id === scheduleCourse.id
      );

      const projectedEnrollmentFind = projectedEnrollmentList.courses.find(
        course => course.id === scheduleCourse.id
      );
      const projectedEnrollment =
        (projectedEnrollmentFind &&
          projectedEnrollmentFind.projectedEnrollment) ||
        0;

      return {
        ...scheduleCourse,
        courseInfo: courseInfo,
        programGroups: matchingCourses.map(c => c.programGroup), // Return all program groups
        projectedEnrollment
      };
    });

    let scheduleValidationList = [];
    if (validationList) {
      scheduleValidationList = validationList
        .flat()
        .filter(v => v.validationLevel === 'schedule');
    }
    let scheduleCourses = []
      .concat(augmentedScheduleCourses)
      .filter(
        c =>
          this.state.selectedProgramGroup === 'all' || // Show all courses, including those that don't have a match in student groups
          c.programGroups.some(g => g === this.state.selectedProgramGroup) || // Show only the courses associated with the selected group
          (this.state.selectedProgramGroup === 'other' &&
            !c.programGroups.length) // Show courses that aren't in student groups
      )
      .sort((a, b) => {
        if (!a || !b) {
          return 1;
        }
        if (!a.courseInfo || !b.courseInfo) {
          return 1;
        }
        return ('' + a.courseInfo.courseNumber).localeCompare(
          b.courseInfo.courseNumber
        );
      });
    return (
      <div className="schedule-courses">
        {scheduleValidationList.map((m, i) => (
          <ValidationMessage message={m.message} type={m.type} key={i} />
        ))}
        {scheduleCourses.length ? (
          scheduleCourses.map(scheduleCourse => {
            if (!scheduleCourse) {
              return null;
            }
            return (
              <ScheduleCourse
                key={scheduleCourse.id}
                scheduleCourse={scheduleCourse}
              />
            );
          })
        ) : (
          <span>No courses associated with this group.</span>
        )}
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector([
  GET_SCHEDULES,
  GET_COURSES,
  GET_STUDENT_GROUPS,
  GET_CONFIG
]);
const errorSelector = createErrorMessageSelector([GET_COURSES]);
const mapStateToProps = state => {
  const { auth, config, courses, schedules, studentGroups, programs } = state;
  return {
    scopes: auth.scopes,
    courseComponents:
      config && config.config && config.config.courseComponentTypes,
    loading: loadingSelector(state),
    programs: programs.list,
    selectedProgramGroup: programs.selectedProgramGroup,
    projectedEnrollmentList: schedules.projectedEnrollmentList,
    schedule: schedules.viewIndividual,
    validationList: schedules.validationResults,
    courseList: courses.list,
    termList: config.termsByCalendarYear,
    studentGroupList: studentGroups.list,
    errorMessage: errorSelector(state)
  };
};

export default connect(mapStateToProps, null)(ScheduleViewCourses);
