import React from 'react';
import './UpdateTermCourses.scss';
import { connect } from 'react-redux';
import { putStudentGroupTermCourses } from '../../service/actions/studentGroups';
import { showLoadingModal, hideModal } from '../../service/actions/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import FormReveal from '../common/FormReveal';
import Checkbox from '../common/Checkbox';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import { PUT_STUDENT_GROUP_TERM_COURSES } from '../../service/types';

class UpdateTermCourses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCourses: []
    };
    this.baseState = { ...this.state };
    this.formRevealRef = React.createRef();
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  componentDidMount() {
    this.populateLocalTermCourseSelections();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.updating !== this.props.updating) {
      if (this.props.updating) {
        this.props.showLoadingModal();
      } else {
        this.props.hideLoadingModal();
        this.populateLocalTermCourseSelections();
      }
    } else if (
      prevState.resetting !== this.state.resetting &&
      this.state.resetting
    ) {
      this.setState({
        resetting: false
      });
    }
  }

  populateLocalTermCourseSelections() {
    const { term } = this.props;
    let courseIds = [];
    if (term.courseIds && term.courseIds.length) {
      courseIds = [].concat(term.courseIds);
    }
    this.setState({
      selectedCourses: courseIds
    });
  }

  render() {
    const { errorPayload, term } = this.props;
    const form = this.renderUpdateTermCoursesForm();
    if (!form) return null;
    return (
      <div className="update-term-courses-wrapper">
        {errorPayload &&
          errorPayload.id.term === term.term &&
          errorPayload.id.year === term.year && (
            <div className="error-message plans-of-study-error-message">
              <FontAwesomeIcon icon={faExclamationCircle} />
              {errorPayload.error}
            </div>
          )}
        <FormReveal
          buttonText="Assign/Unassign Courses"
          buttonId="update-sg-courses"
          form={form}
          submitFunc={() => this.handleSubmitClick()}
          cancelFunc={() => this.resetForm()}
          ref={this.formRevealRef}
        />
      </div>
    );
  }

  renderUpdateTermCoursesForm() {
    const { term } = this.props;
    return (
      <form
        className="update-term-courses-form"
        id={`form-${term.term}-${term.year}`}
      >
        <fieldset>{this.renderCourseList()}</fieldset>
        <div className="buttons">
          <button
            className="cancel-save-courses-button"
            onClick={event => this.handleCancelClick(event)}
            onKeyUp={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                this.handleCancelClick(event);
              }
            }}
          >
            Cancel
          </button>
          <button
            className="save-courses-button"
            onClick={event => this.updateTermCourses(event)}
            onKeyUp={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                this.updateTermCourses(event);
              }
            }}
          >
            Save Courses
            <FontAwesomeIcon icon={faCheckCircle} />
          </button>
        </div>
      </form>
    );
  }

  renderCourseList() {
    const { term, courseList } = this.props;

    const noArchivedCourses = courseList.filter(
      c => !c.archived || term.courseIds.includes(c.id)
    );

    if (!term || !courseList) {
      return null;
    }
    return (
      <ul className="none interactive course-list">
        {[]
          .concat(noArchivedCourses)
          .sort((a, b) => {
            if (a.isNew > b.isNew) return 1;
            if (a.isNew < b.isNew) return -1;
            return ('' + a.courseNumber).localeCompare(b.courseNumber);
          })
          .map((course, index) => {
            const courseId = course.id;
            return (
              <Checkbox
                key={index}
                domId={courseId + '_' + term.term + term.year}
                label={courseId}
                id={{ courseId: courseId }}
                value={this.isSelected(courseId)}
                controlFunc={this.handleCheckboxChange}
                hiddenLabel={true}
                liClassName="course"
              >
                <div className="content">
                  <span className="course-number">
                    {course.courseNumber.replace(/(\d)/, ' $1')}
                  </span>
                  <span className="course-name">
                    {course.name}
                    <span className="credit-hours">
                      ({course.variableCredit ? 'Variable' : course.credits} CR)
                    </span>
                  </span>
                </div>
              </Checkbox>
            );
          })}
      </ul>
    );
  }

  updateTermCourses(event) {
    event.preventDefault();

    const { term, studentGroup, putStudentGroupTermCourses } = this.props;
    const { selectedCourses } = this.state;

    let termIndex = studentGroup.planOfStudy.terms.findIndex(element => {
      return term.year === element.year && term.term === element.term;
    });
    let arrayCopy = [...studentGroup.planOfStudy.terms];
    arrayCopy.splice(termIndex, 1);
    arrayCopy.push({
      ...term,
      courseIds: selectedCourses
    });
    putStudentGroupTermCourses(studentGroup.id, term, arrayCopy);
  }

  handleCheckboxChange(identifier) {
    const { selectedCourses } = this.state;
    let courseIndex = selectedCourses.findIndex(element => {
      return identifier.courseId === element;
    });
    if (courseIndex >= 0) {
      // unselect
      let arrayCopy = [...selectedCourses];
      arrayCopy.splice(courseIndex, 1);
      this.setState({
        selectedCourses: arrayCopy
      });
    } else {
      // select
      this.setState(state => ({
        selectedCourses: state.selectedCourses.concat([identifier.courseId])
      }));
    }
  }

  resetForm() {
    let newState = { ...this.baseState, resetting: true };
    this.setState(newState);
    this.populateLocalTermCourseSelections();
  }

  handleCancelClick(event) {
    event.preventDefault();
    this.formRevealRef.current.handleCancelClick(event);
  }

  handleSubmitClick(event) {
    event.preventDefault();
    this.formRevealRef.current.handleSubmitClick(event);
  }

  isSelected(courseId) {
    const elementIndex = this.state.selectedCourses.findIndex(element => {
      return element === courseId;
    });

    return elementIndex >= 0 ? 'checked' : '';
  }
}

const updatingSelector = createLoadingSelector([
  PUT_STUDENT_GROUP_TERM_COURSES
]);
const errorMessageSelector = createErrorMessageSelector([
  PUT_STUDENT_GROUP_TERM_COURSES
]);
const mapStateToProps = state => {
  const { courses, studentGroups } = state;
  return {
    updating: updatingSelector(state),
    errorPayload: errorMessageSelector(state),
    studentGroup: studentGroups.viewIndividual,
    courseList: courses.list
  };
};

export default connect(
  mapStateToProps,
  { putStudentGroupTermCourses, showLoadingModal, hideModal }
)(UpdateTermCourses);
