import React from 'react';
import { connect } from 'react-redux';
import Input from '../common/Input';
import './StudentGroupTermEnrollment.scss';
import { showLoadingModal, hideModal } from '../../service/actions/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { updateStudentGroupEnrollment } from '../../service/actions/studentGroups';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import { PUT_STUDENT_GROUP_ENROLLMENT } from '../../service/types';

class StudentGroupTermEnrollment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enrollment: props.enrollment.students,
      isEditMode: false,
      isFormInputValid: true
    };

    this.formValues = {
      enrollment: {
        name: 'enrollment-input',
        idSuffix: props.idNumber,
        valueId: 'enrollment',
        defaultValue: props.enrollment.students,
        labelTitle: 'Enrollment',
        validatorFunc: value => {
          return {
            isValid:
              value === '' ||
              (/^\d+$/.test(value) &&
                parseInt(value) > 0 &&
                parseInt(value) < 10000),
            errorMessage: 'Invalid'
          };
        }
      }
    };

    this.inputRef = React.createRef();
    this.handleValueChange = this.handleValueChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      if (this.props.loading) {
        this.props.showLoadingModal();
      } else {
        this.props.hideModal();

        if (!this.props.errorMessage) {
          this.setState({
            isEditMode: false
          });
        }
      }
    }
  }

  render() {
    if (!this.props.scopes) {
      return null;
    }
    const readAuthorized = this.props.scopes.includes('student-group.read');
    const writeAuthorized = this.props.scopes.includes('student-group.write');

    if (!readAuthorized) {
      return null;
    }
    const { canEdit } = this.props;
    const { isEditMode } = this.state;

    return (
      <div className="student-group-enrollment">
        <div className="text-content">
          {this.props.enrollment.students || isEditMode ? (
            <>
              <div className="count">
                {isEditMode && canEdit ? (
                  <Input
                    formValue={this.formValues.enrollment}
                    controlFunc={this.handleValueChange}
                    immediatelyValidate={true}
                    hideLabel={true}
                    hideError={true}
                    ref={this.inputRef}
                  />
                ) : (
                  <span>{this.props.enrollment.students}</span>
                )}
              </div>
              <span>Students</span>
            </>
          ) : (
            <span>No enrollment set</span>
          )}
        </div>

        {writeAuthorized && isEditMode && canEdit && (
          <div className="buttons">
            <button
              className="circle"
              onClick={() => this.setEditMode(false)}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  this.setEditMode(false);
                }
              }}
              title="Cancel changes"
            >
              <FontAwesomeIcon icon={faTimes} />
              <span className="visually-hidden">Cancel changes</span>
            </button>
            <button
              disabled={!this.state.isFormInputValid}
              className="circle"
              onClick={() => this.saveChanges()}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  this.saveChanges();
                }
              }}
              title="Save changes"
            >
              <FontAwesomeIcon icon={faCheck} />
              <span className="visually-hidden">Save changes</span>
            </button>
          </div>
        )}

        {writeAuthorized && !isEditMode && canEdit && (
          <div className="buttons">
            <button
              className="circle"
              onClick={() => this.setEditMode(true)}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  this.setEditMode(true);
                }
              }}
              title="Edit term enrollment"
            >
              <FontAwesomeIcon icon={faEdit} />
              <span className="visually-hidden">Edit term enrollment</span>
            </button>
          </div>
        )}
      </div>
    );
  }

  saveChanges() {
    const {
      studentGroup,
      enrollment,
      updateStudentGroupEnrollment
    } = this.props;
    const newEnrollment = [...studentGroup.enrollment].reduce(
      (result, element) => {
        if (
          element.term === enrollment.term &&
          element.year === enrollment.year
        ) {
          if (this.state.enrollment === '') {
            let toDeleteStudents = {
              ...enrollment
            };
            delete toDeleteStudents.students;
            result.push(toDeleteStudents);
          } else {
            result.push({
              ...enrollment,
              students: parseInt(this.state.enrollment)
            });
          }
        } else {
          result.push(element);
        }
        return result;
      },
      []
    );

    updateStudentGroupEnrollment(studentGroup.id, newEnrollment);
  }

  setEditMode(visibility) {
    this.setState({
      isEditMode: visibility
    });
    if (visibility) {
      setTimeout(
        function() {
          this.inputRef.current.focusTextInput();
        }.bind(this),
        30
      );
    }
  }

  handleValueChange(formValue) {
    const isFormInputValid = this.formValues.enrollment.validatorFunc(
      formValue.value
    );
    this.setState({
      [formValue.valueId]: formValue.value,
      isFormInputValid: isFormInputValid.isValid
    });
  }
}

const loadingSelector = createLoadingSelector([PUT_STUDENT_GROUP_ENROLLMENT]);
const errorSelector = createErrorMessageSelector([
  PUT_STUDENT_GROUP_ENROLLMENT
]);
const mapStateToProps = state => {
  const { auth, studentGroups } = state;
  return {
    scopes: auth.scopes,
    studentGroup: studentGroups.viewIndividual,
    loading: loadingSelector(state),
    errorMessage: errorSelector(state)
  };
};

export default connect(
  mapStateToProps,
  { updateStudentGroupEnrollment, showLoadingModal, hideModal }
)(StudentGroupTermEnrollment);
