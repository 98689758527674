import React from 'react';
import PropTypes from 'prop-types';
import './Input.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';

class Input extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasBeenBlurred: false,
      isValid: true
    };

    this.inputRef = React.createRef();

    if (props.clearCommand) {
      props.clearCommand.invoke = () => this.handleClearValue();
    }
  }

  focusTextInput() {
    this.inputRef.current.focus();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.formValue.defaultValue !== prevProps.formValue.defaultValue
    ) {
      const { isValid, errorMessage } = this.props.formValue.validatorFunc(
        this.props.formValue.defaultValue
      );
      this.setState({
        isValid,
        errorMessage,
        hasBeenBlurred: true
      });
    }
    if (this.props.disabled !== prevProps.disabled) {
      if (this.props.disabled) {
        this.inputRef.current.value = '';
        this.setState({
          isValid: true,
          errorMessage: '',
          hasBeenBlurred: false
        });
      }
    }
  }

  render() {
    const {
      formValue,
      type,
      placeholder,
      hideLabel,
      hideError,
      hideErrorMessage,
      idSuffix,
      disabled,
      errorStyle,
      optional,
      actionable
    } = this.props;
    const { hasBeenBlurred, isValid, errorMessage } = this.state;
    const showError = !hideError && hasBeenBlurred;

    return (
      <div className="input-container">
        <label
          htmlFor={formValue.name + idSuffix}
          className={
            (hideLabel ? 'visually-hidden ' : '') +
            (isValid ? '' : 'error ') +
            (disabled ? 'disabled ' : '') +
            (actionable ? 'actionable ' : '')
          }
        >
          {formValue.labelTitle}
        </label>

        <div className="facts-input">
          <input
            defaultValue={formValue.defaultValue}
            id={formValue.name + '-input' + idSuffix}
            name={formValue.name + idSuffix}
            type={type}
            placeholder={placeholder}
            onChange={this.handleChange.bind(this)}
            onBlur={this.handleBlur.bind(this)}
            className={
              (isValid ? '' : 'error ') +
              (disabled ? 'disabled ' : '') +
              (actionable ? 'actionable ' : '')
            }
            tabIndex={disabled ? '-1' : '0'}
            ref={this.inputRef}
            aria-disabled={disabled ? 'true' : 'false'}
            disabled={disabled}
          />

          {!hideError && hasBeenBlurred && (
            <div
              className={
                isValid
                  ? 'input-validation-icons'
                  : 'input-validation-icons error'
              }
            >
              {isValid ? (
                document.getElementById(formValue.name + '-input' + idSuffix)
                  .value.length > 0 && <FontAwesomeIcon icon={faCheckCircle} />
              ) : (
                <FontAwesomeIcon icon={faExclamationCircle} />
              )}
            </div>
          )}
        </div>
        <div className="optional-input-label">
          {optional ? <p>&#x28;OPTIONAL&#x29;</p> : null}
        </div>

        {showError && !hideErrorMessage && (
          <div
            id={formValue.name + '-error'}
            style={errorStyle}
            className="input-error"
            hidden={isValid}
          >
            {errorMessage}
          </div>
        )}
      </div>
    );
  }

  getObjectFromEvent(event) {
    let eventValue = event.target.value;

    if (this.props.toUppercase) {
      eventValue = eventValue.toUpperCase();
    }
    if (this.props.trimWhitespaces) {
      eventValue = eventValue.trim();
    }

    return {
      valueId: this.props.formValue.valueId,
      value: eventValue
    };
  }

  handleChange(event) {
    const obj = this.getObjectFromEvent(event);

    if (this.state.hasBeenBlurred || this.props.immediatelyValidate) {
      this.updateAndValidate(obj);
    } else {
      this.update(obj);
    }
  }

  handleBlur(event) {
    const obj = this.getObjectFromEvent(event);
    this.updateAndValidate(obj);
  }

  updateAndValidate(obj) {
    const { isValid, errorMessage } = this.props.formValue.validatorFunc(
      obj.value
    );

    this.setState({
      isValid,
      errorMessage,
      hasBeenBlurred: true
    });

    this.update(obj);
  }

  update(obj) {
    this.props.controlFunc(obj);
  }

  handleClearValue() {
    this.setState({
      hasBeenBlurred: false,
      isValid: true
    });
  }
}

Input.propTypes = {
  formValue: PropTypes.object.isRequired,
  controlFunc: PropTypes.func.isRequired,
  hideLabel: PropTypes.bool,
  hideError: PropTypes.bool,
  hideErrorMessage: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  immediatelyValidate: PropTypes.bool,
  toUppercase: PropTypes.bool,
  trimWhitespaces: PropTypes.bool,
  idSuffix: PropTypes.string,
  disabled: PropTypes.bool,
  errorStyle: PropTypes.object,
  optional: PropTypes.bool,
  actionable: PropTypes.bool
};

Input.defaultProps = {
  hideLabel: false,
  hideError: false,
  hideErrorMessage: false,
  immediatelyValidate: false,
  toUppercase: false,
  trimWhitespaces: false,
  type: 'text',
  idSuffix: '',
  disabled: false,
  optional: false
};

export default Input;
