import React from 'react';
import './DefaultEffort.scss';
import { connect } from 'react-redux';
import {
  getDefaultTeachingEffort,
  setDefaultTeachingEffort
} from '../../service/actions/faculty';
import { getCourses } from '../../service/actions/courses';
import {
  showLoadingModal,
  showCustomJSXModal,
  hideModal
} from '../../service/actions/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import CourseEffort from './CourseEffort';
import { effortValidatorFunc } from './helpers';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import {
  GET_COURSES,
  GET_CONFIG,
  GET_DEFAULT_TEACHING_EFFORT,
  SET_DEFAULT_TEACHING_EFFORT
} from '../../service/types';
import { mainViewComponent } from '../common/HOCs';

class DefaultEffort extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      isFormInputValid: true
    };
  }

  componentDidMount() {
    this.props.getDefaultTeachingEffort();
    this.props.getCourses();
    if (!this.props.loading) {
      this.setupStateData();
    }

    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      if (!this.props.loading) {
        this.setupStateData();
      }
    }

    if (prevProps.updating !== this.props.updating) {
      if (this.props.updating) {
        this.props.showLoadingModal();
      } else {
        if (this.props.updatingError) {
          this.props.hideModal();
          this.setupStateData();
        } else {
          this.props.showCustomJSXModal(
            <div id="success-message" style={{ textAlign: 'center' }}>
              <FontAwesomeIcon icon={faCheckCircle} />
              Course Effort has been updated
            </div>
          );

          setTimeout(() => {
            this.props.hideModal();
          }, 1800);
        }
      }
    }
  }

  setupStateData() {
    const { courseComponents, courses, effort } = this.props;

    if (!effort) {
      return null;
    }

    const augmentedCourses = courses
      .filter(c => c.archived !== true)
      .map(course => {
        return {
          ...course,
          components: course.components.map(component => {
            return {
              component: {
                name: (
                  courseComponents.find(
                    item => item.id === component.componentType
                  ) || {}
                ).name,
                type: component.componentType,
                index:
                  courseComponents.findIndex(configComponent => {
                    return configComponent.id === component.componentType;
                  }) + 1
              },
              defaultEffort:
                (
                  effort.find(
                    effortItem =>
                      effortItem.courseId === course.id &&
                      effortItem.componentType === component.componentType
                  ) || {}
                ).defaultEffort || undefined
            };
          })
        };
      });

    // remove invalid defaultEffort & course/component combinations
    const effortToSend = effort.filter(defaultEffortItem => {
      const relevantCourse = courses.find(
        c => c.id === defaultEffortItem.courseId
      );
      if (relevantCourse) {
        return (
          relevantCourse.components.findIndex(
            component =>
              component.componentType === defaultEffortItem.componentType
          ) >= 0
        );
      } else {
        return false;
      }
    });

    this.setState({
      augmentedCourses,
      effortToSend
    });
  }

  render() {
    const { loadingError } = this.props;

    return (
      <div className="default-effort course-list">
        <div className="top-container default-effort-container">
          <div>
            <h1 className="no-bar">Default Effort Per Course</h1>
            {<p>Current Effort Unit: {this.props.effortUnit}</p>}
          </div>
          <div>
            <button
              disabled={!this.state.isFormInputValid || this.props.loadingError}
              onClick={() => this.setEfforts()}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  this.setEfforts();
                }
              }}
            >
              Save All <FontAwesomeIcon icon={faCheckCircle} />
            </button>
          </div>
        </div>

        {loadingError && (
          <div className="error-message">
            <FontAwesomeIcon icon={faExclamationCircle} />
            {loadingError}
          </div>
        )}
        {!loadingError && this.renderEffort()}
      </div>
    );
  }

  handleInputChange(event) {
    this.setState({
      filter: event.target.value
    });
  }

  renderEffort() {
    const { loading, updatingError } = this.props;
    const { augmentedCourses, filter } = this.state;

    if (loading) {
      return null;
    }

    if (augmentedCourses && augmentedCourses.length) {
      let filteredCourses = this.filterCourses();

      return (
        <>
          {updatingError && (
            <div className="error-message">
              <FontAwesomeIcon icon={faExclamationCircle} />
              {updatingError}
            </div>
          )}
          <div className="course-results">
            <label htmlFor="course-search-input">Search</label>
            <input
              id="course-search-input"
              className="search-bar"
              type="text"
              value={filter}
              onChange={this.handleInputChange.bind(this)}
            />

            {filteredCourses.length ? (
              <ul className="none course-ul">
                {filteredCourses.map(course => (
                  <CourseEffort
                    key={course.id}
                    course={course}
                    loading={loading}
                    controlFunc={updatedEffortItem =>
                      this.updateEffortItem(updatedEffortItem)
                    }
                  />
                ))}
              </ul>
            ) : (
              <p>No courses match the criteria</p>
            )}
          </div>
        </>
      );
    } else {
      return <p className="empty">There are no courses.</p>;
    }
  }

  setEfforts() {
    const { setDefaultTeachingEffort } = this.props;
    const { effortToSend } = this.state;
    setDefaultTeachingEffort(effortToSend);
  }

  updateEffortItem(updatedEffortItem) {
    let newEffortToSend = [...this.state.effortToSend];
    let existingItemIndex = newEffortToSend.findIndex(
      effortItem =>
        effortItem.courseId === updatedEffortItem.courseId &&
        effortItem.componentType === updatedEffortItem.componentType
    );

    if (updatedEffortItem.defaultEffort === '') {
      if (existingItemIndex !== -1) {
        newEffortToSend.splice(existingItemIndex, 1);
      }
    } else {
      if (existingItemIndex !== -1) {
        newEffortToSend[existingItemIndex] = updatedEffortItem;
      } else {
        newEffortToSend.push(updatedEffortItem);
      }
    }

    this.setState({
      effortToSend: newEffortToSend.map(val => {
        return {
          ...val,
          defaultEffort:
            (val.defaultEffort && parseFloat(val.defaultEffort)) || ''
        };
      }),
      isFormInputValid: newEffortToSend.reduce((result, val) => {
        return result && effortValidatorFunc(val.defaultEffort);
      }, true)
    });
  }

  filterCourses() {
    const { augmentedCourses, filter } = this.state;

    return [].concat(augmentedCourses).filter(val => {
      if (filter) {
        return this.containsFilter(val);
      }
      return true;
    });
  }

  containsFilter(valueIn) {
    let returnVal = false;
    let modifiedFilter = this.state.filter.replace(/\s+/g, '').toUpperCase();

    if (
      valueIn.courseNumber
        .replace(/\s+/g, '')
        .toUpperCase()
        .indexOf(modifiedFilter) >= 0
    ) {
      returnVal = true;
    } else if (
      valueIn.name
        .replace(/\s+/g, '')
        .toUpperCase()
        .indexOf(modifiedFilter) >= 0
    ) {
      returnVal = true;
    }

    return returnVal;
  }
}

const loadingSelector = createLoadingSelector([
  GET_COURSES,
  GET_CONFIG,
  GET_DEFAULT_TEACHING_EFFORT
]);
const loadingErrorSelector = createErrorMessageSelector([
  GET_DEFAULT_TEACHING_EFFORT,
  GET_COURSES,
  GET_CONFIG
]);
const updatingSelector = createLoadingSelector([SET_DEFAULT_TEACHING_EFFORT]);
const updatingErrorSelector = createErrorMessageSelector([
  SET_DEFAULT_TEACHING_EFFORT
]);
const mapStateToProps = state => {
  const { auth, config, courses, faculty } = state;
  return {
    scopes: auth.scopes,
    loading: loadingSelector(state),
    updating: updatingSelector(state),
    courses: courses.list,
    effortUnit:
      config && config.config && config.config.facultyWorkPlanEffortUnit,
    effort: faculty.defaultTeachingEffort,
    loadingError: loadingErrorSelector(state),
    updatingError: updatingErrorSelector(state),
    courseComponents:
      config && config.config && config.config.courseComponentTypes
  };
};

const mainViewConfig = {
  pageTitle: 'Default Effort'
};

export default connect(mapStateToProps, {
  getDefaultTeachingEffort,
  setDefaultTeachingEffort,
  getCourses,
  showLoadingModal,
  showCustomJSXModal,
  hideModal
})(mainViewComponent({ component: DefaultEffort, mainViewConfig }));
