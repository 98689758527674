import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons/faFileDownload';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import './AddFacultyMember.scss';
import Select from '../common/Select';
import { exportWorkAssignments } from '../../service/actions/faculty';
import { getFullAcademicYearList } from './dateHelpers';
import {
  showCustomJSXModal,
  showCustomLoadingModal,
  hideModal
} from '../../service/actions/modal';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import { EXPORT_WORK_ASSIGNMENTS } from '../../service/types';

class ExportWorkAssignments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormInputValid: false
    };
    this.handleValueChange = this.handleValueChange.bind(this);

    this.formValues = {
      academicYear: {
        name: 'academic-year',
        valueId: 'academicYear',
        defaultValue: '',
        labelTitle: 'Academic Year',
        validatorFunc: value => ({
          isValid: value && value !== '' && value !== 'Select',
          errorMessage: 'Invalid'
        })
      }
    };
    this.academicYearList = getFullAcademicYearList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.exporting !== this.props.exporting) {
      if (this.props.exporting) {
        this.props.showCustomLoadingModal('Downloading...');
      } else {
        this.props.hideModal();
      }
    }
  }

  render() {
    const { exportingError } = this.props;

    return (
      <section className="export-sect">
        <h1 className="visually-hidden">Export Faculty Work Assignments</h1>
        <p>Select which academic year of work assignments to export.</p>
        <form id="export-form-wrapper">
          <div id="export-entry-form">
            <div className="dates">
              <div>
                <Select
                  formValue={this.formValues.academicYear}
                  controlFunc={this.handleValueChange}
                  optionList={this.academicYearList}
                />
              </div>
            </div>
          </div>
          <div id="form-end-wrapper">
            {exportingError && (
              <div className="form-message unexpected-error-message error-message">
                <FontAwesomeIcon icon={faExclamationCircle} />
                An unknown error occurred. Please try again.
              </div>
            )}
            <div className="buttons">
              <button
                id="cancel-faculty-member-export"
                onClick={event =>
                  this.props.handleCancelClick('ExportForm', event)
                }
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    this.props.handleCancelClick('ExportForm', event);
                  }
                }}
              >
                Cancel
              </button>
              <button
                id="submit-faculty-member-export"
                onClick={event => this.handleSubmitClick(event)}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    this.handleSubmitClick(event);
                  }
                }}
                disabled={!this.state.isFormInputValid}
              >
                Export <FontAwesomeIcon icon={faFileDownload} />
              </button>
            </div>
          </div>
        </form>
      </section>
    );
  }

  handleSubmitClick(event) {
    event.preventDefault();
    this.exportWorkAssignments();
  }

  exportWorkAssignments() {
    this.validateFormInput();
    const { academicYear } = this.state;
    const parseAcademicYear = parseInt(academicYear.split('\u2013')[0]);
    this.props.exportWorkAssignments(parseAcademicYear, academicYear);
  }

  handleValueChange(formValue) {
    this.setState({
      [formValue.valueId]: formValue.value,
      isFormInputValid: Object.keys(this.formValues).reduce((result, next) => {
        let theResult;
        if (next === formValue.valueId) {
          theResult =
            result &&
            this.formValues[next].validatorFunc(formValue.value).isValid;
        } else {
          theResult =
            result &&
            this.formValues[next].validatorFunc(this.state[next]).isValid;
        }
        return theResult;
      }, true)
    });
  }

  validateFormInput() {
    this.setState({
      isFormInputValid: Object.keys(this.formValues).reduce((result, next) => {
        let theResult =
          result &&
          this.formValues[next].validatorFunc(this.state[next]).isValid;
        return theResult;
      }, true)
    });
  }
}

const exportingSelector = createLoadingSelector([EXPORT_WORK_ASSIGNMENTS]);
const exportingErrorSelector = createErrorMessageSelector([
  EXPORT_WORK_ASSIGNMENTS
]);
const mapStateToProps = state => {
  const { auth } = state;
  return {
    scopes: auth.scopes,
    exporting: exportingSelector(state),
    exportingError: exportingErrorSelector(state)
  };
};

export default connect(
  mapStateToProps,
  {
    exportWorkAssignments,
    showCustomJSXModal,
    showCustomLoadingModal,
    hideModal
  }
)(ExportWorkAssignments);
