import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import './AddFacultyMember.scss';
import Select from '../common/Select';
import SelectRank from '../common/SelectRank';
import SelectAppointment from '../common/SelectAppointment';
import SelectDepartment from '../common/SelectDepartment';
import Input from '../common/Input';
import { addFacultyMember } from '../../service/actions/faculty';
import { getMonthList, getYearList, getMonthNumberString } from './dateHelpers';
import {
  showCustomJSXModal,
  showValidatingModal,
  hideModal
} from '../../service/actions/modal';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import { ADD_FACULTY_MEMBER } from '../../service/types';

class AddFacultyMember extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormInputValid: false
    };
    this.baseState = { ...this.state };

    this.handleValueChange = this.handleValueChange.bind(this);

    this.formValues = {
      appointmentId: {
        name: 'appointment',
        valueId: 'appointmentId',
        defaultValue: '',
        labelTitle: 'Appointment',
        validatorFunc: value => ({
          isValid: value && value !== '' && value !== 'Select',
          errorMessage: 'Invalid'
        })
      },
      rankId: {
        name: 'rank',
        valueId: 'rankId',
        defaultValue: '',
        labelTitle: 'Rank',
        validatorFunc: value => ({
          isValid: value && value !== '' && value !== 'Select',
          errorMessage: 'Invalid'
        })
      },
      departmentId: {
        name: 'department',
        valueId: 'departmentId',
        defaultValue: '',
        labelTitle: 'Department',
        validatorFunc: () => ({
          isValid: true,
          errorMessage: 'Invalid'
        })
      },
      startMonth: {
        name: 'start-month',
        valueId: 'startMonth',
        defaultValue: '',
        labelTitle: 'Month',
        validatorFunc: value => ({
          isValid: value && value !== '' && value !== 'Select',
          errorMessage: 'Invalid'
        })
      },
      startYear: {
        name: 'start-year',
        valueId: 'startYear',
        defaultValue: '',
        labelTitle: 'Year',
        validatorFunc: value => ({
          isValid: value && value !== '' && value !== 'Select',
          errorMessage: 'Invalid'
        })
      },
      firstName: {
        name: 'first-name',
        valueId: 'firstName',
        labelTitle: 'First Name',
        defaultValue: '',
        validatorFunc: value => {
          return {
            isValid:
              /^.{1,30}$/.test(value) && value && value.trim().length > 0,
            errorMessage: 'Invalid first name'
          };
        }
      },
      lastName: {
        name: 'last-name',
        valueId: 'lastName',
        labelTitle: 'Last Name',
        defaultValue: '',
        validatorFunc: value => {
          return {
            isValid:
              /^.{1,30}$/.test(value) && value && value.trim().length > 0,
            errorMessage: 'Invalid last name'
          };
        }
      },
      emailAddress: {
        name: 'email-address',
        valueId: 'emailAddress',
        labelTitle: 'Email',
        defaultValue: '',
        validatorFunc: value => {
          const isDuplicate =
            value &&
            value !== '' &&
            props.facultyList.reduce((aggregator, item) => {
              return (
                aggregator ||
                ((item.emailAddress || '').toUpperCase() ===
                  value.toUpperCase() &&
                  'Duplicate email address')
              );
            }, false);

          if (isDuplicate) {
            return {
              isValid: false,
              errorMessage:
                'Duplicate Email Address Found. Please validate your entry.'
            };
          }

          return {
            isValid:
              (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
                value
              ) &&
                value.length <= 100) ||
              !value ||
              value.length === 0,
            errorMessage: 'Invalid email address'
          };
        }
      },
      employeeId: {
        name: 'employee-id',
        valueId: 'employeeId',
        labelTitle: 'Employee ID',
        defaultValue: '',
        validatorFunc: value => {
          const isDuplicate =
            value &&
            value !== '' &&
            props.facultyList.reduce((aggregator, item) => {
              return (
                aggregator ||
                ((item.employeeId || '').toUpperCase() ===
                  value.toUpperCase() &&
                  'Duplicate employee id')
              );
            }, false);

          if (isDuplicate) {
            return {
              isValid: false,
              errorMessage:
                'Duplicate Employee ID Found. Please validate your entry.'
            };
          }

          return {
            isValid:
              /^(\w|\.|-){1,20}$/.test(value) || !value || value.length === 0,
            errorMessage: 'Invalid Employee ID'
          };
        }
      }
    };

    this.monthList = getMonthList();
    this.yearList = getYearList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.addingFacultyMember !== this.props.addingFacultyMember) {
      if (!this.props.addingFacultyMember) {
        if (this.props.errorMessage) {
          this.props.hideModal();
        } else {
          this.props.showCustomJSXModal(
            <div id="success-message" style={{ textAlign: 'center' }}>
              <FontAwesomeIcon icon={faCheckCircle} />
              New Faculty Member Added
              <br />
              {`${this.props.newlyAddedFacultyMember.firstName} ${this.props.newlyAddedFacultyMember.lastName}`}
            </div>
          );
          setTimeout(() => {
            this.props.hideModal();
          }, 1800);
        }
      } else {
        this.props.showValidatingModal();
      }
    }
  }

  render() {
    const { errorMessage, departmentsList } = this.props;

    return (
      <section className="add-faculty-sect">
        <h1 className="visually-hidden">Add a Faculty Member</h1>
        <form id="faculty-member-form-wrapper">
          <div id="faculty-member-entry-form">
            <div className="first-name">
              <Input
                formValue={this.formValues.firstName}
                controlFunc={this.handleValueChange}
              />
            </div>
            <div className="last-name">
              <Input
                formValue={this.formValues.lastName}
                controlFunc={this.handleValueChange}
              />
            </div>
            <div className="email-address">
              <Input
                formValue={this.formValues.emailAddress}
                controlFunc={this.handleValueChange}
                immediatelyValidate={true}
                optional={true}
              />
            </div>
            <div className="employee-id">
              <Input
                formValue={this.formValues.employeeId}
                controlFunc={this.handleValueChange}
                immediatelyValidate={true}
                optional={true}
              />
            </div>
            <div className="appointment">
              <SelectAppointment
                formValue={this.formValues.appointmentId}
                controlFunc={this.handleValueChange}
              />
            </div>
            <div className="rank">
              <SelectRank
                formValue={this.formValues.rankId}
                controlFunc={this.handleValueChange}
              />
            </div>
            {departmentsList && departmentsList.length ? (
              <div className="department">
                <SelectDepartment
                  formValue={this.formValues.departmentId}
                  controlFunc={this.handleValueChange}
                  optional={true}
                />
              </div>
            ) : null}
            <div className="dates">
              <div className="start-date">
                <h3>Start Date</h3>
                <div className="selects">
                  <div>
                    <Select
                      formValue={this.formValues.startMonth}
                      controlFunc={this.handleValueChange}
                      optionList={this.monthList}
                    />
                  </div>
                  <div>
                    <Select
                      formValue={this.formValues.startYear}
                      controlFunc={this.handleValueChange}
                      optionList={this.yearList}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="form-end-wrapper">
            {errorMessage && (
              <div className="form-message unexpected-error-message error-message">
                <FontAwesomeIcon icon={faExclamationCircle} />
                An unknown error occurred. Please try again.
              </div>
            )}

            <div className="buttons">
              <button
                id="cancel-faculty-member-entry"
                onClick={event =>
                  this.props.handleCancelClick('AddFacultyForm', event)
                }
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    this.props.handleCancelClick('AddFacultyForm', event);
                  }
                }}
              >
                Cancel
              </button>
              <button
                id="submit-faculty-member-entry"
                onClick={event => this.handleSubmitClick(event)}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    this.handleSubmitClick(event);
                  }
                }}
                disabled={!this.state.isFormInputValid}
              >
                Add Faculty Member <FontAwesomeIcon icon={faPlusCircle} />
              </button>
            </div>
          </div>
        </form>
      </section>
    );
  }

  handleSubmitClick(event) {
    event.preventDefault();
    this.addFacultyMember();
  }

  addFacultyMember() {
    this.validateFormInput();
    const {
      firstName,
      lastName,
      emailAddress,
      employeeId,
      appointmentId,
      rankId,
      departmentId,
      startMonth,
      startYear
    } = this.state;

    const appointmentDate = `${startYear}-${getMonthNumberString(startMonth)}`;
    const newFacultyMember = {
      firstName,
      lastName,
      emailAddress: emailAddress || undefined,
      employeeId: employeeId || undefined,
      appointmentId,
      rankId,
      departmentId,
      appointmentDate
    };

    this.props.addFacultyMember(newFacultyMember);
  }

  handleValueChange(formValue) {
    this.setState({
      [formValue.valueId]: formValue.value,
      isFormInputValid: Object.keys(this.formValues).reduce((result, next) => {
        let theResult;
        if (next === formValue.valueId) {
          theResult =
            result &&
            this.formValues[next].validatorFunc(formValue.value).isValid;
        } else {
          theResult =
            result &&
            this.formValues[next].validatorFunc(this.state[next]).isValid;
        }
        return theResult;
      }, true)
    });
  }

  validateFormInput() {
    this.setState({
      isFormInputValid: Object.keys(this.formValues).reduce((result, next) => {
        let theResult =
          result &&
          this.formValues[next].validatorFunc(this.state[next]).isValid;
        return theResult;
      }, true)
    });
  }
}

const addingSelector = createLoadingSelector([ADD_FACULTY_MEMBER]);
const errorSelector = createErrorMessageSelector([ADD_FACULTY_MEMBER]);
const mapStateToProps = state => {
  const { config, faculty } = state;
  return {
    addingFacultyMember: addingSelector(state),
    errorMessage: errorSelector(state),
    facultyList: faculty.list,
    newlyAddedFacultyMember: faculty.newest,
    departmentsList: config && config.config && config.config.departments
  };
};

export default connect(mapStateToProps, {
  addFacultyMember,
  showCustomJSXModal,
  showValidatingModal,
  hideModal
})(AddFacultyMember);
