import {
  GET_LOCATIONS,
  ADD_LOCATION,
  UPDATE_LOCATION,
  ARCHIVE_LOCATION
} from '../types';
import {
  getLocationsEndpoint,
  addLocationEndpoint,
  updateLocationEndpoint
} from '../../utils/endpoints';
import requestWrapper from '../requestWrapper';

export const getLocations = () => async dispatch => {
  requestWrapper(dispatch, {
    actionType: GET_LOCATIONS,
    requestObject: {
      url: getLocationsEndpoint(),
      method: 'GET',
      responseType: 'json'
    },
    responseDataKey: 'locations'
  });
};

export const addLocation = (newLocation, id) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: ADD_LOCATION,
    requestObject: {
      url: addLocationEndpoint(),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: newLocation,
      responseType: 'json'
    },
    responseDataKey: 'location'
  });
};

export const updateLocation = (changes, locationId) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: UPDATE_LOCATION,
    requestObject: {
      url: updateLocationEndpoint(locationId),
      method: 'PUT',
      header: {
        'Content-Type': 'application/json'
      },
      data: changes,
      responseDataKey: 'location'
    },
    responsePayload: {
      ...changes,
      id: locationId
    }
  });
};

export const archiveLocation = (changes, locationId) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: ARCHIVE_LOCATION,
    requestObject: {
      url: updateLocationEndpoint(locationId),
      method: 'PUT',
      header: {
        'Content-Type': 'application/json'
      },
      data: changes
    },
    responsePayload: {
      id: locationId,
      name: changes.name
    }
  });
};
