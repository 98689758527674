import { EXPAND_ALL, SET_EXPANDED } from '../types';

const initialState = {
  expandedItems: {},
  selectorTouchCount: 0,
  expandAll: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EXPAND_ALL:
      return {
        ...state,
        selectorTouchCount: state.selectorTouchCount + 1,
        expandAll: action.payload
      };

    case SET_EXPANDED:
      return {
        ...state,
        expandedItems: {
          ...state.expandedItems,
          [action.payload.id]: action.payload.isExpanded
        }
      };

    default:
      return state;
  }
};
