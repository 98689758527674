import React from 'react';
import { connect } from 'react-redux';
import Textarea from '../common/Textarea';
import { setFacultyMemberNotes } from '../../service/actions/faculty';
import FormReveal from '../common/FormReveal';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';

class FacultyMemberNotes extends React.Component {
  constructor(props) {
    super(props);
    this.facultyNotesFormRevealRef = React.createRef();
    this.state = {
      showFacultyNotesForm: false,
      resetting: false
    };
    this.baseState = { ...this.state };
  }

  componentDidUpdate(prevState) {
    if (prevState.resetting !== this.state.resetting && this.state.resetting) {
      this.setState({
        resetting: false
      });
    }
  }

  render() {
    const { facultyMemberNotes, scopes } = this.props;
    const { notesIsOpen } = this.state;
    const facultyNotesForm = this.renderFacultyNotesForm();
    if (!facultyNotesForm) return null;
    return (
      <div className="faculty-notes-wrapper no-print">
        <div className="notes-header">
          <h1>Notes</h1>
          {!notesIsOpen &&
            facultyMemberNotes &&
            scopes.includes('faculty-member.notes.write') && (
              <button
                className="circle small edit"
                onClick={event => this.showForm(true, event)}
                id="editNotesFormBtn"
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>
            )}
        </div>
        {!notesIsOpen &&
          !facultyMemberNotes &&
          scopes.includes('faculty-member.notes.write') && (
            <button
              className="no-print"
              onClick={event => this.showForm(true, event)}
              id="addNotesFormBtn"
            >
              Add Notes
              <FontAwesomeIcon icon={faPlusCircle} />
            </button>
          )}
        {!notesIsOpen && facultyMemberNotes && (
          <div className="notes-viewer">{facultyMemberNotes}</div>
        )}
        <FormReveal
          hideButton
          parentShowForm={this.state.showFacultyNotesForm}
          parentShowFormSendFocus={this.state.showFacultyNotesFormSendFocus}
          isOpening={state =>
            this.setState({
              notesIsOpen: state
            })
          }
          form={facultyNotesForm}
          submitFunc={() => this.hideForm()}
          cancelFunc={() => this.resetForm()}
          ref={this.facultyNotesFormRevealRef}
        />
      </div>
    );
  }

  renderFacultyNotesForm() {
    const { facultyMemberNotes } = this.props;
    const { resetting } = this.state;
    if (resetting) {
      return null;
    }

    const formValues = {
      facultyNotes: {
        name: 'faculty-notes',
        valueId: 'facultyNotes',
        labelTitle: 'Faculty Notes',
        defaultValue: facultyMemberNotes,
        validatorFunc: value => ({
          isValid: true,
          errorMessage: 'Invalid notes'
        })
      }
    };

    return (
      <form id="faculty-notes-form" className="notes-form">
        <div className="notes-container">
          <Textarea
            hideLabel
            hideError
            hideErrorMessage
            formValue={formValues.facultyNotes}
            controlFunc={this.handleValueChange}
          />
        </div>
        <div id="form-end-wrapper">
          <div className="buttons">
            <button
              type="button"
              id="cancel-notes"
              onClick={event => this.handleCancelClick(event)}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  this.handleCancelClick(event);
                }
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              id="submit-notes"
              onClick={event => this.handleSubmitClick(event)}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  this.handleSubmitClick(event);
                }
              }}
            >
              Save
              <FontAwesomeIcon icon={faCheckCircle} />
            </button>
          </div>
        </div>
      </form>
    );
  }

  handleValueChange = formValue => {
    this.setState({
      [formValue.valueId]: formValue.value
    });
  };

  hideForm() {
    this.setState({
      showFacultyNotesForm: false,
      showFacultyNotesFormSendFocus: false
    });
  }

  handleCancelClick(event) {
    this.facultyNotesFormRevealRef.current.handleCancelClick(event);
  }

  handleSubmitClick(event) {
    event.preventDefault();
    const { facultyNotes } = this.state;
    this.props.setFacultyMemberNotes(this.props.match.params.facultyMemberId, {
      notes: facultyNotes ? facultyNotes : ''
    });
    this.facultyNotesFormRevealRef.current.handleSubmitClick(event);
    this.facultyNotesFormRevealRef.current.handleSubmitClose(event);
  }

  showForm(value, event) {
    this.facultyNotesFormRevealRef.current.handleOpenClick(event);
    let showFacultyNotesFormSendFocus = false;
    if (event) {
      showFacultyNotesFormSendFocus = true;
    }
    this.setState({
      showFacultyNotesForm: value,
      showFacultyNotesFormSendFocus
    });
  }

  resetForm() {
    this.setState({
      ...this.baseState,
      showFacultyNotesForm: false,
      showFacultyNotesFormSendFocus: false,
      resetting: true
    });
  }
}

const mapStateToProps = ({ faculty, auth }) => {
  return {
    scopes: auth.scopes,
    facultyMember: faculty.viewIndividual,
    facultyMemberNotes: faculty.facultyMemberNotes
  };
};

export default connect(mapStateToProps, {
  setFacultyMemberNotes
})(withRouter(FacultyMemberNotes));
