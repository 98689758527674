import { EXPAND_ALL, SET_EXPANDED } from '../types';

export const expandAll = value => dispatch => {
  dispatch({
    type: EXPAND_ALL,
    payload: value
  });
};

export const setExpanded = value => dispatch => {
  dispatch({
    type: SET_EXPANDED,
    payload: value
  });
};
