import React from 'react';
import { withRouter } from 'react-router-dom';

const A11YLink = props => {
  const authorized = !props.unauthorized;
  return (
    <a
      href={authorized ? props.path : '/'}
      title={props.title ? props.title : undefined}
      tabIndex={authorized ? '0' : '-1'}
      aria-current={props.location.pathname.indexOf(props.path) === 0}
      className={
        (props.location.pathname.indexOf(props.path) === 0 ? 'active ' : '') +
        (authorized ? '' : 'unauthorized ')
      }
      onClick={event => {
        event.preventDefault();
        if (!authorized) {
          return;
        }

        if (event.metaKey || event.ctrlKey) {
          window.open(props.history.createHref({ pathname: props.path }));
        } else {
          event.preventDefault();
          props.history.push({
            pathname: props.path
          });
        }
      }}
      onKeyDown={event => {
        if (event.key === 'Enter') {
          event.preventDefault();
          if (!authorized) {
            return;
          }

          props.history.push({
            pathname: props.path,
            state: {
              keyed: true
            }
          });
        }
      }}
    >
      {props.children}
    </a>
  );
};

export default withRouter(A11YLink);
