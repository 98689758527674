import React from 'react';
import './ScheduleView.scss';
import {
  getFacultyMembers,
  getProposedTeaching
} from '../../service/actions/faculty';
import { getLocations } from '../../service/actions/locations';
import { getCourses } from '../../service/actions/courses';
import { getConfig } from '../../service/actions/config';
import ScheduleViewStudentGroups from './ScheduleViewStudentGroups';
import ScheduleViewCourses from './ScheduleViewCourses';
import ViewSelector from '../common/ViewSelector';
import FormReveal from '../common/FormReveal';
import ChangeState from './ChangeState';
import {
  getSchedule,
  getScheduleProjectedEnrollment,
  validateSchedule,
  getScheduleList,
  getScheduleNotes,
  postScheduleStateChange,
  addCourseToSchedule,
  clearScheduleUpdatingError,
  exportSchedule
} from '../../service/actions/schedules';
import { getStudentGroupList } from '../../service/actions/studentGroups';
import {
  showLoadingModal,
  showCustomLoadingModal,
  hideModal
} from '../../service/actions/modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons/faExchangeAlt';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons/faFileDownload';
import AddCourse from './AddCourse';
import updatePageTitle from '../common/helpers/updatePageTitle';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import {
  GET_COURSES,
  GET_SCHEDULES,
  GET_SCHEDULE,
  GET_STUDENT_GROUPS,
  GET_CONFIG,
  EXPORT_SCHEDULE,
  POST_SCHEDULE_STATE_CHANGE,
  GET_LOCATIONS,
  PUT_SCHEDULE_SECTION,
  GET_SCHEDULE_ENROLLMENT,
  GET_SCHEDULE_NOTES,
  ADD_SCHEDULE_SECTION,
  DELETE_SCHEDULE_SECTION,
  ADD_COURSE_TO_SCHEDULE,
  DELETE_SCHEDULE_COURSE,
  SET_SCHEDULE_NOTES
} from '../../service/types';
import { mainViewComponent } from '../common/HOCs';

class ScheduleView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showChangeStateForm: false
    };
    this.stateChangeFormRevealRef = React.createRef();
    this.submitStateChange = this.submitStateChange.bind(this);
    this.submitAddCourse = this.submitAddCourse.bind(this);
  }

  componentDidMount() {
    const { courseComponents } = this.props;

    updatePageTitle(
      this.props.match.params.term +
        ' ' +
        this.props.match.params.year +
        ' Schedule'
    );

    this.props.getScheduleList();
    this.props.getCourses();
    this.props.getSchedule(
      this.props.match.params.year,
      this.props.match.params.term
    );
    this.props.getScheduleProjectedEnrollment(
      this.props.match.params.year,
      this.props.match.params.term
    );
    if (this.props.scopes.includes('schedule.notes.read')) {
      this.props.getScheduleNotes(
        this.props.match.params.year,
        this.props.match.params.term
      );
    }
    this.props.getProposedTeaching(
      this.props.match.params.year,
      this.props.match.params.term
    );
    this.props.getStudentGroupList();

    if (!this.props.courseComponents) {
      this.props.getConfig();
    }
    if (!this.props.locations) {
      this.props.getLocations();
    }
    if (!this.props.facultyMembers) {
      this.props.getFacultyMembers();
    }

    if (
      this.props.schedule &&
      this.props.courseList &&
      this.props.projectedEnrollmentList
    ) {
      let fullSchedule = this.formatFullSchedule();
      if (this.props.schedule.state !== 'completed') {
        this.props.validateSchedule(fullSchedule, courseComponents);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.exporting !== this.props.exporting) {
      if (this.props.exporting) {
        this.props.showCustomLoadingModal('Downloading...');
      } else {
        this.props.hideModal();
      }
    }

    if (
      prevProps.schedule !== this.props.schedule ||
      prevProps.courseList !== this.props.courseList ||
      prevProps.projectedEnrollmentList !==
        this.props.projectedEnrollmentList ||
      prevProps.courseComponents !== this.props.courseComponents
    ) {
      if (
        this.props.schedule &&
        this.props.courseList &&
        this.props.courseComponents &&
        this.props.projectedEnrollmentList
      ) {
        let fullSchedule = this.formatFullSchedule();
        this.props.validateSchedule(fullSchedule, this.props.courseComponents);
      }
    }

    if (prevProps.schedule !== this.props.schedule) {
      if (this.props.schedule && prevProps.schedule) {
        if (this.props.scopes.includes('schedule.notes.read')) {
          this.props.getScheduleNotes(
            this.props.match.params.year,
            this.props.match.params.term
          );
        }
      }
    }

    if (prevProps.scheduleUpdating !== this.props.scheduleUpdating) {
      if (!this.props.scheduleUpdating && !this.props.errorMessage) {
        this.props.getSchedule(
          this.props.match.params.year,
          this.props.match.params.term
        );
        if (this.props.scopes.includes('schedule.notes.read')) {
          this.props.getScheduleNotes(
            this.props.match.params.year,
            this.props.match.params.term
          );
        }
        this.setState({ showChangeStateForm: false });
      }
    }
  }

  render() {
    const {
      errorMessage,
      schedule,
      scopes,
      validationList,
      exportingError,
      programs,
      programGroups
    } = this.props;
    const { showChangeStateForm } = this.state;
    const stateChangeForm = this.renderChangeState();

    if (!scopes.includes('schedule.read')) {
      return <p>Unauthorized</p>;
    }

    if (errorMessage) {
      return (
        <div className="form-message unexpected-error-message error-message">
          <FontAwesomeIcon icon={faExclamationCircle} />
          {errorMessage}
        </div>
      );
    }

    if (!schedule || !programs || !programGroups) {
      return null;
    }
    const viewTuples = this.createViewTuples();
    const newState = this.getStateToChangeTo(schedule.state, 'next');
    const isReady =
      validationList &&
      schedule.state === 'planning' &&
      validationList.flat().some(v => v.type === 'error');
    return (
      <div className="main">
        <section className="schedule-view">
          {schedule.state === 'completed' && (
            <div className={`state`}>{`${schedule.state} Term`}</div>
          )}
          {schedule.state !== 'completed' && (
            <div className={`label state status-${schedule.state}-bg`}>
              {schedule.state}
            </div>
          )}
          <div className="top-container">
            <h1>
              {schedule.term} {schedule.year} Schedule of Classes
            </h1>
            <div className="right">
              {!showChangeStateForm &&
                scopes.includes('schedule.state.write') &&
                (schedule.state === 'projected' ||
                  schedule.state === 'planning') && (
                  <button
                    disabled={isReady}
                    onClick={() => this.showForm(true)}
                    onKeyDown={event => {
                      if (event.key === 'Enter') {
                        this.showForm(true, true);
                      }
                    }}
                    id="statusChange"
                  >
                    Move to{' '}
                    {newState.charAt(0).toUpperCase() + newState.slice(1)}
                    <FontAwesomeIcon icon={faExchangeAlt} />
                  </button>
                )}
              {!showChangeStateForm &&
                scopes.includes('schedule.state.write') &&
                schedule.state === 'ready' && (
                  <button
                    onClick={event => this.showForm(true, event)}
                    id="statusChange"
                  >
                    Change Status
                    <FontAwesomeIcon icon={faExchangeAlt} />
                  </button>
                )}
              {schedule.state !== 'projected' && (
                <button
                  onClick={() => this.exportSchedule()}
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      this.exportSchedule();
                    }
                  }}
                  id="export-schedule"
                >
                  Export
                  <FontAwesomeIcon icon={faFileDownload} />
                </button>
              )}
            </div>
          </div>

          {exportingError && (
            <div className="form-message unexpected-error-message error-message">
              <FontAwesomeIcon icon={faExclamationCircle} />
              {exportingError}
            </div>
          )}

          {scopes.includes('schedule.state.write') && (
            <div>
              <FormReveal
                hideButton
                parentShowForm={this.state.showChangeStateForm}
                parentShowFormSendFocus={
                  this.state.showChangeStateFormSendFocus
                }
                form={stateChangeForm}
                submitFunc={() => this.submitStateChange()}
                cancelFunc={() => this.hideForm()}
                ref={this.stateChangeFormRevealRef}
              />
            </div>
          )}
          {schedule.state === 'planning' &&
            scopes.includes('schedule.course.write') && (
              <div>{this.renderAddCourseForm()}</div>
            )}

          <ViewSelector
            viewTuples={viewTuples}
            defaultSelected="all"
            callbackFunction={group =>
              this.setState({
                selectedProgramGroup: group.id
              })
            }
          />

          <div aria-live="polite">{this.renderContents()}</div>
        </section>
      </div>
    );
  }

  renderContents() {
    const { location } = this.props;

    switch (location.hash) {
      case '#student-groups':
        return <ScheduleViewStudentGroups />;

      default:
        return (
          <ScheduleViewCourses
            selectedGroup={this.state.selectedProgramGroup}
          />
        );
    }
  }

  renderChangeState() {
    const { schedule, scheduleList } = this.props;
    let hasPriorYear;
    if (scheduleList && schedule) {
      hasPriorYear =
        scheduleList.filter(
          s =>
            s.year === parseInt(schedule.year) - 1 &&
            s.term === schedule.term &&
            (s.state === 'ready' || s.state === 'completed')
        ).length > 0;
    }

    return (
      <ChangeState
        handleSubmitClick={this.submitStateChange}
        hasPriorYear={hasPriorYear}
        schedule={schedule}
        cancelFunc={event => this.handleCancelClick(event)}
      />
    );
  }

  renderAddCourseForm() {
    const { courseList, schedule } = this.props;

    let scheduleCourses;
    if (courseList && schedule) {
      scheduleCourses = courseList.filter(c => {
        return !schedule.courses.some(d => d.id === c.id);
      });
    }

    return (
      <AddCourse
        handleSubmitClick={this.submitAddCourse}
        courseList={scheduleCourses}
      />
    );
  }

  // Build a schedule that includes course numbers and names
  formatFullSchedule() {
    let { schedule, courseList } = this.props;
    let fullSchedule = { ...schedule };
    fullSchedule.courses = schedule.courses.map(sc => {
      return {
        ...sc,
        courseInfo: courseList.find(course => course.id === sc.id)
      };
    });
    fullSchedule.studentGroups = schedule.studentGroups.map(sg => {
      return {
        ...sg,
        courses: sg.courseIds.map(c =>
          courseList.find(course => c === course.id)
        )
      };
    });
    return fullSchedule;
  }

  createViewTuples() {
    const { programGroups } = this.props;
    let programGroupList = programGroups.map(group => {
      return { label: group, id: group };
    });

    // Create the viewTuples selectors including an ALL and Student Group option
    let viewTuples = [
      { label: 'All', id: 'all' },
      ...programGroupList,
      { label: 'Other', id: 'other', lastBeforePipe: true },
      { label: 'Student Groups', id: '#student-groups', useHash: true }
    ];

    return viewTuples;
  }

  exportSchedule() {
    const { token, schedule } = this.props;
    this.props.exportSchedule(token, schedule.year, schedule.term);
  }

  showForm(value, sendFocus) {
    let showChangeStateFormSendFocus = false;
    if (sendFocus) {
      showChangeStateFormSendFocus = true;
    }
    this.setState({
      showChangeStateForm: value,
      showChangeStateFormSendFocus
    });
  }

  handleCancelClick(event) {
    this.props.clearScheduleUpdatingError();
    this.stateChangeFormRevealRef.current.handleCancelClick(event);
  }

  hideForm() {
    this.setState({
      showChangeStateForm: false,
      showChangeStateFormSendFocus: false
    });
  }

  submitAddCourse(courseId) {
    const { schedule, addCourseToSchedule } = this.props;
    addCourseToSchedule(schedule.year, schedule.term, courseId);
  }

  submitStateChange(direction, mergePriorYear) {
    const { schedule, postScheduleStateChange } = this.props;
    let newState = this.getStateToChangeTo(schedule.state, direction);

    postScheduleStateChange(
      schedule.year,
      schedule.term,
      newState,
      mergePriorYear
    );
  }

  getStateToChangeTo(state, direction) {
    const states = ['projected', 'planning', 'ready', 'completed'];
    const offset = direction === 'next' ? 1 : -1;
    return states[(states.indexOf(state) + offset) % states.length];
  }
}

const loadingSelector = createLoadingSelector([
  GET_SCHEDULES,
  GET_SCHEDULE,
  GET_COURSES,
  GET_STUDENT_GROUPS,
  GET_LOCATIONS,
  GET_CONFIG,
  POST_SCHEDULE_STATE_CHANGE,
  ADD_SCHEDULE_SECTION,
  GET_SCHEDULE_NOTES,
  SET_SCHEDULE_NOTES,
  PUT_SCHEDULE_SECTION,
  GET_SCHEDULE_ENROLLMENT,
  DELETE_SCHEDULE_SECTION,
  ADD_COURSE_TO_SCHEDULE,
  DELETE_SCHEDULE_COURSE
]);
const errorSelector = createErrorMessageSelector([
  GET_SCHEDULE,
  POST_SCHEDULE_STATE_CHANGE
]);
const exportingSelector = createLoadingSelector([EXPORT_SCHEDULE]);
const exportingErrorSelector = createErrorMessageSelector([EXPORT_SCHEDULE]);
const updatingSelector = createLoadingSelector([POST_SCHEDULE_STATE_CHANGE]);
const mapStateToProps = state => {
  const {
    auth,
    config,
    courses,
    programs,
    faculty,
    locations,
    schedules,
    studentGroups
  } = state;
  return {
    scopes: auth.scopes,
    token: auth.token,
    programGroups: config && config.config && config.config.programGroups,
    courseComponents:
      config && config.config && config.config.courseComponentTypes,
    loading: loadingSelector(state),
    exporting: exportingSelector(state),
    scheduleUpdating: updatingSelector(state),
    exportingError: exportingErrorSelector(state),
    errorMessage: errorSelector(state),
    schedule: schedules.viewIndividual,
    scheduleList: schedules.list,
    projectedEnrollmentList: schedules.projectedEnrollmentList,
    validationList: schedules.validationResults,
    programs: programs.list,
    selectedProgramGroup: programs.selectedProgramGroup,
    courseList: courses.list,
    termList: config.termsByCalendarYear,
    studentGroupList: studentGroups.list,
    locations: locations.list,
    facultyMembers: faculty.list,
    proposedTeaching: faculty.proposedTeaching
  };
};

const mainViewConfig = {
  pageTitle: 'Schedules'
};

export default connect(mapStateToProps, {
  getConfig,
  getCourses,
  getSchedule,
  getScheduleProjectedEnrollment,
  validateSchedule,
  getScheduleList,
  postScheduleStateChange,
  getFacultyMembers,
  getProposedTeaching,
  getLocations,
  getStudentGroupList,
  addCourseToSchedule,
  getScheduleNotes,
  exportSchedule,
  showLoadingModal,
  showCustomLoadingModal,
  clearScheduleUpdatingError,
  hideModal
})(withRouter(mainViewComponent({ component: ScheduleView, mainViewConfig })));
