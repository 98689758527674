// Teaching assignments augmentation function imported into the faculty actions

function parseToPrecision(allocation, actual, { removeTrailingZeros } = {}) {
  let allocationStr = allocation.toString().split('.');
  let allocationPrecision =
    allocationStr.length > 1 ? allocationStr[1].length : 0;
  const rounded = Number.parseFloat(actual).toFixed(allocationPrecision);
  return removeTrailingZeros ? (+rounded).toString() : rounded;
}

// Augmenting function
export const augmentTeachingAssignments = (
  facultyMemberId,
  schedule,
  courseList,
  workPlan,
  defaultTeachingEffort,
  workPlanProjectedEnrollment,
  config
) => {
  return new Promise(resolve => {
    // Assigned section actual effort overrrides
    let assignedSectionDefaults = workPlan.assignedCourseSections;
    const effortMode = config.config.facultyWorkPlanEffortUnit;

    // Get the max precision of all effort total floating-point numbers (ex: Precision of 1 for 5.5)
    const effortMaxPrecision = (() => {
      if (effortMode === 'TU') {
        return '3.00';
      }
      let precision = workPlan.allocation.reduce(function(
        precisionAggregator,
        next
      ) {
        let nextStr = next.expectedEffort.toString().split('.');
        let nextPrecision = nextStr.length > 1 ? nextStr[1].length : 0;
        return Math.max(precisionAggregator, nextPrecision);
      },
      0);
      return Number.parseFloat(precision).toFixed(precision);
    })();

    let scheduleProjectedEnrollment;
    if (schedule && workPlanProjectedEnrollment) {
      scheduleProjectedEnrollment = workPlanProjectedEnrollment.find(
        s => s.term === schedule.term && s.year === schedule.year
      );
    }

    // All courses from the schedule that have faculty teaching assignments
    const assignedScheduleCourses = schedule.courses
      .map(scheduleCourse => {
        let components = scheduleCourse.components.map(cm => {
          let sections = [];
          if (cm.sections) {
            sections = cm.sections.filter(
              section =>
                section &&
                section.facultyMember &&
                parseInt(section.facultyMember.id) === parseInt(facultyMemberId)
            );
          }
          return { ...cm, sections };
        });
        let course = { ...scheduleCourse, components };
        if (components.map(cm => cm.sections).flat(2).length > 0) {
          return course;
        } else {
          return undefined;
        }
      })
      .filter(c => c !== undefined);

    // Proposed teaching courses,
    // filtered to exclude any that have been assigned in schedule,
    // augmented to include course info
    let plannedTeaching = [];
    if (workPlan && workPlan.state !== 'past') {
      plannedTeaching = workPlan.proposedTeaching
        .filter(proposedCourse => {
          return (
            proposedCourse.term === schedule.term &&
            !assignedScheduleCourses.some(
              course => course.id === proposedCourse.courseId
            )
          );
        })
        .map(proposedCourse => {
          const courseInfo = courseList.find(course => {
            return course.id === proposedCourse.courseId;
          });

          let enrollment;
          if (scheduleProjectedEnrollment) {
            enrollment = scheduleProjectedEnrollment.courses.find(course => {
              return course.id === proposedCourse.courseId;
            });
          }

          let components = [];
          if (proposedCourse.sections) {
            components = proposedCourse.sections.map(section => {
              // Get default teaching effort, if it exists
              const componentDefaultEffort = defaultTeachingEffort.find(d => {
                return (
                  d.courseId === proposedCourse.courseId &&
                  d.componentType === section.componentType
                );
              });
              let defaultEffort = componentDefaultEffort
                ? componentDefaultEffort.defaultEffort
                : '\u2014';

              let sections = [];
              for (let i = 0; i < section.sectionCount; i++) {
                sections.push({ isAssigned: false, effort: defaultEffort });
              }
              let newComponent = {
                ...section,
                type: section.componentType,
                sections: sections
              };
              return newComponent;
            });
          }

          return {
            ...proposedCourse,
            id: proposedCourse.courseId,
            isProposed: true,
            components: components,
            projectedEnrollment: enrollment
              ? enrollment.projectedEnrollment
              : 0,
            courseInfo: courseInfo
          };
        });
    }

    const augmentedScheduleCourses = assignedScheduleCourses.map(
      scheduleCourse => {
        const courseInfo = courseList.find(course => {
          return course.id === scheduleCourse.id;
        });

        let enrollment;
        if (scheduleProjectedEnrollment) {
          enrollment = scheduleProjectedEnrollment.courses.find(course => {
            return course.id === scheduleCourse.id;
          });
        }

        let components = scheduleCourse.components.map(component => {
          // Get default teaching effort, if it exists
          var componentDefaultEffort = defaultTeachingEffort.find(d => {
            return (
              d.courseId === scheduleCourse.id &&
              d.componentType === component.type
            );
          });
          var defaultEffort =
            workPlan.state !== 'past' && componentDefaultEffort
              ? componentDefaultEffort.defaultEffort
              : '\u2014';
          // Find any planned sections that belong to the assigned course
          var plannedCourse = workPlan.proposedTeaching.filter(
            c => c.courseId === scheduleCourse.id
          );
          var plannedCourseComponent;
          if (plannedCourse.length > 0) {
            plannedCourseComponent = plannedCourse[0].sections.find(
              section => section.componentType === component.type
            );
          }

          // Get actual section count
          let actualSectionCount;
          if (plannedCourseComponent) {
            actualSectionCount =
              parseInt(plannedCourseComponent.sectionCount) -
              parseInt(component.sections.length);
          }

          var sections = [];
          if (component.sections.length > 0) {
            component.sections.forEach(section => {
              // Get the actual effort from proposed courses, if it exists
              const actualEffort = assignedSectionDefaults.find(
                s => parseInt(s.scheduleSectionId) === parseInt(section.id)
              );
              const sectionComponentType = config.config.courseComponentTypes.find(
                cct => cct.id === component.type
              );
              const sectionCapacityExceedsThreshold =
                section.capacity >
                sectionComponentType?.enrollmentEffortModifier?.capacity;

              const finalEffort = (() => {
                if (actualEffort?.actualEffort) {
                  return actualEffort.actualEffort;
                }
                const modifier =
                  (sectionCapacityExceedsThreshold &&
                    sectionComponentType.enrollmentEffortModifier.modifier) ||
                  1;
                return defaultEffort * modifier || 0;
              })();

              const roundedEffort = parseToPrecision(
                effortMaxPrecision,
                finalEffort,
                { removeTrailingZeros: effortMode === 'TU' }
              );

              // Add the sections assigned to the faculty member
              if (
                section.facultyMember &&
                parseInt(section.facultyMember.id) === parseInt(facultyMemberId)
              ) {
                sections.push({
                  ...section,
                  isAssigned: true,
                  enrollmentEffortApplied:
                    sectionCapacityExceedsThreshold && !actualEffort,
                  effort: finalEffort,
                  roundedEffort
                });
              }
            });

            // If the actual section count is greater than
            // the number of assigned sections, add as many 'planned' sections
            // as equals the difference
            if (plannedCourseComponent && actualSectionCount > 0) {
              for (let i = 0; i < actualSectionCount; i++) {
                sections.push({ isAssigned: false, effort: defaultEffort });
              }
            }
          } else {
            // If there are no sections in the component, add as many 'planned' sections as the sectionCount
            if (plannedCourseComponent) {
              for (let i = 0; i < plannedCourseComponent.sectionCount; i++) {
                sections.push({ isAssigned: false, effort: defaultEffort });
              }
            }
          }
          let newComponent = {
            ...component,
            projectedEnrollment: enrollment
              ? enrollment.projectedEnrollment
              : 0,
            sectionCount: sections.length,
            sections: sections
          };
          return newComponent;
        });

        if (!courseInfo) {
          return null;
        }

        return {
          ...scheduleCourse,
          components: components,
          courseInfo: courseInfo
        };
      }
    );

    // Combine planned and assigned courses
    let augmentedAssignments = {
      term: schedule.term,
      year: schedule.year,
      courses: augmentedScheduleCourses.concat(plannedTeaching)
    };

    resolve(augmentedAssignments);
  });
};
