import React, { useRef, useState } from 'react';
import Select from '../common/Select';
import Input from '../common/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faArchive } from '@fortawesome/free-solid-svg-icons/faArchive';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';

import propTypes from 'prop-types';

const LocationForm = ({
  children,
  formValues,
  handleValueChange,
  optionNames,
  handleCancelClick,
  handleSubmitClick,
  handleArchiveClick,
  isFormInputValid,
  keyHandler,
  errorMessage,
  selectDefault,
  cancelId,
  submitId,
  action
}) => {
  const closeButton = useRef(null);
  const [archiving, setArchiving] = useState(false);

  const renderArchiveButton = () => {
    if (archiving) {
      return (
        <div className="archival-buttons">
          <span className="confirmation-message">Archive Location?</span>
          <button
            className="circle"
            title="Cancel archival"
            tabIndex="0"
            onClick={event => {
              event.preventDefault();
              setArchiving(false);
            }}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                setArchiving(false);
              }
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <button
            className="circle"
            title="Confirm archival"
            tabIndex="0"
            onClick={event => {
              event.preventDefault();
              handleArchiveClick(event);
            }}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                handleArchiveClick(event);
              }
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </button>
        </div>
      );
    }
    return (
      <div className="archival-buttons">
        <button
          className="circle"
          title="Archive Location"
          tabIndex="0"
          onClick={event => {
            event.preventDefault();
            setArchiving(true);
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              event.preventDefault();
              setArchiving(true);
            }
          }}
        >
          <FontAwesomeIcon icon={faArchive} />
        </button>
      </div>
    );
  };

  return (
    <section
      className={(action === 'adding' ? 'add-' : 'update-') + 'location-sect'}
    >
      <div className="top first-focus">
        <h1>{children}</h1>

        {action === 'updating' ? (
          <button
            className="circle"
            ref={closeButton}
            tabIndex="0"
            title="Close Update Panel"
            id={cancelId}
            onClick={e => handleCancelClick(e)}
            onKeyDown={e => keyHandler(e)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        ) : null}
      </div>

      <form id="add-location" className="add-location-form-wrapper">
        <div
          className={
            (action === 'updating' ? 'updating ' : '') +
            'add-location-entry-form'
          }
        >
          <div className="building-unit-input">
            <Input
              formValue={formValues.building}
              controlFunc={handleValueChange}
              immediatelyValidate={true}
            />
          </div>
          <div className="capacity-type-wrapper">
            <div className="location-capacity">
              <Input
                formValue={formValues.capacity}
                controlFunc={handleValueChange}
                trimWhitespace={true}
                optional={true}
                immediatelyValidate={true}
              />
            </div>
            <div className="location-type">
              <Select
                formValue={formValues.type}
                controlFunc={handleValueChange}
                optionList={optionNames}
                startingValue={selectDefault}
              />
            </div>
          </div>
        </div>

        <div id="form-end-wrapper">
          {errorMessage && (
            <div className="form-message unexpected-error-message error-message">
              <FontAwesomeIcon icon={faExclamationCircle} />
              An unknown error occurred. Please try again.
            </div>
          )}

          <div className="bottom row">
            <div className="buttons">
              <button
                type="button"
                id={cancelId}
                onClick={e => handleCancelClick(e)}
                onKeyDown={e => keyHandler(e)}
              >
                Cancel
              </button>
              <button
                id={submitId}
                onClick={e => handleSubmitClick(e)}
                onKeyDown={e => keyHandler(e)}
                disabled={!isFormInputValid}
              >
                {action === 'updating' ? 'Update Location ' : 'Add Location '}
                <FontAwesomeIcon icon={faPlusCircle} />
              </button>
            </div>

            {action === 'updating' ? renderArchiveButton() : undefined}
          </div>
        </div>
      </form>
    </section>
  );
};

export default LocationForm;

LocationForm.propTypes = {
  children: propTypes.string.isRequired,
  optionNames: propTypes.arrayOf(propTypes.string).isRequired,
  isFormInputValid: propTypes.bool,
  selectDefault: propTypes.string,
  cancelId: propTypes.string.isRequired,
  submitId: propTypes.string.isRequired,
  action: propTypes.string.isRequired
};
