import React from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';
import { connect } from 'react-redux';

class SelectProgram extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valueMappings: new Map(),
      reverseValueMappings: new Map(),
      strippedOptionList: []
    };

    this.controlFunc = this.controlFunc.bind(this);
  }

  componentDidMount() {
    if (this.props.programList) {
      this.buildValueMappings();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.programList !== this.props.programList) {
      this.buildValueMappings();
    }
  }

  render() {
    const {
      programFormValue,
      concentrationFormValue,
      defaultOption,
      hideLabel,
      loading,
      actionable,
      disabled
    } = this.props;
    const { strippedOptionList, reverseValueMappings } = this.state;

    let defaultText = defaultOption;
    if (loading) {
      defaultText = 'Loading...';
    }

    const startingValue = {
      programId: programFormValue.defaultValue,
      concentrationId:
        (concentrationFormValue && concentrationFormValue.defaultValue) || null
    };

    let startingValueInMap = undefined;
    for (const k of reverseValueMappings.keys()) {
      if (
        k.programId === startingValue.programId &&
        k.concentrationId === startingValue.concentrationId
      ) {
        startingValueInMap = reverseValueMappings.get(k);
      }
    }

    return (
      <Select
        formValue={programFormValue}
        controlFunc={this.controlFunc}
        startingValue={startingValueInMap}
        optionList={strippedOptionList}
        defaultOption={defaultText}
        hideLabel={hideLabel}
        actionable={actionable}
        containerStyle={{ zIndex: 100 }}
        disabled={disabled}
      />
    );
  }

  controlFunc(formValue) {
    const { programId, concentrationId } = this.state.valueMappings.get(
      formValue.value
    ) || {
      programId: null,
      concentrationId: null
    };
    this.props.controlFunc(
      {
        valueId: this.props.programFormValue.valueId,
        value: programId
      },
      {
        valueId: this.props.concentrationFormValue.valueId,
        value: concentrationId
      }
    );
  }

  buildValueMappings() {
    let valueMappings = new Map();
    let reverseValueMappings = new Map();
    let strippedOptionList = [];
    if (!this.props.programList) {
      return null;
    }
    this.props.programList.forEach(program => {
      if (program.concentrationRequired === false) {
        valueMappings.set(program.name, {
          programId: program.id,
          concentrationId: null
        });
        reverseValueMappings.set(
          {
            programId: program.id,
            concentrationId: null
          },
          program.name
        );
        strippedOptionList.push(program.name);
      }

      if (!program.concentrations) {
        return;
      }

      program.concentrations.forEach(concentration => {
        let comboProgramConcentrationText =
          program.name + ', ' + concentration.name;
        valueMappings.set(comboProgramConcentrationText, {
          programId: program.id,
          concentrationId: concentration.id
        });
        reverseValueMappings.set(
          {
            programId: program.id,
            concentrationId: concentration.id
          },
          comboProgramConcentrationText
        );
        strippedOptionList.push(comboProgramConcentrationText);
      });
      strippedOptionList.sort((a, b) => {
        return ('' + a).localeCompare(b);
      });
    });

    this.setState({
      valueMappings,
      reverseValueMappings,
      strippedOptionList
    });
  }
}

SelectProgram.propTypes = {
  programFormValue: PropTypes.object.isRequired,
  concentrationFormValue: PropTypes.object.isRequired,
  controlFunc: PropTypes.func.isRequired,
  programList: PropTypes.array.isRequired,
  defaultOption: PropTypes.string,
  hideLabel: PropTypes.bool,
  actionable: PropTypes.bool,
  disabled: PropTypes.bool
};

SelectProgram.defaultProps = {
  actionable: false,
  disabled: false
};

const mapStateToProps = ({ plansOfStudy }) => {
  return {
    loading: plansOfStudy.loading
  };
};

export default connect(mapStateToProps, null)(SelectProgram);
