import React from 'react';
import './FacultyMemberView.scss';
import convertAppointmentDateToObj from './convertAppointmentDateToObj';
import { connect } from 'react-redux';
import {
  getFacultyMember,
  getFacultyMembers,
  getFacultyColor,
  getFacultyMemberNotes,
  clearFacultyMember
} from '../../service/actions/faculty';
import { getCourses } from '../../service/actions/courses';
import { showLoadingModal, hideModal } from '../../service/actions/modal';
import { showSidePanel } from '../../service/actions/sidePanel';
import { withRouter } from 'react-router-dom';
import FacultyMemberWorkPlans from './FacultyMemberWorkPlans';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons/faArrowAltCircleLeft';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons/faArrowAltCircleRight';
import updatePageTitle from '../common/helpers/updatePageTitle';
import UpdateFacultyMember from './UpdateFacultyMember';
import FacultyMemberNotes from '../FacultyMembers/FacultyMemberNotes';

import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import {
  GET_FACULTY_MEMBER,
  GET_FACULTY_MEMBER_NOTES,
  SET_FACULTY_MEMBER_NOTES,
  GET_COURSES,
  GET_CONFIG,
  UPDATE_FACULTY_MEMBER,
  PUT_WORKPLAN_CURRYEAR,
  UPDATE_WORK_ACTIVITY,
  DELETE_WORK_ACTIVITY,
  GET_FACULTY_MEMBERS,
  ADD_PROSPECTIVE_COURSE,
  DELETE_PROSPECTIVE_COURSE,
  PUT_WORKPLAN_ALLOCATION,
  ADD_SECTION_PLACEHOLDER,
  DELETE_SECTION_PLACEHOLDER,
  ADD_WORK_ACTIVITY,
  SET_DEFAULT_TEACHING_EFFORT,
  SET_TEACHING_EFFORT_TOTALS,
  UPDATE_ASSIGNED_COURSE_SECTION
} from '../../service/types';
import { mainViewComponent } from '../common/HOCs';

class FacultyMemberView extends React.Component {
  constructor(props) {
    super(props);
    this.updateFacultyButton = React.createRef();
    this.facultyNotesFormRevealRef = React.createRef();
    this.state = {
      showFacultyNotesForm: false
    };
  }
  componentDidMount() {
    this.retrieveFacultyDataForId(this.props.match.params.facultyMemberId);
  }

  retrieveFacultyDataForId(id) {
    updatePageTitle('Faculty Member: ' + id);

    this.props.getFacultyMember(id);
    if (this.props.scopes.includes('faculty-member.notes.read')) {
      this.props.getFacultyMemberNotes(id);
    }
    this.props.getCourses();
    this.props.getFacultyMembers();
  }

  componentWillUnmount() {
    this.props.clearFacultyMember();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.facultyMember !== this.props.facultyMember) {
      if (this.props.facultyMember) {
        updatePageTitle(
          this.props.facultyMember.firstName +
            ' ' +
            this.props.facultyMember.lastName
        );
      }
    }
    if (
      prevProps.match.params.facultyMemberId !==
      this.props.match.params.facultyMemberId
    ) {
      this.retrieveFacultyDataForId(this.props.match.params.facultyMemberId);
    }
  }

  render() {
    const {
      scopes,
      facultyMember,
      facultyMemberNotes,
      errorMessage
    } = this.props;

    if (errorMessage) {
      return (
        <div className="faculty-member-error-message error-message">
          <FontAwesomeIcon icon={faExclamationCircle} />
          {errorMessage}
        </div>
      );
    }

    if (!facultyMember || !facultyMemberNotes === undefined) {
      return null;
    }

    const augmentedFaculty = this.augmentFacultyMember();
    if (!augmentedFaculty) {
      return null;
    }

    let bottomLineMetaText = augmentedFaculty.rankName;
    if (augmentedFaculty.appointmentDate) {
      const dateObj = convertAppointmentDateToObj(
        augmentedFaculty.appointmentDate
      );
      bottomLineMetaText += ` | Started ${dateObj.month} ${dateObj.year}`;
    }

    if (augmentedFaculty.departmentName) {
      bottomLineMetaText += ` | ${augmentedFaculty.departmentName}`;
    }

    const mailToLink = `mailto:${augmentedFaculty.emailAddress}`;

    const facultyBrowsing = this.getFacultyBrowsing();

    return (
      <div className="main" id="faculty-view-wrapper">
        <div className="browse-faculty-wrapper">
          <button
            onClick={event =>
              this.browseFacultyClicked(
                event,
                facultyBrowsing.previousId,
                false
              )
            }
            onKeyDown={event =>
              this.browseFacultyKeyDowned(event, facultyBrowsing.previousId)
            }
          >
            <FontAwesomeIcon icon={faArrowAltCircleLeft} />
            Prev
          </button>
          <button
            onClick={event =>
              this.browseFacultyClicked(event, facultyBrowsing.nextId, false)
            }
            onKeyDown={event =>
              this.browseFacultyKeyDowned(event, facultyBrowsing.nextId)
            }
          >
            Next
            <FontAwesomeIcon icon={faArrowAltCircleRight} />
          </button>
        </div>

        <div id="faculty-member-info">
          <div
            className={
              'initials circle-badge large ' + augmentedFaculty.colorClass
            }
          >
            {augmentedFaculty.firstName.charAt(0) +
              augmentedFaculty.lastName.charAt(0)}
          </div>
          <div className="top-container">
            <div className="meta-info first-focus">
              {augmentedFaculty.active === false && (
                <div>
                  <div className="label">Inactive</div>
                </div>
              )}
              <span className="appointment">
                {augmentedFaculty.appointmentName}
              </span>
              <h1 className="no-bar">
                {`${augmentedFaculty.firstName} ${augmentedFaculty.lastName}`}
              </h1>
              <span>{bottomLineMetaText}</span>
              <span>
                {augmentedFaculty.emailAddress && (
                  <a href={mailToLink}>{augmentedFaculty.emailAddress}</a>
                )}
                {augmentedFaculty.employeeId && (
                  <>
                    {augmentedFaculty.emailAddress && ' | '}
                    <span>{augmentedFaculty.employeeId}</span>
                  </>
                )}
              </span>
            </div>

            {this.props.facultyList && this.renderUpdateFaculty()}
          </div>
        </div>
        {scopes.includes('faculty-member.notes.read') &&
          facultyMemberNotes !== undefined && <FacultyMemberNotes />}
        {scopes.includes('faculty-member.work-plan.read') && (
          <FacultyMemberWorkPlans workPlans={augmentedFaculty.workPlans} />
        )}
      </div>
    );
  }

  renderUpdateFaculty() {
    const { scopes } = this.props;
    if (!scopes.includes('faculty-member.write')) {
      return null;
    }
    return (
      <button
        className="no-print"
        ref={this.updateFacultyButton}
        onClick={() => this.openSidePanel(false)}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            event.preventDefault();
            this.openSidePanel(true);
          }
        }}
        title="Update Faculty Member"
      >
        Update Faculty
        <FontAwesomeIcon icon={faEdit} />
      </button>
    );
  }

  openSidePanel(keyActivated) {
    this.props.showSidePanel(
      <UpdateFacultyMember
        facultyMember={this.props.facultyMember}
        facultyList={this.props.facultyList}
      />,
      this.updateFacultyButton,
      keyActivated
    );
  }

  browseFacultyKeyDowned(event, id) {
    if (event.key === 'Enter') {
      this.browseFacultyClicked(event, id, true);
    }
  }

  browseFacultyClicked(event, id, keyed) {
    event.preventDefault();
    const pathname = '/faculty-members/' + (id ?? '');
    if (event.ctrlKey || event.metaKey) {
      window.open(this.props.history.createHref({ pathname }));
    } else {
      this.props.history.push({
        pathname,
        state: {
          keyed
        }
      });
    }
  }

  getFacultyBrowsing() {
    const { facultyMember, facultyList } = this.props;

    const browsableList = facultyList
      ?.filter(x => x.active)
      ?.sort((a, b) => ('' + a.lastName).localeCompare(b.lastName));

    const facultyListSize = browsableList?.length;

    const currentListIndex = browsableList?.findIndex(
      x => x.id === facultyMember.id
    );
    if (currentListIndex === -1) {
      return {};
    }

    const previousListIndex =
      browsableList && currentListIndex > 0 ? currentListIndex - 1 : undefined;
    const nextListIndex =
      browsableList && currentListIndex < facultyListSize - 1
        ? currentListIndex + 1
        : undefined;
    const previousId = browsableList?.[previousListIndex]?.id;
    const nextId = browsableList?.[nextListIndex]?.id;

    return {
      previousId,
      nextId
    };
  }

  augmentFacultyMember() {
    const { facultyMember, ranks, departments, appointments } = this.props;
    if (!ranks || !departments || !appointments || !facultyMember) {
      return;
    }

    let augmentedFaculty = { ...facultyMember };

    let matchingRank = ranks.find(rank => {
      return rank.id === augmentedFaculty.rankId;
    });
    augmentedFaculty.rankName = matchingRank ? matchingRank.name : '';

    let matchingDepartment = departments.find(dept => {
      return dept.id === augmentedFaculty.departmentId;
    });
    augmentedFaculty.departmentName = matchingDepartment
      ? matchingDepartment.name
      : '';

    let matchingAppointment = appointments.find(appt => {
      return appt.id === augmentedFaculty.appointmentId;
    });
    augmentedFaculty.appointmentName = matchingAppointment
      ? matchingAppointment.name
      : '';

    augmentedFaculty.colorClass = getFacultyColor(facultyMember);

    return augmentedFaculty;
  }
}

const loadingSelector = createLoadingSelector([
  GET_CONFIG,
  GET_FACULTY_MEMBERS,
  GET_FACULTY_MEMBER,
  GET_FACULTY_MEMBER_NOTES,
  SET_FACULTY_MEMBER_NOTES,
  GET_COURSES,
  UPDATE_FACULTY_MEMBER,
  PUT_WORKPLAN_CURRYEAR,
  ADD_WORK_ACTIVITY,
  UPDATE_WORK_ACTIVITY,
  DELETE_WORK_ACTIVITY,
  SET_TEACHING_EFFORT_TOTALS,
  UPDATE_ASSIGNED_COURSE_SECTION,
  ADD_PROSPECTIVE_COURSE,
  DELETE_PROSPECTIVE_COURSE,
  ADD_SECTION_PLACEHOLDER,
  DELETE_SECTION_PLACEHOLDER,
  SET_DEFAULT_TEACHING_EFFORT,
  PUT_WORKPLAN_ALLOCATION
]);
const errorSelector = createErrorMessageSelector([GET_FACULTY_MEMBER]);
const mapStateToProps = state => {
  const { auth, config, faculty } = state;
  return {
    scopes: auth.scopes,
    loading: loadingSelector(state),
    errorMessage: errorSelector(state),
    departments: config.config && config.config.departments,
    appointments: config.config && config.config.facultyMemberAppointments,
    ranks: config.config && config.config.facultyMemberRanks,
    facultyMember: faculty.viewIndividual,
    facultyMemberNotes: faculty.facultyMemberNotes,
    facultyList: faculty.list,
    workPlanSchedules: faculty.workPlanSchedules
  };
};

const mainViewConfig = {
  pageTitle: 'View Faculty Member'
};

export default connect(mapStateToProps, {
  getFacultyMember,
  getFacultyMembers,
  getFacultyMemberNotes,
  getCourses,
  showSidePanel,
  showLoadingModal,
  hideModal,
  clearFacultyMember
})(
  withRouter(
    mainViewComponent({ component: FacultyMemberView, mainViewConfig })
  )
);
