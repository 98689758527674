import React from 'react';
import PropTypes from 'prop-types';
import './FormReveal.scss';
import transitionEndEvent from '../helpers/transitionEndEvent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons/faChevronCircleRight';
import { FORM_REVEAL_ANIMATION_SPEED } from '../../../config';

class FormReveal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showForm: props.parentShowForm || false,
      isAnimating: false
    };

    this.buttonRef = React.createRef();
    this.formRef = React.createRef();
  }

  transitionListenerFunc() {
    this.setState({ isAnimating: false });
  }

  componentDidMount() {
    this.formRef.current.addEventListener(
      transitionEndEvent(),
      this.transitionListenerFunc.bind(this)
    );
  }

  componentWillUnmount() {
    this.formRef.current.removeEventListener(
      transitionEndEvent(),
      this.transitionListenerFunc.bind(this)
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.parentShowForm !== this.props.parentShowForm) {
      if (this.props.parentShowForm) {
        this.setState({
          showForm: true,
          isAnimating: true
        });
        if (this.props.parentShowFormSendFocus) {
          setTimeout(() => {
            this.formRef.current.focus();
          }, 0);
        }
      }
    }
  }

  render() {
    const {
      form,
      buttonText,
      buttonId,
      hideButton,
      hideRevealJsx,
      hideFormAnimation,
      useChevronIcon
    } = this.props;

    // === placeholder variable for UI testing ===
    const { showForm, isAnimating } = this.state;

    const buttonHeight = this.buttonRef.current
      ? this.buttonRef.current.scrollHeight
      : 55;
    const buttonHeightText = `${buttonHeight}px`;

    const formHeight = this.formRef.current
      ? this.formRef.current.scrollHeight
      : 200;
    const formHeightText = `${formHeight}px`;

    return (
      <>
        <div
          className={
            'collapsible ' +
            (showForm ? 'collapsed' : 'visible') +
            (isAnimating ? ' animating' : '')
          }
          ref={this.buttonRef}
          style={
            showForm
              ? undefined
              : {
                  maxHeight:
                    !this.buttonRef.current && hideRevealJsx
                      ? undefined
                      : buttonHeightText
                }
          }
        >
          {!hideButton && (
            <button
              id={buttonId}
              className="no-print"
              onClick={event => this.handleOpenClick(event)}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  this.handleOpenClick(event);
                }
              }}
            >
              {buttonText}{' '}
              <FontAwesomeIcon
                icon={useChevronIcon ? faChevronCircleRight : faPlusCircle}
              />
            </button>
          )}
          {hideButton && hideRevealJsx && <div>{hideRevealJsx}</div>}
        </div>
        <div
          className={
            'collapsible ' +
            (showForm ? 'visible' : 'collapsed') +
            ((!hideFormAnimation || !showForm) && isAnimating
              ? ' animating'
              : '')
          }
          ref={this.formRef}
          style={
            showForm
              ? {
                  maxHeight:
                    formHeight > buttonHeight ? formHeightText : undefined,
                  height:
                    formHeight > buttonHeight ? undefined : buttonHeightText,
                  display: formHeight > buttonHeight ? undefined : 'flex',
                  flexDirection:
                    formHeight > buttonHeight ? undefined : 'column'
                }
              : undefined
          }
          tabIndex={showForm ? 0 : -1}
        >
          {form}
        </div>
      </>
    );
  }

  handleOpenClick(event) {
    event.preventDefault();
    this.setState({ showForm: true, isAnimating: true });
    if (this.props.isOpening) {
      this.props.isOpening(true);
    }
    if (event.key) {
      setTimeout(() => {
        this.formRef.current.focus();
      }, 0);
    }
  }

  handleCancelClick(event) {
    event.preventDefault();
    this.closeForm();
    setTimeout(() => {
      this.props.cancelFunc();
    }, FORM_REVEAL_ANIMATION_SPEED);
  }

  handleSubmitClick(event) {
    event.preventDefault();
    this.props.submitFunc();
  }

  handleSubmitClose(event) {
    event.preventDefault();
    this.closeForm();
  }

  closeForm() {
    this.setState({ isAnimating: true, showForm: false });
    if (this.props.isOpening) {
      setTimeout(() => {
        this.props.isOpening(false);
      }, FORM_REVEAL_ANIMATION_SPEED);
    }
  }
}

FormReveal.propTypes = {
  parentShowForm: PropTypes.bool,
  parentShowFormSendFocus: PropTypes.bool,
  hideButton: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonId: PropTypes.string,
  form: PropTypes.object.isRequired,
  cancelFunc: PropTypes.func.isRequired,
  submitFunc: PropTypes.func,
  isOpening: PropTypes.func,
  rerenderCounter: PropTypes.number,
  hideRevealJsx: PropTypes.object,
  hideFormAnimation: PropTypes.bool,
  useChevronIcon: PropTypes.bool
};

FormReveal.defaultProps = {
  parentShowForm: false,
  hideButton: false,
  hideFormAnimation: false,
  useChevronIcon: false
};

export default FormReveal;
