const errorReducer = (state = {}, action) => {
  const { type, payload } = action;
  const matches = /(.*)_(REQUEST|FAILURE|CLEAR_ERROR)/.exec(type);

  // not a *_REQUEST / *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;

  let message = requestState === 'FAILURE' ? payload : '';
  if (requestState === 'CLEAR_ERROR') {
    message = '';
  }

  return {
    ...state,
    [requestName]: message
  };
};
export default errorReducer;
