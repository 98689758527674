import React from 'react';
import { Authenticator } from 'aws-amplify-react';
import { Hub } from '@aws-amplify/core';
import CustomSignIn from './CustomSignIn';
import { Greetings, SignIn } from 'aws-amplify-react';
import App from './App';
import { connect } from 'react-redux';
import { validateUserSession } from '../../service/actions/auth';
import config from '../../config';

class AppWithAuth extends React.Component {
  constructor(props) {
    super(props);
    this.interval = null;
    this.state = {};

    Hub.listen('auth', data => {
      const { channel, payload } = data;

      if (channel === 'auth') {
        switch (payload.event) {
          case 'signIn':
            console.log('Redirect.onHubCapsule() user signed in');
            this.props.validateUserSession();
            break;
          default:
            console.log(payload);
            console.log('Unmatched payload event');
        }
      }
    });
  }

  componentDidMount() {
    if (
      typeof this.props.authenticated === 'undefined' ||
      this.props.authenticated === false
    ) {
      this.props.validateUserSession();
    }
  }

  render() {
    const authorized =
      this.props.groups && this.props.groups.includes(config.AUTHORIZED_GROUP);
    return (
      <Authenticator hideDefault={[SignIn, Greetings]}>
        <App
          signedIn={this.props.signedIn}
          displayName={this.props.displayName}
          authorized={authorized}
        />
        <CustomSignIn override={'SignIn'} />
      </Authenticator>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  if (auth.session) {
    return {
      signedIn: true,
      displayName: auth.session.idToken.payload.given_name,
      groups: auth.session.idToken.payload['cognito:groups']
    };
  }
  return {
    signedIn: false,
    displayName: '',
    groups: []
  };
};

export default connect(mapStateToProps, { validateUserSession })(AppWithAuth);
