import React from 'react';
import './AddStudentGroup.scss';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { addStudentGroup } from '../../service/actions/studentGroups';
import { getPrograms } from '../../service/actions/programs';
import {
  showCustomJSXModal,
  showValidatingModal,
  hideModal
} from '../../service/actions/modal';
import FormReveal from '../common/FormReveal';
import Select from '../common/Select';
import Input from '../common/Input';
import SelectProgram from '../common/SelectProgram';
import { withRouter } from 'react-router-dom';
import SelectPlanOfStudy, {
  filterPlanOfStudyList
} from '../common/SelectPlanOfStudy';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import { ADD_STUDENT_GROUP } from '../../service/types';

class AddStudentGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showForm: false,
      isFormInputValid: false,
      duplicateError: '',
      resetting: false
    };
    this.baseState = { ...this.state };

    this.handleValueChange = this.handleValueChange.bind(this);

    this.formRevealRef = React.createRef();

    this.formValues = {
      programId: {
        name: 'program-concentration-select',
        valueId: 'programId',
        defaultValue: 'Select',
        labelTitle: 'Programs and Concentrations',
        validatorFunc: value => ({
          isValid: value && value !== '' && value !== 'Select',
          errorMessage: 'Invalid program'
        })
      },
      concentrationId: {
        valueId: 'concentrationId',
        validatorFunc: () => ({
          isValid: true,
          errorMessage: 'Invalid concentration'
        })
      },
      track: {
        name: 'track-type-select',
        valueId: 'track',
        labelTitle: 'Track Type',
        defaultValue: 'Select',
        validatorFunc: value => ({
          isValid: value && value !== '' && value !== 'Select',
          errorMessage: 'Invalid track type'
        })
      },
      startTerm: {
        name: 'starting-term-select',
        valueId: 'startTerm',
        labelTitle: 'Starting Term',
        defaultValue: 'Select',
        validatorFunc: value => ({
          isValid: value && value !== '' && value !== 'Select',
          errorMessage: 'Invalid starting term'
        })
      },
      startYear: {
        name: 'year-select',
        valueId: 'startYear',
        labelTitle: 'Starting Year',
        defaultValue: 'Select',
        validatorFunc: value => ({
          isValid: value && value !== '' && value !== 'Select',
          errorMessage: 'Invalid starting year'
        })
      },
      groupName: {
        name: 'group-name',
        valueId: 'groupName',
        labelTitle: 'Group Name',
        defaultValue: '',
        validatorFunc: value => {
          return {
            isValid: /^.{0,30}$/.test(value),
            errorMessage: 'Invalid Student Group Name'
          };
        }
      },
      initialEnrollment: {
        name: 'initial-enrollment-input',
        valueId: 'initialEnrollment',
        defaultValue: '',
        labelTitle: 'Enrollment',
        validatorFunc: value => ({
          isValid:
            value === undefined ||
            value === '' ||
            (/^\d+$/.test(value) &&
              parseInt(value) > 0 &&
              parseInt(value) < 10000),
          errorMessage: 'Invalid initial enrollment'
        })
      },
      planOfStudyId: {
        name: 'plan-of-study-select',
        valueId: 'planOfStudyId',
        defaultValue: 'Select',
        labelTitle: 'Plan of Study',
        validatorFunc: () => ({
          isValid: true,
          errorMessage: 'Invalid'
        })
      }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.addingStudentGroup !== this.props.addingStudentGroup) {
      this.props.showValidatingModal();

      if (!this.props.addingStudentGroup) {
        this.props.hideModal();
        if (!this.props.errorMessage) {
          this.props.history.push(
            this.props.match.path + '/' + this.props.newlyAddedStudentGroup.id
          );
        }
      }
    } else if (
      prevState.resetting !== this.state.resetting &&
      this.state.resetting
    ) {
      this.setState({
        resetting: false
      });
    }
  }

  render() {
    const form = this.renderAddStudentGroupForm();
    if (!form) return null;
    return (
      <div className="add-student-group-wrapper">
        <FormReveal
          buttonText="Add Student Group"
          buttonId="add-student-group"
          form={form}
          submitFunc={() => this.addStudentGroup()}
          cancelFunc={() => this.resetForm()}
          ref={this.formRevealRef}
        />
      </div>
    );
  }

  renderAddStudentGroupForm() {
    const { errorMessage } = this.props;
    const { duplicateError, filteredPlanOfStudyList, resetting } = this.state;
    if (resetting) {
      return null;
    }

    const { programList, trackList, termList } = this.props;

    if (!programList || !trackList || !termList) {
      return null;
    }

    const yearList = this.generateYearList();
    return (
      <section>
        <h1>Add a Student Group</h1>

        <form id="sg-form-wrapper">
          <div id="sg-entry-form">
            <div>
              <SelectProgram
                programFormValue={this.formValues.programId}
                concentrationFormValue={this.formValues.concentrationId}
                controlFunc={this.handleValueChange}
                programList={programList}
              />
            </div>
            <div>
              <Select
                formValue={this.formValues.track}
                controlFunc={this.handleValueChange}
                optionList={trackList}
              />
            </div>
            <div>
              <Select
                formValue={this.formValues.startTerm}
                controlFunc={this.handleValueChange}
                optionList={termList}
              />
            </div>
            <div>
              <Select
                formValue={this.formValues.startYear}
                controlFunc={this.handleValueChange}
                optionList={yearList}
                containerStyle={{ zIndex: 99 }}
              />
            </div>
            <div>
              <Input
                formValue={this.formValues.groupName}
                controlFunc={this.handleValueChange}
                immediatelyValidate={true}
                optional={true}
              />
            </div>
            <div>
              <h2 className="no-border">Additional Information</h2>
            </div>
            <div>
              <Input
                formValue={this.formValues.initialEnrollment}
                controlFunc={this.handleValueChange}
                immediatelyValidate={true}
                optional={true}
              />
            </div>
            <div>
              <SelectPlanOfStudy
                planOfStudyFormValue={this.formValues.planOfStudyId}
                controlFunc={this.handleValueChange}
                planOfStudyList={filteredPlanOfStudyList}
                containerStyle={{ zIndex: 98 }}
                optional={true}
              />
            </div>
          </div>

          <div className="form-end-wrapper">
            {errorMessage && (
              <div className="form-message unexpected-error-message error-message">
                <FontAwesomeIcon icon={faExclamationCircle} />
                An unexpected error occurred. Please try again.
              </div>
            )}

            {duplicateError && (
              <div className="form-message duplicate-error-message warning-message">
                <FontAwesomeIcon icon={faExclamationTriangle} />
                This student group already exists. Please validate your entries
                and consider an alternative name for the group.
              </div>
            )}
            <div className="buttons">
              <button
                id="cancel-sg-entry"
                onClick={event => this.handleCancelClick(event)}
                onKeyUp={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    this.handleCancelClick(event);
                  }
                }}
              >
                Cancel
              </button>
              <button
                id="submit-sg-entry"
                onClick={event => this.handleSubmitClick(event)}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    this.handleSubmitClick(event);
                  }
                }}
                disabled={!this.state.isFormInputValid}
              >
                Add Student Group <FontAwesomeIcon icon={faPlusCircle} />
              </button>
            </div>
          </div>
        </form>
      </section>
    );
  }

  handleOpenClick(event) {
    event.preventDefault();
    this.setState({ showForm: true });
  }

  handleCancelClick(event) {
    event.preventDefault();
    this.formRevealRef.current.handleCancelClick(event);
  }

  handleSubmitClick(event) {
    event.preventDefault();
    this.addStudentGroup();
  }

  addStudentGroup() {
    const {
      programId,
      concentrationId,
      track,
      startTerm,
      startYear,
      groupName,
      planOfStudyId,
      initialEnrollment
    } = this.state;

    let newStudentGroup = {
      programId: programId,
      track: track,
      startTerm: startTerm,
      startYear: startYear
    };
    if (concentrationId) newStudentGroup.concentrationId = concentrationId;
    if (groupName) newStudentGroup.name = groupName;
    if (planOfStudyId) newStudentGroup.planOfStudyId = planOfStudyId;
    if (initialEnrollment)
      newStudentGroup.initialEnrollment = parseInt(initialEnrollment);
    this.props.addStudentGroup(newStudentGroup);
  }

  handleValueChange(formValue, formValue2 = {}) {
    let currentFormState = Object.keys(this.formValues).reduce(
      (result, next) => {
        switch (next) {
          case formValue.valueId:
            result[formValue.valueId] = formValue.value;
            break;
          case formValue2.valueId:
            result[formValue2.valueId] = formValue2.value;
            break;
          default:
            result[next] = this.state[next];
        }
        return result;
      },
      {}
    );

    const isFormInputValid = Object.keys(this.formValues).reduce(
      (result, next) => {
        return (
          result &&
          this.formValues[next].validatorFunc(currentFormState[next]).isValid
        );
      },
      true
    );

    const isDuplicate = this.isDuplicate(currentFormState);
    const filteredPlanOfStudyList = filterPlanOfStudyList(
      currentFormState,
      this.props.planOfStudyList
    );

    this.setState({
      [formValue.valueId]: formValue.value,
      [formValue2.valueId]: formValue2.value,
      isFormInputValid: isFormInputValid && !isDuplicate,
      duplicateError: isDuplicate,
      filteredPlanOfStudyList: filteredPlanOfStudyList
    });
  }

  isDuplicate(currentFormState) {
    return this.props.studentGroups.reduce((result, next) => {
      if (
        next.program.id === currentFormState.programId &&
        next.track === currentFormState.track &&
        next.startYear === currentFormState.startYear &&
        next.startTerm === currentFormState.startTerm
      ) {
        if (this.checkGroupNameDuplicate(currentFormState, next)) {
          if (!next.concentration) {
            return result || !currentFormState.concentrationId;
          } else {
            return (
              result ||
              next.concentration.id === currentFormState.concentrationId
            );
          }
        }
      }
      return result || false;
    }, false);
  }

  checkGroupNameDuplicate(currentFormState, next) {
    return (
      ((next.name === '' ||
        typeof next.name === 'undefined' ||
        next.name === null) &&
        (currentFormState.groupName === '' ||
          typeof currentFormState.groupName === 'undefined' ||
          currentFormState.groupName === null)) ||
      next.name === currentFormState.groupName
    );
  }

  resetForm() {
    let newState = { ...this.baseState, resetting: true };
    Object.keys(this.formValues).forEach(key => {
      newState[key] = undefined;
    });
    this.setState(newState);
  }

  generateYearList() {
    const thisYear = new Date().getFullYear();
    return Array(10)
      .fill()
      .map((_, index) => thisYear - 4 + index);
  }
}

const addingSelector = createLoadingSelector([ADD_STUDENT_GROUP]);
const errorSelector = createErrorMessageSelector([ADD_STUDENT_GROUP]);
const mapStateToProps = state => {
  const { config, studentGroups, plansOfStudy, programs } = state;

  return {
    studentGroups: studentGroups.list,
    addingStudentGroup: addingSelector(state),
    errorMessage: errorSelector(state),
    newlyAddedStudentGroup: studentGroups.newest,
    programList: programs.list,
    planOfStudyList: plansOfStudy.list,
    trackList: config.config && config.config.tracks,
    termList: config.termsByAcademicYear
  };
};

export default connect(mapStateToProps, {
  getPrograms,
  addStudentGroup,
  showCustomJSXModal,
  showValidatingModal,
  hideModal
})(withRouter(AddStudentGroup));
