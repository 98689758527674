const monthMap = [
  { name: 'Jan', number: '01' },
  { name: 'Feb', number: '02' },
  { name: 'Mar', number: '03' },
  { name: 'Apr', number: '04' },
  { name: 'May', number: '05' },
  { name: 'Jun', number: '06' },
  { name: 'Jul', number: '07' },
  { name: 'Aug', number: '08' },
  { name: 'Sep', number: '09' },
  { name: 'Oct', number: '10' },
  { name: 'Nov', number: '11' },
  { name: 'Dec', number: '12' }
];
const currentYear = new Date().getFullYear();

export const getMonthList = () => {
  return monthMap.map(month => month.name);
};

export const getYearList = () => {
  let yearArray = [];
  const numberYearsPast = 30;
  const numberYearsFuture = 2;

  for (let i = 0; i < numberYearsPast + numberYearsFuture + 1; i++) {
    yearArray.push(currentYear + numberYearsFuture - i);
  }
  return yearArray;
};

export const getFullAcademicYearList = () => {
  return [
    `${currentYear - 2}\u2013${currentYear - 1}`,
    `${currentYear - 1}\u2013${currentYear}`,
    `${currentYear}\u2013${currentYear + 1}`,
    `${currentYear + 1}\u2013${currentYear + 2}`,
    `${currentYear + 2}\u2013${currentYear + 3}`
  ];
};

export const getMonthNumberString = monthNameString => {
  const value = monthMap.find(month => month.name === monthNameString);
  if (value) {
    return value.number;
  }
  return null;
};
export const getMonthNameString = monthNumberString => {
  const value = monthMap.find(month => month.number === monthNumberString);
  if (value) {
    return value.name;
  }
  return null;
};
