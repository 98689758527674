import {
  GET_STUDENT_GROUPS,
  GET_STUDENT_GROUP,
  ADD_STUDENT_GROUP,
  PUT_STUDENT_GROUP,
  PUT_STUDENT_GROUP_TERM_COURSES,
  DELETE_STUDENT_GROUP,
  PUT_STUDENT_GROUP_ENROLLMENT
} from '../types';
import {
  getStudentGroupListEndpoint,
  addStudentGroupEndpoint,
  updateStudentGroupEndpoint,
  updateStudentGroupPlanOfStudyEndpoint,
  updateStudentGroupEnrollmentEndpoint,
  getStudentGroupEndpoint
} from '../../utils/endpoints';
import requestWrapper from '../requestWrapper';

export const getStudentGroupList = () => async dispatch => {
  requestWrapper(dispatch, {
    actionType: GET_STUDENT_GROUPS,
    requestObject: {
      url: getStudentGroupListEndpoint(),
      method: 'GET',
      responseType: 'json'
    },
    errorMessage: 'Error retrieving student groups'
  });
};

export const addStudentGroup = newStudentGroup => async dispatch => {
  requestWrapper(dispatch, {
    actionType: ADD_STUDENT_GROUP,
    requestObject: {
      url: addStudentGroupEndpoint(),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: newStudentGroup,
      responseType: 'json'
    },
    responseDataKey: 'studentGroup'
  });
};

export const updateStudentGroup = (
  studentGroupId,
  studentGroupUpdate
) => async dispatch => {
  if (studentGroupUpdate.enrollment) {
    delete studentGroupUpdate.enrollment;
  }

  requestWrapper(dispatch, {
    actionType: PUT_STUDENT_GROUP,
    requestObject: {
      url: updateStudentGroupEndpoint(studentGroupId),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      data: studentGroupUpdate,
      responseType: 'json'
    }
  });
};

export const putStudentGroupTermCourses = (
  studentGroupId,
  term,
  terms
) => async dispatch => {
  terms.forEach(term => {
    if (!term.courseIds) {
      term.courseIds = [];
    }
  });
  requestWrapper(dispatch, {
    actionType: PUT_STUDENT_GROUP_TERM_COURSES,
    requestObject: {
      url: updateStudentGroupPlanOfStudyEndpoint(studentGroupId),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      data: { terms },
      responseType: 'json'
    },
    errorPayload: {
      id: term,
      error: 'Error updating student group.'
    }
  });
};

export const updateStudentGroupEnrollment = (
  studentGroupId,
  studentGroupEnrollmentUpdate
) => async dispatch => {
  studentGroupEnrollmentUpdate.forEach(enrollment => {
    if (!enrollment.students) {
      delete enrollment.students;
    }
  });

  requestWrapper(dispatch, {
    actionType: PUT_STUDENT_GROUP_ENROLLMENT,
    requestObject: {
      url: updateStudentGroupEnrollmentEndpoint(studentGroupId),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      data: studentGroupEnrollmentUpdate,
      responseType: 'json'
    },
    responsePayload: studentGroupEnrollmentUpdate
  });
};

export const getStudentGroup = studentGroupId => async dispatch => {
  requestWrapper(dispatch, {
    actionType: GET_STUDENT_GROUP,
    requestObject: {
      url: getStudentGroupEndpoint(studentGroupId),
      method: 'GET',
      responseType: 'json'
    },
    errorMessage: 'Error retrieving student group',
    customResponseStatusMessages: {
      '404': 'Student Group not found.'
    }
  });
};

export const deleteStudentGroup = studentGroupId => async dispatch => {
  requestWrapper(dispatch, {
    actionType: DELETE_STUDENT_GROUP,
    requestObject: {
      url: getStudentGroupEndpoint(studentGroupId),
      method: 'DELETE',
      responseType: 'json'
    },
    errorMessage: 'Error deleting student group'
  });
};
