import React from 'react';
import './CheckboxInput.scss';
import Input from '../Input';
import Checkbox from '../Checkbox';

class CheckboxInput extends React.Component {
  constructor(props) {
    super(props);
    let startingState = {
      selectedCheckboxes:
        (props.startingValues &&
          props.startingValues.map(value => value.checkboxId)) ||
        []
    };
    if (props.startingValues) {
      props.startingValues.forEach(value => {
        if (value.inputValue) {
          startingState[value.checkboxId] = value.inputValue;
        }
      });
    }
    this.state = startingState;

    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  render() {
    const {
      checkboxFormValues,
      inputFormValues,
      formRows,
      checkboxLabel,
      inputLabel
    } = this.props;

    return (
      <div className="checkbox-input-row">
        <div className="checkbox-input-col">
          <div role="group" className="checkbox-input-group">
            <ul className="none interactive checkbox-list">
              <legend id="checkbox-legend">{checkboxLabel}</legend>
              {formRows.map((item, index) => {
                let checkboxId = item.id;
                return (
                  <div key={index}>
                    <Checkbox
                      domId={checkboxId + '_' + index}
                      label={checkboxId}
                      id={{ valueId: checkboxId }}
                      value={this.isSelected(checkboxId)}
                      controlFunc={this.handleCheckboxChange}
                      hiddenLabel={true}
                      liClassName="checkboxComponent"
                    >
                      <div className="content">
                        <span className="checkbox-name">{item.name}</span>
                      </div>
                    </Checkbox>
                    {this.state.isInvalid && (
                      <div
                        id={checkboxFormValues.name + '-error'}
                        className="input-error"
                      >
                        {checkboxFormValues.validatorFunc().errorMessage}
                      </div>
                    )}
                  </div>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="checkbox-input-col">
          <div role="group" className="checkbox-input-group">
            <legend
              className={
                !this.state.selectedCheckboxes.length > 0
                  ? 'visually-hidden'
                  : null
              }
              id="input-label"
            >
              {inputLabel}
            </legend>
            {formRows.map((item, index) => {
              let inputId = item.id;
              let inputFormValue = {
                ...inputFormValues,
                name: inputId,
                valueId: inputId,
                labelTitle: item.name + ' ' + inputFormValues.labelTitle,
                defaultValue: this.state[inputId] || ''
              };
              return (
                <div key={index} className="checkbox-input-list">
                  {this.state.selectedCheckboxes.includes(inputId) && (
                    <Input
                      formValue={inputFormValue}
                      controlFunc={this.handleValueChange}
                      hideLabel={true}
                      immediatelyValidate={false}
                      trimWhitespaces={true}
                      optional={this.props.inputOptional}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  isSelected(id) {
    const elementIndex = this.state.selectedCheckboxes.findIndex(element => {
      return element === id;
    });
    return elementIndex >= 0 ? 'checked' : '';
  }

  update(inputValues) {
    this.props.controlFunc(inputValues);
  }

  handleCheckboxChange(identifier) {
    const { selectedCheckboxes } = this.state;

    let inputValues = selectedCheckboxes.map(el => {
      return { checkboxValue: el, inputValue: this.state[el] };
    });

    let checkboxIndex = selectedCheckboxes.findIndex(element => {
      return identifier.valueId === element;
    });

    if (checkboxIndex >= 0) {
      // unselect
      let arrayCopy = [...selectedCheckboxes];
      arrayCopy.splice(checkboxIndex, 1);
      inputValues = arrayCopy.map(el => {
        return { checkboxValue: el, inputValue: this.state[el] };
      });
      this.setState(state => ({
        selectedCheckboxes: arrayCopy,
        isInvalid: state.selectedCheckboxes.length > 0 && arrayCopy.length === 0
      }));
    } else {
      // select
      inputValues = inputValues.concat([
        {
          checkboxValue: identifier.valueId,
          inputValue: this.state[identifier.valueId]
        }
      ]);
      this.setState(state => ({
        isInvalid: false,
        selectedCheckboxes: state.selectedCheckboxes.concat([
          identifier.valueId
        ])
      }));
    }

    this.props.controlFunc(inputValues);
  }

  handleValueChange(formValue) {
    const { selectedCheckboxes } = this.state;
    let inputValues = selectedCheckboxes.map(el => {
      return {
        checkboxValue: el,
        inputValue: formValue.valueId === el ? formValue.value : this.state[el]
      };
    });

    this.setState({
      [formValue.valueId]: formValue.value
    });
    this.update(inputValues);
  }
}

export default CheckboxInput;
