import React from 'react';
import PropTypes from 'prop-types';
import './ViewSelector.scss';
import { withRouter } from 'react-router-dom';

class ViewSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: this.props.defaultSelected
    };
  }

  render() {
    const { viewTuples, selectorStyle } = this.props;
    return (
      <div className="hash-selector" style={selectorStyle}>
        {viewTuples.map(viewTuple => {
          return (
            <React.Fragment key={viewTuple.id}>
              <div
                tabIndex="0"
                onClick={() => this.changeView(viewTuple)}
                onKeyDown={event => this.changeViewKeyed(event, viewTuple)}
                className={
                  this.getViewValue(viewTuple) === viewTuple.id
                    ? 'active'
                    : undefined
                }
              >
                <span>{viewTuple.label}</span>
              </div>
              {viewTuple.lastBeforePipe && (
                <div className="pipe-separator"></div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  getViewValue(newView) {
    if (this.props.useLocalStorage) {
      return localStorage.getItem(this.props.storageRoot);
    } else if (newView.useHash) {
      return this.props.location.hash;
    } else {
      return this.state.selected;
    }
  }

  changeView(newView) {
    if (this.props.useLocalStorage) {
      localStorage.setItem(this.props.storageRoot, newView.id);
      this.setState({
        updated: ((this.state && this.state.updated) || 0) + 1
      });
    } else {
      this.setState({
        selected: newView.id
      });
      let location = newView.useHash
        ? `${this.props.location.pathname}${newView.id}`
        : `${this.props.location.pathname}`;
      this.props.history.push(location);
    }
    if (this.props.callbackFunction) {
      this.props.callbackFunction(newView);
    }
  }

  changeViewKeyed(event, newView) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.changeView(newView);
    }
  }
}

ViewSelector.propTypes = {
  viewTuples: PropTypes.array.isRequired,
  useLocalStorage: PropTypes.bool,
  storageRoot: PropTypes.string,
  callbackFunction: PropTypes.func,
  groups: PropTypes.array,
  defaultSelected: PropTypes.string,
  selectorStyle: PropTypes.object
};

ViewSelector.defaultProps = {
  useLocalStorage: false,
  storageRoot: 'VIEW_DEFAULT'
};

export default withRouter(ViewSelector);
