import React from 'react';
import PropTypes from 'prop-types';
import './Accordion.scss';
import { setExpanded } from '../../../service/actions/accordion';
import transitionEndEvent from '../helpers/transitionEndEvent';
import dropdownSvg from '../../../assets/images/angle-down-solid.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { connect } from 'react-redux';

class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: props.startExpanded || false,
      showDeleteOptions: false,
      showDeleteButtons: false,
      isAnimating: false
    };
    this.contentWrapperRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      maxHeight: this.contentWrapperRef.current.scrollHeight
    });

    this.contentWrapperRef.current.addEventListener(
      transitionEndEvent(),
      () => {
        this.setState({ isAnimating: false });
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    var that = this;
    if (prevProps !== this.props) {
      this.setState({
        maxHeight: this.contentWrapperRef.current.scrollHeight
      });
      if (prevProps.selectorTouchCount !== this.props.selectorTouchCount) {
        that.toggleExpandAll(this.props.expandAll);
      }
    }
    if (prevState.showDeleteOptions !== this.state.showDeleteOptions) {
      setTimeout(function() {
        that.toggleButtons();
      }, 650);
    }
    if (prevState.isExpanded !== this.state.isExpanded) {
      if (this.state.isExpanded) {
        this.contentWrapperRef.current.focus();
      }
    }
  }

  render() {
    const {
      errorMessage,
      heading,
      headingAlt,
      content,
      enableDeletion,
      deleteLabel,
      deleteFunction,
      isRemoved,
      validationStatus
    } = this.props;
    const {
      isExpanded,
      isAnimating,
      showDeleteOptions,
      showDeleteButtons
    } = this.state;

    const maxHeight = this.state.maxHeight || '500px';

    return (
      <>
        {errorMessage && (
          <div className="plan-of-study-error-message error-message">
            {errorMessage}
          </div>
        )}
        <div
          className={
            'accordion' +
            (enableDeletion ? ' deletion-enabled' : '') +
            (showDeleteOptions ? ' shift-left ' : '') +
            (isRemoved ? ' is-removed ' : '')
          }
        >
          <div
            className={
              'accordion-wrapper' + (enableDeletion ? ' deletion-enabled' : '')
            }
          >
            <div
              className={
                'heading' +
                (isExpanded ? ' expanded' : ' collapsed') +
                ' ' +
                validationStatus
              }
              onClick={() => this.toggleVisibility()}
            >
              {heading}
              <img
                src={dropdownSvg}
                alt={
                  (isExpanded ? 'Collapse accordion' : 'Expand accordion') +
                  ' for ' +
                  headingAlt
                }
                tabIndex="0"
                onClick={() => this.toggleVisibility()}
                onKeyDown={event => this.imgKeyPressed(event)}
              />
            </div>
            {enableDeletion && (
              <div className="button-box no-print">
                <div
                  className={
                    'delete-options' +
                    (showDeleteButtons
                      ? ' buttons-visible '
                      : ' buttons-hidden ')
                  }
                >
                  {!showDeleteButtons && (
                    <button
                      type="button"
                      className="circle small delete"
                      onClick={() => this.toggleDelete()}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  )}
                  {showDeleteButtons && (
                    <>
                      <span>{deleteLabel}</span>
                      <button
                        type="button"
                        className="circle small delete"
                        onClick={() => this.toggleDelete()}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                      <button
                        type="button"
                        className="circle small delete"
                        onClick={() => deleteFunction()}
                      >
                        <FontAwesomeIcon icon={faCheck} />
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          <div
            className={
              'accordion-wrapper' + (enableDeletion ? ' deletion-enabled' : '')
            }
          >
            <div
              className={
                'accordion-content-wrapper ' +
                (isExpanded ? ' expanded ' : ' collapsed ') +
                (isAnimating ? ' animating' : '')
              }
              style={isExpanded ? { maxHeight: maxHeight } : undefined}
              ref={this.contentWrapperRef}
              tabIndex="0"
            >
              <div
                className="accordion-content"
                aria-hidden={isExpanded ? 'false' : 'true'}
              >
                {content}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  imgKeyPressed(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.toggleVisibility();
    }
  }

  toggleDelete() {
    this.setState(state => {
      return { showDeleteOptions: !state.showDeleteOptions };
    });
  }

  toggleVisibility() {
    this.setState(state => {
      return { isExpanded: !state.isExpanded };
    }, this.props.setExpanded({ id: this.props.accordionId, isExpanded: !this.state.isExpanded }));
  }

  toggleExpandAll(val) {
    this.setState({
      isExpanded: val
    });
  }

  toggleButtons() {
    this.setState(state => {
      return { showDeleteButtons: !state.showDeleteButtons };
    });
  }
}

Accordion.propTypes = {
  heading: PropTypes.element.isRequired,
  content: PropTypes.element.isRequired,
  headingAlt: PropTypes.string.isRequired,
  startExpanded: PropTypes.bool
};

Accordion.defaultProps = {
  startExpanded: false
};

const mapStateToProps = ({ accordion }) => {
  return {
    expandAll: accordion.expandAll,
    expandedItems: accordion.expandedItems,
    selectorTouchCount: accordion.selectorTouchCount
  };
};

export default connect(mapStateToProps, { setExpanded })(Accordion);
