import React from 'react';
// import Input from '../common/Input';
// import Select from '../common/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import FormReveal from '../common/FormReveal';
import { connect } from 'react-redux';
import { addLocation } from '../../service/actions/locations';
import {
  showCustomJSXModal,
  showValidatingModal,
  hideModal
} from '../../service/actions/modal';
import { ADD_LOCATION } from '../../service/types';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
// import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import LocationForm from './LocationForm';

class AddLocation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormInputValid: false,
      resetting: false,
      building: undefined,
      capacity: undefined,
      type: undefined,
      reRender: 0
    };
    this.formRevealRef = React.createRef();
    this.baseState = { ...this.state };

    this.formValues = {
      building: {
        name: 'building',
        valueId: 'building',
        labelTitle: 'Building And Unit',
        defaultValue: '',
        validatorFunc: value => {
          const isDuplicate =
            value &&
            value !== '' &&
            value.length < 51 &&
            this.props.locations.reduce((aggregator, item) => {
              return (
                aggregator ||
                (item.archived !== true &&
                  (item.name || '').toUpperCase() ===
                    value.toUpperCase().trimEnd())
              );
            }, false);

          if (isDuplicate) {
            return {
              isValid: false,
              errorMessage:
                'Duplicate Location Name found. Please validate your entry.'
            };
          }
          return {
            isValid:
              /^.{1,50}$/.test(value) && value && value.trim().length > 0,
            errorMessage: 'Invalid Building Name'
          };
        }
      },
      capacity: {
        name: 'capacity',
        valueId: 'capacity',
        labelTitle: 'Capacity',
        defaultValue: '',
        validatorFunc: value => ({
          isValid:
            (/^\d{1,4}$/.test(value) && value > 0) ||
            value === undefined ||
            value === '',
          errorMessage: 'Invalid Capacity'
        })
      },
      type: {
        name: 'type',
        valueId: 'type',
        defaultValue: '',
        labelTitle: 'Type',
        validatorFunc: value => ({
          isValid: !!value,
          errorMessage: 'Invalid'
        })
      }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.addingLocation !== this.props.addingLocation) {
      if (!this.props.addingLocation) {
        if (this.props.errorMessage) {
          this.setState(prevProps => {
            return { reRender: prevProps.reRender + 1 };
          });
          this.props.hideModal();
        } else {
          this.props.showCustomJSXModal(
            <div id="success-message" style={{ textAlign: 'center' }}>
              <FontAwesomeIcon icon={faCheckCircle} />
              New Location Added
              <br />
              {`${this.props.newlyAddedLocation.name}`}
            </div>
          );
          this.formRevealRef.current.handleCancelClick(
            document.createEvent('Event')
          );

          setTimeout(() => {
            this.props.hideModal();
          }, 1800);
        }
      } else {
        this.props.showValidatingModal();
      }
    } else if (
      prevState.resetting !== this.state.resetting &&
      this.state.resetting
    ) {
      this.setState({
        resetting: false
      });
    }
  }

  handleValueChange = formValue => {
    this.setState({
      [formValue.valueId]: formValue.value,
      isFormInputValid: Object.keys(this.formValues).reduce((result, next) => {
        let theResult;
        if (next === formValue.valueId) {
          theResult =
            result &&
            this.formValues[next].validatorFunc(formValue.value).isValid;
        } else {
          theResult =
            result &&
            this.formValues[next].validatorFunc(this.state[next]).isValid;
        }
        if (theResult === false) {
          this.setState(prevProps => {
            return { reRender: prevProps.reRender + 1 };
          });
        }
        return theResult;
      }, true)
    });
  };

  resetForm = () => {
    const newState = {
      ...this.baseState,
      resetting: true
    };
    Object.values(this.formValues).forEach(key => {
      newState[key.valueId] = undefined;
    });
    this.setState(newState);
  };

  validateFormInput = () => {
    this.setState({
      isFormInputValid: Object.keys(this.formValues).reduce((result, next) => {
        let theResult =
          result &&
          this.formValues[next].validatorFunc(this.state[next]).isValid;
        return theResult;
      }, true)
    });
  };

  handleSubmitClick = e => {
    e.preventDefault();
    this.validateFormInput();
    const { building, capacity, type } = this.state;

    const newLocation = {
      name: building,
      capacity: parseInt(capacity) || undefined,
      type: this.props.typeList.find(tp => tp.name === type).id
    };
    this.props.addLocation(newLocation);
  };

  handleCancelClick = e => {
    e.preventDefault();
    this.formRevealRef.current.handleCancelClick(e);
  };

  keyHandler = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (e.target.id === 'cancel-add-location') {
        this.handleCancelClick(e);
      } else {
        this.handleSubmitClick(e);
      }
    }
  };

  renderAddLocationForm = () => {
    const { resetting, reRender, isFormInputValid } = this.state;
    const { typeList, errorMessage } = this.props;
    const optionNames = typeList.map(type => type.name);
    // deletes input values by unmounting this component
    if (resetting) return null;
    return (
      <LocationForm
        formValues={this.formValues}
        isFormInputValid={isFormInputValid}
        reRender={reRender}
        optionNames={optionNames}
        errorMessage={errorMessage}
        handleValueChange={e => this.handleValueChange(e)}
        handleCancelClick={e => this.handleCancelClick(e)}
        handleSubmitClick={e => this.handleSubmitClick(e)}
        keyHandler={e => this.keyHandler(e)}
        cancelId={'cancel-add-location'}
        submitId={'submit-add-location'}
        action="adding"
      >
        Add a Location
      </LocationForm>
    );
  };

  render() {
    const form = this.renderAddLocationForm();
    if (!form) return null;
    return (
      <FormReveal
        buttonText="Add a Location"
        buttonId="add-location-btn"
        form={form}
        cancelFunc={() => this.resetForm()}
        rerenderCounter={this.state.reRender}
        ref={this.formRevealRef}
      />
    );
  }
}

const addingSelector = createLoadingSelector([ADD_LOCATION]);
const errorSelector = createErrorMessageSelector([ADD_LOCATION]);

const mapStateToProps = state => {
  const {
    config: { config },
    locations: { list, newest }
  } = state;

  return {
    addingLocation: addingSelector(state),
    errorMessage: errorSelector(state),
    typeList: config && config.locationTypes.filter(loc => loc.active),
    newlyAddedLocation: newest,
    locations: list
  };
};

export default connect(mapStateToProps, {
  addLocation,
  showCustomJSXModal,
  showValidatingModal,
  hideModal
})(AddLocation);
