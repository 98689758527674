import {
  GET_PLANS_OF_STUDY,
  ADD_PLAN_OF_STUDY,
  GET_PLAN_OF_STUDY,
  PUT_PLAN_OF_STUDY_TERMS,
  UPDATE_PLAN_OF_STUDY,
  ARCHIVE_PLAN_OF_STUDY
} from '../types';
import {
  generateRequestActionTypeName,
  generateSuccessActionTypeName
} from '../actionNamingMethods';

export default (state = {}, action) => {
  switch (action.type) {
    case generateSuccessActionTypeName(GET_PLANS_OF_STUDY):
      return {
        ...state,
        list: [].concat(
          action.payload.plansOfStudy.filter(val => val.archived !== true)
        )
      };

    case generateSuccessActionTypeName(ADD_PLAN_OF_STUDY):
      return {
        ...state,
        list: [action.payload].concat(state.list),
        newest: action.payload
      };

    case generateRequestActionTypeName(GET_PLAN_OF_STUDY):
      return {
        ...state,
        viewIndividual: null
      };

    case generateSuccessActionTypeName(GET_PLAN_OF_STUDY):
      return {
        ...state,
        viewIndividual: action.payload
      };

    case generateSuccessActionTypeName(UPDATE_PLAN_OF_STUDY):
      return {
        ...state,
        viewIndividual: action.payload,
        list: state.list.map(item => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        })
      };

    case generateSuccessActionTypeName(ARCHIVE_PLAN_OF_STUDY):
      return {
        ...state,
        viewIndividual: undefined,
        list: state.list.filter(val => val.id !== action.payload.planOfStudyId)
      };

    case generateSuccessActionTypeName(PUT_PLAN_OF_STUDY_TERMS):
      return {
        ...state,
        viewIndividual: { ...state.viewIndividual, terms: action.payload }
      };

    default:
      return state;
  }
};
