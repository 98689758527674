import React from 'react';
import './StudentGroupView.scss';
import { connect } from 'react-redux';
import {
  getStudentGroup,
  updateStudentGroup,
  deleteStudentGroup
} from '../../service/actions/studentGroups';
import { getScheduleList } from '../../service/actions/schedules';
import {
  showLoadingModal,
  hideModal,
  showCustomLoadingModal,
  showCustomJSXModal
} from '../../service/actions/modal';
import { withRouter } from 'react-router-dom';
import SelectPlanOfStudy, {
  filterPlanOfStudyList
} from '../common/SelectPlanOfStudy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import StudentGroupTerms from './StudentGroupTerms';
import updatePageTitle from '../common/helpers/updatePageTitle';
import { ValidationMessage } from '../common/Validation/validationFunctionComponent';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import {
  GET_STUDENT_GROUPS,
  GET_SCHEDULES,
  GET_STUDENT_GROUP,
  GET_CONFIG,
  PUT_STUDENT_GROUP_ENROLLMENT,
  PUT_STUDENT_GROUP_TERM_COURSES,
  PUT_STUDENT_GROUP,
  DELETE_STUDENT_GROUP
} from '../../service/types';
import { mainViewComponent } from '../common/HOCs';

const formValues = {
  planOfStudyId: {
    name: 'plan-of-study-select',
    valueId: 'planOfStudyId',
    defaultValue: 'Select',
    labelTitle: 'Plan of Study',
    validatorFunc: value => ({
      isValid: value && value !== 'Select',
      errorMessage: 'Invalid plan of study selected'
    })
  }
};

class StudentGroupView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      isFormInputValid: false
    };
    this.baseState = { ...this.state };

    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  componentDidMount() {
    updatePageTitle('Student Group: ' + this.props.match.params.studentGroupId);
    this.props.getStudentGroup(this.props.match.params.studentGroupId);
    if (!this.props.schedules) {
      this.props.getScheduleList();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deletingStudentGroup !== this.props.deletingStudentGroup) {
      this.props.showCustomLoadingModal('Deleting Student Group...');
      if (!this.props.deletingStudentGroup) {
        if (this.props.deleteError) {
          this.props.hideModal();
        } else {
          this.props.showCustomJSXModal(
            <div id="success-message" style={{ textAlign: 'center' }}>
              <FontAwesomeIcon icon={faCheckCircle} />
              Student Group Deleted
            </div>
          );
          setTimeout(() => {
            this.props.history.push('/student-groups');
          }, 600);
        }
      }
    } else if (prevProps.updating !== this.props.updating) {
      if (this.props.updating) {
        this.props.showLoadingModal();
      } else {
        this.props.getStudentGroup(this.props.match.params.studentGroupId);
      }
    }
  }
  render() {
    const {
      studentGroup,
      errorMessage,
      loading,
      scopes,
      deleteError
    } = this.props;

    if (loading) {
      return null;
    }

    if (errorMessage) {
      return (
        <div className="student-group-error-message error-message">
          {errorMessage}
        </div>
      );
    }

    if (!studentGroup) {
      return null;
    }

    let studentGroupName = null;
    if (studentGroup.name) {
      studentGroupName = (
        <span className="student-group-name"> | {studentGroup.name}</span>
      );
    }
    return (
      <div className="main student-group-view">
        {studentGroup.state === 'completed' && (
          <div className="label state status-completed-bg">Completed</div>
        )}
        <div className="top-container">
          <div id="student-group-info" className="left-bar first-focus">
            <h1 id="program-name" className="no-bar">
              {studentGroup.program.name}
            </h1>
            {studentGroup.concentration && (
              <h3 id="concentration-name">{studentGroup.concentration.name}</h3>
            )}
            <p id="plan-of-study-meta">
              {`${studentGroup.track} | `}
              {`${studentGroup.startTerm}\u00A0${studentGroup.startYear}`}
              {studentGroup.planOfStudyId &&
                `\u2013${studentGroup.endTerm}\u00A0${studentGroup.endYear}`}
              {studentGroupName}
            </p>
          </div>
          {studentGroup.canDelete && scopes.includes('student-group.write') && (
            <div className="right">{this.renderDeleteOptions()}</div>
          )}
        </div>
        {studentGroup.canDelete &&
          scopes.includes('student-group.write') &&
          deleteError && (
            <div className="student-group-error-message error-message">
              {deleteError}
            </div>
          )}
        {this.renderPlanOfStudy()}
      </div>
    );
  }

  renderPlanOfStudy() {
    const { scopes, studentGroup } = this.props;
    if (!studentGroup) {
      return null;
    }

    const writeAuthorized = scopes.includes('student-group.write');
    let errorMessage = 'No plan of study assigned.';
    if (writeAuthorized) {
      errorMessage += ' Please assign one below.';
    }
    let isDriftedWarning =
      'This student group’s plan of study differs from the original plan of study.';
    return (
      <section>
        <div className="top-container">
          <h1>Plan of Study</h1>
        </div>
        {studentGroup.planOfStudy && studentGroup.planOfStudy.isDrifted && (
          <ValidationMessage message={isDriftedWarning} type={'info'} />
        )}
        {studentGroup.planOfStudyId ? (
          <StudentGroupTerms studentGroup={studentGroup} />
        ) : (
          <>
            <div className="error-notice">
              <span>{errorMessage}</span>
            </div>
            {writeAuthorized && this.renderSelectPlanOfStudy()}
          </>
        )}
      </section>
    );
  }

  renderSelectPlanOfStudy() {
    const { studentGroup, planOfStudyList } = this.props;
    if (!planOfStudyList) {
      return null;
    }
    const filterCriteria = {
      programId: studentGroup.program && studentGroup.program.id,
      concentrationId: studentGroup.concentration
        ? studentGroup.concentration.id
        : undefined,
      track: studentGroup.track,
      startTerm: studentGroup.startTerm,
      startYear: studentGroup.startYear
    };
    const filteredPlanOfStudyList = filterPlanOfStudyList(
      filterCriteria,
      planOfStudyList
    );
    return (
      <form id="set-plan-of-study">
        <div>
          <SelectPlanOfStudy
            planOfStudyFormValue={formValues.planOfStudyId}
            controlFunc={this.handleValueChange}
            planOfStudyList={filteredPlanOfStudyList}
            optional={true}
          />
        </div>
        <div className="buttons">
          <button
            id="submit-pos-assignment"
            onClick={event => this.handleSubmitClick(event)}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                this.handleSubmitClick(event);
              }
            }}
            tabIndex={this.state.isFormInputValid ? 0 : -1}
            disabled={!this.state.isFormInputValid}
          >
            Assign Plan of Study <FontAwesomeIcon icon={faCheckCircle} />
          </button>
        </div>
      </form>
    );
  }

  renderDeleteOptions() {
    const { showDeleteButtons } = this.state;
    let deleteLabel = 'Delete Student Group?';
    return (
      <div className="button-box">
        <div
          className={
            'delete-options' +
            (showDeleteButtons ? ' buttons-visible ' : ' buttons-hidden ')
          }
        >
          {!showDeleteButtons && (
            <button
              onClick={() => this.toggleDeleteButtons()}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  this.toggleDeleteButtons();
                }
              }}
              id="toggleDeleteOption"
            >
              Delete Student Group
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          )}
          {showDeleteButtons && (
            <>
              <span>{deleteLabel}</span>
              <button
                type="button"
                className="circle small delete"
                onClick={() => this.toggleDeleteButtons()}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <button
                type="button"
                className="circle small delete"
                onClick={() => this.deleteStudentGroup()}
              >
                <FontAwesomeIcon icon={faCheck} />
              </button>
            </>
          )}
        </div>
      </div>
    );
  }

  toggleDeleteButtons() {
    this.setState(prevState => ({
      showDeleteButtons: !prevState.showDeleteButtons
    }));
  }

  deleteStudentGroup() {
    const { studentGroup, deleteStudentGroup } = this.props;
    deleteStudentGroup(studentGroup.id);
  }

  handleValueChange(formValue) {
    let currentFormState = Object.keys(formValues).reduce((result, next) => {
      switch (next) {
        case formValue.valueId:
          result[formValue.valueId] = formValue.value;
          break;
        default:
          result[next] = this.state[next];
      }
      return result;
    }, {});

    const isFormInputValid = Object.keys(formValues).reduce((result, next) => {
      return (
        result && formValues[next].validatorFunc(currentFormState[next]).isValid
      );
    }, true);

    this.setState({
      [formValue.valueId]: formValue.value,
      isFormInputValid: isFormInputValid
    });
  }

  handleSubmitClick(event) {
    event.preventDefault();

    const { studentGroup } = this.props;
    const { planOfStudyId } = this.state;
    if (!planOfStudyId) {
      alert('error');
    }
    this.props.updateStudentGroup(studentGroup.id, { planOfStudyId });
  }
}

const loadingSelector = createLoadingSelector([
  GET_STUDENT_GROUPS,
  GET_STUDENT_GROUP,
  GET_SCHEDULES,
  GET_CONFIG
]);
const updatingSelector = createLoadingSelector([
  PUT_STUDENT_GROUP_ENROLLMENT,
  PUT_STUDENT_GROUP,
  PUT_STUDENT_GROUP_TERM_COURSES
]);
const viewErrorSelector = createErrorMessageSelector([GET_STUDENT_GROUP]);
const deletingSelector = createLoadingSelector([DELETE_STUDENT_GROUP]);
const deleteErrorSelector = createErrorMessageSelector([DELETE_STUDENT_GROUP]);
const mapStateToProps = state => {
  const { auth, studentGroups, plansOfStudy } = state;
  return {
    scopes: auth.scopes,
    loading: loadingSelector(state),
    updating: updatingSelector(state),
    errorMessage: viewErrorSelector(state),
    deletingStudentGroup: deletingSelector(state),
    deleteError: deleteErrorSelector(state),
    planOfStudyList: plansOfStudy.list,
    studentGroup: studentGroups.viewIndividual
  };
};

const mainViewConfig = {
  pageTitle: 'View Student Group'
};

export default connect(mapStateToProps, {
  getStudentGroup,
  getScheduleList,
  updateStudentGroup,
  deleteStudentGroup,
  showLoadingModal,
  showCustomJSXModal,
  showCustomLoadingModal,
  hideModal
})(
  withRouter(mainViewComponent({ component: StudentGroupView, mainViewConfig }))
);
