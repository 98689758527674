import config from '../config';

const CONFIG_ENDPOINT = config.CONFIG_ROOT;
const COURSES_ENDPOINT = config.API_ROOT_CURRICULUM + '/courses';
const PROGRAMS_ENDPOINT = config.API_ROOT_CURRICULUM + '/programs';
const PLANS_OF_STUDY_ENDPOINT = config.API_ROOT_CURRICULUM + '/plans-of-study';
const STUDENT_GROUPS_ENDPOINT = config.API_ROOT_CURRICULUM + '/student-groups';
const FACULTY_MEMBERS_ENDPOINT = config.API_ROOT_FACULTY + '/faculty-members';
const FACULTY_DEFAULT_TEACHING_EFFORT_ENDPOINT =
  config.API_ROOT_FACULTY + '/default-teaching-effort';
const FACULTY_PROPOSED_TEACHING_ENDPOINT =
  config.API_ROOT_FACULTY + '/proposed-teaching';
const SCHEDULES_ENDPOINT = config.API_ROOT_SCHEDULE + '/schedules';
const SCHEDULES_WITH_COURSE_ENDPOINT =
  config.API_ROOT_SCHEDULE + '/schedules-with-course';
const LOCATIONS_ENDPOINT = config.API_ROOT_SCHEDULE + '/locations';

export const getConfigEndpoint = () => {
  return CONFIG_ENDPOINT;
};
export const getCoursesEndpoint = () => {
  return COURSES_ENDPOINT;
};
export const getCourseEndpoint = courseId => {
  return COURSES_ENDPOINT + `/${courseId}`;
};
export const getCourseSchedulesEndpoint = courseId => {
  return SCHEDULES_WITH_COURSE_ENDPOINT + `/${courseId}`;
};
export const addCourseEndpoint = () => {
  return COURSES_ENDPOINT;
};

export const archiveCourseEndpoint = courseId => {
  return `${COURSES_ENDPOINT}/${courseId}`;
};
export const updateCourseEndpoint = courseId => {
  return COURSES_ENDPOINT + `/${courseId}`;
};
export const updateCourseComponentsEndpoint = courseId => {
  return COURSES_ENDPOINT + `/${courseId}/components`;
};
export const getPlanOfStudyListEndpoint = () => {
  return PLANS_OF_STUDY_ENDPOINT;
};
export const addPlanOfStudyEndpoint = () => {
  return PLANS_OF_STUDY_ENDPOINT;
};
export const getPlanOfStudyEndpoint = planOfStudyId => {
  return PLANS_OF_STUDY_ENDPOINT + `/${planOfStudyId}`;
};
export const updatePlanOfStudyEndpoint = planOfStudyId => {
  return PLANS_OF_STUDY_ENDPOINT + `/${planOfStudyId}`;
};
export const putPlanOfStudyTermsEndpoint = planOfStudyId => {
  return PLANS_OF_STUDY_ENDPOINT + `/${planOfStudyId}/terms`;
};
export const getProgramsEndpoint = () => {
  return PROGRAMS_ENDPOINT;
};
export const getScheduleEndpoint = (year, term) => {
  return SCHEDULES_ENDPOINT + `/${year}/${term}`;
};
export const getScheduleProjectedEnrollmentEndpoint = (year, term) => {
  return SCHEDULES_ENDPOINT + `/${year}/${term}/projected-enrollment`;
};
export const getScheduleNotesEndpoint = (year, term) => {
  return SCHEDULES_ENDPOINT + `/${year}/${term}/notes`;
};
export const setScheduleCourseNotesEndpoint = (year, term, courseId) => {
  return SCHEDULES_ENDPOINT + `/${year}/${term}/courses/${courseId}/notes`;
};
export const postScheduleStateChangeEndpoint = (year, term) => {
  return SCHEDULES_ENDPOINT + `/${year}/${term}/state-change`;
};
export const addCourseToScheduleEndpoint = (year, term) => {
  return SCHEDULES_ENDPOINT + `/${year}/${term}/courses`;
};
export const deleteScheduleCourseEndpoint = (year, term, courseId) => {
  return SCHEDULES_ENDPOINT + `/${year}/${term}/courses/${courseId}`;
};
export const getScheduleListEndpoint = () => {
  return SCHEDULES_ENDPOINT;
};
export const getStudentGroupListEndpoint = () => {
  return STUDENT_GROUPS_ENDPOINT;
};
export const addStudentGroupEndpoint = () => {
  return STUDENT_GROUPS_ENDPOINT;
};
export const updateStudentGroupEndpoint = studentGroupId => {
  return STUDENT_GROUPS_ENDPOINT + `/${studentGroupId}`;
};
export const updateStudentGroupPlanOfStudyEndpoint = studentGroupId => {
  return STUDENT_GROUPS_ENDPOINT + `/${studentGroupId}/plan-of-study`;
};
export const updateStudentGroupEnrollmentEndpoint = studentGroupId => {
  return STUDENT_GROUPS_ENDPOINT + `/${studentGroupId}/enrollment`;
};
export const getStudentGroupEndpoint = studentGroupId => {
  return STUDENT_GROUPS_ENDPOINT + `/${studentGroupId}`;
};
export const addFacultyMemberEndpoint = () => {
  return FACULTY_MEMBERS_ENDPOINT;
};
export const getExportWorkAssignmentsEndpoint = academicYear => {
  return config.API_ROOT_FACULTY + `/faculty-work-summary/${academicYear}`;
};
export const updateFacultyMemberEndpoint = id => {
  return FACULTY_MEMBERS_ENDPOINT + `/${id}`;
};
export const getFacultyMembersEndpoint = () => {
  return FACULTY_MEMBERS_ENDPOINT;
};
export const getFacultyMemberEndpoint = facultyMemberId => {
  return FACULTY_MEMBERS_ENDPOINT + `/${facultyMemberId}`;
};
export const getFacultyMemberNotesEndpoint = facultyMemberId => {
  return FACULTY_MEMBERS_ENDPOINT + `/${facultyMemberId}/notes`;
};
export const getFacultyProposedTeachingEndpoint = (year, term) => {
  return FACULTY_PROPOSED_TEACHING_ENDPOINT + `/${year}/${term}`;
};
export const getFacultyMemberWorkPlanEndpoint = (
  facultyMemberId,
  academicYear
) => {
  return (
    FACULTY_MEMBERS_ENDPOINT + `/${facultyMemberId}/work-plans/${academicYear}`
  );
};
export const putWorkPlanCurrentYearEndpoint = facultyMemberId => {
  return (
    FACULTY_MEMBERS_ENDPOINT + `/${facultyMemberId}/work-plans/current-year`
  );
};

export const putAllWorkPlansCurrentYearEndpoint = () => {
  return FACULTY_MEMBERS_ENDPOINT + '/work-plans/current-year';
};
export const putWorkplanAllocationEndpoint = (
  facultyMemberId,
  academicYear
) => {
  return (
    FACULTY_MEMBERS_ENDPOINT +
    `/${facultyMemberId}/work-plans/${academicYear}/allocation`
  );
};
export const getDefaultTeachingEffortEndpoint = () => {
  return FACULTY_DEFAULT_TEACHING_EFFORT_ENDPOINT;
};
export const setDefaultTeachingEffortEndpoint = () => {
  return FACULTY_DEFAULT_TEACHING_EFFORT_ENDPOINT;
};
export const addFacultyMemberWorkItemEndpoint = (
  facultyMemberId,
  academicYear
) => {
  return (
    FACULTY_MEMBERS_ENDPOINT +
    `/${facultyMemberId}/work-plans/${academicYear}/work-items`
  );
};
export const updateFacultyMemberWorkItemEndpoint = (
  facultyMemberId,
  academicYear,
  workItemId
) => {
  return (
    FACULTY_MEMBERS_ENDPOINT +
    `/${facultyMemberId}/work-plans/${academicYear}/work-items/${workItemId}`
  );
};
export const deleteFacultyMemberWorkItemEndpoint = (
  facultyMemberId,
  academicYear,
  workItemId
) => {
  return (
    FACULTY_MEMBERS_ENDPOINT +
    `/${facultyMemberId}/work-plans/${academicYear}/work-items/${workItemId}`
  );
};
export const addFacultyMemberOtherTeachingItemEndpoint = (
  facultyMemberId,
  academicYear
) => {
  return (
    FACULTY_MEMBERS_ENDPOINT +
    `/${facultyMemberId}/work-plans/${academicYear}/other-teaching-items`
  );
};
export const deleteFacultyMemberOtherTeachingItemEndpoint = (
  facultyMemberId,
  academicYear,
  otherTeachingItemId
) => {
  return (
    FACULTY_MEMBERS_ENDPOINT +
    `/${facultyMemberId}/work-plans/${academicYear}/other-teaching-items/${otherTeachingItemId}`
  );
};
export const prospectiveCourseEndpoint = (
  facultyMemberId,
  academicYear,
  term,
  courseId
) => {
  return (
    FACULTY_MEMBERS_ENDPOINT +
    `/${facultyMemberId}/work-plans/${academicYear}/teaching/${term}/proposed-courses/${courseId}`
  );
};
export const updateAssignedCourseSectionEndpoint = (
  facultyMemberId,
  academicYear,
  term,
  assignedSectionId
) => {
  return (
    FACULTY_MEMBERS_ENDPOINT +
    `/${facultyMemberId}/work-plans/${academicYear}/teaching/${term}/assigned-course-sections/${assignedSectionId}`
  );
};
export const getLocationsEndpoint = () => {
  return LOCATIONS_ENDPOINT;
};
export const addLocationEndpoint = () => {
  return LOCATIONS_ENDPOINT;
};
export const updateLocationEndpoint = locationId => {
  return `${LOCATIONS_ENDPOINT}/${locationId}`;
};
export const archiveLocationEndpoint = locationId => {
  return `${LOCATIONS_ENDPOINT}/${locationId}`;
};
export const addScheduleSectionEndpoint = (
  year,
  term,
  courseId,
  componentType
) => {
  return (
    SCHEDULES_ENDPOINT +
    `/${year}/${term}/courses/${courseId}/components/${componentType}/sections`
  );
};

export const putScheduleSectionEndpoint = (
  year,
  term,
  courseId,
  componentType,
  sectionId
) => {
  return (
    SCHEDULES_ENDPOINT +
    `/${year}/${term}/courses/${courseId}/components/${componentType}/sections/${sectionId}`
  );
};

export const setSectionConfirmationStatusEndpoint = sectionId => {
  return SCHEDULES_ENDPOINT + `/sections/${sectionId}/confirmed`;
};

export const deleteScheduleSectionEndpoint = (
  year,
  term,
  courseId,
  componentType,
  sectionId
) => {
  return (
    SCHEDULES_ENDPOINT +
    `/${year}/${term}/courses/${courseId}/components/${componentType}/sections/${sectionId}`
  );
};
