import React from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';

import ScheduleListView from './ScheduleListView';
import ScheduleView from './ScheduleView';
import NoMatchPage from '../App/404';

const SchedulesPage = props => {
  const { match } = props;
  return (
    <Switch>
      <Route exact path={match.path} component={ScheduleListView} />
      <Route path={match.path + '/:year/:term'} component={ScheduleView} />
      <Route path={match.path + '/*'} component={NoMatchPage} />
    </Switch>
  );
};

export default withRouter(SchedulesPage);
