import {
  SHOW_SIDE_PANEL,
  HIDE_SIDE_PANEL,
  CLEAR_SIDE_PANEL_CONTENT,
  UPDATE_TRAP_FOCUS_PANEL
} from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SHOW_SIDE_PANEL:
      return {
        ...state,
        sidePanelVisible: true,
        content: action.payload.content,
        activatingElement: action.payload.activatingElement,
        keyActivated: action.payload.keyActivated,
        updateFocusTrap: 0
      };

    case HIDE_SIDE_PANEL:
      if (action.payload.sendFocus) {
        if (state.activatingElement) {
          if (state.activatingElement.current) {
            state.activatingElement.current.focus();
          } else {
            if (typeof state.activatingElement.focus === 'function') {
              state.activatingElement.focus();
            }
          }
        }
      }
      return {
        ...state,
        sidePanelVisible: false,
        activatingElement: null,
        keyActivated: null
      };

    case CLEAR_SIDE_PANEL_CONTENT:
      return {
        ...state,
        content: null
      };

    case UPDATE_TRAP_FOCUS_PANEL:
      return {
        ...state,
        updateFocusTrap: (state.updateFocusTrap || 0) + 1
      };

    default:
      return state;
  }
};
