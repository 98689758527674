import {
  UPDATE_FACULTY_MEMBER,
  GET_FACULTY_MEMBERS,
  ADD_FACULTY_MEMBER,
  PUT_WORKPLAN_CURRYEAR,
  PUT_ALL_WORKPLANS_CURRYEAR,
  GET_FACULTY_MEMBER,
  PUT_WORKPLAN_ALLOCATION,
  GET_FACULTY_MEMBER_WORKPLAN,
  GET_DEFAULT_TEACHING_EFFORT,
  SET_DEFAULT_TEACHING_EFFORT,
  GET_WORKPLAN_SCHEDULES,
  SET_WORKPLAN_SECTION_CONFIRMATION_STATUS,
  SET_TEACHING_EFFORT_TOTALS,
  GET_TEACHING_ASSIGNMENTS,
  ADD_WORK_ACTIVITY,
  UPDATE_WORK_ACTIVITY,
  ADD_OTHER_TEACHING_ITEM,
  DELETE_OTHER_TEACHING_ITEM,
  ADD_PROSPECTIVE_COURSE,
  DELETE_WORK_ACTIVITY,
  GET_WORKPLAN_SCHEDULE_ENROLLMENT,
  ADD_SECTION_PLACEHOLDER,
  DELETE_SECTION_PLACEHOLDER,
  DELETE_PROSPECTIVE_COURSE,
  UPDATE_ASSIGNED_COURSE_SECTION,
  GET_PROPOSED_TEACHING,
  GET_FACULTY_MEMBER_NOTES,
  SET_FACULTY_MEMBER_NOTES,
  EXPORT_WORK_ASSIGNMENTS
} from '../types';
import {
  getFacultyMembersEndpoint,
  getFacultyMemberEndpoint,
  getFacultyMemberWorkPlanEndpoint,
  addFacultyMemberWorkItemEndpoint,
  addFacultyMemberEndpoint,
  addFacultyMemberOtherTeachingItemEndpoint,
  updateFacultyMemberEndpoint,
  putWorkplanAllocationEndpoint,
  putWorkPlanCurrentYearEndpoint,
  putAllWorkPlansCurrentYearEndpoint,
  getExportWorkAssignmentsEndpoint,
  getScheduleEndpoint,
  getDefaultTeachingEffortEndpoint,
  setDefaultTeachingEffortEndpoint,
  setSectionConfirmationStatusEndpoint,
  prospectiveCourseEndpoint,
  getScheduleProjectedEnrollmentEndpoint,
  updateFacultyMemberWorkItemEndpoint,
  deleteFacultyMemberWorkItemEndpoint,
  deleteFacultyMemberOtherTeachingItemEndpoint,
  updateAssignedCourseSectionEndpoint,
  getFacultyProposedTeachingEndpoint,
  getFacultyMemberNotesEndpoint
} from '../../utils/endpoints';
import { augmentTeachingAssignments } from '../../components/FacultyMembers/augmentTeachingAssignments';
import axios from 'axios';
import Auth from '@aws-amplify/auth';
import requestWrapper from '../requestWrapper';
import {
  generateRequestActionTypeName,
  generateSuccessActionTypeName,
  generateFailureActionTypeName,
  generateClearItemActionTypeName
} from '../actionNamingMethods';

export const addFacultyMember = newFacultyMember => async dispatch => {
  requestWrapper(dispatch, {
    actionType: ADD_FACULTY_MEMBER,
    requestObject: {
      url: addFacultyMemberEndpoint(),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: newFacultyMember,
      responseType: 'json'
    },
    responseDataKey: 'facultyMember'
  });
};

export const exportWorkAssignments = (year, fullYear) => async dispatch => {
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  dispatch({
    type: generateRequestActionTypeName(EXPORT_WORK_ASSIGNMENTS)
  });

  axios(getExportWorkAssignmentsEndpoint(year), {
    headers: {
      Authorization: 'Bearer ' + token,
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    responseType: 'blob',
    data: {}
  })
    .then(result => {
      dispatch({
        type: generateSuccessActionTypeName(EXPORT_WORK_ASSIGNMENTS),
        payload: result.data
      });

      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Faculty_Member_Work_Assignments_${fullYear}.xlsx`
      );
      document.body.appendChild(link);
      if (window.Cypress) {
        return;
      }
      link.click();
    })
    .catch(error => {
      dispatch({
        type: generateFailureActionTypeName(EXPORT_WORK_ASSIGNMENTS),
        payload: { error }
      });
    });
};

export const updateFacultyMember = (
  facultyMemberId,
  facultyMemberUpdate
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: UPDATE_FACULTY_MEMBER,
    requestObject: {
      url: updateFacultyMemberEndpoint(facultyMemberId),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      data: facultyMemberUpdate,
      responseType: 'json'
    },
    responsePayload: {
      ...facultyMemberUpdate,
      id: facultyMemberId
    }
  });
};

export const getFacultyMembers = () => async dispatch => {
  requestWrapper(dispatch, {
    actionType: GET_FACULTY_MEMBERS,
    requestObject: {
      url: getFacultyMembersEndpoint(),
      method: 'GET',
      responseType: 'json'
    },
    errorMessage: 'Error retrieving faculty members'
  });
};

export const getFacultyMemberNotes = facultyMemberId => async dispatch => {
  requestWrapper(dispatch, {
    actionType: GET_FACULTY_MEMBER_NOTES,
    requestObject: {
      url: getFacultyMemberNotesEndpoint(facultyMemberId),
      method: 'GET',
      responseType: 'json'
    },
    errorMessage: 'Error retrieving faculty member notes',
    responseDataKey: 'notes'
  });
};

export const setFacultyMemberNotes = (
  facultyMemberId,
  notesObj
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: SET_FACULTY_MEMBER_NOTES,
    requestObject: {
      url: getFacultyMemberNotesEndpoint(facultyMemberId),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      data: notesObj
    },
    errorMessage: 'Error saving faculty member notes',
    responsePayload: notesObj.notes
  });
};

export const getProposedTeaching = (year, term) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: GET_PROPOSED_TEACHING,
    requestObject: {
      url: getFacultyProposedTeachingEndpoint(year, term),
      method: 'GET',
      responseType: 'json'
    },
    responseDataKey: 'proposedTeaching'
  });
};

export const getFacultyMember = facultyMemberId => async dispatch => {
  requestWrapper(dispatch, {
    actionType: GET_FACULTY_MEMBER,
    requestObject: {
      url: getFacultyMemberEndpoint(facultyMemberId),
      method: 'GET',
      responseType: 'json'
    },
    responseDataKey: 'facultyMember',
    customResponseStatusMessages: {
      '404': 'Faculty Member not found.'
    }
  });
};

export const putWorkplanAllocation = (
  facultyMemberId,
  academicYear,
  allocationObj
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: PUT_WORKPLAN_ALLOCATION,
    requestObject: {
      url: putWorkplanAllocationEndpoint(facultyMemberId, academicYear),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      data: allocationObj,
      responseType: 'json'
    }
  });
};

export const putWorkPlanCurrentYear = (
  facultyMemberId,
  newCurrentYear
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: PUT_WORKPLAN_CURRYEAR,
    requestObject: {
      url: putWorkPlanCurrentYearEndpoint(facultyMemberId),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      data: newCurrentYear,
      responseType: 'json'
    }
  });
};

export const putAllWorkPlansCurrentYear = newCurrentYear => async dispatch => {
  requestWrapper(dispatch, {
    actionType: PUT_ALL_WORKPLANS_CURRYEAR,
    requestObject: {
      url: putAllWorkPlansCurrentYearEndpoint(),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      data: newCurrentYear,
      responseType: 'json'
    }
  });
};

export const getFacultyMemberWorkPlan = (
  facultyMemberId,
  academicYear
) => async dispatch => {
  dispatch({
    type: generateRequestActionTypeName(GET_FACULTY_MEMBER_WORKPLAN)
  });

  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  return new Promise(async function(resolve, reject) {
    axios({
      url: getFacultyMemberWorkPlanEndpoint(facultyMemberId, academicYear),
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      },
      responseType: 'json'
    })
      .then(result => {
        dispatch({
          type: generateSuccessActionTypeName(GET_FACULTY_MEMBER_WORKPLAN),
          payload: result.data.workPlan
        });
        resolve(result.data.workPlan);
      })
      .catch(error => {
        dispatch({
          type: generateFailureActionTypeName(GET_FACULTY_MEMBER_WORKPLAN),
          payload: { error }
        });
        reject(error);
      });
  });
};

export const setWorkPlanSectionConfirmationStatus = sectionId => async dispatch => {
  requestWrapper(dispatch, {
    actionType: SET_WORKPLAN_SECTION_CONFIRMATION_STATUS,
    requestObject: {
      url: setSectionConfirmationStatusEndpoint(sectionId),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'json'
    },
    responsePayload: {
      sectionId
    },
    payloadMerge: [
      {
        payloadKey: 'confirmed'
      }
    ]
  });
};

export const getDefaultTeachingEffort = () => async dispatch => {
  requestWrapper(dispatch, {
    actionType: GET_DEFAULT_TEACHING_EFFORT,
    requestObject: {
      url: getDefaultTeachingEffortEndpoint(),
      method: 'GET',
      responseType: 'json'
    },
    responseDataKey: 'defaultTeachingEffort',
    errorMessage: 'Error retrieving teaching effort.'
  });
};

export const setDefaultTeachingEffort = updatedEffort => async dispatch => {
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  requestWrapper(dispatch, {
    actionType: SET_DEFAULT_TEACHING_EFFORT,
    requestObject: {
      url: setDefaultTeachingEffortEndpoint(),
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: updatedEffort,
      responseType: 'json'
    },
    responsePayload: updatedEffort
  });
};

export const getWorkPlanSchedules = (
  academicYear,
  termObjects
) => async dispatch => {
  dispatch({ type: generateRequestActionTypeName(GET_WORKPLAN_SCHEDULES) });
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  return new Promise(async function(resolve, reject) {
    const requestSchedule = async (year, term) => {
      try {
        const response = await axios(getScheduleEndpoint(year, term), {
          headers: {
            Authorization: 'Bearer ' + token
          }
        });
        return response.data;
      } catch (error) {
        if (error && error.response && error.response.status === 404) {
          return true;
        }
        throw error;
      }
    };

    let schedules = [];
    let scheduleError = '';
    try {
      await Promise.all(
        termObjects.map(async termObj => {
          try {
            const scheduleResponse = await requestSchedule(
              academicYear + termObj.calendarYearOffset,
              termObj.name
            );
            if (scheduleResponse && scheduleResponse.schedule) {
              schedules.push(scheduleResponse.schedule);
            }
            return true;
          } catch (error) {
            console.log(error);
            scheduleError = 'At least one schedule failed to load.';
            return false;
          }
        })
      );

      if (scheduleError) {
        dispatch({
          type: generateFailureActionTypeName(GET_WORKPLAN_SCHEDULES),
          payload: scheduleError
        });
        reject(schedules);
      } else {
        dispatch({
          type: generateSuccessActionTypeName(GET_WORKPLAN_SCHEDULES),
          payload: schedules
        });
        resolve(schedules);
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: generateFailureActionTypeName(GET_WORKPLAN_SCHEDULES),
        payload: { error }
      });
      reject(error);
    }
  });
};

export const setTeachingEffortTotals = teachingAssignments => async dispatch => {
  dispatch({ type: generateRequestActionTypeName(SET_TEACHING_EFFORT_TOTALS) });

  const totalEfforts = assignments => {
    return new Promise(resolve => {
      // Total up planned and assigned effort totals
      let plannedEffortTotal = 0;
      let assignedEffortTotal = 0;
      assignments.courses
        .map(c => c.components.map(cm => cm.sections))
        .flat(2)
        .forEach(s => {
          if (s.isAssigned && !Number.isNaN(parseFloat(s.effort))) {
            assignedEffortTotal =
              parseFloat(assignedEffortTotal) + parseFloat(s.effort);
          } else if (!s.isAssigned && !Number.isNaN(parseFloat(s.effort))) {
            plannedEffortTotal =
              parseFloat(plannedEffortTotal) + parseFloat(s.effort);
          }
        });
      resolve({ assigned: assignedEffortTotal, planned: plannedEffortTotal });
    });
  };

  let effortTotals = [];
  let effortTotalsError = '';
  try {
    await Promise.all(
      teachingAssignments.map(async assignments => {
        try {
          const totalEffortsResponse = await totalEfforts(assignments);
          if (totalEffortsResponse) {
            effortTotals.push(totalEffortsResponse);
          }
          return true;
        } catch (error) {
          console.log(error);
          effortTotalsError =
            'At least one teaching effort failed to calculate.';
          return false;
        }
      })
    );

    if (effortTotalsError) {
      console.log(effortTotalsError);
      dispatch({
        type: generateFailureActionTypeName(SET_TEACHING_EFFORT_TOTALS),
        payload: { error: effortTotalsError }
      });
    } else {
      dispatch({
        type: generateSuccessActionTypeName(SET_TEACHING_EFFORT_TOTALS),
        payload: effortTotals
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: generateFailureActionTypeName(SET_TEACHING_EFFORT_TOTALS),
      payload: { error }
    });
  }
};

export const clearFacultyMember = () => async dispatch => {
  dispatch({ type: generateClearItemActionTypeName(GET_FACULTY_MEMBER) });
};

export const getTeachingAssignments = facultyId => async (
  dispatch,
  getState
) => {
  const { faculty, courses, config } = getState();

  const {
    workPlanSchedules,
    defaultTeachingEffort,
    viewWorkPlan,
    workPlanProjectedEnrollment
  } = faculty;
  const { list } = courses;

  dispatch({ type: generateRequestActionTypeName(GET_TEACHING_ASSIGNMENTS) });
  return new Promise(async function(resolve, reject) {
    let teachingAssignments = [];
    let teachingAssignmentsError = '';
    try {
      if (!workPlanSchedules || !viewWorkPlan || !workPlanProjectedEnrollment) {
        return false;
      }
      await Promise.all(
        workPlanSchedules.map(async workPlanSchedule => {
          try {
            const teachingAssignmentResponse = await augmentTeachingAssignments(
              facultyId,
              workPlanSchedule,
              list,
              viewWorkPlan,
              defaultTeachingEffort,
              workPlanProjectedEnrollment,
              config
            );
            if (teachingAssignmentResponse) {
              teachingAssignments.push(teachingAssignmentResponse);
            }
            return true;
          } catch (error) {
            console.log(error);
            teachingAssignmentsError =
              'At least one teaching assignment failed to load.';
            return false;
          }
        })
      );

      if (teachingAssignmentsError) {
        console.log('error!!! trying to get teaching assignments');
        dispatch({
          type: generateFailureActionTypeName(GET_TEACHING_ASSIGNMENTS),
          payload: { error: teachingAssignmentsError }
        });
      } else {
        dispatch({
          type: generateSuccessActionTypeName(GET_TEACHING_ASSIGNMENTS),
          payload: teachingAssignments
        });
        resolve(teachingAssignments);
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: generateFailureActionTypeName(GET_TEACHING_ASSIGNMENTS),
        payload: { error }
      });
      reject(error);
    }
  });
};

export const addNewWorkItem = (
  facultyMemberId,
  academicYear,
  activityObject
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: ADD_WORK_ACTIVITY,
    requestObject: {
      url: addFacultyMemberWorkItemEndpoint(facultyMemberId, academicYear),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: activityObject,
      responseType: 'json'
    },
    responseDataKey: 'workItem',
    errorPayload: activityObject.workType
  });
};

export const updateWorkItem = (
  facultyMemberId,
  academicYear,
  workItemId,
  workItemUpdate
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: UPDATE_WORK_ACTIVITY,
    requestObject: {
      url: updateFacultyMemberWorkItemEndpoint(
        facultyMemberId,
        academicYear,
        workItemId
      ),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      data: workItemUpdate,
      responseType: 'json'
    },
    responsePayload: {
      ...workItemUpdate,
      id: workItemId
    }
  });
};

export const deleteWorkItem = (
  facultyMemberId,
  academicYear,
  workItemId
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: DELETE_WORK_ACTIVITY,
    requestObject: {
      url: deleteFacultyMemberWorkItemEndpoint(
        facultyMemberId,
        academicYear,
        workItemId
      ),
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'json'
    },
    responsePayload: {
      id: workItemId
    }
  });
};

export const addNewOtherTeachingItem = (
  facultyMemberId,
  academicYear,
  otherTeachingObject
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: ADD_OTHER_TEACHING_ITEM,
    requestObject: {
      url: addFacultyMemberOtherTeachingItemEndpoint(
        facultyMemberId,
        academicYear
      ),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: otherTeachingObject,
      responseType: 'json'
    },
    errorPayload: otherTeachingObject.description
  });
};

export const deleteOtherTeachingItem = (
  facultyMemberId,
  academicYear,
  otherTeachingItemId
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: DELETE_OTHER_TEACHING_ITEM,
    requestObject: {
      url: deleteFacultyMemberOtherTeachingItemEndpoint(
        facultyMemberId,
        academicYear,
        otherTeachingItemId
      ),
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'json'
    },
    responsePayload: {
      id: otherTeachingItemId
    }
  });
};

export const addProspectiveCourse = (
  facultyMemberId,
  academicYear,
  term,
  courseId
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: ADD_PROSPECTIVE_COURSE,
    requestObject: {
      url: prospectiveCourseEndpoint(
        facultyMemberId,
        academicYear,
        term,
        courseId
      ),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {},
      responseType: 'json'
    },
    responseDataKey: 'proposedCourse'
  });
};

export const deleteProspectiveCourse = (
  facultyMemberId,
  academicYear,
  term,
  courseId
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: DELETE_PROSPECTIVE_COURSE,
    requestObject: {
      url: prospectiveCourseEndpoint(
        facultyMemberId,
        academicYear,
        term,
        courseId
      ),
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {},
      responseType: 'json'
    },
    responsePayload: { courseId: courseId, term: term },
    errorPayload: {
      id: courseId,
      error: 'Could not delete prospective course.'
    }
  });
};

export const getWorkPlanProjectedEnrollment = (
  academicYear,
  termObjects
) => async dispatch => {
  dispatch({
    type: generateRequestActionTypeName(GET_WORKPLAN_SCHEDULE_ENROLLMENT)
  });
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  return new Promise(async function(resolve, reject) {
    const requestScheduleEnrollment = async (year, term) => {
      try {
        const response = await axios(
          getScheduleProjectedEnrollmentEndpoint(year, term),
          {
            headers: {
              Authorization: 'Bearer ' + token
            }
          }
        );
        return response.data;
      } catch (error) {
        if (error && error.response && error.response.status === 404) {
          return true;
        }
        throw Error;
      }
    };

    let scheduleEnrollment = [];
    var scheduleEnrollmentError;
    try {
      await Promise.all(
        termObjects.map(async termObj => {
          try {
            const scheduleResponse = await requestScheduleEnrollment(
              academicYear + termObj.calendarYearOffset,
              termObj.name
            );
            if (scheduleResponse && scheduleResponse.projectedEnrollment) {
              scheduleEnrollment.push(scheduleResponse.projectedEnrollment);
            }
            return true;
          } catch (error) {
            scheduleEnrollmentError =
              'At least one projected enrollment failed to load.';
            return false;
          }
        })
      );

      if (scheduleEnrollmentError) {
        reject(scheduleEnrollmentError);
        dispatch({
          type: generateFailureActionTypeName(GET_WORKPLAN_SCHEDULE_ENROLLMENT),
          payload: scheduleEnrollmentError
        });
      } else {
        resolve(scheduleEnrollment);
        dispatch({
          type: generateSuccessActionTypeName(GET_WORKPLAN_SCHEDULE_ENROLLMENT),
          payload: scheduleEnrollment
        });
      }
    } catch (error) {
      reject(error);
      dispatch({
        type: generateFailureActionTypeName(GET_WORKPLAN_SCHEDULE_ENROLLMENT),
        payload: { error }
      });
    }
  });
};

export const assignSectionPlaceholder = (
  facultyMemberId,
  academicYear,
  term,
  courseId,
  sectionsObj
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: ADD_SECTION_PLACEHOLDER,
    requestObject: {
      url: prospectiveCourseEndpoint(
        facultyMemberId,
        academicYear,
        term,
        courseId
      ),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      data: sectionsObj,
      responseType: 'json'
    },
    responseDataKey: 'proposedCourse',
    errorPayload: {
      id: courseId,
      error: 'Could not add section placeholder.'
    }
  });
};

export const deleteSectionPlaceholder = (
  facultyMemberId,
  academicYear,
  term,
  courseId,
  sectionsObj
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: DELETE_SECTION_PLACEHOLDER,
    requestObject: {
      url: prospectiveCourseEndpoint(
        facultyMemberId,
        academicYear,
        term,
        courseId
      ),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      data: sectionsObj,
      responseType: 'json'
    },
    responseDataKey: 'proposedCourse',
    errorPayload: { id: courseId, error: 'Could not delete planned section.' }
  });
};

export const updateAssignedCourseSection = (
  facultyMemberId,
  academicYear,
  term,
  scheduleCourseId,
  sectionId,
  sectionsObj
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: UPDATE_ASSIGNED_COURSE_SECTION,
    requestObject: {
      url: updateAssignedCourseSectionEndpoint(
        facultyMemberId,
        academicYear,
        term,
        sectionId
      ),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      data: sectionsObj,
      responseType: 'json'
    },
    responsePayload: {
      scheduleSectionId: sectionId,
      actualEffort: sectionsObj.actualEffort
    },
    errorPayload: {
      id: scheduleCourseId,
      error: 'Could not update planned section.'
    }
  });
};

export const getFacultyColor = faculty => {
  if (faculty.active === false) {
    return 'inactive-faculty-color';
  }

  let number = 0;
  faculty.firstName.split('').forEach(letter => {
    number += letter.charCodeAt(0);
  });
  faculty.lastName.split('').forEach(letter => {
    number += letter.charCodeAt(0);
  });
  number = (number % 10) + 1;
  return `faculty-${number}-color`;
};
