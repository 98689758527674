import React from 'react';
import './Modal.scss';
import { sendFocusToFirstH1 } from './SkipLink';

const FOCUSABLE_ELEMENTS_QUERY =
  'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.trap = this.trap.bind(this);
    this.modalContentRef = React.createRef();
    this.state = {
      lastFocusedItem: undefined
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modalVisible !== this.props.modalVisible) {
      if (this.props.modalVisible) {
        if (this.props.trapFocus) {
          this.setState({
            lastFocusedItem: document.activeElement
          });
          this.trapFocus();
        }
      } else {
        if (this.state && this.state.lastFocusedItem) {
          this.modalContentRef.current.removeEventListener(
            'keydown',
            this.trap
          );

          const focusableEls = this.modalContentRef.current.querySelectorAll(
            FOCUSABLE_ELEMENTS_QUERY
          );
          let isFocusInModal = false;
          focusableEls.forEach(elem => {
            if (elem === this.state.lastFocusedItem) {
              isFocusInModal = true;
            }
          });

          isFocusInModal
            ? sendFocusToFirstH1()
            : this.state.lastFocusedItem.focus();
        }
      }
    }
  }

  render() {
    const { modalVisible } = this.props;
    return (
      <div id="modal-wrapper" className={modalVisible ? 'visible ' : 'hidden '}>
        <div id="modal-bg"></div>
        <div id="modal-contents" ref={this.modalContentRef} tabIndex="0">
          {this.props.children}
        </div>
      </div>
    );
  }

  trap(e) {
    const focusableEls = this.modalContentRef.current.querySelectorAll(
      FOCUSABLE_ELEMENTS_QUERY
    );
    const firstFocusableEl = focusableEls[0];
    const lastFocusableEl = focusableEls[focusableEls.length - 1];

    if (e.key === 'Enter') {
      e.preventDefault();
    }

    if (e.key !== 'Tab') {
      return;
    }

    if (e.shiftKey) {
      if (document.activeElement === firstFocusableEl) {
        lastFocusableEl.focus();
        e.preventDefault();
      }
    } else {
      if (document.activeElement === lastFocusableEl) {
        firstFocusableEl.focus();
        e.preventDefault();
      }
    }
  }

  trapFocus() {
    this.modalContentRef.current.removeEventListener('keydown', this.trap);
    this.modalContentRef.current.addEventListener('keydown', this.trap);
    this.modalContentRef.current.focus();
  }
}

export default Modal;
