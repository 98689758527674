import React from 'react';

const UnauthorizedPage = props => {
  return (
    <div>
      <h3>
        You are not authorized to view this page. Please contact your system
        administrator.
      </h3>
    </div>
  );
};

export default UnauthorizedPage;
