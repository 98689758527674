import { GET_PROGRAMS } from '../types';
import { getProgramsEndpoint } from '../../utils/endpoints';
import requestWrapper from '../requestWrapper';

export const getPrograms = () => async dispatch => {
  requestWrapper(dispatch, {
    actionType: GET_PROGRAMS,
    requestObject: {
      url: getProgramsEndpoint(),
      method: 'GET',
      responseType: 'json'
    },
    responseDataKey: 'programs'
  });
};
