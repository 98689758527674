import React from 'react';
import SchedulewViewCourseSection from './ScheduleViewCourseSection';
import { showSidePanel } from '../../service/actions/sidePanel';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import SectionPanel from './SectionPanel';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import {
  GET_SCHEDULES,
  GET_CONFIG,
  GET_COURSES,
  GET_STUDENT_GROUPS,
  GET_SCHEDULE
} from '../../service/types';

class ScheduleCourseComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addingSection: false
    };

    this.addSectionButton = React.createRef();
  }
  render() {
    const {
      courseComponents,
      schedule,
      component,
      scheduleCourse
    } = this.props;

    const componentTypeIndex =
      courseComponents.findIndex(configComponent => {
        return configComponent.id === component.type;
      }) + 1;

    let componentStudentCapacityText = '';
    if (schedule.state === 'projected') {
      componentStudentCapacityText =
        `${component.sectionCount} section` +
        (component.sectionCount > 1 ? 's' : '') +
        ' with ' +
        (component.perSectionCapacity !== null
          ? `${component.perSectionCapacity} student capacity` +
            (component.sectionCount > 1 ? ' per section' : '')
          : 'no student capacity defined');
    } else {
      componentStudentCapacityText =
        `${component.sections.length} section` +
        (component.sections.length !== 1 ? 's' : '');
    }

    const wrapperClass =
      schedule.state === 'projected'
        ? 'component-wrapper-list-item'
        : 'instructional-component-wrapper';

    return (
      <div key={component.type} className={wrapperClass}>
        <div className="instructional-component-header">
          <div
            className={`instructional-component component-${componentTypeIndex}-bg-light`}
          >
            <div className={`label component-${componentTypeIndex}-bg`}>
              {component.type}
            </div>
            <span>{componentStudentCapacityText}</span>
          </div>
          {schedule.state === 'planning' && this.renderAddSection()}
        </div>

        {schedule.state !== 'projected'
          ? component.sections
              .sort((a, b) => {
                return ('' + a.number).localeCompare(b.number);
              })
              .map(section => {
                return (
                  <SchedulewViewCourseSection
                    key={section.number}
                    section={section}
                    component={component}
                    scheduleCourse={scheduleCourse}
                  />
                );
              })
          : null}
      </div>
    );
  }

  renderAddSection() {
    const { scopes } = this.props;
    if (!scopes.includes('schedule.section.write')) {
      return null;
    }
    return (
      <>
        <button
          className="small"
          ref={this.addSectionButton}
          onClick={() => this.openSidePanel(false)}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              event.preventDefault();
              this.openSidePanel(true);
            }
          }}
          title="Add Section"
        >
          Add Section
          <FontAwesomeIcon icon={faPlusCircle} />
        </button>
      </>
    );
  }

  openSidePanel(keyActivated) {
    const { scheduleCourse, component } = this.props;

    const courseObject = {
      ...scheduleCourse.courseInfo,
      projectedEnrollment: scheduleCourse.projectedEnrollment
    };
    this.props.showSidePanel(
      <SectionPanel
        course={courseObject}
        component={component}
        section={null}
      />,
      this.addSectionButton,
      keyActivated
    );
  }
}

const loadingSelector = createLoadingSelector([
  GET_SCHEDULES,
  GET_CONFIG,
  GET_COURSES,
  GET_STUDENT_GROUPS
]);
const errorSelector = createErrorMessageSelector([GET_SCHEDULE]);
const mapStateToProps = state => {
  const { auth, config, courses, schedules, studentGroups } = state;

  return {
    scopes: auth.scopes,
    loading: loadingSelector(state),
    errorMessage: errorSelector(state),
    courseComponents:
      config && config.config && config.config.courseComponentTypes,
    schedule: schedules.viewIndividual,
    validationList: schedules.validationResults,
    courseList: courses.list,
    termList: config.termsByCalendarYear,
    studentGroupList: studentGroups.list
  };
};

export default connect(
  mapStateToProps,
  { showSidePanel }
)(ScheduleCourseComponent);
