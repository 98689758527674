import React from 'react';
import './CourseListItems.scss';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons/faChevronCircleRight';

const CourseListItems = props => {
  const { courses, selectable } = props;
  const courseKeyed = (event, courseId) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      selectCourse(event, courseId, true);
    }
  };

  const selectCourse = (event, id, keyed) => {
    event.preventDefault();
    const pathname = props.match.path + '/' + id;
    if (event.ctrlKey || event.metaKey) {
      window.open(props.history.createHref({ pathname }));
    } else {
      props.history.push({
        pathname,
        state: {
          keyed
        }
      });
    }
  };
  return []
    .concat(courses)
    .sort((a, b) => {
      if (a.isNew > b.isNew) return 1;
      if (a.isNew < b.isNew) return -1;
      return ('' + a.courseNumber).localeCompare(b.courseNumber);
    })
    .map(item => {
      if (!item) return null;

      const courseContent = (
        <>
          <div className="content">
            <span className="course-number">
              {item.courseNumber.replace(/(\d)/, ' $1')}
            </span>
            <span className="course-name">
              {item.name}
              <span className="credit-hours">
                ({item.variableCredit ? 'Variable' : item.credits} CR)
              </span>
            </span>
          </div>
          {selectable && <FontAwesomeIcon icon={faChevronCircleRight} />}
        </>
      );
      return (
        <li
          id={item.id}
          key={item.id}
          className={'course ' + (selectable ? 'with-anchor ' : '')}
        >
          {selectable ? (
            <a
              className={item.isNew ? 'new ' : ''}
              href={props.match.path + '/' + item.id}
              onClick={event => selectCourse(event, item.id, false)}
              onKeyDown={event => courseKeyed(event, item.id)}
            >
              {courseContent}
            </a>
          ) : (
            courseContent
          )}
        </li>
      );
    });
};

export default withRouter(CourseListItems);
