import React from 'react';
import PropTypes from 'prop-types';
import './Checkbox.scss';

class Checkbox extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  render() {
    const {
      domId,
      label,
      value,
      readOnly,
      hiddenLabel,
      liClassName,
      children
    } = this.props;
    return (
      <li
        className={
          'checkbox-container ' +
          (value ? 'checked ' : '') +
          (readOnly ? 'read-only ' : '') +
          liClassName
        }
        onClick={this.handleChange}
        onKeyDown={this.handleChange}
      >
        <span className="checkbox"></span>
        <input
          className="checkbox-input"
          id={domId}
          type="checkbox"
          value={value}
        />
        <label
          htmlFor={domId}
          className={hiddenLabel ? 'visually-hidden' : undefined}
        >
          {label}
        </label>
        {children}
      </li>
    );
  }

  handleChange(event) {
    if (this.props.readOnly) return;

    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
    } else if (event.key) {
      return;
    }

    this.props.controlFunc(this.props.id);
  }
}

Checkbox.propTypes = {
  domId: PropTypes.string.isRequired,
  id: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  controlFunc: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  hiddenLabel: PropTypes.bool,
  liClassName: PropTypes.string
};

Checkbox.defaultProps = {
  readOnly: false,
  hiddenLabel: false,
  liClassName: ''
};

export default Checkbox;
