import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';

const SelectCourseHTMLSelect = props => {
  const {
    handleOpenOptions,
    openOptions,
    currentOption,
    selectRef,
    name,
    includeEnrollment,
    hasBeenChanged
  } = props;

  let courseEnrollmentText;
  if (currentOption.projectedEnrollment) {
    courseEnrollmentText =
      currentOption.projectedEnrollment +
      ' student' +
      (currentOption.projectedEnrollment > 1 ? 's' : '');
  } else {
    courseEnrollmentText = '';
  }

  return (
    <div
      id={name}
      tabIndex="0"
      role="button"
      onClick={handleOpenOptions}
      onKeyDown={handleOpenOptions}
      aria-pressed={openOptions}
      aria-expanded={openOptions}
      className={'select ' + (!hasBeenChanged ? 'unchanged' : '')}
      ref={selectRef}
    >
      {currentOption === undefined || currentOption === 'Select' ? (
        'Select'
      ) : (
        <div className="course-option-content">
          <span className="course-number">
            {currentOption.courseNumber.replace(/(\d)/, ' $1')}
          </span>
          <span className="course-name">
            {currentOption.name}
            <span className="credit-hours">{` (${
              currentOption.variableCredit ? 'Variable' : currentOption.credits
            } CR)`}</span>
            {includeEnrollment && (
              <span className="course-enrollment">{` ${courseEnrollmentText}`}</span>
            )}
          </span>
        </div>
      )}
      <FontAwesomeIcon
        className="dropdown-arrow"
        icon={faChevronDown}
        title="Clear value"
      />
    </div>
  );
};

export default SelectCourseHTMLSelect;
