import {
  GET_PLANS_OF_STUDY,
  GET_PLAN_OF_STUDY,
  ADD_PLAN_OF_STUDY,
  UPDATE_PLAN_OF_STUDY,
  PUT_PLAN_OF_STUDY_TERMS,
  ARCHIVE_PLAN_OF_STUDY
} from '../types';
import {
  getPlanOfStudyListEndpoint,
  addPlanOfStudyEndpoint,
  getPlanOfStudyEndpoint,
  updatePlanOfStudyEndpoint,
  putPlanOfStudyTermsEndpoint
} from '../../utils/endpoints';
import requestWrapper from '../requestWrapper';
import { generateClearErrorActionTypeName } from '../actionNamingMethods';

export const getPlanOfStudyList = () => async dispatch => {
  requestWrapper(dispatch, {
    actionType: GET_PLANS_OF_STUDY,
    requestObject: {
      url: getPlanOfStudyListEndpoint(),
      method: 'GET',
      responseType: 'json'
    },
    errorMessage: 'Error retrieving plans of study'
  });
};

export const addPlanOfStudy = newPlanOfStudy => async dispatch => {
  requestWrapper(dispatch, {
    actionType: ADD_PLAN_OF_STUDY,
    requestObject: {
      url: addPlanOfStudyEndpoint(),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: newPlanOfStudy,
      responseType: 'json'
    },
    errorMessage: 'Error adding plans of study',
    responseDataKey: 'planOfStudy'
  });
};

export const getPlanOfStudy = planOfStudyId => async dispatch => {
  requestWrapper(dispatch, {
    actionType: GET_PLAN_OF_STUDY,
    requestObject: {
      url: getPlanOfStudyEndpoint(planOfStudyId),
      method: 'GET',
      responseType: 'json'
    },
    errorMessage: 'Error retrieving plan of study',
    responseDataKey: 'planOfStudy',
    customResponseStatusMessages: {
      '404': 'Plan of Study not found.'
    }
  });
};

export const updatePlanOfStudy = (
  planOfStudyId,
  planOfStudyUpdate
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: UPDATE_PLAN_OF_STUDY,
    requestObject: {
      url: updatePlanOfStudyEndpoint(planOfStudyId),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'json',
      data: planOfStudyUpdate
    },
    errorMessage: 'Error updating plan of study',
    responseDataKey: 'planOfStudy'
  });
};

export const archivePlanOfStudy = (
  planOfStudyId,
  planOfStudyUpdate
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: ARCHIVE_PLAN_OF_STUDY,
    requestObject: {
      url: updatePlanOfStudyEndpoint(planOfStudyId),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'json',
      data: planOfStudyUpdate
    },
    responsePayload: {
      planOfStudyId
    },
    errorMessage: 'Error archiving plan of study'
  });
};

export const clearUpdatePlanOfStudyError = () => async dispatch => {
  dispatch({
    type: generateClearErrorActionTypeName(UPDATE_PLAN_OF_STUDY)
  });
  dispatch({
    type: generateClearErrorActionTypeName(ARCHIVE_PLAN_OF_STUDY)
  });
};

export const putPlanOfStudyTerms = (planOfStudyId, terms) => async dispatch => {
  terms.forEach(term => {
    if (!term.courseIds) {
      term.courseIds = [];
    }
  });

  requestWrapper(dispatch, {
    actionType: PUT_PLAN_OF_STUDY_TERMS,
    requestObject: {
      url: putPlanOfStudyTermsEndpoint(planOfStudyId),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      data: { terms },
      responseType: 'json'
    },
    errorMessage: 'Error updating terms in plan of study',
    responsePayload: terms
  });
};
