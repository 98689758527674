import React from 'react';

const SelectOptions = props => {
  const {
    handleOptionsEvents,
    optionsWrapperRef,
    setOptionRef,
    focusedOption,
    optionList,
    topList,
    topListHeading,
    currentOption,
    emptyMessage
  } = props;
  let setSize = Object.keys(optionList).length || 1;
  return (
    <div className="options" ref={optionsWrapperRef}>
      {topList && topList.length ? (
        <div className="top-list">
          <div>
            <span className="top-list-heading">{topListHeading}</span>
          </div>
        </div>
      ) : null}
      {optionList && optionList.length ? (
        optionList.map((optionValue, index) => {
          return (
            <div
              className={
                (currentOption === optionValue ? 'active option' : 'option') +
                (topList && index === topList.length
                  ? ' first-after-toplist '
                  : '') +
                (topList && index === topList.length - 1
                  ? ' last-toplist-item '
                  : '')
              }
              role="option"
              tabIndex="0"
              id={optionValue}
              aria-selected={focusedOption === optionValue}
              key={optionValue}
              onClick={e => handleOptionsEvents(optionValue, index, e)}
              onKeyDown={e => handleOptionsEvents(optionValue, index, e)}
              onBlur={e => handleOptionsEvents(optionValue, index, e)}
              ref={setOptionRef}
              aria-posinset={index}
              aria-setsize={setSize}
            >
              <span>{optionValue}</span>
            </div>
          );
        })
      ) : (
        <div
          className="option unselectable"
          ref={setOptionRef}
          id="empty-option"
        >
          <span>{emptyMessage}</span>
        </div>
      )}
    </div>
  );
};

export default SelectOptions;
