import React from 'react';
import './AddCourse.scss';
import { connect } from 'react-redux';
import { ADD_COURSE, GET_CONFIG } from '../../service/types';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import FormReveal from '../common/FormReveal';
import Select from '../common/Select';
import Input from '../common/Input';
import ToggleSlider from '../common/ToggleSlider';
import CheckboxInput from '../common/CheckboxInput';
import { addCourse, clearErrors } from '../../service/actions/courses';
import {
  showCustomJSXModal,
  showValidatingModal,
  hideModal
} from '../../service/actions/modal';

class AddCourse extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormInputValid: false,
      duplicateErrorMessage: '',
      resetting: false
    };
    this.baseState = { ...this.state };

    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleCheckboxInputChange = this.handleCheckboxInputChange.bind(this);

    this.formRevealRef = React.createRef();

    this.formValues = {
      courseNumberPrefix: {
        name: 'course-number-select',
        valueId: 'courseNumberPrefix',
        defaultValue: '',
        labelTitle: 'Course Number Prefix',
        validatorFunc: () => ({
          isValid: true,
          errorMessage: 'Invalid'
        })
      },
      courseNumber: {
        name: 'course-number',
        labelTitle: 'Course Number',
        valueId: 'courseNumber',
        defaultValue: '',
        validatorFunc: value => ({
          isValid: /^[0-9]{3,4}([A-Z]{0,2})?$/.test(value),
          errorMessage: 'Course number is invalid'
        })
      },
      courseCredits: {
        name: 'course-credits',
        valueId: 'courseCredits',
        labelTitle: 'Credits',
        defaultValue: '',
        validatorFunc: value => ({
          isValid:
            /^(\d{1,2})+(\.\d{1,2})?$/.test(value) &&
            parseFloat(value) > 0 && parseFloat(value) <= 20,
          errorMessage: 'Invalid credit hours'
        })
      },
      variableCredit: {
        name: 'variable-credit',
        valueId: 'variableCredit',
        labelTitle: 'Variable Credit',
        defaultValue: '',
        validatorFunc: value => ({
          isValid: true,
          errorMessage: 'Invalid credit hours'
        })
      },
      courseName: {
        name: 'course-name',
        valueId: 'courseName',
        labelTitle: 'Course Name',
        defaultValue: '',
        validatorFunc: value => {
          return {
            isValid:
              /^.{1,100}$/.test(value) && value && value.trim().length > 0,
            errorMessage: 'Invalid course name'
          };
        }
      },
      maxPerSectionCapacity: {
        name: 'max-per-section-capacity',
        valueId: 'maxPerSectionCapacity',
        labelTitle: 'Section Capacity',
        defaultValue: '',
        validatorFunc: value => ({
          isValid:
            (/^\d+$/.test(value) &&
              parseFloat(value) > 0 &&
              parseFloat(value) <= 9999) ||
            !value,
          errorMessage: 'Invalid max per-section capacity'
        })
      },
      sectionComponent: {
        name: 'section-component',
        valueId: 'sectionComponent',
        labelTitle: 'Instructional Components',
        defaultValue: '',
        validatorFunc: value => {
          return {
            isValid:
              /^.{1,20}$/.test(value) && value && value.trim().length > 0,
            errorMessage: 'Invalid section component'
          };
        }
      }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.addingCourse !== this.props.addingCourse) {
      this.props.showValidatingModal();

      if (!this.props.addingCourse) {
        if (this.props.errorMessage) {
          this.props.hideModal();
        } else {
          this.props.showCustomJSXModal(
            <div id="success-message" style={{ textAlign: 'center' }}>
              <FontAwesomeIcon icon={faCheckCircle} />
              New Course Added
              <br />
              {this.props.newlyAddedCourse.courseNumber}
            </div>
          );
          this.formRevealRef.current.handleCancelClick(
            document.createEvent('Event')
          );

          setTimeout(() => {
            this.props.hideModal();
          }, 1800);
        }
      }
    } else if (
      prevState.resetting !== this.state.resetting &&
      this.state.resetting
    ) {
      this.setState({
        resetting: false
      });
    }
  }

  render() {
    const form = this.renderAddCourseForm();
    if (!form) return null;
    return (
      <div className="add-course-wrapper">
        <FormReveal
          buttonText="Add Course"
          buttonId="add-course"
          form={form}
          submitFunc={() => this.addCourse()}
          cancelFunc={() => this.resetForm()}
          ref={this.formRevealRef}
        />
      </div>
    );
  }

  renderAddCourseForm() {
    const {
      courseNumberPrefix,
      courseNumber,
      duplicateErrorMessage,
      resetting
    } = this.state;
    const { coursePrefixOptions, courseComponentTypes } = this.props;

    if (resetting || !coursePrefixOptions) return null;

    let defaultOption = coursePrefixOptions[0]; //TODO handle gracefully between adding courses/cancellations
    let sortedOptions = [].concat(coursePrefixOptions).sort((a, b) => {
      return ('' + a).localeCompare(b);
    });

    let populatedPrefix;
    if (!courseNumberPrefix) {
      populatedPrefix = defaultOption;
    } else {
      populatedPrefix = courseNumberPrefix;
    }

    const { errorMessage } = this.props;

    return (
      <section>
        <h1>Add a New Course</h1>

        <form id="course-form-wrapper">
          <div id="course-entry-form">
            <div role="group" aria-labelledby="course-number-label">
              <label id="course-number-label">Course Number</label>
              <div className="select-input-combo">
                <Select
                  controlFunc={this.handleValueChange}
                  optionList={sortedOptions}
                  defaultOption={defaultOption}
                  formValue={this.formValues.courseNumberPrefix}
                  hideLabel={true}
                  showClearButton={false}
                />

                <Input
                  formValue={this.formValues.courseNumber}
                  controlFunc={this.handleValueChange}
                  hideLabel={true}
                  toUppercase={true}
                  trimWhitespaces={true}
                  errorStyle={{ left: '-86px' }}
                />
              </div>
            </div>
            <div
              onClick={
                this.state.variableCredit
                  ? () => {
                      this.handleValueChange({
                        valueId: 'variableCredit',
                        value: false
                      });
                    }
                  : undefined
              }
            >
              <Input
                formValue={this.formValues.courseCredits}
                controlFunc={this.handleValueChange}
                immediatelyValidate={false}
                trimWhitespaces={true}
                hideError={this.state.variableCredit}
                hideErrorMessage={this.state.variableCredit}
                disabled={this.state.variableCredit}
                actionable={this.state.variableCredit}
              />
            </div>
            <div>
              <label id="variable-credit-label">Variable Credit</label>
              {this.renderVariableCreditSlider()}
            </div>
            <div>
              <Input
                formValue={this.formValues.courseName}
                controlFunc={this.handleValueChange}
                immediatelyValidate={true}
              />
            </div>
            <div>
              <CheckboxInput
                formRows={[]
                  .concat(courseComponentTypes)
                  .sort((a, b) => ('' + a.name).localeCompare(b.name))}
                controlFunc={this.handleCheckboxInputChange}
                checkboxFormValues={this.formValues.sectionComponent}
                inputOptional={true}
                inputFormValues={this.formValues.maxPerSectionCapacity}
                checkboxLabel="Instructional Components"
                inputLabel="Section Capacity"
              />
            </div>
          </div>

          <div className="form-end-wrapper">
            {errorMessage && (
              <div className="form-message unexpected-error-message error-message">
                <FontAwesomeIcon icon={faExclamationCircle} />
                An unknown error occurred. Please try again.
              </div>
            )}

            {duplicateErrorMessage && (
              <div className="form-message duplicate-error-message warning-message">
                <FontAwesomeIcon icon={faExclamationTriangle} />
                {populatedPrefix + courseNumber} already exists in the course
                list.
              </div>
            )}
            <div className="buttons">
              <button
                id="cancel-course-entry"
                onClick={event => this.handleCancelClick(event)}
              >
                Cancel
              </button>
              <button
                id="submit-course-entry"
                onClick={event => this.handleSubmitClick(event)}
                disabled={!this.state.isFormInputValid}
              >
                Add Course <FontAwesomeIcon icon={faPlusCircle} />
              </button>
            </div>
          </div>
        </form>
      </section>
    );
  }

  renderVariableCreditSlider() {
    const toggleFunction = value => {
      if (value) {
        this.setState({
          courseCredits: undefined
        });
      }
      this.handleValueChange({
        valueId: 'variableCredit',
        value: value || false
      });
    };

    const value = this.state.variableCredit || false;
    return (
      <ToggleSlider
        controlFunc={value => toggleFunction(value)}
        value={value}
      />
    );
  }

  handleCancelClick(event) {
    event.preventDefault();
    this.formRevealRef.current.handleCancelClick(event);
  }

  handleSubmitClick(event) {
    event.preventDefault();
    this.addCourse();
  }

  addCourse() {
    this.validateFormInput();
    const {
      courseNumber,
      courseCredits,
      courseName,
      components,
      variableCredit
    } = this.state;
    const { coursePrefixOptions } = this.props;

    let courseNumberPrefix;
    if (!this.state.courseNumberPrefix) {
      courseNumberPrefix = coursePrefixOptions[0];
    } else {
      courseNumberPrefix = this.state.courseNumberPrefix;
    }

    let newCourse = {
      courseNumber: courseNumberPrefix + courseNumber,
      name: courseName,
      components: components,
      variableCredit: !!variableCredit
    };
    if (!variableCredit) {
      newCourse.credits = parseFloat(courseCredits);
    }

    this.props.addCourse(newCourse);
  }

  handleValueChange(formValue) {
    console.log(formValue);
    const { coursePrefixOptions } = this.props;

    let currentCourseNumber;
    if (formValue.valueId === 'courseNumberPrefix') {
      currentCourseNumber = formValue.value;
    } else {
      currentCourseNumber =
        this.state.courseNumberPrefix || coursePrefixOptions[0];
    }

    if (formValue.valueId === 'courseNumber') {
      currentCourseNumber += formValue.value;
    } else {
      currentCourseNumber += this.state.courseNumber;
    }

    this.setState({
      [formValue.valueId]: formValue.value,
      isFormInputValid: Object.keys(this.formValues).reduce((result, next) => {
        let theResult;
        if (next === formValue.valueId) {
          theResult =
            result &&
            this.formValues[next].validatorFunc(formValue.value).isValid;
        } else {
          theResult =
            result &&
            this.formValues[next].validatorFunc(this.state[next]).isValid;
        }

        if (next.toString() === 'courseCredits') {
          theResult =
            theResult ||
            (formValue.valueId === 'variableCredit'
              ? formValue.value
              : this.state.variableCredit);
        }
        return theResult;
      }, true),
      duplicateErrorMessage: this.props.courses
        .filter(crs => crs.archived !== true)
        .reduce((aggregator, item) => {
          return aggregator || item.courseNumber === currentCourseNumber;
        }, false)
    });
  }

  handleCheckboxInputChange(values) {
    let components;
    components = values.map(c => {
      let newComponent = {};
      newComponent.componentType = c.checkboxValue;
      if (c.inputValue) newComponent.maxCapacity = parseInt(c.inputValue);
      return newComponent;
    });

    let isCheckboxInputValid;
    if (values.length > 0) {
      isCheckboxInputValid = values.reduce((result, next) => {
        let theResult =
          result &&
          this.formValues.sectionComponent.validatorFunc(next.checkboxValue)
            .isValid &&
          this.formValues.maxPerSectionCapacity.validatorFunc(next.inputValue)
            .isValid;
        return theResult;
      }, true);
    } else {
      isCheckboxInputValid = false;
    }

    this.setState({
      sectionComponent: isCheckboxInputValid
        ? values[0].checkboxValue
        : isCheckboxInputValid,
      maxPerSectionCapacity: isCheckboxInputValid
        ? values[0].inputValue
        : isCheckboxInputValid,
      isFormInputValid: Object.keys(this.formValues).reduce((result, next) => {
        let theResult;
        if (
          next.toString() === 'sectionComponent' ||
          next.toString() === 'maxPerSectionCapacity'
        ) {
          theResult = result && isCheckboxInputValid;
        } else if (next.toString() === 'courseCredits') {
          theResult =
            result &&
            (this.formValues[next].validatorFunc(this.state[next]).isValid ||
              this.state.variableCredit);
        } else {
          theResult =
            result &&
            this.formValues[next].validatorFunc(this.state[next]).isValid;
        }
        return theResult;
      }, true),
      components: components
    });
  }

  resetForm() {
    let newState = { ...this.baseState, resetting: true };
    Object.keys(this.formValues).forEach(key => {
      newState[key] = undefined;
    });
    newState.courseNumberPrefix = undefined;
    this.setState(newState);
    this.props.clearErrors();
  }

  validateFormInput() {
    const fieldsValid = Object.keys(this.formValues).reduce((result, next) => {
      let theResult = this.formValues[next].validatorFunc(this.state[next])
        .isValid;
      if (next.toString() === 'courseCredits') {
        theResult = theResult || this.state.variableCredit;
      }

      return result && theResult;
    }, true);

    this.setState({
      isFormInputValid: fieldsValid
    });
  }
}

const loadingSelector = createLoadingSelector([ADD_COURSE, GET_CONFIG]);
const errorMessageSelector = createErrorMessageSelector([ADD_COURSE]);
const mapStateToProps = state => {
  const { config, courses } = state;
  return {
    addingCourse: loadingSelector(state),
    errorMessage: errorMessageSelector(state),
    courses: courses.list,
    newlyAddedCourse: courses.newest,
    coursePrefixOptions: config.config && config.config.coursePrefixes,
    courseComponentTypes: config.config && config.config.courseComponentTypes
  };
};

export default connect(mapStateToProps, {
  addCourse,
  clearErrors,
  showCustomJSXModal,
  showValidatingModal,
  hideModal
})(AddCourse);
