import React from 'react';
import './validation.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';

export const ValidationCounter = ({ validationList }) => {
  if (!validationList) {
    return null;
  } else {
    let errors = validationList.filter(x => x.type === 'error');
    let warnings = validationList.filter(x => x.type === 'warning');
    let type =
      errors.length > 0
        ? 'error'
        : errors.length === 0 && warnings.length > 0
        ? 'warning'
        : 'valid';
    let count =
      errors.length > 0
        ? errors.length
        : errors.length === 0 && warnings.length > 0
        ? warnings.length
        : null;
    let validationIcon =
      type === 'valid' ? <FontAwesomeIcon icon={faCheck} /> : null;
    return (
      <span className={'validation-counter ' + type}>
        {count}
        {validationIcon}
      </span>
    );
  }
};

export const ValidationMessage = ({ message, type }) => {
  const validationIcon = type => {
    switch (type) {
      case 'error':
        return faExclamationCircle;
      case 'warning':
        return faExclamationTriangle;
      case 'info':
        return faInfoCircle;
      default:
        return faExclamationTriangle;
    }
  };

  if (type === 'valid') {
    return null;
  }
  return (
    <div className="validation-message-container">
      <span className={'validation-message-small ' + type}>
        <FontAwesomeIcon icon={validationIcon(type)} />
        {message}
      </span>
    </div>
  );
};
