import React from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';
import { connect } from 'react-redux';

class SelectRank extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valueMappings: new Map(),
      reverseValueMappings: new Map(),
      strippedOptionList: []
    };

    this.controlFunc = this.controlFunc.bind(this);
  }

  componentDidMount() {
    if (this.props.ranksList) {
      this.buildValueMappings();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ranksList !== this.props.ranksList) {
      this.buildValueMappings();
    }
  }

  render() {
    const {
      containerStyle,
      formValue,
      hideLabel,
      loading,
      value,
      disabled,
      actionable
    } = this.props;
    const { strippedOptionList, reverseValueMappings } = this.state;

    let defaultText = formValue.defaultValue || 'Select';
    if (loading) {
      defaultText = 'Loading...';
    }
    return (
      <Select
        formValue={formValue}
        startingValue={reverseValueMappings.get(value)}
        controlFunc={this.controlFunc}
        optionList={strippedOptionList}
        defaultOption={defaultText}
        hideLabel={hideLabel}
        containerStyle={containerStyle}
        disabled={disabled}
        actionable={actionable}
        emptyValueOption={'Select'}
      />
    );
  }

  controlFunc(formValue) {
    const { rankId } = this.state.valueMappings.get(formValue.value) || {
      rankId: null
    };
    this.props.controlFunc({
      valueId: this.props.formValue.valueId,
      value: rankId
    });
  }

  buildValueMappings() {
    let valueMappings = new Map();
    let reverseValueMappings = new Map();
    let strippedOptionList = [];
    if (!this.props.ranksList) {
      return null;
    }
    this.props.ranksList.forEach(rank => {
      valueMappings.set(rank.name, {
        rankId: rank.id
      });
      reverseValueMappings.set(rank.id, rank.name);
      strippedOptionList.push(rank.name);
    });

    this.setState({
      valueMappings,
      reverseValueMappings,
      strippedOptionList
    });
  }
}

SelectRank.propTypes = {
  formValue: PropTypes.object.isRequired,
  controlFunc: PropTypes.func.isRequired,
  value: PropTypes.string,
  hideLabel: PropTypes.bool,
  containerStyle: PropTypes.object,
  disabled: PropTypes.bool,
  actionable: PropTypes.bool
};

SelectRank.defaultProps = {
  disabled: false,
  actionable: false
};

const mapStateToProps = ({ config }) => {
  return {
    loading: config.loading,
    ranksList: config && config.config && config.config.facultyMemberRanks
  };
};

export default connect(
  mapStateToProps,
  null
)(SelectRank);
