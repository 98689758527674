import React from 'react';
import './ScheduleListView.scss';
import { connect } from 'react-redux';
import { getScheduleList } from '../../service/actions/schedules';
import { showLoadingModal, hideModal } from '../../service/actions/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons/faChevronCircleRight';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import { GET_SCHEDULES, GET_CONFIG } from '../../service/types';
import { mainViewComponent } from '../common/HOCs';

class ScheduleList extends React.Component {
  componentDidMount() {
    this.props.getScheduleList();
  }

  render() {
    const { loading, errorMessage, scopes } = this.props;

    if (!scopes) {
      return null;
    }

    if (!scopes.includes('schedule.read')) {
      return <p>Not authorized to view schedules.</p>;
    }

    return (
      <div id="schedules-list">
        <h1>Schedules</h1>
        {loading ? null : (
          <>
            {errorMessage && (
              <div className="error-message schedules-error-message">
                <FontAwesomeIcon icon={faExclamationCircle} />
                {errorMessage}
              </div>
            )}
            {!errorMessage && this.renderActiveScheduleList()}
            {!errorMessage && this.renderCompletedScheduleList()}
          </>
        )}
      </div>
    );
  }

  renderActiveScheduleList() {
    const { scheduleList } = this.props;

    if (scheduleList && scheduleList.length) {
      let sortedScheduleList = this.buildActiveScheduleListJsx();

      return (
        <div className="schedules-results">
          <ul className="none interactive schedules">{sortedScheduleList}</ul>
        </div>
      );
    } else {
      return <p className="empty no-schedules-message">No active schedules.</p>;
    }
  }

  buildActiveScheduleListJsx() {
    const { scheduleList, termList } = this.props;
    if (!termList || !scheduleList) {
      return null;
    }
    return []
      .concat(scheduleList)
      .sort((a, b) => {
        if (a.year === b.year) {
          return termList.indexOf(a.term) - termList.indexOf(b.term);
        }
        return a.year - b.year;
      })
      .filter(removeCompleted => {
        return removeCompleted.state !== 'completed';
      })
      .map((schedule, index) => {
        return (
          <li className="with-anchor schedule" key={index}>
            <a
              href={
                this.props.match.path +
                '/' +
                schedule.year +
                '/' +
                schedule.term
              }
              onClick={event => {
                this.scheduleClicked(
                  event,
                  schedule.year,
                  schedule.term,
                  false
                );
              }}
              onKeyDown={event =>
                this.scheduleKeyDowned(event, schedule.year, schedule.term)
              }
            >
              <div className="content">
                <span>{`${schedule.year} ${schedule.term}`}</span>
                <div className={`label status-${schedule.state}-bg`}>
                  {schedule.state}
                </div>
              </div>
              <FontAwesomeIcon icon={faChevronCircleRight} />
            </a>
          </li>
        );
      });
  }

  renderCompletedScheduleList() {
    const { scheduleList } = this.props;
    if (scheduleList && scheduleList.length) {
      let completedScheduleList = this.buildCompletedScheduleListJsx();

      if (completedScheduleList) {
        return (
          <div className="completed-schedules-results">
            <h2>Completed Terms</h2>
            <ul className="none interactive completed-schedules">
              {completedScheduleList}
            </ul>
          </div>
        );
      }
    }
    return null;
  }

  buildCompletedScheduleListJsx() {
    const { scheduleList, termList } = this.props;

    return []
      .concat(scheduleList)
      .filter(keepCompleted => {
        return keepCompleted.state === 'completed';
      })
      .sort((a, b) => {
        if (a.year === b.year) {
          return termList.indexOf(b.term) - termList.indexOf(a.term);
        }
        return b.year - a.year;
      })
      .slice(0, 6)
      .map((schedule, index) => {
        return (
          <li key={index} className="with-anchor schedule">
            <a
              href={
                this.props.match.path +
                '/' +
                schedule.year +
                '/' +
                schedule.term
              }
              onClick={event => {
                this.scheduleClicked(
                  event,
                  schedule.year,
                  schedule.term,
                  false
                );
              }}
              onKeyDown={event =>
                this.scheduleKeyDowned(event, schedule.year, schedule.term)
              }
            >
              <div className="content">
                <span>{`${schedule.year} ${schedule.term}`}</span>
              </div>
              <FontAwesomeIcon icon={faChevronCircleRight} />
            </a>
          </li>
        );
      });
  }

  scheduleKeyDowned(event, year, term) {
    if (event.key === 'Enter') {
      this.scheduleClicked(event, year, term, true);
    }
  }

  scheduleClicked(event, year, term, keyed) {
    event.preventDefault();

    const pathname = `${this.props.match.path}/${year}/${term}`;
    if (event.ctrlKey || event.metaKey) {
      window.open(this.props.history.createHref({ pathname }));
    } else {
      this.props.history.push({
        pathname,
        state: {
          keyed
        }
      });
    }
  }
}

const loadingSelector = createLoadingSelector([GET_CONFIG, GET_SCHEDULES]);
const errorSelector = createErrorMessageSelector([GET_SCHEDULES]);
const mapStateToProps = state => {
  const { auth, config, schedules } = state;
  return {
    scopes: auth.scopes,
    loading: loadingSelector(state),
    errorMessage: errorSelector(state),
    scheduleList: schedules.list,
    termList: config.termsByCalendarYear
  };
};

const mainViewConfig = {
  pageTitle: 'Schedules'
};

export default connect(
  mapStateToProps,
  { getScheduleList, showLoadingModal, hideModal }
)(mainViewComponent({ component: ScheduleList, mainViewConfig }));
