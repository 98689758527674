import React from 'react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteOtherTeachingItem } from '../../service/actions/faculty';
import { connect } from 'react-redux';
import { showLoadingModal, hideModal } from '../../service/actions/modal';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import { DELETE_OTHER_TEACHING_ITEM } from '../../service/types';

class FacultyOtherTeachingItem extends React.Component {
  render() {
    const {
      id,
      description,
      effort,
      year,
      facultyMemberId,
      workPlan,
      isNew
    } = this.props;

    return (
      <div
        className={
          isNew
            ? 'new other-teaching-container heading'
            : 'other-teaching-container heading'
        }
      >
        <div className="other-teaching-label label">Other Teaching Effort</div>
        <div className="other-teaching-description">{description}</div>
        <div className="other-teaching-effort">{effort} TU</div>
        {workPlan.state !== 'past' ? (
          <button
            className="circle small no-print other-teaching-delete"
            onClick={event => {
              event.preventDefault();
              this.props.deleteOtherTeachingItem(facultyMemberId, year, id);
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { faculty } = state;
  return {
    workPlan: faculty.viewWorkPlan,
    loading: loadingSelector(state),
    error: errorSelector(state)
  };
};

const loadingSelector = createLoadingSelector([DELETE_OTHER_TEACHING_ITEM]);
const errorSelector = createErrorMessageSelector([DELETE_OTHER_TEACHING_ITEM]);
export default connect(mapStateToProps, {
  deleteOtherTeachingItem,
  showLoadingModal,
  hideModal
})(FacultyOtherTeachingItem);
