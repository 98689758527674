const config = {
  APP_NAME: 'coursFACTS',
  AWS_REGION: process.env.REACT_APP_AWS_REGION,
  AWS_COGNITO_IDENTITY_POOL_ID:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  AWS_COGNITO_USER_POOL_ID: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  AWS_COGNITO_CLIENT_ID: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
  AWS_COGNITO_CLIENT_DOMAIN_NAME:
    process.env.REACT_APP_AWS_COGNITO_CLIENT_DOMAIN_NAME,
  AWS_COGNITO_DEFINE_IDP:
    process.env.REACT_APP_AWS_COGNITO_DEFINE_IDP === 'true',
  AWS_COGNITO_IDP_NAME: process.env.REACT_APP_AWS_COGNITO_IDP_NAME,
  AWS_COGNITO_IDP_SIGNIN_URL: process.env.REACT_APP_AWS_COGNITO_IDP_SIGNIN_URL,
  AWS_COGNITO_IDP_SIGNOUT_URL:
    process.env.REACT_APP_AWS_COGNITO_IDP_SIGNOUT_URL,
  AWS_COGNITO_IDP_OAUTH_CLAIMS:
    process.env.REACT_APP_AWS_COGNITO_IDP_OAUTH_CLAIMS,
  AWS_COGNITO_IDP_GRANT_FLOW: process.env.REACT_APP_AWS_COGNITO_IDP_GRANT_FLOW,
  AUTHORIZED_GROUP: process.env.REACT_APP_AUTHORIZED_GROUP,
  API_ROOT_CURRICULUM: process.env.REACT_APP_API_ROOT_CURRICULUM,
  API_ROOT_SCHEDULE: process.env.REACT_APP_API_ROOT_SCHEDULE,
  API_ROOT_FACULTY: process.env.REACT_APP_API_ROOT_FACULTY,
  CONFIG_ROOT: process.env.REACT_APP_CONFIG_ROOT
};

export default config;

export const FORM_REVEAL_ANIMATION_SPEED = 600;
