import { GET_PROGRAMS } from '../types';
import { generateSuccessActionTypeName } from '../actionNamingMethods';

export default (state = {}, action) => {
  switch (action.type) {
    case generateSuccessActionTypeName(GET_PROGRAMS):
      return {
        ...state,
        list: action.payload
      };

    default:
      return state;
  }
};
