import React from 'react';
import { connect } from 'react-redux';
import CourseListItems from '../common/CourseListItems';
import StudentGroupTermEnrollment from './StudentGroupTermEnrollment';
import UpdateTermCourses from './UpdateTermCourses';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import { GET_STUDENT_GROUPS, GET_COURSES } from '../../service/types';

class StudentGroupTerms extends React.Component {
  render() {
    const {
      studentGroup,
      courseList,
      errorMessage,
      termList,
      schedules,
      scopes
    } = this.props;
    if (!(studentGroup.planOfStudy && studentGroup.planOfStudy.terms)) {
      return null;
    }

    const planOfStudyTerms = studentGroup.planOfStudy.terms;
    const studentGroupEnrollment = studentGroup.enrollment;
    if (!planOfStudyTerms || !termList || !schedules) {
      return null;
    }

    if (errorMessage) {
      return (
        <div className="plan-of-study-error-message error-message">
          An unexpected error occurred. Please try again
        </div>
      );
    }

    if (!courseList) {
      return null;
    }

    const totalNumberOfCourses = planOfStudyTerms.reduce((result, next) => {
      if (!next.courseIds) {
        return result;
      }
      return result + next.courseIds.length;
    }, 0);

    return (
      <>
        <span className="subheading">{totalNumberOfCourses} Total Courses</span>
        {[]
          .concat(planOfStudyTerms)
          .sort((a, b) => {
            if (a.year === b.year) {
              return termList.indexOf(a.term) - termList.indexOf(b.term);
            }
            return a.year - b.year;
          })
          .map(term => {
            let termEnrollment = studentGroupEnrollment.find(val => {
              return val.year === term.year && val.term === term.term;
            });

            let { state } = schedules.find(val => {
              return val.year === term.year && val.term === term.term;
            }) || { state: 'projected' };

            const canEdit = state === 'planning' || state === 'projected';

            if (!termEnrollment) {
              termEnrollment = {
                year: term.year,
                term: term.term
              };
            }
            return (
              <div className="term" key={term.term + term.year}>
                <h2>
                  <span>
                    {term.term} {term.year}
                  </span>
                  {state === 'completed' && (
                    <div className={`state`}>{`${state} Term`}</div>
                  )}
                  {state !== 'completed' && (
                    <div className={`label state status-${state}-bg`}>
                      {state}
                    </div>
                  )}
                  <StudentGroupTermEnrollment
                    enrollment={termEnrollment}
                    canEdit={canEdit}
                    idSuffix={term.term + term.year}
                  />
                </h2>
                <div className="view-term-courses">
                  {this.renderTermCourses(term)}
                </div>
                {canEdit && scopes.includes('student-group.write') && (
                  <div className="update-term-courses">
                    <UpdateTermCourses term={term} />
                  </div>
                )}
              </div>
            );
          })}
      </>
    );
  }

  renderTermCourses(term) {
    const { courseList } = this.props;

    const noArchivedCourses = courseList.filter(
      c => !c.archived || term.courseIds.includes(c.id)
    );
    if (!term.courseIds) {
      return null;
    }

    const courses = term.courseIds.map(courseId => {
      return noArchivedCourses.find(element => {
        return element.id === courseId;
      });
    });
    return (
      <ul className="none">
        <CourseListItems courses={courses} selectable={false} />
      </ul>
    );
  }
}

const loadingSelector = createLoadingSelector([GET_STUDENT_GROUPS]);
const errorSelector = createErrorMessageSelector([GET_COURSES]);
const mapStateToProps = state => {
  const { config, courses, studentGroups, schedules, auth } = state;
  return {
    loading: loadingSelector(state),
    errorMessage: errorSelector(state),
    studentGroup: studentGroups.viewIndividual,
    courseList: courses.list,
    schedules: schedules.list,
    termList: config.termsByCalendarYear,
    scopes: auth.scopes
  };
};

export default connect(
  mapStateToProps,
  null
)(StudentGroupTerms);
