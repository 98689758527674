import { validate } from '../../components/Schedules/Validation/ValidationConditions';
import {
  GET_SCHEDULE,
  GET_SCHEDULES,
  ADD_SCHEDULE_SECTION,
  DELETE_SCHEDULE_SECTION,
  POST_SCHEDULE_STATE_CHANGE,
  ADD_COURSE_TO_SCHEDULE,
  DELETE_SCHEDULE_COURSE,
  GET_SCHEDULE_ENROLLMENT,
  VALIDATE_SCHEDULE,
  EXPORT_SCHEDULE,
  CLEAR_SCHEDULE_UPDATING_ERROR,
  PUT_SCHEDULE_SECTION,
  SET_SECTION_CONFIRMATION_STATUS,
  GET_SCHEDULE_NOTES,
  SET_SCHEDULE_NOTES
} from '../types';
import {
  getScheduleEndpoint,
  getScheduleListEndpoint,
  addScheduleSectionEndpoint,
  postScheduleStateChangeEndpoint,
  addCourseToScheduleEndpoint,
  putScheduleSectionEndpoint,
  setSectionConfirmationStatusEndpoint,
  deleteScheduleSectionEndpoint,
  deleteScheduleCourseEndpoint,
  getScheduleProjectedEnrollmentEndpoint,
  getScheduleNotesEndpoint,
  setScheduleCourseNotesEndpoint
} from '../../utils/endpoints';
import axios from 'axios';
import {
  generateRequestActionTypeName,
  generateSuccessActionTypeName,
  generateFailureActionTypeName,
  generateClearErrorActionTypeName
} from '../actionNamingMethods';
import requestWrapper from '../requestWrapper';

export const getSchedule = (scheduleYear, scheduleTerm) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: GET_SCHEDULE,
    requestObject: {
      url: getScheduleEndpoint(scheduleYear, scheduleTerm),
      method: 'GET',
      responseType: 'json'
    },
    errorMessage: 'Error retrieving schedule',
    customResponseStatusMessages: {
      '404': 'Schedule not found.'
    }
  });
};

export const getScheduleProjectedEnrollment = (
  scheduleYear,
  scheduleTerm
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: GET_SCHEDULE_ENROLLMENT,
    requestObject: {
      url: getScheduleProjectedEnrollmentEndpoint(scheduleYear, scheduleTerm),
      method: 'GET',
      responseType: 'json'
    },
    responseDataKey: 'projectedEnrollment'
  });
};

export const getScheduleNotes = (
  scheduleYear,
  scheduleTerm
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: GET_SCHEDULE_NOTES,
    requestObject: {
      url: getScheduleNotesEndpoint(scheduleYear, scheduleTerm),
      method: 'GET',
      responseType: 'json'
    },
    responseDataKey: 'courseNotes',
    errorMessage: 'Error retrieving schedule notes'
  });
};

export const setScheduleCourseNotes = (
  scheduleYear,
  scheduleTerm,
  courseId,
  notes
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: SET_SCHEDULE_NOTES,
    requestObject: {
      url: setScheduleCourseNotesEndpoint(scheduleYear, scheduleTerm, courseId),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      data: { notes },
      responseType: 'json'
    },
    responsePayload: {
      courseId,
      notes
    },
    errorMessage: 'Error retrieving schedule notes'
  });
};

export const clearScheduleCourseNotesError = () => async dispatch => {
  dispatch({
    type: generateClearErrorActionTypeName(SET_SCHEDULE_NOTES)
  });
};

export const getScheduleList = () => async dispatch => {
  requestWrapper(dispatch, {
    actionType: GET_SCHEDULES,
    requestObject: {
      url: getScheduleListEndpoint(),
      method: 'GET',
      responseType: 'json'
    },
    errorMessage: 'Error retrieving schedules'
  });
};

export const postScheduleStateChange = (
  year,
  term,
  newState,
  mergePriorYear
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: POST_SCHEDULE_STATE_CHANGE,
    requestObject: {
      url: postScheduleStateChangeEndpoint(year, term),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: { newState, mergePriorYear },
      responseType: 'json'
    },
    errorMessage: 'Error retrieving courses',
    responsePayload: newState
  });
};

export const addScheduleSection = (
  scheduleYear,
  scheduleTerm,
  courseId,
  componentType,
  newSectionObject
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: ADD_SCHEDULE_SECTION,
    requestObject: {
      url: addScheduleSectionEndpoint(
        scheduleYear,
        scheduleTerm,
        courseId,
        componentType
      ),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: newSectionObject,
      responseType: 'json'
    },
    errorMessage: 'Error adding section schedule',
    responsePayload: {
      courseId,
      componentType
    },
    payloadMerge: [
      {
        payloadKey: 'newSection',
        responseDataKey: 'section'
      }
    ]
  });
};

export const addCourseToSchedule = (year, term, courseId) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: ADD_COURSE_TO_SCHEDULE,
    requestObject: {
      url: addCourseToScheduleEndpoint(year, term),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: { courseId },
      responseType: 'json'
    },
    responseDataKey: 'course'
  });
};

export const deleteScheduleCourse = (
  year,
  term,
  courseId
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: DELETE_SCHEDULE_COURSE,
    requestObject: {
      url: deleteScheduleCourseEndpoint(year, term, courseId),
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {},
      responseType: 'json'
    },
    responsePayload: courseId
  });
};

export const putScheduleSection = (
  scheduleYear,
  scheduleTerm,
  courseId,
  componentType,
  sectionId,
  sectionObject
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: PUT_SCHEDULE_SECTION,
    requestObject: {
      url: putScheduleSectionEndpoint(
        scheduleYear,
        scheduleTerm,
        courseId,
        componentType,
        sectionId
      ),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      data: sectionObject,
      responseType: 'json'
    },
    responsePayload: {
      courseId,
      componentType,
      sectionId
    },
    payloadMerge: [
      {
        payloadKey: 'updatedSection',
        responseDataKey: 'section'
      }
    ]
  });
};

export const setSectionConfirmationStatus = sectionId => async dispatch => {
  requestWrapper(dispatch, {
    actionType: SET_SECTION_CONFIRMATION_STATUS,
    requestObject: {
      url: setSectionConfirmationStatusEndpoint(sectionId),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'json'
    },
    responsePayload: {
      sectionId
    },
    payloadMerge: [
      {
        payloadKey: 'confirmed'
      }
    ]
  });
};

export const validateSchedule = (
  schedule,
  componentRequirements
) => async dispatch => {
  dispatch({ type: generateRequestActionTypeName(VALIDATE_SCHEDULE) });
  validate(schedule, componentRequirements)
    .then(result => {
      dispatch({
        type: generateSuccessActionTypeName(VALIDATE_SCHEDULE),
        payload: result
      });
    })
    .catch(error => {
      dispatch({
        type: generateFailureActionTypeName(VALIDATE_SCHEDULE),
        payload: { error }
      });
    });
};

export const deleteScheduleSection = (
  scheduleYear,
  scheduleTerm,
  courseId,
  componentType,
  sectionId
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: DELETE_SCHEDULE_SECTION,
    requestObject: {
      url: deleteScheduleSectionEndpoint(
        scheduleYear,
        scheduleTerm,
        courseId,
        componentType,
        sectionId
      ),
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'json'
    },
    responsePayload: {
      courseId,
      componentType,
      sectionId
    }
  });
};

export const exportSchedule = (token, year, term) => dispatch => {
  dispatch({
    type: generateRequestActionTypeName(EXPORT_SCHEDULE)
  });

  axios(getScheduleEndpoint(year, term), {
    headers: {
      Authorization: 'Bearer ' + token,
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Content-Type':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    responseType: 'blob',
    data: {}
  })
    .then(result => {
      dispatch({
        type: generateSuccessActionTypeName(EXPORT_SCHEDULE),
        payload: result.data
      });

      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Schedule_${year}_${term}.xlsx`);
      document.body.appendChild(link);
      if (window.Cypress) {
        return;
      }
      link.click();
    })
    .catch(error => {
      dispatch({
        type: generateFailureActionTypeName(EXPORT_SCHEDULE),
        payload: { error }
      });
    });
};

export const clearScheduleUpdatingError = () => dispatch => {
  dispatch({
    type: CLEAR_SCHEDULE_UPDATING_ERROR
  });
};
