import React from 'react';
import './CourseList.scss';
import { connect } from 'react-redux';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import { getCourses } from '../../service/actions/courses';
import { showLoadingModal, hideModal } from '../../service/actions/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import AddCourse from './AddCourse';
import CourseListItems from '../common/CourseListItems';
import { GET_COURSES } from '../../service/types';
import { mainViewComponent } from '../common/HOCs/mainViewComponent';

class CourseListView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: ''
    };
  }

  componentDidMount() {
    this.props.getCourses();
  }

  render() {
    const { loading, errorMessage, scopes } = this.props;

    return (
      <div className="course-list">
        <h1>Courses</h1>

        {loading ? null : (
          <>
            {errorMessage && (
              <div className="error-message">
                <FontAwesomeIcon icon={faExclamationCircle} />
                {errorMessage}
              </div>
            )}

            {!errorMessage && scopes.includes('course.write') && <AddCourse />}
            {!errorMessage && this.renderCourseList()}
          </>
        )}
      </div>
    );
  }

  handleInputChange(event) {
    this.setState({
      filter: event.target.value
    });
  }

  renderCourseList() {
    const { courses } = this.props;
    const { filter } = this.state;

    if (courses && courses.length) {
      let filteredCourses = this.filterCourses().filter(
        crs => !!crs.archived !== true
      );

      return (
        <div className="course-results">
          <label htmlFor="course-search-input">Search</label>
          <input
            id="course-search-input"
            className="search-bar"
            type="text"
            value={filter}
            onChange={this.handleInputChange.bind(this)}
          />

          {filteredCourses.length ? (
            <ul className="none interactive course-ul">
              <CourseListItems courses={filteredCourses} selectable={true} />
            </ul>
          ) : (
            <p>No courses match the criteria</p>
          )}
        </div>
      );
    } else {
      return <p className="empty">There have been no courses added.</p>;
    }
  }

  filterCourses() {
    const { courses } = this.props;
    const { filter } = this.state;
    const noArchivedCourses = courses.filter(crs => crs.archived !== true);
    return [].concat(noArchivedCourses).filter(val => {
      if (filter) {
        return this.containsFilter(val);
      }
      return true;
    });
  }

  containsFilter(valueIn) {
    let returnVal = false;
    let modifiedFilter = this.state.filter.replace(/\s+/g, '').toUpperCase();

    if (
      valueIn.courseNumber
        .replace(/\s+/g, '')
        .toUpperCase()
        .indexOf(modifiedFilter) >= 0
    ) {
      returnVal = true;
    } else if (
      valueIn.name
        .replace(/\s+/g, '')
        .toUpperCase()
        .indexOf(modifiedFilter) >= 0
    ) {
      returnVal = true;
    }

    return returnVal;
  }
}

const loadingSelector = createLoadingSelector([GET_COURSES]);
const errorSelector = createErrorMessageSelector([GET_COURSES]);
const mapStateToProps = state => {
  const { auth, courses } = state;
  return {
    scopes: auth.scopes,
    loading: loadingSelector(state),
    courses: courses.list,
    errorMessage: errorSelector(state)
  };
};

const mainViewConfig = {
  pageTitle: 'Courses'
};

export default connect(
  mapStateToProps,
  { getCourses, showLoadingModal, hideModal }
)(
  mainViewComponent({
    component: CourseListView,
    mainViewConfig: mainViewConfig
  })
);
