import React from 'react';
import './AddCourse.scss';
import { connect } from 'react-redux';
import { showLoadingModal, hideModal } from '../../service/actions/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import SelectCourse from '../common/SelectCourse';
import FormReveal from '../common/FormReveal';
import { createErrorMessageSelector } from '../../service/selectors';
import { ADD_COURSE_TO_SCHEDULE } from '../../service/types';

class AddCourse extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormInputValid: false,
      selectedOption: null
    };
    this.baseState = { ...this.state };
    this.addCourseSubmit = this.addCourseSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.formRevealRef = React.createRef();
    this.formValues = {
      courseId: {
        name: 'courses-select',
        valueId: 'courseId',
        defaultValue: 'Select',
        labelTitle: 'Available Courses',
        validatorFunc: () => ({
          isValid: true,
          errorMessage: 'Invalid course selected'
        })
      }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.resetting !== this.state.resetting && this.state.resetting) {
      this.setState({
        resetting: false
      });
    }
    if (prevProps.schedule !== this.props.schedule && this.props.schedule) {
      this.resetForm();
    }
  }

  render() {
    const { resetting } = this.state;

    if (resetting) {
      return null;
    }

    const form = this.renderAddCourseForm();
    if (!form) return null;
    return (
      <div className="add-course-wrapper">
        <FormReveal
          buttonText="Add Course to Schedule"
          buttonId="add-course-to-schedule"
          form={form}
          cancelFunc={() => this.resetForm()}
          ref={this.formRevealRef}
        />
      </div>
    );
  }

  renderAddCourseForm() {
    const { errorMessage, courseList } = this.props;
    if (!courseList) {
      return null;
    }

    const noneArchivedCourses = courseList.filter(crs => crs.archived !== true);

    return (
      <section>
        <h1>Add a Course</h1>
        <form id="course-add-wrapper">
          <div id="course-add-form">
            <div>
              <SelectCourse
                courseFormValue={this.formValues.courseId}
                controlFunc={this.handleOptionChange}
                courseList={noneArchivedCourses}
              />
            </div>
          </div>

          <div className="form-end-wrapper">
            {errorMessage && (
              <div className="form-message unexpected-error-message error-message">
                <FontAwesomeIcon icon={faExclamationCircle} />
                An unexpected error occurred. Please try again.
              </div>
            )}
            <div className="buttons">
              <button
                id="cancel-add-course"
                onClick={event => this.handleCancelClick(event)}
                onKeyUp={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    this.handleCancelClick(event);
                  }
                }}
              >
                Cancel
              </button>
              <button
                id="submit-add-course"
                onClick={event => {
                  event.preventDefault();
                  this.addCourseSubmit();
                }}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    this.addCourseSubmit();
                  }
                }}
                disabled={!this.state.isFormInputValid}
              >
                Add Course to Schedule <FontAwesomeIcon icon={faPlusCircle} />
              </button>
            </div>
          </div>
        </form>
      </section>
    );
  }

  addCourseSubmit() {
    let courseId = this.state.selectedOption;
    this.props.handleSubmitClick(courseId);
  }

  validateFormInput() {
    this.setState(state => ({
      isFormInputValid: this.formValues[state.selectedOption].validatorFunc()
        .isValid
    }));
  }

  handleCancelClick(event) {
    event.preventDefault();
    this.formRevealRef.current.handleCancelClick(event);
  }

  resetForm() {
    let newState = { ...this.baseState, resetting: true };
    Object.keys(this.formValues).forEach(key => {
      newState[key] = undefined;
    });

    this.setState(newState);
  }

  handleOptionChange(selectedOption) {
    this.setState({
      selectedOption: selectedOption.value,
      isFormInputValid: this.formValues[selectedOption.valueId].validatorFunc()
        .isValid
    });
  }
}

const errorSelector = createErrorMessageSelector([ADD_COURSE_TO_SCHEDULE]);
const mapStateToProps = state => {
  const { schedules } = state;
  return {
    errorMessage: errorSelector(state),
    schedule: schedules.viewIndividual
  };
};

export default connect(
  mapStateToProps,
  { showLoadingModal, hideModal }
)(AddCourse);
