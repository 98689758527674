import React from 'react';
import fieldValueMappings from '../common/FieldMappings/FieldMappings';
import {
  deleteSectionPlaceholder,
  updateAssignedCourseSection
} from '../../service/actions/faculty';
import { setWorkPlanSectionConfirmationStatus } from '../../service/actions/faculty';
import ToggleSlider from '../common/ToggleSlider';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Input from '../common/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import { showLoadingModal, hideModal } from '../../service/actions/modal';
import {
  SET_WORKPLAN_SECTION_CONFIRMATION_STATUS,
  UPDATE_ASSIGNED_COURSE_SECTION
} from '../../service/types';

class FacultyViewCourseSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
      isFormInputValid: false
    };
    this.baseState = { ...this.state };
    this.editSectionButton = React.createRef();
    this.handleValueChange = this.handleValueChange.bind(this);
    this.formValidation = {
      validatorFunc: value => {
        return {
          isValid:
            /^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/.test(value) &&
            parseFloat(value) <= 100 &&
            parseFloat(value) >= 0,
          errorMessage: 'Invalid effort'
        };
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.updatingAssignedSection !== prevProps.updatingAssignedSection
    ) {
      if (
        !this.props.updatingAssignedSection &&
        this.props.updatedSection &&
        this.props.updatedSection === this.props.section.id
      ) {
        if (!this.props.updateAssignedSectionError) {
          this.toggleEditEffort();
        }
      }
    }

    if (
      this.props.updatingConfirmedToggle !== prevProps.updatingConfirmedToggle
    ) {
      if (!this.props.toggleError) {
        if (this.props.updatingConfirmedToggle) {
          this.props.showLoadingModal();
        } else {
          this.props.hideModal();
        }
      }
    }
  }

  render() {
    const {
      scopes,
      section,
      component,
      courseComponentTypes,
      effortUnit,
      workPlan,
      schedule,
      facultyMember
    } = this.props;

    if (!section || !component) {
      return null;
    }

    const { fieldRequirements } = courseComponentTypes.find(type => {
      return type.id === component.type;
    });

    const sectionValues = {
      effort: {
        name: 'effort-input',
        idSuffix: section.id,
        valueId: section.id,
        defaultValue: section.effort,
        labelTitle: 'Effort',
        validatorFunc: value => this.formValidation.validatorFunc(value)
      }
    };

    if (section.isAssigned) {
      return (
        <div className="section-details-wrapper assigned">
          <div className="section-details assigned">
            <div className="section-labels assigned">
              <div className="section-fields">
                {schedule.state !== 'completed' &&
                  !section.facultyMemberTba &&
                  section.facultyMember && (
                    <ToggleSlider
                      controlFunc={() =>
                        this.props.setWorkPlanSectionConfirmationStatus(
                          section.id
                        )
                      }
                      value={section.confirmed}
                      confirmToggle={true}
                      disabled={!scopes.includes('schedule.section.confirm')}
                    />
                  )}
                {this.renderField('number', fieldRequirements)}
                {this.renderField('schedule', fieldRequirements)}
                {this.renderField('capacity', fieldRequirements)}
                {this.renderField('location', fieldRequirements)}
              </div>
              <div className={effortUnit === ' TU' ? 'modifier' : ''}>
                {section.enrollmentEffortApplied ? (
                  <span className="label modifier-label">
                    Enrollment Effort
                  </span>
                ) : null}
              </div>
            </div>
            <div className="section-effort">
              <div className="section-effort-number">
                {this.state.isEditMode ? (
                  <>
                    <div
                      className={effortUnit === ' TU' ? 'effort-input-tu' : ''}
                    >
                      <Input
                        formValue={sectionValues.effort}
                        controlFunc={this.handleValueChange}
                        immediatelyValidate={true}
                        hideLabel={true}
                        hideError={true}
                        ref={this.inputRef}
                      />
                    </div>
                    {effortUnit}
                  </>
                ) : (
                  <>
                    {section.enrollmentEffortApplied
                      ? section.roundedEffort
                      : section.effort}
                    {effortUnit}
                  </>
                )}
              </div>
              {this.state.isEditMode ? (
                <div className="section-buttons">
                  <button
                    type="button"
                    className="circle small no-print"
                    onClick={() => this.toggleEditEffort()}
                    onKeyDown={event => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        this.toggleEditEffort();
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                  <button
                    type="button"
                    disabled={!this.state.isFormInputValid}
                    className="circle small"
                    onClick={() => this.updateSectionEffort()}
                    onKeyDown={event => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        this.updateSectionEffort();
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </button>
                </div>
              ) : (
                facultyMember.active === true && (
                  <div className="section-buttons">
                    {workPlan &&
                      workPlan.state !== 'past' &&
                      schedule.state !== 'completed' &&
                      this.renderEditButton()}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="section-details-wrapper planned">
          <div className="section-details planned">
            <div className="section-labels planned">
              <span>Planned Section</span>
              <div className="section-status">
                <span>Planned</span>
              </div>
            </div>
            <div className="section-effort">
              <div className="section-effort-number">
                {section.effort}
                {effortUnit}
              </div>
              <div className="section-buttons">
                {workPlan &&
                  workPlan.state !== 'past' &&
                  facultyMember.active === true &&
                  this.renderDeleteButton()}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  renderEditButton() {
    const { scopes } = this.props;
    if (!scopes.includes('faculty-member.work-plan.write')) {
      return null;
    }

    return (
      <button
        className="circle small no-print"
        ref={this.editSectionButton}
        title="Edit Section"
        tabIndex="0"
        onClick={() => {
          this.toggleEditEffort();
        }}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            event.preventDefault();
            this.toggleEditEffort();
          }
        }}
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>
    );
  }

  renderDeleteButton() {
    const { scopes } = this.props;
    if (!scopes.includes('faculty-member.work-plan.write')) {
      return null;
    }

    return (
      <button
        className="circle small no-print"
        ref={this.editSectionButton}
        title="Delete Section"
        tabIndex="0"
        onClick={() => this.deleteSectionPlaceholder()}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            event.preventDefault();
            this.deleteSectionPlaceholder();
          }
        }}
      >
        <FontAwesomeIcon icon={faTrashAlt} />
      </button>
    );
  }

  renderField(field, fieldReqs) {
    const { section } = this.props;
    const currentFieldMapping = fieldValueMappings[field];

    let sectionValue;
    let className = field + ' field ';
    if (fieldReqs[field] === 'n/a') {
      sectionValue = 'Not Applicable';
      className += 'na';
    } else {
      if (section[field + 'Tba'] === true) {
        sectionValue = 'TBA';
      } else if (!section[field]) {
        if (field === 'capacity') {
          sectionValue = 'No capacity set';
        } else {
          sectionValue = '\u2014';
        }
      } else {
        sectionValue = currentFieldMapping.renderFunc(section[field]);
      }
    }

    if (field === 'number' && section.isNew) {
      className += ' new';
    }

    return (
      <div className={className}>
        {currentFieldMapping.icon && (
          <div className="icon-wrapper">
            <FontAwesomeIcon icon={currentFieldMapping.icon} />
          </div>
        )}
        {sectionValue}
      </div>
    );
  }

  toggleEditEffort() {
    this.setState(prevState => ({
      isEditMode: !prevState.isEditMode
    }));
  }

  updateSectionEffort() {
    const {
      updateAssignedCourseSection,
      schedule,
      section,
      scheduleCourse,
      selectedYear
    } = this.props;

    let facultyMemberId = this.props.match.params.facultyMemberId;
    let assignedEffortObj = {
      actualEffort: parseFloat(this.state[section.id])
    };

    updateAssignedCourseSection(
      facultyMemberId,
      selectedYear,
      schedule.term,
      scheduleCourse.id,
      section.id,
      assignedEffortObj
    );
  }

  handleValueChange(formValue) {
    const isFormInputValid = this.formValidation.validatorFunc(formValue.value);
    this.setState({
      [formValue.valueId]: formValue.value,
      isFormInputValid: isFormInputValid.isValid
    });
  }

  deleteSectionPlaceholder() {
    const {
      component,
      deleteSectionPlaceholder,
      schedule,
      scheduleCourse,
      selectedYear
    } = this.props;
    let facultyMemberId = this.props.match.params.facultyMemberId;
    let sectionsArr = [];
    scheduleCourse.components.forEach(c => {
      if (component.type === c.type) {
        sectionsArr.push({
          componentType: c.type,
          sectionCount: parseInt(component.sectionCount) - 1
        });
      } else {
        if (c.sectionCount > 0) {
          sectionsArr.push({
            componentType: c.type,
            sectionCount: c.sectionCount
          });
        }
      }
    });

    let sectionsObj = { sections: sectionsArr };

    deleteSectionPlaceholder(
      facultyMemberId,
      selectedYear,
      schedule.term,
      scheduleCourse.id,
      sectionsObj
    );
  }
}

const loadingSelector = createLoadingSelector([UPDATE_ASSIGNED_COURSE_SECTION]);
const errorSelector = createErrorMessageSelector([
  UPDATE_ASSIGNED_COURSE_SECTION
]);
const toggleSelector = createLoadingSelector([
  SET_WORKPLAN_SECTION_CONFIRMATION_STATUS
]);
const toggleErrorSelector = createErrorMessageSelector([
  SET_WORKPLAN_SECTION_CONFIRMATION_STATUS
]);

const mapStateToProps = state => {
  const { config, auth, faculty } = state;

  return {
    scopes: auth.scopes,
    updatingAssignedSection: loadingSelector(state),
    updateAssignedSectionError: errorSelector(state),
    updatingConfirmedToggle: toggleSelector(state),
    toggleError: toggleErrorSelector(state),
    workPlan: faculty.viewWorkPlan,
    courseComponentTypes:
      config && config.config && config.config.courseComponentTypes,
    facultyMember: faculty.viewIndividual,
    updatedSection: faculty.updatedSection
  };
};

export default connect(mapStateToProps, {
  deleteSectionPlaceholder,
  updateAssignedCourseSection,
  setWorkPlanSectionConfirmationStatus,
  showLoadingModal,
  hideModal
})(withRouter(FacultyViewCourseSection));
