import React from 'react';
import { withRouter, Route } from 'react-router-dom';
import FacultyMemberListView from './FacultyMemberListView';
import FacultyMemberView from './FacultyMemberView';

const FacultyMembersPage = props => {
  const { match } = props;
  return (
    <>
      <Route exact path={match.path} component={FacultyMemberListView} />
      <Route
        path={match.path + '/:facultyMemberId'}
        component={FacultyMemberView}
      />
    </>
  );
};

export default withRouter(FacultyMembersPage);
