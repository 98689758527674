import React from 'react';
import './StudentGroupList.scss';
import { connect } from 'react-redux';
import { getStudentGroupList } from '../../service/actions/studentGroups';
import { getPlanOfStudyList } from '../../service/actions/plansOfStudy';
import { showLoadingModal, hideModal } from '../../service/actions/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons/faChevronCircleRight';
import AddStudentGroup from './AddStudentGroup';
import { withRouter } from 'react-router-dom';
import sortStudentGroups from '../common/helpers/sortStudentGroups';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import { GET_CONFIG, GET_STUDENT_GROUPS } from '../../service/types';
import { mainViewComponent } from '../common/HOCs';

class StudentGroupListView extends React.Component {
  componentDidMount() {
    this.props.getStudentGroupList();
    this.props.getPlanOfStudyList();
  }

  render() {
    const { loading, errorMessage, scopes } = this.props;

    return (
      <div className="">
        <h1>Student Groups</h1>
        {loading ? null : (
          <>
            {errorMessage && (
              <div className="error-message student-groups-error-message">
                <FontAwesomeIcon icon={faExclamationCircle} />
                {errorMessage}
              </div>
            )}

            {!errorMessage && scopes.includes('student-group.write') && (
              <AddStudentGroup />
            )}
            {!errorMessage && this.renderStudentGroupList()}
          </>
        )}
      </div>
    );
  }

  renderStudentGroupList() {
    const { studentGroupList, termList } = this.props;

    if (studentGroupList && studentGroupList.length && termList) {
      let sortedStudentGroupList = this.buildStudentGroupListJsx();

      return (
        <div className="student-group-results">
          <ul className="none">{sortedStudentGroupList}</ul>
        </div>
      );
    } else {
      return (
        <p className="empty no-student-groups-message">
          No student groups have been added.
        </p>
      );
    }
  }

  buildStudentGroupListJsx() {
    const { termList, studentGroupList } = this.props;

    let programConcentrationCombos = studentGroupList
      .filter(s => s.state !== 'completed')
      .reduce(function(res, obj) {
        const programName = obj.program.name;
        const concentrationName = obj.concentration && obj.concentration.name;
        let keyName = programName;
        if (concentrationName) {
          keyName += ', ' + concentrationName;
        }

        res[keyName] = res[keyName] || {};
        res[keyName].tracks = res[keyName].tracks || {};
        res[keyName].tracks[obj.track] = (
          res[keyName].tracks[obj.track] || []
        ).concat(obj);

        return res;
      }, {});

    return Object.keys(programConcentrationCombos)
      .sort((a, b) => {
        return ('' + a).localeCompare(b);
      })
      .map(programKey => (
        <li className="program" key={programKey}>
          <h2>{programKey}</h2>
          <ul className="none">
            {Object.keys(programConcentrationCombos[programKey].tracks)
              .sort((a, b) => {
                return ('' + a).localeCompare(b);
              })
              .map(track => (
                <li key={track}>
                  <h3>{track}</h3>
                  <ul className="none interactive sg-list">
                    {programConcentrationCombos[programKey].tracks[track]
                      .sort(sortStudentGroups(termList))
                      .map((item, index) => (
                        <li
                          key={index}
                          className={
                            'with-anchor ' +
                            (item.planOfStudyId ? undefined : 'alert')
                          }
                        >
                          <a
                            href={this.props.match.path + '/' + item.id}
                            onClick={event =>
                              this.studentGroupClicked(event, item.id, false)
                            }
                            onKeyUp={event => {
                              this.studentGroupKeyDowned(event, item.id);
                            }}
                          >
                            <div className="content">
                              <span className="dates">
                                {`${item.startTerm}\u00A0${item.startYear}`}
                                {item.planOfStudyId &&
                                  `\u2013${item.endTerm}\u00A0${item.endYear}`}
                              </span>
                              <span className="group-name">{item.name}</span>
                              <span className="plan-of-study-message">
                                {!item.planOfStudyId && <>No plan of study</>}
                              </span>
                            </div>
                            <FontAwesomeIcon icon={faChevronCircleRight} />
                          </a>
                        </li>
                      ))}
                  </ul>
                </li>
              ))}
          </ul>
        </li>
      ));
  }

  studentGroupKeyDowned(event, id) {
    if (event.key === 'Enter') {
      this.studentGroupClicked(event, id, true);
    }
  }

  studentGroupClicked(event, id, keyed) {
    event.preventDefault();

    const pathname = this.props.match.path + '/' + id;
    if (event.ctrlKey || event.metaKey) {
      window.open(this.props.history.createHref({ pathname }));
    } else {
      this.props.history.push({
        pathname,
        state: {
          keyed
        }
      });
    }
  }
}

const loadingSelector = createLoadingSelector([GET_CONFIG, GET_STUDENT_GROUPS]);
const errorSelector = createErrorMessageSelector([GET_STUDENT_GROUPS]);
const mapStateToProps = state => {
  const { auth, config, studentGroups } = state;
  return {
    scopes: auth.scopes,
    loading: loadingSelector(state),
    errorMessage: errorSelector(state),
    studentGroupList: studentGroups.list,
    termList: config.termsByCalendarYear
  };
};

const mainViewConfig = {
  pageTitle: 'Student Groups'
};

export default connect(
  mapStateToProps,
  { getStudentGroupList, getPlanOfStudyList, showLoadingModal, hideModal }
)(
  withRouter(
    mainViewComponent({ component: StudentGroupListView, mainViewConfig })
  )
);
