import React from 'react';
import PropTypes from 'prop-types';
import './ConnectedSelector.scss';

class ConnectedSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOptions: props.preselectedOptions || []
    };
  }

  componentDidUpdate(_, prevState) {
    if (prevState.selectedOptions !== this.state.selectedOptions) {
      this.props.controlFunc(this.state.selectedOptions);
    }
  }

  render() {
    const { options, disabled, upperCase, actionable } = this.props;
    const { selectedOptions } = this.state;
    return (
      <div
        className={
          'connected-selector ' +
          (disabled ? 'disabled ' : '') +
          (actionable ? 'actionable ' : '')
        }
      >
        {options.map(option => (
          <div
            key={option}
            className={
              (selectedOptions.indexOf(option) >= 0
                ? 'selected opt '
                : 'opt ') +
              (upperCase ? 'uppercase ' : '') +
              (disabled ? 'disabled ' : '') +
              (actionable ? 'actionable ' : '')
            }
            tabIndex={disabled ? '-1' : '0'}
            onClick={disabled ? undefined : () => this.optionSelected(option)}
            onKeyDown={
              disabled ? undefined : event => this.optionKeyed(event, option)
            }
          >
            {option}
          </div>
        ))}
      </div>
    );
  }

  optionSelected(option) {
    if (this.props.multiSelect === false) {
      this.setState({
        selectedOptions: [option]
      });
      return;
    }

    const { selectedOptions } = this.state;
    let newOptions = [].concat(selectedOptions);
    const optionIndex = selectedOptions.indexOf(option);
    if (optionIndex >= 0) {
      newOptions.splice(optionIndex, 1);
    } else {
      newOptions.push(option);
    }

    this.setState({
      selectedOptions: newOptions
    });
  }

  optionKeyed(event, option) {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault();
      this.optionSelected(option);
    } else if (event.key === 'ArrowLeft' || event.key === 'ArrowUp') {
      event.preventDefault();
      this.shiftFocus('prev');
    } else if (event.key === 'ArrowRight' || event.key === 'ArrowDown') {
      event.preventDefault();
      this.shiftFocus('next');
    }
  }

  shiftFocus(direction) {
    const elements = {
      prev: document.activeElement.previousElementSibling,
      next: document.activeElement.nextElementSibling
    };
    if (elements[direction] && elements[direction].classList.contains('opt')) {
      elements[direction].focus();
    }
  }
}

ConnectedSelector.propTypes = {
  options: PropTypes.array.isRequired,
  preselectedOptions: PropTypes.array,
  controlFunc: PropTypes.func.isRequired,
  multiSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  upperCase: PropTypes.bool,
  actionable: PropTypes.bool
};

ConnectedSelector.defaultProps = {
  multiSelect: true,
  disabled: false,
  upperCase: true
};
export default ConnectedSelector;
