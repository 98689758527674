import React from 'react';
import PropTypes from 'prop-types';
import './UpdateWorkItem.scss';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import Input from '../common/Input';
import Textarea from '../common/Textarea';
import Select from '../common/Select';
import { updateWorkItem, deleteWorkItem } from '../../service/actions/faculty';
import {
  hideSidePanel,
  updateTrapFocus
} from '../../service/actions/sidePanel';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import {
  UPDATE_WORK_ACTIVITY,
  DELETE_WORK_ACTIVITY,
  UPDATE_FACULTY_MEMBER,
  PUT_WORKPLAN_CURRYEAR
} from '../../service/types';

class UpdateWorkItem extends React.Component {
  constructor(props) {
    super(props);

    if (!props.workItem) {
      console.error('no work item provided to the side panel');
    }

    const { workItem, workTypes, workType, effortUnit, effortMode } = props;

    const workTypeName = workTypes.find(wt => wt.id === workItem.workType).name;

    this.state = {
      isFormInputValid: true,
      resetting: false,
      workType: workTypeName,
      name: workItem.name,
      description: workItem.description,
      actualEffort: workItem.actualEffort
    };
    this.baseState = { ...this.state };

    this.handleValueChange = this.handleValueChange.bind(this);

    this.formValues = {
      workType: {
        name: 'workType',
        valueId: 'workType',
        labelTitle: 'Type',
        defaultValue: '',
        validatorFunc: value => {
          return {
            // If not non-teaching division, must be a category type -- no need for validation
            isValid:
              workType.id !== 'non-teaching' ||
              (value && value !== '' && value !== 'Select'),
            errorMessage: 'Invalid'
          };
        }
      },
      name: {
        name: 'name',
        valueId: 'name',
        labelTitle: 'Name',
        defaultValue: this.state.name,
        validatorFunc: value => {
          return {
            isValid:
              /^.{1,50}$/.test(value) && value && value.trim().length > 0,
            errorMessage: 'Invalid activity name'
          };
        }
      },
      description: {
        name: 'description',
        valueId: 'description',
        labelTitle: 'Description',
        defaultValue: this.state.description,
        validatorFunc: value => {
          return {
            isValid: /^.{0,250}$/.test(value),
            errorMessage: 'Invalid activity description'
          };
        }
      },
      actualEffort: {
        name: 'actualEffort',
        valueId: 'actualEffort',
        labelTitle: effortMode === '%' ? `${effortUnit} Effort` : 'Effort',
        defaultValue: this.state.actualEffort,
        validatorFunc:
          effortMode === '%'
            ? value => ({
                isValid:
                  /^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/.test(value) &&
                  value &&
                  parseFloat(value) >= 0 &&
                  parseFloat(value) <= 100,
                errorMessage: 'Invalid effort'
              })
            : value => ({
                isValid:
                  !value ||
                  (/^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/.test(value) &&
                    value &&
                    parseFloat(value) >= 0 &&
                    parseFloat(value) <= 100) ||
                  value.trim() === '',
                errorMessage: 'Invalid effort'
              })
      }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.updating !== prevProps.updating) {
      if (!this.props.updating && !this.props.errorMessage) {
        this.closePanel(true);
      }
    }
    if (this.state.isFormInputValid !== prevState.isFormInputValid) {
      this.props.updateTrapFocus();
    }
  }

  render() {
    const { workTypes, errorMessage, workType } = this.props;

    return (
      <section id="update-work-item-wrapper">
        <div className="top first-focus">
          <h1>Update Activity</h1>
          <button
            className="circle"
            ref={this.closeButton}
            tabIndex="0"
            title="Close Section Panel"
            onClick={event => {
              event.preventDefault();
              this.closePanel(false);
            }}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                this.closePanel(true);
              }
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        <form id="work-item-form-wrapper">
          <div id="entry-form">
            {workType.id === 'non-teaching' && (
              <div className="type">
                <Select
                  formValue={this.formValues.workType}
                  controlFunc={this.handleValueChange}
                  startingValue={this.state.workType}
                  optionList={workTypes
                    .filter(x => x.division === 'non-teaching')
                    .map(x => x.name)}
                />
              </div>
            )}
            <div className="name-and-delete">
              <div className="name">
                <Input
                  formValue={this.formValues.name}
                  controlFunc={this.handleValueChange}
                  immediatelyValidate={false}
                />
              </div>
              {this.renderDeleteButton()}
            </div>
            <div className="description">
              <Textarea
                formValue={this.formValues.description}
                controlFunc={this.handleValueChange}
                immediatelyValidate={false}
                optional={true}
              />
            </div>
            <div className="actual-effort">
              <Input
                formValue={this.formValues.actualEffort}
                controlFunc={this.handleValueChange}
                immediatelyValidate={false}
                optional={this.props.effortMode === '%' ? false : true}
              />
            </div>
          </div>

          <div className="form-end-wrapper">
            {errorMessage && (
              <div className="form-message unexpected-error-message error-message">
                <FontAwesomeIcon icon={faExclamationCircle} />
                An unknown error occurred. Please try again.
              </div>
            )}

            <div className="buttons">
              <button
                id="cancel-work-item-entry"
                onClick={event => {
                  event.preventDefault();
                  this.closePanel(false);
                }}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    this.closePanel(true);
                  }
                }}
              >
                Cancel
              </button>
              <button
                id="submit-work-item-entry"
                onClick={event => this.handleSubmitClick(event)}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    this.handleSubmitClick(event);
                  }
                }}
                disabled={!this.state.isFormInputValid}
              >
                Save
                <FontAwesomeIcon icon={faCheckCircle} />
              </button>
            </div>
          </div>
        </form>
      </section>
    );
  }

  renderDeleteButton() {
    const { scopes } = this.props;
    const { deleting } = this.state;
    if (!scopes.includes('faculty-member.work-plan.write')) {
      return null;
    }

    if (deleting) {
      return (
        <div className="deletion-buttons">
          <span className="confirmation-message">Delete activity?</span>
          <button
            className="circle"
            title="Cancel deletion"
            tabIndex="0"
            onClick={event => {
              event.preventDefault();
              this.setState({
                deleting: false
              });
            }}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                this.setState({
                  deleting: false
                });
              }
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <button
            className="circle"
            title="Confirm deletion"
            tabIndex="0"
            onClick={event => {
              event.preventDefault();
              this.deleteWorkItem();
            }}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                this.deleteWorkItem();
              }
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </button>
        </div>
      );
    }
    return (
      <div className="deletion-buttons">
        <button
          className="circle"
          title="Delete Section"
          tabIndex="0"
          onClick={event => {
            event.preventDefault();
            this.setState({
              deleting: true
            });
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              event.preventDefault();
              this.setState({
                deleting: true
              });
            }
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </div>
    );
  }

  handleSubmitClick(event) {
    event.preventDefault();
    this.updateWorkItem();
  }

  handleDeleteClick(event) {
    event.preventDefault();
    this.deleteWorkItem();
  }

  updateWorkItem() {
    this.validateFormInput();
    const { workType, name, description, actualEffort } = this.state;

    const {
      workItem,
      workTypes,
      facultyMemberId,
      academicYear,
      updateWorkItem
    } = this.props;

    const workTypeId = workTypes.find(wt => wt.name === workType).id;

    updateWorkItem(facultyMemberId, academicYear, workItem.id, {
      workType: workTypeId,
      name,
      description: description || undefined,
      actualEffort: (() => {
        if (parseFloat(actualEffort) >= 0) {
          return parseFloat(actualEffort);
        }

        return undefined;
      })()
    });
  }

  deleteWorkItem() {
    const {
      workItem,
      facultyMemberId,
      academicYear,
      deleteWorkItem
    } = this.props;

    deleteWorkItem(facultyMemberId, academicYear, workItem.id);
  }

  handleValueChange(formValue) {
    this.setState({
      [formValue.valueId]: formValue.value,
      isFormInputValid: Object.keys(this.formValues).reduce((result, next) => {
        let theResult;
        if (next === formValue.valueId) {
          theResult =
            result &&
            this.formValues[next].validatorFunc(formValue.value).isValid;
        } else {
          theResult =
            result &&
            this.formValues[next].validatorFunc(this.state[next]).isValid;
        }
        return theResult;
      }, true)
    });
  }

  validateFormInput() {
    const fieldsValid = Object.keys(this.formValues).reduce((result, next) => {
      let theResult = this.formValues[next].validatorFunc(this.state[next])
        .isValid;

      return result && theResult;
    }, true);

    this.setState({
      isFormInputValid: fieldsValid
    });
  }

  closePanel(shouldSendFocus) {
    this.props.hideSidePanel({
      sendFocus: !!shouldSendFocus
    });
  }
}

UpdateWorkItem.propTypes = {
  facultyMemberId: PropTypes.number.isRequired,
  academicYear: PropTypes.number.isRequired,
  workItem: PropTypes.object.isRequired,
  effortUnit: PropTypes.string,
  workTypes: PropTypes.array.isRequired
};

const updatingSelector = createLoadingSelector([
  UPDATE_FACULTY_MEMBER,
  PUT_WORKPLAN_CURRYEAR,
  UPDATE_WORK_ACTIVITY,
  DELETE_WORK_ACTIVITY
]);
const errorSelector = createErrorMessageSelector([
  UPDATE_WORK_ACTIVITY,
  DELETE_WORK_ACTIVITY
]);
const mapStateToProps = state => {
  const { auth } = state;
  return {
    scopes: auth.scopes,
    updating: updatingSelector(state),
    errorMessage: errorSelector(state)
  };
};

export default connect(mapStateToProps, {
  updateWorkItem,
  deleteWorkItem,
  hideSidePanel,
  updateTrapFocus
})(UpdateWorkItem);
