import React from 'react';
import { withRouter, Route } from 'react-router-dom';

import CourseListView from './CourseListView';
import CourseView from './CourseView';

const CoursesPage = props => {
  const { match } = props;
  return (
    <>
      <Route exact path={match.path} component={CourseListView} />
      <Route path={match.path + '/:courseId'} component={CourseView} />
    </>
  );
};

export default withRouter(CoursesPage);
