import React from 'react';
import './SkipLink.scss';
const SkipLink = () => {
  return (
    <button
      id="skip-link"
      tabIndex="0"
      onClick={() => {
        sendFocusToFirstH1();
      }}
      onKeyDown={event => {
        if (event.key === 'Enter') {
          sendFocusToFirstH1();
          event.preventDefault();
        }
      }}
    >
      Skip to content
    </button>
  );
};

export const sendFocusToFirstH1 = () => {
  let firstH1 =
    document.querySelector('#main-content .first-focus') ||
    document.querySelector('#main-content h1');
  if (!firstH1) return;
  firstH1.setAttribute('tabindex', '0');
  firstH1.focus();
};

export default SkipLink;
