import React from 'react';
import PropTypes from 'prop-types';
import './SectionPanel.scss';
import Input from '../../common/Input';
import ToggleSlider from '../../common/ToggleSlider';
import SelectFaculty from '../../common/SelectFaculty';
import SelectLocation from '../../common/SelectLocation';
import SchedulePicker from './SchedulePicker';
import fieldValueMappings from '../../common/FieldMappings/FieldMappings';
import {
  hideSidePanel,
  updateTrapFocus
} from '../../../service/actions/sidePanel';
import { connect } from 'react-redux';
import {
  addScheduleSection,
  putScheduleSection,
  deleteScheduleSection
} from '../../../service/actions/schedules';
import { getFacultyMembers } from '../../../service/actions/faculty';
import { getLocations } from '../../../service/actions/locations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import {
  createErrorMessageSelector,
  createLoadingSelector
} from '../../../service/selectors';
import {
  ADD_SCHEDULE_SECTION,
  PUT_SCHEDULE_SECTION,
  GET_LOCATIONS,
  DELETE_SCHEDULE_SECTION
} from '../../../service/types';

class SectionPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormInputValid: false,
      duplicateError: '',
      isNewSection: !props.section,
      deleting: false
    };
    this.baseState = { ...this.state };

    this.formValues = {
      sectionNumber: {
        name: 'section-number',
        labelTitle: 'Section Number',
        valueId: 'number',
        defaultValue: '',
        validatorFunc: value => {
          if (
            props.component.sections.findIndex(sect => {
              if (!sect.number || !value) return false;
              return (
                sect.number.toUpperCase() === value.toUpperCase() &&
                sect.id !== (props.section && props.section.id)
              );
            }) >= 0
          ) {
            return {
              isValid: false,
              errorMessage: 'Duplicate section number. Please try again.'
            };
          }
          return {
            isValid: /^\w{1,3}$/.test(value),
            errorMessage: 'Section number is invalid'
          };
        }
      },
      facultyMemberId: {
        name: 'faculty-select',
        valueId: 'facultyMemberId',
        defaultValue: 'Select',
        labelTitle: 'Faculty',
        validatorFunc: () => ({
          isValid: true,
          errorMessage: 'Invalid'
        })
      },
      capacity: {
        name: 'capacity',
        labelTitle: 'Student Capacity',
        valueId: 'capacity',
        defaultValue: '',
        validatorFunc: value => ({
          isValid:
            typeof value === 'undefined' ||
            value === '' ||
            (parseInt(value) > 0 &&
              parseInt(value) < 1000 &&
              /^\d{1,3}$/.test(value)),
          errorMessage: 'Capacity is invalid'
        })
      },
      locationId: {
        name: 'location-select',
        valueId: 'locationId',
        defaultValue: 'Select',
        labelTitle: 'Location',
        validatorFunc: () => ({
          isValid: true,
          errorMessage: 'Invalid'
        })
      },
      scheduleDaysOfWeek: {
        name: 'days-of-week',
        valueId: 'scheduleDaysOfWeek',
        defaultValue: [],
        labelTitle: 'Days of Week',
        validatorFunc: () => ({
          isValid: true,
          errorMessage: 'Invalid'
        })
      },
      scheduleStartTime: {
        name: 'start-time',
        valueId: 'scheduleStartTime',
        defaultValue: '',
        labelTitle: 'Start Time',
        validatorFunc: () => ({
          isValid: true,
          errorMessage: 'Invalid'
        })
      },
      scheduleEndTime: {
        name: 'end-time',
        valueId: 'scheduleEndTime',
        defaultValue: '',
        labelTitle: 'End Time',
        validatorFunc: () => ({
          isValid: true,
          errorMessage: 'Invalid'
        })
      }
    };

    if (!this.state.isNewSection && props.section) {
      let existingSectionState = {};
      if (props.section.number) {
        existingSectionState[this.formValues.sectionNumber.valueId] =
          props.section.number;
        this.formValues.sectionNumber.defaultValue = props.section.number;
      }
      if (props.section.schedule) {
        existingSectionState[this.formValues.scheduleDaysOfWeek.valueId] =
          props.section.schedule.daysOfWeek;
        existingSectionState[this.formValues.scheduleStartTime.valueId] =
          props.section.schedule.startTime;
        existingSectionState[this.formValues.scheduleEndTime.valueId] =
          props.section.schedule.endTime;
      }
      if (props.section.location) {
        existingSectionState[this.formValues.locationId.valueId] =
          props.section.location.id;
      }
      if (props.section.facultyMember) {
        existingSectionState[this.formValues.facultyMemberId.valueId] =
          props.section.facultyMember.id;
      }
      if (props.section.capacity) {
        existingSectionState[this.formValues.capacity.valueId] =
          '' + props.section.capacity;
        this.formValues.capacity.defaultValue = '' + props.section.capacity;
      }

      ['facultyMemberTba', 'locationTba', 'scheduleTba'].map(elem => {
        if (props.section[elem]) {
          existingSectionState[elem] = props.section[elem] || false;
        }
        return null;
      });

      this.state = {
        ...this.state,
        ...existingSectionState
      };
    }
  }

  componentDidMount() {
    if (!this.state.isNewSection) {
      setTimeout(() => {
        this.isFormInputValid();
      }, 0);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.updatingSection !== prevProps.updatingSection) {
      if (!this.props.updatingSection && !this.props.errorMessage) {
        this.closePanel(true);
      }
    }
    if (this.state.isFormInputValid !== prevState.isFormInputValid) {
      this.props.updateTrapFocus();
    }
  }

  render() {
    const {
      course,
      component,
      courseComponents,
      errorMessage,
      scopes
    } = this.props;
    const { isNewSection } = this.state;
    if (!course || !component) {
      return null;
    }
    const creditHoursText = course.variableCredit
      ? 'Variable Credit'
      : `${course.credits} credit` + (course.credits !== 1 ? 's' : '');
    const projectedEnrollmentText = course.projectedEnrollment
      ? `${course.projectedEnrollment} student` +
        (course.projectedEnrollment !== 1 ? 's' : '')
      : 'No enrollment set';

    const componentTypeIndex =
      courseComponents.findIndex(configComponent => {
        return configComponent.id === component.type;
      }) + 1;

    return (
      <form>
        <div className="section-wrapper">
          <div className="course-info">
            <div className="top first-focus">
              <h2 className="h1">
                {course.courseNumber.replace(/(\d)/, ' $1')}
              </h2>
              <button
                className="circle"
                ref={this.closeButton}
                tabIndex="0"
                title="Close Section Panel"
                onClick={event => {
                  event.preventDefault();
                  this.closePanel(false);
                }}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    this.closePanel(true);
                  }
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <p>{course.name}</p>
            <p>{creditHoursText}</p>
            <p>{projectedEnrollmentText}</p>
          </div>
          <div className="section-info">
            <div className={`label component-${componentTypeIndex}-bg`}>
              {component.type}
            </div>
            <div className="section-top">
              <div className="section-number">
                <h2 className="h1">
                  SECT
                  {!scopes.includes('schedule.section.write') &&
                    ' ' + this.state[this.formValues.sectionNumber.valueId]}
                </h2>
                {scopes.includes('schedule.section.write') && (
                  <Input
                    formValue={this.formValues.sectionNumber}
                    controlFunc={event => this.handleValueChange(event)}
                    immediatelyValidate={true}
                    hideLabel={true}
                  />
                )}
              </div>
              {this.renderDeleteButton()}
            </div>
            {this.renderSchedule()}
            {this.renderFaculty()}
            {this.renderCapacity()}
            {this.renderLocation()}
          </div>

          {errorMessage && (
            <div className="form-message unexpected-error-message error-message">
              <FontAwesomeIcon icon={faExclamationCircle} />
              {errorMessage}
            </div>
          )}

          <div className="buttons">
            <button
              id="cancel-sg-entry"
              onClick={event => {
                event.preventDefault();
                this.closePanel(false);
              }}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  this.closePanel(true);
                }
              }}
            >
              Cancel
            </button>
            <button
              id="submit-sg-entry"
              onClick={event => {
                event.preventDefault();
                this.handleSubmitClick();
              }}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  this.handleSubmitClick();
                }
              }}
              disabled={!this.state.isFormInputValid}
            >
              {isNewSection ? 'Add Section' : 'Save'}
              {isNewSection ? (
                <FontAwesomeIcon icon={faPlusCircle} />
              ) : (
                <FontAwesomeIcon icon={faCheckCircle} />
              )}
            </button>
          </div>
        </div>
      </form>
    );
  }

  renderDeleteButton() {
    const { scopes } = this.props;
    const { deleting, isNewSection } = this.state;
    if (!scopes.includes('schedule.section.write') || isNewSection) {
      return null;
    }

    if (deleting) {
      return (
        <div className="deletion-buttons">
          <span className="confirmation-message">Delete section?</span>
          <button
            className="circle"
            title="Cancel deletion"
            tabIndex="0"
            onClick={event => {
              event.preventDefault();
              this.setState({
                deleting: false
              });
            }}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                this.setState({
                  deleting: false
                });
              }
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <button
            className="circle"
            title="Confirm deletion"
            tabIndex="0"
            onClick={event => {
              event.preventDefault();
              this.deleteSection();
            }}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                this.deleteSection();
              }
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </button>
        </div>
      );
    }
    return (
      <div className="deletion-buttons">
        <button
          className="circle"
          title="Delete Section"
          tabIndex="0"
          onClick={event => {
            event.preventDefault();
            this.setState({
              deleting: true
            });
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              event.preventDefault();
              this.setState({
                deleting: true
              });
            }
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </div>
    );
  }

  renderField(fieldName, children) {
    const { component, courseComponents, scopes } = this.props;
    const fieldRequirement = courseComponents.find(componentType => {
      return componentType.id === component.type;
    }).fieldRequirements[fieldName];

    if (fieldRequirement === 'n/a') {
      return null;
    }

    if (!scopes.includes('schedule.section.write')) {
      if (fieldName === 'facultyMember') {
        if (!scopes.includes('schedule.section.faculty-member.write')) {
          return null;
        }
      } else {
        return null;
      }
    }

    const toggleOffTba = () => {
      this.handleValueChange({
        valueId: [fieldName + 'Tba'],
        value: false
      });
    };

    const IS_DISABLED = this.state[fieldName + 'Tba'];
    return (
      <div className={fieldName + ' field'}>
        <div className="heading">
          <h3>
            <FontAwesomeIcon icon={fieldValueMappings[fieldName].icon} />
            {fieldValueMappings[fieldName].label}
          </h3>
          {fieldName !== 'capacity' && this.renderTba(fieldName)}
        </div>
        <div
          className={IS_DISABLED ? 'disabled actionable ' : ''}
          onClick={IS_DISABLED ? () => toggleOffTba() : undefined}
        >
          {children}
        </div>
      </div>
    );
  }

  renderTba(fieldName) {
    const toggleFunction = value => {
      this.handleValueChange({
        valueId: [fieldName + 'Tba'],
        value: value || false
      });
    };

    const value = this.state[fieldName + 'Tba'] || false;
    return (
      <ToggleSlider
        controlFunc={value => toggleFunction(value)}
        leftLabel={'TBA'}
        value={value}
      />
    );
  }

  renderFaculty() {
    const { facultyMembers, course, component, proposedTeaching } = this.props;
    const isDisabled = !!this.state.facultyMemberTba;
    const courseComponent = { courseId: course.id, ...component };

    const children = (
      <SelectFaculty
        facultyFormValue={this.formValues.facultyMemberId}
        value={this.state[this.formValues.facultyMemberId.valueId]}
        valueLabel={
          this.state[this.formValues.facultyMemberId.valueId + 'label']
        }
        controlFunc={event => this.handleValueChange(event)}
        facultyList={facultyMembers || []}
        proposedTeachingList={proposedTeaching || []}
        courseComponent={courseComponent}
        hideLabel={true}
        containerStyle={{ zIndex: 100 }}
        disabled={isDisabled}
        actionable={true}
      />
    );
    return this.renderField('facultyMember', children);
  }

  renderCapacity() {
    const children = (
      <Input
        formValue={this.formValues.capacity}
        controlFunc={event => this.handleValueChange(event)}
        hideErrorMessage={true}
        hideLabel={true}
      />
    );
    return this.renderField('capacity', children);
  }

  renderLocation() {
    const { locations, component, courseComponents, section } = this.props;
    const isDisabled = !!this.state.locationTba;
    const validLocationTypes =
      courseComponents.find(
        componentType => componentType.id === component.type
      ).compatibleLocationTypes || [];
    const filteredLocations = locations.filter(location =>
      validLocationTypes.includes(location.type)
    );

    const children = (
      <SelectLocation
        locationFormValue={this.formValues.locationId}
        value={this.state[this.formValues.locationId.valueId]}
        startingValue={section && section.location && section.location.id}
        controlFunc={event => this.handleValueChange(event)}
        locationsList={filteredLocations || []}
        hideLabel={true}
        disabled={isDisabled}
        actionable={true}
      />
    );
    return this.renderField('location', children);
  }

  renderSchedule() {
    const isDisabled = !!this.state.scheduleTba;

    const children = (
      <SchedulePicker
        daysOfWeekFormValue={this.formValues.scheduleDaysOfWeek}
        startTimeFormValue={this.formValues.scheduleStartTime}
        endTimeFormValue={this.formValues.scheduleEndTime}
        daysOfWeekValue={this.state[this.formValues.scheduleDaysOfWeek.valueId]}
        startTimeValue={this.state[this.formValues.scheduleStartTime.valueId]}
        endTimeValue={this.state[this.formValues.scheduleEndTime.valueId]}
        controlFunc={event => this.handleScheduleChange(event)}
        disabled={isDisabled}
        actionable={true}
      />
    );
    return this.renderField('schedule', children);
  }

  isFormInputValid() {
    let isValid = true;
    const { component, courseComponents } = this.props;
    const fieldRequirements = courseComponents.find(componentType => {
      return componentType.id === component.type;
    }).fieldRequirements;

    if (
      !this.formValues.sectionNumber.validatorFunc(
        this.state[this.formValues.sectionNumber.valueId]
      ).isValid
    ) {
      isValid = false;
    }

    if (fieldRequirements.schedule !== 'n/a') {
      if (
        this.state.scheduleTba !== true &&
        this.state[this.formValues.scheduleDaysOfWeek.valueId] === null &&
        this.state[this.formValues.scheduleStartTime.valueId] === null &&
        this.state[this.formValues.scheduleEndTime.valueId] === null &&
        this.state.partialSchedule === true
      ) {
        isValid = false;
      }
    }

    if (fieldRequirements.capacity !== 'n/a') {
      if (
        !this.formValues.capacity.validatorFunc(
          this.state[this.formValues.capacity.valueId]
        ).isValid
      ) {
        isValid = false;
      }
    }
    this.setState({
      isFormInputValid: isValid
    });
  }

  handleSubmitClick() {
    const {
      component,
      courseComponents,
      course,
      schedule,
      section
    } = this.props;
    const fieldRequirements = courseComponents.find(componentType => {
      return componentType.id === component.type;
    }).fieldRequirements;

    let requestObject = {
      number: this.state[this.formValues.sectionNumber.valueId]
    };

    requestObject.scheduleTba = this.state.scheduleTba || false;
    if (fieldRequirements.schedule !== 'n/a') {
      if (this.state.scheduleDaysOfWeek && !requestObject.scheduleTba) {
        requestObject.schedule = {
          daysOfWeek: this.state.scheduleDaysOfWeek,
          startTime: this.state.scheduleStartTime,
          endTime: this.state.scheduleEndTime
        };
      }
    }

    requestObject.facultyMemberTba = this.state.facultyMemberTba || false;
    if (fieldRequirements.facultyMember !== 'n/a') {
      if (
        this.state[this.formValues.facultyMemberId.valueId] &&
        !requestObject.facultyMemberTba
      ) {
        requestObject.facultyMemberId = this.state[
          this.formValues.facultyMemberId.valueId
        ];
      }
    }

    if (fieldRequirements.capacity !== 'n/a') {
      if (this.state[this.formValues.capacity.valueId]) {
        requestObject.capacity = parseInt(
          this.state[this.formValues.capacity.valueId]
        );
      }
    }

    requestObject.locationTba = this.state.locationTba || false;
    if (fieldRequirements.location !== 'n/a') {
      if (
        this.state[this.formValues.locationId.valueId] &&
        !requestObject.locationTba
      ) {
        requestObject.locationId = this.state[
          this.formValues.locationId.valueId
        ];
      }
    }

    if (this.state.isNewSection) {
      this.props.addScheduleSection(
        schedule.year,
        schedule.term,
        course.id,
        component.type,
        requestObject
      );
    } else {
      this.props.putScheduleSection(
        schedule.year,
        schedule.term,
        course.id,
        component.type,
        section.id,
        requestObject
      );
    }
  }

  deleteSection() {
    const { component, course, schedule, section } = this.props;

    this.props.deleteScheduleSection(
      schedule.year,
      schedule.term,
      course.id,
      component.type,
      section.id
    );
  }

  handleValueChange(formValue) {
    this.setState({
      [formValue.valueId]: formValue.value,
      [formValue.valueId + 'label']: formValue.valueLabel
    });
    setTimeout(() => {
      this.isFormInputValid();
    }, 0);
  }

  handleScheduleChange(scheduleStateUpdate) {
    this.setState(scheduleStateUpdate);
    setTimeout(() => {
      this.isFormInputValid();
    }, 0);
  }

  closePanel(shouldSendFocus) {
    this.props.hideSidePanel({
      sendFocus: !!shouldSendFocus
    });
  }
}

SectionPanel.propTypes = {
  course: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  section: PropTypes.object
};

const errorSelector = createErrorMessageSelector([
  ADD_SCHEDULE_SECTION,
  DELETE_SCHEDULE_SECTION,
  PUT_SCHEDULE_SECTION,
  GET_LOCATIONS
]);
const updatingSelector = createLoadingSelector([
  ADD_SCHEDULE_SECTION,
  DELETE_SCHEDULE_SECTION,
  PUT_SCHEDULE_SECTION
]);
const mapStateToProps = state => {
  const { auth, config, faculty, locations, schedules, sidePanel } = state;
  return {
    scopes: auth.scopes,
    visible: sidePanel.sidePanelVisible,
    courseComponents:
      config && config.config && config.config.courseComponentTypes,
    keyActivated: sidePanel.keyActivated,
    locations: locations.list,
    facultyMembers: faculty.list,
    proposedTeaching: faculty.proposedTeaching,
    schedule: schedules.viewIndividual,
    errorMessage: errorSelector(state),
    updatingSection: updatingSelector(state)
  };
};

export default connect(
  mapStateToProps,
  {
    hideSidePanel,
    getLocations,
    getFacultyMembers,
    addScheduleSection,
    putScheduleSection,
    deleteScheduleSection,
    updateTrapFocus
  }
)(SectionPanel);
