import React from 'react';
import PropTypes from 'prop-types';
import SelectCourseContainer from './SelectCourseContainer';

class SelectCourse extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valueMappings: {},
      strippedOptionList: []
    };

    this.controlFunc = this.controlFunc.bind(this);
  }

  componentDidMount() {
    if (this.props.courseList) {
      this.buildValueMappings();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.courseList !== this.props.courseList) {
      this.buildValueMappings();
    }
  }

  render() {
    const {
      courseFormValue,
      defaultOption,
      hideLabel,
      includeEnrollment,
      order
    } = this.props;
    const { strippedOptionList } = this.state;
    return (
      <SelectCourseContainer
        formValue={courseFormValue}
        controlFunc={this.controlFunc}
        optionList={strippedOptionList}
        defaultOption={defaultOption}
        hideLabel={hideLabel}
        containerStyle={{ zIndex: order ? 100 - parseInt(order) : 100 }}
        includeEnrollment={includeEnrollment}
      />
    );
  }

  controlFunc(formValue) {
    const { courseId } = this.state.valueMappings.get(formValue.value.id);

    this.props.controlFunc({
      valueId: this.props.courseFormValue.valueId,
      value: courseId
    });
  }

  buildValueMappings() {
    let valueMappings = new Map();
    let strippedOptionList = [];
    if (!this.props.courseList) {
      return null;
    }
    this.props.courseList.forEach(course => {
      let {
        courseNumber,
        name,
        credits,
        variableCredit,
        projectedEnrollment,
        id
      } = course;
      valueMappings.set(id, {
        courseId: course.id
      });
      strippedOptionList.push({
        courseNumber,
        name,
        credits,
        variableCredit,
        projectedEnrollment,
        id
      });

      strippedOptionList.sort((a, b) => {
        return ('' + a).localeCompare(b);
      });
    });

    this.setState({
      valueMappings,
      strippedOptionList
    });
  }
}

SelectCourse.propTypes = {
  controlFunc: PropTypes.func.isRequired,
  courseList: PropTypes.array.isRequired,
  defaultOption: PropTypes.string,
  hideLabel: PropTypes.bool
};

export default SelectCourse;
