import Auth from '@aws-amplify/auth';
import axios from 'axios';
import {
  generateRequestActionTypeName,
  generateSuccessActionTypeName,
  generateFailureActionTypeName
} from './actionNamingMethods';
import { RESET_ACTIVITY_COUNTER } from './types';
import { refreshToken } from './actions/auth';

const requestWrapper = (dispatch, options) => {
  const {
    actionType,
    requestObject,
    errorMessage,
    responseDataKey,
    onlyUrlRequest
  } = options;

  dispatch({ type: RESET_ACTIVITY_COUNTER });
  dispatch({ type: generateRequestActionTypeName(actionType) });
  Auth.currentSession().then(session => {
    const SECONDS_REMAINING =
      parseInt(session.getIdToken().payload.exp) -
      Math.floor(new Date().getTime() / 1000);
    if (SECONDS_REMAINING < 750) {
      refreshToken();
    }

    axios(
      onlyUrlRequest
        ? requestObject.url
        : {
            ...requestObject,
            headers: {
              ...requestObject.headers,
              Authorization: 'Bearer ' + session.getIdToken().jwtToken
            }
          }
    )
      .then(result => {
        let payload = options.responsePayload
          ? options.responsePayload
          : responseDataKey
          ? result.data[responseDataKey]
          : result.data;

        if (options.payloadMerge) {
          options.payloadMerge.forEach(mergeObj => {
            payload[mergeObj.payloadKey] = mergeObj.payloadValue
              ? mergeObj.payloadValue
              : mergeObj.responseDataKey
              ? result.data[mergeObj.responseDataKey]
              : result.data;
          });
        }

        dispatch({
          type: generateSuccessActionTypeName(actionType),
          payload
        });
      })
      .catch(err => {
        let customResponseStatusMessage;
        if (options.customResponseStatusMessages && err && err.response) {
          customResponseStatusMessage =
            options.customResponseStatusMessages[err.response.status];
        }
        dispatch({
          type: generateFailureActionTypeName(actionType),
          payload:
            customResponseStatusMessage ||
            options.errorPayload ||
            errorMessage ||
            (err && err.toString()) ||
            'Error requesting data'
        });
      });
  });
};

export default requestWrapper;
