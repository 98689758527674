import React, { useState } from 'react';
import Accordion from '../common/Accordion';
import sortStudentGroups from '../common/helpers/sortStudentGroups';
import { deleteScheduleCourse } from '../../service/actions/schedules';
import ScheduleCourseComponent from './ScheduleCourseComponent';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons/faCommentDots';
import {
  ValidationCounter,
  ValidationMessage
} from '../common/Validation/validationFunctionComponent';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import {
  GET_SCHEDULES,
  GET_COURSES,
  GET_STUDENT_GROUPS,
  DELETE_SCHEDULE_COURSE,
  GET_SCHEDULE,
  GET_CONFIG,
  GET_SCHEDULE_NOTES
} from '../../service/types';
import ViewSelector from '../common/ViewSelector';
import ScheduleCourseNotes from './ScheduleCourseNotes';

const ScheduleCourse = props => {
  const {
    schedule,
    studentGroupList,
    termList,
    validationList,
    scheduleCourse,
    deleteScheduleCourse,
    scheduleNotes,
    scopes,
    removedCourses,
    errorMessage
  } = props;

  const DEFAULT_VIEW = 'sections';
  const [selectedView, setSelectedView] = useState(DEFAULT_VIEW);
  const [rerenderCount, setRerenderCount] = useState(0);

  const deleteFunction = function() {
    let year = props.match.params.year;
    let term = props.match.params.term;
    let courseId = scheduleCourse.id;
    deleteScheduleCourse(year, term, courseId);
  };

  const applicableStudentGroups = []
    .concat(schedule.studentGroups)
    .filter(studentGroup => {
      if (!studentGroup.courseIds) {
        return false;
      }
      return studentGroup.courseIds.includes(scheduleCourse.id);
    })
    .map(studentGroup => {
      return {
        ...studentGroup,
        studentGroupInfo: studentGroupList.find(elem => {
          return elem.id === studentGroup.id;
        })
      };
    })
    .sort(sortStudentGroups(termList));

  let courseEnrollmentText;
  if (scheduleCourse.projectedEnrollment) {
    courseEnrollmentText =
      scheduleCourse.projectedEnrollment +
      ' student' +
      (scheduleCourse.projectedEnrollment > 1 ? 's' : '');
  } else {
    courseEnrollmentText = 'No projected enrollment';
  }

  const courseInfo = scheduleCourse.courseInfo;

  // Build a list the counter will use to get the error & warning count
  let countList;
  if (validationList) {
    countList = validationList.flat().filter(x => x.courseId === courseInfo.id);
  }

  let courseValidationList;
  if (validationList) {
    courseValidationList = validationList
      .flat()
      .filter(
        x => x.validationLevel === 'course' && x.courseId === courseInfo.id
      );
  }

  const validationStatus = countList => {
    if (schedule.state === 'projected' || schedule.state === 'completed') {
      return '';
    }

    if (!countList) {
      return null;
    }
    let hasError = countList.some(x => x.type === 'error');
    let hasWarning = countList.some(x => x.type === 'warning');
    if (hasError) {
      return 'error';
    } else if (!hasError && hasWarning) {
      return 'warning';
    } else {
      return '';
    }
  };

  const getCourseStudentGroups = () => {
    if (applicableStudentGroups.length === 0) {
      return (
        <p>
          There are no student groups associated with this course in{' '}
          {schedule.term} {schedule.year}.
        </p>
      );
    }
    return (
      <>
        {applicableStudentGroups.map(studentGroup => {
          const info = studentGroup.studentGroupInfo;
          let studentGroupText = info.program.name;
          if (info.concentration)
            studentGroupText += ', ' + info.concentration.name;
          studentGroupText += ` | ${info.track} | ${info.startTerm}\u00A0${info.startYear}\u2013${info.endTerm}\u00A0${info.endYear}`;
          if (info.name) {
            studentGroupText += ' | ' + info.name.replace(/ /g, '\u00A0');
          }

          let studentGroupEnrollmentText;
          if (!studentGroup.enrollment) {
            studentGroupEnrollmentText = 'No enrollment set';
          } else {
            studentGroupEnrollmentText =
              `${studentGroup.enrollment} student` +
              (studentGroup.enrollment > 1 ? 's' : '');
          }
          return (
            <div key={studentGroupText}>
              <div className="assigned-student-group">
                <div className="label">{studentGroupEnrollmentText}</div>
                <span>{studentGroupText}</span>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const hideValidations =
    schedule.state === 'projected' || schedule.state === 'completed';

  const courseNotes =
    scheduleNotes &&
    scheduleNotes.find(note => note.courseId === courseInfo.id);

  const heading = (
    <div className="course-content">
      {hideValidations ? null : (
        <ValidationCounter validationList={countList} />
      )}
      <span className="course-number">
        {courseInfo.courseNumber.replace(/(\d)/, ' $1')}
      </span>
      <span className="course-name">
        {courseInfo.name}
        <span className="credit-hours">{`(${
          courseInfo.variableCredit ? 'Variable' : courseInfo.credits
        }\u00A0CR)`}</span>
      </span>
      <div className="enrollment">
        <FontAwesomeIcon icon={faUsers} />
        <span>{courseEnrollmentText}</span>
      </div>
    </div>
  );

  const headingAlt = `${courseInfo.courseNumber} ${courseInfo.name}`;
  const SHOW_VIEW_SELECTOR = schedule.state !== 'projected';
  const SHOW_NOTES =
    schedule.state !== 'projected' && scopes.includes('schedule.notes.read');

  const STUDENT_GROUP_TEXT = `Student Groups (${applicableStudentGroups.length})`;
  const NOTES_TEXT = (
    <>
      {'NOTES ('}
      {courseNotes && courseNotes !== '' ? (
        <FontAwesomeIcon className="notes" icon={faCommentDots} />
      ) : (
        '0'
      )}
      {')'}
    </>
  );
  let viewTuples = [{ label: 'Section Details', id: 'sections' }];
  SHOW_NOTES && viewTuples.push({ label: NOTES_TEXT, id: 'notes' });
  viewTuples.push({ label: STUDENT_GROUP_TEXT, id: 'student-groups' });

  const content = (
    <div className="course-views-wrapper">
      {SHOW_VIEW_SELECTOR && (
        <ViewSelector
          viewTuples={viewTuples}
          defaultSelected="sections"
          callbackFunction={tuple => setSelectedView(tuple.id)}
          selectorStyle={{ marginTop: '0.55rem' }}
        />
      )}
      {selectedView === 'sections' && (
        <div className="schedule-course-content">
          {schedule.state === 'projected' && (
            <h3 className="subheading">Instructional Components</h3>
          )}
          {courseValidationList && (
            <div className="validation-message-wrapper">
              {courseValidationList.map((c, i) => {
                return (
                  <ValidationMessage
                    message={c.message}
                    type={c.type}
                    key={i}
                  />
                );
              })}
            </div>
          )}
          {scheduleCourse.components.map(component => {
            let componentValidationList;
            if (validationList) {
              componentValidationList = validationList
                .flat()
                .filter(
                  x =>
                    x.componentType === component.type &&
                    x.courseId === scheduleCourse.courseInfo.id
                );
            }
            return (
              <React.Fragment key={component.type}>
                <div className="validation-message-wrapper">
                  {componentValidationList &&
                    componentValidationList.map((c, i) => {
                      return (
                        <ValidationMessage
                          message={c.message}
                          type={c.type}
                          key={i}
                        />
                      );
                    })}
                </div>
                <ScheduleCourseComponent
                  scheduleCourse={scheduleCourse}
                  component={component}
                />
              </React.Fragment>
            );
          })}

          {schedule.state === 'projected' && (
            <>
              <h3 className="subheading">Student Groups Assigned</h3>
              {getCourseStudentGroups()}
            </>
          )}
        </div>
      )}
      {selectedView === 'notes' && (
        <ScheduleCourseNotes
          notes={courseNotes && courseNotes.notes}
          canEdit={schedule.state === 'planning'}
          courseId={courseInfo.id}
          term={schedule.term}
          year={schedule.year}
          renderTriggerFunc={() => setRerenderCount(rerenderCount + 1)}
        />
      )}
      {selectedView === 'student-groups' && getCourseStudentGroups()}
    </div>
  );

  return (
    <Accordion
      errorMessage={
        errorMessage && errorMessage.id === scheduleCourse.id
          ? errorMessage.error
          : false
      }
      isRemoved={removedCourses && removedCourses.includes(scheduleCourse.id)}
      key={scheduleCourse.id}
      heading={heading}
      headingAlt={headingAlt}
      validationStatus={validationStatus(countList)}
      content={content}
      rerenderCount={rerenderCount}
      enableDeletion={
        schedule.state === 'planning' &&
        scopes.includes('schedule.course.write')
      }
      deleteLabel="Delete Course?"
      deleteFunction={deleteFunction}
      startExpanded={schedule.state === 'planning' ? true : false}
    />
  );
};

const loadingSelector = createLoadingSelector([
  GET_SCHEDULES,
  GET_SCHEDULE,
  GET_CONFIG,
  GET_COURSES,
  GET_STUDENT_GROUPS,
  GET_SCHEDULE_NOTES
]);
const errorSelector = createErrorMessageSelector([
  GET_SCHEDULE,
  GET_SCHEDULE_NOTES,
  DELETE_SCHEDULE_COURSE
]);
const mapStateToProps = state => {
  const { auth, config, courses, schedules, studentGroups } = state;
  return {
    scopes: auth.scopes,
    loading: loadingSelector(state),
    errorMessage: errorSelector(state),
    courseComponents:
      config && config.config && config.config.courseComponentTypes,
    schedule: schedules.viewIndividual,
    scheduleNotes: schedules.notes,
    courseList: courses.list,
    validationList: schedules.validationResults,
    removedCourses: schedules.removedCourses,
    termList: config.termsByCalendarYear,
    studentGroupList: studentGroups.list
  };
};

export default connect(mapStateToProps, { deleteScheduleCourse })(
  withRouter(ScheduleCourse)
);
