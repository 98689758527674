import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';

const fieldValueMappings = {
  number: {
    label: 'Section Number',
    icon: null,
    renderFunc: value => {
      return `SECT ${value}`;
    }
  },
  schedule: {
    label: 'Day and Time',
    icon: faClock,
    renderFunc: value => {
      let startTime = convertTimeValue(value.startTime);
      let endTime = convertTimeValue(value.endTime);
      let sectionValue = value.daysOfWeek.join('');

      return `${sectionValue} ${startTime}\u2013${endTime}`;
    }
  },
  facultyMember: {
    label: 'Faculty Member',
    icon: faUserCircle,
    renderFunc: value => {
      let facultyName = `${value.lastName}, ${value.firstName}`;
      return facultyName;
    }
  },
  employeeId: {
    label: 'EmployeeId',
    renderFunc: value => {
      let employeeId = value ? `${value}` : '';
      return employeeId;
    }
  },
  capacity: {
    label: 'Student Capacity',
    icon: faUsers,
    renderFunc: value => {
      return `${value} student cap`;
    }
  },
  location: {
    label: 'Location',
    icon: faMapMarkerAlt,
    renderFunc: value => {
      return value.name;
    }
  }
};

function convertTimeValue(timeString) {
  let hour = timeString.substring(0, 2);
  const minute = timeString.substring(3, 5);
  const aMpM = hour < 12 ? 'AM' : 'PM';
  hour -= hour <= 12 ? 0 : 12;
  if (hour === 0) {
    hour = 12;
  }
  return `${hour}:${minute} ${aMpM}`;
}

export default fieldValueMappings;
