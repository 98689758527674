import React from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';
import { connect } from 'react-redux';

class SelectPlanOfStudy extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valueMappings: {},
      strippedOptionList: []
    };

    this.controlFunc = this.controlFunc.bind(this);
  }

  componentDidMount() {
    this.buildValueMappings();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.planOfStudyList !== this.props.planOfStudyList) {
      if (this.props.planOfStudyList) {
        this.buildValueMappings();
      }
    }
  }

  render() {
    const {
      planOfStudyFormValue,
      defaultOption,
      hideLabel,
      loading,
      containerStyle,
      actionable,
      optional
    } = this.props;
    const { strippedOptionList } = this.state;
    const emptyMessage =
      'There are no available plans of study that match the criteria above.';

    let defaultText = defaultOption;
    if (loading) {
      defaultText = 'Loading...';
    }

    return (
      <Select
        formValue={planOfStudyFormValue}
        controlFunc={this.controlFunc}
        optionList={strippedOptionList}
        defaultOption={defaultText}
        hideLabel={hideLabel}
        actionable={actionable}
        containerStyle={containerStyle || { zIndex: 99 }}
        emptyMessage={emptyMessage}
        emptyValueOption={'Select'}
        optional={optional}
      />
    );
  }

  controlFunc(formValue) {
    const { planOfStudyId } = this.state.valueMappings.get(formValue.value) || {
      planOfStudyId: null
    };
    this.props.controlFunc({
      valueId: this.props.planOfStudyFormValue.valueId,
      value: planOfStudyId
    });
  }

  buildValueMappings() {
    let valueMappings = new Map();
    let strippedOptionList = [];

    if (!this.props.planOfStudyList) {
      return null;
    }

    this.props.planOfStudyList.forEach(planOfStudy => {
      let labelText = planOfStudy.program.name;

      if (planOfStudy.concentration) {
        labelText += ', ' + planOfStudy.concentration.name;
      }
      labelText += ' \u2014 ' + planOfStudy.track;
      labelText +=
        ', Effective\u00A0' +
        planOfStudy.startTerm +
        '\u00A0' +
        planOfStudy.startYear;
      valueMappings.set(labelText, {
        planOfStudyId: planOfStudy.id
      });
      strippedOptionList.push(labelText);
    });

    strippedOptionList.sort((a, b) => {
      return ('' + a).localeCompare(b);
    });

    this.setState({
      valueMappings,
      strippedOptionList
    });
  }
}

SelectPlanOfStudy.propTypes = {
  planOfStudyFormValue: PropTypes.object.isRequired,
  controlFunc: PropTypes.func.isRequired,
  planOfStudyList: PropTypes.array,
  defaultOption: PropTypes.string,
  hideLabel: PropTypes.bool,
  containerStyle: PropTypes.object,
  actionable: PropTypes.bool,
  optional: PropTypes.bool
};

SelectPlanOfStudy.defaultProps = {
  actionable: false
};

const mapStateToProps = ({ plansOfStudy }) => {
  return {
    loading: plansOfStudy.loading
  };
};

export default connect(mapStateToProps, null)(SelectPlanOfStudy);
