import {
  GET_LOCATIONS,
  ADD_LOCATION,
  UPDATE_LOCATION,
  ARCHIVE_LOCATION
} from '../types';
import { generateSuccessActionTypeName } from '../actionNamingMethods';

export default (state = {}, action) => {
  switch (action.type) {
    case generateSuccessActionTypeName(GET_LOCATIONS):
      return {
        ...state,
        list: action.payload
      };

    case generateSuccessActionTypeName(ADD_LOCATION):
      action.payload.isNew = true;
      return {
        ...state,
        list: [action.payload, ...state.list].sort((a, b) =>
          a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })
        ),
        newest: action.payload
      };

    case generateSuccessActionTypeName(UPDATE_LOCATION):
      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              ...action.payload,
              updated: true
            };
          }
          return item;
        }),
        updatedLocation: action.payload
      };

    case generateSuccessActionTypeName(ARCHIVE_LOCATION):
      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              archived: true
            };
          }
          return item;
        }),
        archivedName: action.payload.name
      };

    default:
      return state;
  }
};
