import React from 'react';
import { SignIn } from 'aws-amplify-react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import factsLogo from '../../assets/images/factslogo-v2.svg';
import vcuLogo from '../../assets/images/vcu-son-logo.svg';
import './CustomSignIn.scss';

import { signIn } from '../../service/actions/auth';
import { Auth } from 'aws-amplify';

class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ['signIn', 'signedOut', 'signedUp'];
  }

  /* eslint-disable react/jsx-handler-names */
  showComponent() {
    return (
      <>
        <Router>
          <Switch>
            <Route
              exact
              path="/logged-out"
              render={props => {
                return (
                  <div>
                    <p>you're not logged in.</p>
                  </div>
                );
              }}
            />
            <Route
              exact
              path="/redirect"
              render={() => {
                Auth.currentSession();
                return (
                  <div id="redirecting">
                    <div>
                      <img
                        alt="FACTS — Faculty and Curricula Tracking System Logo"
                        src={factsLogo}
                      />
                      <img alt="VCU School of Nursing Logo" src={vcuLogo} />
                    </div>
                    <span>Redirecting back to coursFACTS...</span>
                  </div>
                );
              }}
            />
            <Route
              path="/"
              render={() => {
                signIn();
                const { signInFailure, errorMessage } = this.props;
                return (
                  <>
                    {!signInFailure && (
                      <div id="redirecting">
                        <div>
                          <img
                            alt="FACTS — Faculty and Curricula Tracking System Logo"
                            src={factsLogo}
                          />
                          <img alt="VCU School of Nursing Logo" src={vcuLogo} />
                        </div>
                        <span>Redirecting to login...</span>
                      </div>
                    )}

                    {signInFailure && (
                      <div>
                        <span>
                          There was an error during sign in. Error:{' '}
                          {errorMessage}
                        </span>
                      </div>
                    )}
                  </>
                );
              }}
            />
            <Route
              path="*"
              render={() => {
                Auth.currentSession();
              }}
            />
          </Switch>
        </Router>
      </>
    );
  }
  /* eslint-enable react/jsx-handler-names */
}

export default CustomSignIn;
