import React from 'react';
import { connect } from 'react-redux';
import Textarea from '../common/Textarea';
import './ScheduleCourseNotes.scss';
import {
  setScheduleCourseNotes,
  clearScheduleCourseNotesError
} from '../../service/actions/schedules';
import FormReveal from '../common/FormReveal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { SET_SCHEDULE_NOTES } from '../../service/types';
import {
  createErrorMessageSelector,
  createLoadingSelector
} from '../../service/selectors';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { FORM_REVEAL_ANIMATION_SPEED } from '../../config';

class ScheduleCourseNotes extends React.Component {
  constructor(props) {
    super(props);
    this.scheduleCourseNotesFormRevealRef = React.createRef();
    this.state = {
      showNotesForm: false,
      notesIsOpen: false,
      resetting: false
    };
    this.baseState = { ...this.state };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.resetting !== this.state.resetting && this.state.resetting) {
      this.setState({
        resetting: false
      });
    }
    if (prevProps.updating !== this.props.updating) {
      if (!this.props.updating) {
        if (!this.props.errorMessage) {
          this.scheduleCourseNotesFormRevealRef.current.handleSubmitClick(
            document.createEvent('Event')
          );
          this.scheduleCourseNotesFormRevealRef.current.handleSubmitClose(
            document.createEvent('Event')
          );
        }
        if (this.props.renderTriggerFunc) {
          setTimeout(() => {
            this.props.renderTriggerFunc();
          }, FORM_REVEAL_ANIMATION_SPEED);
        }
      }
    }
  }

  render() {
    const { notes, scopes, canEdit } = this.props;
    const { notesIsOpen } = this.state;
    const notesForm = this.renderNotesForm();
    if (!notesForm) return null;

    const hideRevealJsx = (
      <>
        {!notes && scopes.includes('schedule.notes.write') && canEdit && (
          <button
            onClick={() => this.showForm(true, false)}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                this.showForm(true, true);
              }
            }}
            id="addNotesFormBtn"
          >
            Add Notes
            <FontAwesomeIcon icon={faPlusCircle} />
          </button>
        )}
        {notes && <div className="notes-viewer">{notes}</div>}
      </>
    );

    return (
      <div className="schedule-course-notes-wrapper">
        <div className="notes-header">
          <h2 className="left-bar no-border">Course Notes</h2>
          {!notesIsOpen &&
            notes &&
            scopes.includes('schedule.notes.write') &&
            canEdit && (
              <button
                className="circle small"
                onClick={() => this.showForm(true, false)}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    this.showForm(true, true);
                  }
                }}
                id="editNotesFormBtn"
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>
            )}
        </div>

        <FormReveal
          hideButton
          hideRevealJsx={hideRevealJsx}
          isOpening={state =>
            this.setState({
              notesIsOpen: state
            })
          }
          form={notesForm}
          hideFormAnimation={true}
          submitFunc={() => this.hideForm()}
          cancelFunc={() => this.resetForm()}
          ref={this.scheduleCourseNotesFormRevealRef}
        />
      </div>
    );
  }

  renderNotesForm() {
    const { notes, errorMessage } = this.props;
    const { resetting } = this.state;
    if (resetting) {
      return null;
    }

    const formValues = {
      notes: {
        name: 'notes',
        valueId: 'notes',
        labelTitle: 'Course Notes',
        defaultValue: notes,
        validatorFunc: value => ({
          isValid: true,
          errorMessage: 'Invalid notes'
        })
      }
    };

    return (
      <form
        id="schedule-course-notes-form"
        className="notes-form"
        style={{ flexGrow: '1', display: 'flex', flexDirection: 'column' }}
      >
        <div
          className="notes-container"
          style={{ flexGrow: '1', display: 'flex', flexDirection: 'column' }}
        >
          <Textarea
            hideLabel
            hideError
            hideErrorMessage
            formValue={formValues.notes}
            controlFunc={this.handleValueChange}
          />
        </div>
        {errorMessage && (
          <div className="form-message unexpected-error-message error-message">
            <FontAwesomeIcon icon={faExclamationCircle} />
            An unknown error occurred. Please try again.
          </div>
        )}

        <div id="form-end-wrapper">
          <div className="buttons">
            <button
              type="button"
              id="cancel-notes"
              onClick={event => this.handleCancelClick(event)}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  this.handleCancelClick(event);
                }
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              id="submit-notes"
              onClick={event => this.handleSubmitClick(event)}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  this.handleSubmitClick(event);
                }
              }}
            >
              Save
              <FontAwesomeIcon icon={faCheckCircle} />
            </button>
          </div>
        </div>
      </form>
    );
  }

  handleValueChange = formValue => {
    this.setState({
      [formValue.valueId]: formValue.value
    });
  };

  hideForm() {
    this.setState({
      showNotesForm: false,
      showNotesFormSendFocus: false
    });
  }

  resetForm() {
    this.setState({
      ...this.baseState,
      showNotesForm: false,
      showNotesFormSendFocus: false,
      resetting: true
    });
  }

  handleCancelClick(event) {
    this.scheduleCourseNotesFormRevealRef.current.handleCancelClick(event);
    setTimeout(() => {
      this.props.clearScheduleCourseNotesError();
    }, FORM_REVEAL_ANIMATION_SPEED);
  }

  handleSubmitClick(event) {
    event.preventDefault();
    const { notes } = this.state;
    const { term, year, courseId } = this.props;
    this.props.setScheduleCourseNotes(year, term, courseId, notes);
  }

  showForm(value, sendFocus) {
    this.scheduleCourseNotesFormRevealRef.current.handleOpenClick(
      document.createEvent('Event')
    );
    this.setState({
      showNotesForm: value,
      showNotesFormSendFocus: sendFocus || false
    });
    if (this.props.renderTriggerFunc) {
      const { term, year, courseId } = this.props;
      const { notes } = this.state;
      setTimeout(() => {
        this.props.renderTriggerFunc({ year, term, courseId, notes });
      }, 0);
    }
  }
}
const puttingNotesSelector = createLoadingSelector([SET_SCHEDULE_NOTES]);
const errorSelector = createErrorMessageSelector([SET_SCHEDULE_NOTES]);
const mapStateToProps = state => {
  const { auth } = state;
  return {
    scopes: auth.scopes,
    errorMessage: errorSelector(state),
    updating: puttingNotesSelector(state)
  };
};

export default connect(mapStateToProps, {
  setScheduleCourseNotes,
  clearScheduleCourseNotesError
})(ScheduleCourseNotes);
