import React from 'react';
import config from '../../config';

const NoMatchPage = props => {
  return (
    <div>
      <h3>
        That page doesn't exist! You've reached the 404 page of{' '}
        {config.APP_NAME}.
      </h3>
    </div>
  );
};

export default NoMatchPage;
