import React from 'react';
import PropTypes from 'prop-types';
import './Radio.scss';

class Radio extends React.Component {
  constructor(props) {
    super(props);

    this.handleOptionChange = this.handleOptionChange.bind(this);
  }

  componentDidMount() {
    if (this.props.defaultChecked) {
      this.props.controlFunc(this.props.id);
    }
  }
  componentDidUpdate() {
    if (this.props.defaultChecked && !this.props.selected) {
      this.props.controlFunc(this.props.id);
    }
  }

  render() {
    const {
      defaultChecked,
      disabled,
      domId,
      label,
      selected,
      readOnly,
      liClassName,
      id
    } = this.props;
    return (
      <label
        className={
          'radio-container ' +
          (selected || defaultChecked ? 'checked ' : '') +
          (readOnly ? 'read-only ' : '') +
          (disabled ? 'disabled ' : '') +
          liClassName
        }
        htmlFor={domId}
      >
        <span className="radio"></span>
        <input
          className="radio-input"
          disabled={disabled}
          id={domId}
          type="radio"
          name={domId}
          ref={this.setRadioInputRef}
          onChange={this.handleOptionChange}
          onKeyDown={event => this.optionKeyed(event)}
          checked={selected || defaultChecked}
          value={id}
        />
        {label}
      </label>
    );
  }

  handleOptionChange(event) {
    if (this.props.readOnly) return;
    this.props.controlFunc(event.target.value);
  }

  optionKeyed(event) {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault();
      this.props.controlFunc(event.target.value);
    } else if (event.key === 'ArrowLeft' || event.key === 'ArrowUp') {
      event.preventDefault();
      this.shiftFocus('prev');
    } else if (event.key === 'ArrowRight' || event.key === 'ArrowDown') {
      event.preventDefault();
      this.shiftFocus('next');
    }
  }

  shiftFocus(direction) {
    const elements = {
      prev: document.activeElement.closest('label').previousElementSibling,
      next: document.activeElement.closest('label').nextElementSibling
    };
    if (
      elements[direction] &&
      elements[direction].classList.contains('radio-container')
    ) {
      elements[direction].focus();
    }
  }
}

Radio.propTypes = {
  domId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  controlFunc: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  defaultChecked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  hiddenLabel: PropTypes.bool,
  liClassName: PropTypes.string
};

Radio.defaultProps = {
  defaultChecked: false,
  readOnly: false,
  hiddenLabel: false,
  disabled: false,
  liClassName: ''
};

export default Radio;
