import React from 'react';
import { SHOW_MODAL, HIDE_MODAL } from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        modalVisible: true,
        trapFocus: action.options && action.options.trapFocus,
        content: action.payload || <span>no modal content defined</span>
      };
    case HIDE_MODAL:
      return {
        ...state,
        modalVisible: false
      };
    default:
      return state;
  }
};
