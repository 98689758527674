import React from 'react';
import './AddPlanOfStudy.scss';
import { addPlanOfStudy } from '../../service/actions/plansOfStudy';
import { getPrograms } from '../../service/actions/programs';
import {
  showCustomJSXModal,
  showValidatingModal,
  hideModal
} from '../../service/actions/modal';
import FormReveal from '../common/FormReveal';
import Select from '../common/Select';
import SelectProgram from '../common/SelectProgram';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { withRouter } from 'react-router-dom';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import { ADD_PLAN_OF_STUDY } from '../../service/types';
import { generateYearList } from './helpers';

class AddPlanOfStudy extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormInputValid: false,
      duplicateError: '',
      resetting: false
    };
    this.baseState = { ...this.state };

    this.handleValueChange = this.handleValueChange.bind(this);
    this.formRevealRef = React.createRef();
    this.formValues = {
      programId: {
        name: 'program-concentration-select',
        valueId: 'programId',
        defaultValue: 'Select',
        labelTitle: 'Programs and Concentrations',
        validatorFunc: value => ({
          isValid: value && value !== '' && value !== 'Select',
          errorMessage: 'Invalid'
        })
      },
      concentrationId: {
        valueId: 'concentrationId',
        validatorFunc: value => ({
          isValid: true,
          errorMessage: 'Invalid'
        })
      },
      track: {
        name: 'track-type-select',
        valueId: 'track',
        labelTitle: 'Track Type',
        defaultValue: 'Select',
        validatorFunc: value => ({
          isValid: value && value !== '' && value !== 'Select',
          errorMessage: 'Invalid'
        })
      },
      startTerm: {
        name: 'starting-term-select',
        valueId: 'startTerm',
        labelTitle: 'Starting Term',
        defaultValue: 'Select',
        validatorFunc: value => ({
          isValid: value && value !== '' && value !== 'Select',
          errorMessage: 'Invalid'
        })
      },
      startYear: {
        name: 'year-select',
        valueId: 'startYear',
        labelTitle: 'Year',
        defaultValue: 'Select',
        validatorFunc: value => ({
          isValid: value && value !== '' && value !== 'Select',
          errorMessage: 'Invalid'
        })
      }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.addingPlanOfStudy !== this.props.addingPlanOfStudy) {
      this.props.showValidatingModal();

      if (!this.props.addingPlanOfStudy) {
        this.props.hideModal();
        if (!this.props.errorMessage) {
          this.props.history.push(
            this.props.match.path + '/' + this.props.newlyAddedPlanOfStudy.id
          );
        }
      }
    } else if (
      prevState.resetting !== this.state.resetting &&
      this.state.resetting
    ) {
      this.setState({
        resetting: false
      });
    }
  }

  render() {
    const form = this.renderAddPlanOfStudyForm();
    if (!form) return null;
    return (
      <div className="add-plan-of-study-wrapper">
        <FormReveal
          buttonText="Add Plan of Study"
          buttonId="add-plan-of-study"
          form={form}
          cancelFunc={() => this.resetForm()}
          ref={this.formRevealRef}
        />
      </div>
    );
  }

  renderAddPlanOfStudyForm() {
    const { errorMessage, programList, trackList, termList } = this.props;
    const { duplicateError, resetting } = this.state;

    if (resetting) {
      return null;
    }
    const yearList = generateYearList();

    if (!programList || !trackList || !termList) {
      return null;
    }
    return (
      <section>
        <h1>Add a New Plan of Study</h1>

        <form id="pos-form-wrapper">
          <div id="pos-entry-form">
            <div>
              <SelectProgram
                programFormValue={this.formValues.programId}
                concentrationFormValue={this.formValues.concentrationId}
                controlFunc={this.handleValueChange}
                programList={programList}
              />
            </div>
            <div>
              <Select
                formValue={this.formValues.track}
                controlFunc={this.handleValueChange}
                optionList={trackList}
              />
            </div>
            <div>
              <Select
                formValue={this.formValues.startTerm}
                controlFunc={this.handleValueChange}
                optionList={termList}
              />
            </div>
            <div>
              <Select
                formValue={this.formValues.startYear}
                controlFunc={this.handleValueChange}
                optionList={yearList}
              />
            </div>
          </div>

          <div className="form-end-wrapper">
            {errorMessage && (
              <div className="form-message unexpected-error-message error-message">
                <FontAwesomeIcon icon={faExclamationCircle} />
                An unexpected error occurred. Please try again.
              </div>
            )}

            {duplicateError && (
              <div className="form-message duplicate-error-message warning-message">
                <FontAwesomeIcon icon={faExclamationTriangle} />
                This plan of study already exists. Please validate your entries.
              </div>
            )}
            <div className="buttons">
              <button
                id="cancel-pos-entry"
                onClick={event => this.handleCancelClick(event)}
                onKeyUp={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    this.handleCancelClick(event);
                  }
                }}
              >
                Cancel
              </button>
              <button
                id="submit-pos-entry"
                onClick={event => {
                  event.preventDefault();
                  this.addPlanOfStudy();
                }}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    this.addPlanOfStudy();
                  }
                }}
                disabled={!this.state.isFormInputValid}
              >
                Add Plan of Study <FontAwesomeIcon icon={faPlusCircle} />
              </button>
            </div>
          </div>
        </form>
      </section>
    );
  }

  handleCancelClick(event) {
    event.preventDefault();
    this.formRevealRef.current.handleCancelClick(event);
  }

  addPlanOfStudy() {
    const {
      programId,
      concentrationId,
      track,
      startTerm,
      startYear
    } = this.state;

    let newPlanOfStudy = {
      programId: programId,
      track: track,
      startTerm: startTerm,
      startYear: startYear
    };
    if (concentrationId) {
      newPlanOfStudy.concentrationId = concentrationId;
    }
    this.props.addPlanOfStudy(newPlanOfStudy);
  }

  handleValueChange(formValue, formValue2 = {}) {
    let currentFormState = Object.keys(this.formValues).reduce(
      (result, next) => {
        switch (next) {
          case formValue.valueId:
            result[formValue.valueId] = formValue.value;
            break;
          case formValue2.valueId:
            result[formValue2.valueId] = formValue2.value;
            break;
          default:
            result[next] = this.state[next];
        }
        return result;
      },
      {}
    );

    const isFormInputValid = Object.keys(this.formValues).reduce(
      (result, next) => {
        return (
          result &&
          this.formValues[next].validatorFunc(currentFormState[next]).isValid
        );
      },
      true
    );

    const isDuplicate = this.props.plansOfStudy.reduce((result, next) => {
      if (
        next.program.id === currentFormState.programId &&
        next.track === currentFormState.track &&
        next.startYear === currentFormState.startYear &&
        next.startTerm === currentFormState.startTerm
      ) {
        if (!next.concentration) {
          return result || !currentFormState.concentrationId === 'undefined';
        } else {
          return (
            result || next.concentration.id === currentFormState.concentrationId
          );
        }
      }
      return result || false;
    }, false);

    this.setState({
      [formValue.valueId]: formValue.value,
      [formValue2.valueId]: formValue2.value,
      isFormInputValid: isFormInputValid && !isDuplicate,
      duplicateError: isDuplicate
    });
  }

  resetForm() {
    let newState = { ...this.baseState, resetting: true };
    Object.keys(this.formValues).forEach(key => {
      newState[key] = undefined;
    });

    this.setState(newState);
  }
}

const addingSelector = createLoadingSelector([ADD_PLAN_OF_STUDY]);
const errorMessageSelector = createErrorMessageSelector([ADD_PLAN_OF_STUDY]);
const mapStateToProps = state => {
  const { config, plansOfStudy, programs } = state;
  return {
    addingPlanOfStudy: addingSelector(state),
    errorMessage: errorMessageSelector(state),
    plansOfStudy: plansOfStudy.list,
    newlyAddedPlanOfStudy: plansOfStudy.newest,
    programList: programs.list,
    trackList: config.config && config.config.tracks,
    termList: config.termsByAcademicYear
  };
};

export default connect(
  mapStateToProps,
  {
    getPrograms,
    addPlanOfStudy,
    showCustomJSXModal,
    showValidatingModal,
    hideModal
  }
)(withRouter(AddPlanOfStudy));
