import {
  GET_SCHEDULE,
  GET_SCHEDULES,
  ADD_SCHEDULE_SECTION,
  PUT_SCHEDULE_SECTION,
  SET_SECTION_CONFIRMATION_STATUS,
  DELETE_SCHEDULE_SECTION,
  ADD_COURSE_TO_SCHEDULE,
  DELETE_SCHEDULE_COURSE,
  VALIDATE_SCHEDULE,
  GET_SCHEDULE_ENROLLMENT,
  CLEAR_SCHEDULE_UPDATING_ERROR,
  GET_SCHEDULE_NOTES,
  SET_SCHEDULE_NOTES
} from '../types';
import {
  generateRequestActionTypeName,
  generateSuccessActionTypeName,
  generateFailureActionTypeName
} from '../actionNamingMethods';

export default (state = {}, action) => {
  switch (action.type) {
    case generateRequestActionTypeName(GET_SCHEDULE):
      return {
        ...state,
        viewIndividual: null
      };

    case generateSuccessActionTypeName(GET_SCHEDULE):
      return {
        ...state,
        viewIndividual: action.payload.schedule
      };

    case generateFailureActionTypeName(GET_SCHEDULE):
      return {
        ...state,
        viewIndividual: null
      };

    case generateRequestActionTypeName(GET_SCHEDULES):
      return {
        ...state,
        list: null
      };

    case generateSuccessActionTypeName(GET_SCHEDULES):
      return {
        ...state,
        list: action.payload.schedules
      };

    case generateRequestActionTypeName(GET_SCHEDULE_ENROLLMENT):
      return {
        ...state,
        projectedEnrollmentList: undefined
      };

    case generateSuccessActionTypeName(GET_SCHEDULE_ENROLLMENT):
      return {
        ...state,
        projectedEnrollmentList: action.payload
      };

    case generateRequestActionTypeName(GET_SCHEDULE_NOTES):
      return {
        ...state,
        notes: undefined
      };

    case generateSuccessActionTypeName(GET_SCHEDULE_NOTES):
      return {
        ...state,
        notes: action.payload
      };

    case generateSuccessActionTypeName(SET_SCHEDULE_NOTES):
      let alreadyExists = false;
      let newNotes = state.notes.reduce((result, noteObj) => {
        if (noteObj.courseId === action.payload.courseId) {
          alreadyExists = true;
          if (action.payload.notes !== '') {
            result.push({
              ...noteObj,
              notes: action.payload.notes
            });
          }
        } else {
          result.push(noteObj);
        }
        return result;
      }, []);

      if (!alreadyExists) {
        newNotes.push({
          courseId: action.payload.courseId,
          notes: action.payload.notes
        });
      }

      return {
        ...state,
        notes: newNotes
      };

    case generateSuccessActionTypeName(ADD_SCHEDULE_SECTION):
      let newSchedule = {
        ...state.viewIndividual,
        courses: [...state.viewIndividual.courses].map(course => {
          if (course.id !== action.payload.courseId) {
            return course;
          }
          return {
            ...course,
            components: course.components.map(component => {
              if (component.type !== action.payload.componentType) {
                return component;
              }
              return {
                ...component,
                sections: [...component.sections].concat([
                  {
                    ...action.payload.newSection,
                    isNew: true
                  }
                ])
              };
            })
          };
        })
      };
      return {
        ...state,
        loading: false,
        addingSection: false,
        addingError: '',
        viewIndividual: newSchedule
      };

    case generateSuccessActionTypeName(PUT_SCHEDULE_SECTION):
      let updatedSchedule = {
        ...state.viewIndividual,
        courses: [...state.viewIndividual.courses].map(course => {
          if (course.id !== action.payload.courseId) {
            return course;
          }
          return {
            ...course,
            components: course.components.map(component => {
              if (component.type !== action.payload.componentType) {
                return component;
              }
              return {
                ...component,
                sections: component.sections.map(sect => {
                  if (sect.id !== action.payload.sectionId) {
                    return sect;
                  }
                  return {
                    ...action.payload.updatedSection,
                    id: action.payload.sectionId,
                    isNew: true
                  };
                })
              };
            })
          };
        })
      };

      return {
        ...state,
        loading: false,
        updatingSection: false,
        updatingSectionError: '',
        viewIndividual: updatedSchedule
      };

    case generateSuccessActionTypeName(SET_SECTION_CONFIRMATION_STATUS):
      let scheduleWithUpdatedConfirmation = {
        ...state.viewIndividual,
        courses: [...state.viewIndividual.courses].map(course => ({
          ...course,
          components: [...course.components].map(component => ({
            ...component,
            sections: [...component.sections].map(section => {
              if (section.id !== action.payload.sectionId) {
                return section;
              } else {
                return {
                  ...section,
                  confirmed: action.payload.confirmed
                };
              }
            })
          }))
        }))
      };

      return {
        ...state,
        loading: false,
        updatingSection: false,
        updatingSectionError: '',
        viewIndividual: scheduleWithUpdatedConfirmation
      };

    case generateSuccessActionTypeName(DELETE_SCHEDULE_SECTION):
      let removedSectionSchedule = {
        ...state.viewIndividual,
        courses: [...state.viewIndividual.courses].map(course => {
          if (course.id !== action.payload.courseId) {
            return course;
          }
          return {
            ...course,
            components: course.components.map(component => {
              if (component.type !== action.payload.componentType) {
                return component;
              }

              return {
                ...component,
                sections: component.sections.reduce((result, sect) => {
                  if (sect.id !== action.payload.sectionId) {
                    result.push(sect);
                  }
                  return result;
                }, [])
              };
            })
          };
        })
      };

      return {
        ...state,
        loading: false,
        deletingSection: false,
        updatingSectionError: '',
        viewIndividual: removedSectionSchedule
      };

    case generateSuccessActionTypeName(ADD_COURSE_TO_SCHEDULE):
      let revisedSchedule = { ...state.viewIndividual };
      let revisedCourses = revisedSchedule.courses.concat(action.payload);
      revisedSchedule.courses = revisedCourses;

      return {
        ...state,
        loading: false,
        viewIndividual: revisedSchedule,
        updatingError: ''
      };

    case generateRequestActionTypeName(DELETE_SCHEDULE_COURSE):
      let removedCoursesArr = state.removedCourses
        ? [...state.removedCourses]
        : [];
      return {
        ...state,
        removedCourses: removedCoursesArr
      };

    case generateSuccessActionTypeName(DELETE_SCHEDULE_COURSE):
      let trimScheduleCourses = { ...state.viewIndividual };
      trimScheduleCourses.courses.splice(
        trimScheduleCourses.courses.findIndex(x => x.id === action.payload),
        1
      );

      return {
        ...state,
        loading: false,
        removedCourses: [action.payload].concat(state.removedCourses),
        viewIndividual: trimScheduleCourses,
        deletingError: ''
      };

    case generateFailureActionTypeName(DELETE_SCHEDULE_COURSE):
      return {
        ...state,
        removedCourses: [...state.removedCourses]
      };

    case generateSuccessActionTypeName(VALIDATE_SCHEDULE):
      return {
        ...state,
        validationResults: action.payload
      };

    case CLEAR_SCHEDULE_UPDATING_ERROR:
      return {
        ...state,
        updatingError: ''
      };

    default:
      return state;
  }
};
