import React from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';
import { connect } from 'react-redux';
import { createLoadingSelector } from '../../../service/selectors';
import { GET_CONFIG } from '../../../service/types';

class SelectAppointment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valueMappings: new Map(),
      reverseValueMappings: new Map(),
      strippedOptionList: []
    };

    this.controlFunc = this.controlFunc.bind(this);
  }

  componentDidMount() {
    if (this.props.appointmentsList) {
      this.buildValueMappings();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.appointmentsList !== this.props.appointmentsList) {
      this.buildValueMappings();
    }
  }

  render() {
    const {
      containerStyle,
      formValue,
      hideLabel,
      loading,
      value,
      disabled,
      actionable
    } = this.props;
    const { strippedOptionList, reverseValueMappings } = this.state;

    let defaultText = formValue.defaultValue || 'Select';
    if (loading) {
      defaultText = 'Loading...';
    }

    return (
      <Select
        formValue={formValue}
        startingValue={reverseValueMappings.get(value)}
        controlFunc={this.controlFunc}
        optionList={strippedOptionList}
        defaultOption={defaultText}
        hideLabel={hideLabel}
        containerStyle={containerStyle}
        disabled={disabled}
        actionable={actionable}
      />
    );
  }

  controlFunc(formValue) {
    const { appointmentId } = this.state.valueMappings.get(formValue.value) || {
      appointmentId: null
    };
    this.props.controlFunc({
      valueId: this.props.formValue.valueId,
      value: appointmentId
    });
  }

  buildValueMappings() {
    let valueMappings = new Map();
    let reverseValueMappings = new Map();
    let strippedOptionList = [];
    if (!this.props.appointmentsList) {
      return null;
    }
    this.props.appointmentsList.forEach(appointment => {
      valueMappings.set(appointment.name, {
        appointmentId: appointment.id
      });
      reverseValueMappings.set(appointment.id, appointment.name);
      strippedOptionList.push(appointment.name);
    });

    this.setState({
      valueMappings,
      reverseValueMappings,
      strippedOptionList
    });
  }
}

SelectAppointment.propTypes = {
  formValue: PropTypes.object.isRequired,
  controlFunc: PropTypes.func.isRequired,
  value: PropTypes.string,
  hideLabel: PropTypes.bool,
  containerStyle: PropTypes.object,
  disabled: PropTypes.bool,
  actionable: PropTypes.bool
};

SelectAppointment.defaultProps = {
  disabled: false,
  actionable: false
};

const loadingSelector = createLoadingSelector([GET_CONFIG]);
const mapStateToProps = state => {
  const { config } = state;
  return {
    loading: loadingSelector(state),
    appointmentsList:
      config && config.config && config.config.facultyMemberAppointments
  };
};

export default connect(
  mapStateToProps,
  null
)(SelectAppointment);
