import React from 'react';
import './ViewTermCourses.scss';
import { connect } from 'react-redux';
import { getCourses } from '../../service/actions/courses';
import { showLoadingModal, hideModal } from '../../service/actions/modal';
import CourseListItems from '../common/CourseListItems';
import UpdateTermCourses from './UpdateTermCourses';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../service/selectors';
import {
  GET_COURSES,
  GET_PLANS_OF_STUDY,
  PUT_PLAN_OF_STUDY_TERMS
} from '../../service/types';

class ViewTermCourses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      updatingCourses: false,
      selectedCourses: []
    };
  }

  componentDidMount() {
    const { loading, courseList, getCourses } = this.props;
    if (!courseList && !loading) {
      getCourses();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      if (this.props.loading) {
        this.props.showLoadingModal();
      } else {
        this.props.hideModal();
      }
    }
  }

  render() {
    const { scopes, term, courseList } = this.props;
    const { updatingCourses } = this.state;

    if (!courseList) {
      return null;
    }

    return (
      <div className="term">
        <h2 className="term-name">{term.term}</h2>
        <span className="visually-hidden year">{term.year}</span>

        {updatingCourses && scopes.includes('plan-of-study.write')
          ? this.renderUpdateCourses()
          : this.renderViewCourses()}
      </div>
    );
  }

  renderUpdateCourses() {
    const { term } = this.props;
    return (
      <UpdateTermCourses
        term={term}
        hideFormFunc={() => this.setFormVisibility(false)}
      />
    );
  }

  renderViewCourses() {
    const { scopes, term } = this.props;
    const buttonText =
      term && term.courseIds && term.courseIds.length
        ? 'Assign/Unassign Courses'
        : 'Assign Courses';

    return (
      <div className="view-term-courses">
        {this.renderTermCourses()}
        {scopes.includes('plan-of-study.write') && (
          <button
            className="assign-courses-button"
            onClick={() => this.setFormVisibility(true)}
          >
            {buttonText}
            <FontAwesomeIcon icon={faPlusCircle} />
          </button>
        )}
      </div>
    );
  }

  renderTermCourses() {
    const { term, courseList } = this.props;

    if (!term.courseIds) {
      return null;
    }

    const courses = term.courseIds.map(courseId => {
      return courseList.find(element => {
        return element.id === courseId;
      });
    });
    return (
      <ul className="none">
        <CourseListItems courses={courses} selectable={false} />
      </ul>
    );
  }

  setFormVisibility(value) {
    this.setState({
      updatingCourses: value
    });
  }
}

const loadingSelector = createLoadingSelector([
  GET_COURSES,
  GET_PLANS_OF_STUDY
]);
const errorMessageSelector = createErrorMessageSelector([
  GET_COURSES,
  PUT_PLAN_OF_STUDY_TERMS
]);
const mapStateToProps = state => {
  const { auth, courses, plansOfStudy } = state;
  return {
    scopes: auth.scopes,
    loading: loadingSelector(state),
    errorMessage: errorMessageSelector(state),
    courseList: courses.list,
    planOfStudy: plansOfStudy.viewIndividual
  };
};

export default connect(
  mapStateToProps,
  { getCourses, showLoadingModal, hideModal }
)(ViewTermCourses);
