import React from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';
import { connect } from 'react-redux';

class SelectLocation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valueMappings: new Map(),
      reverseValueMappings: new Map(),
      strippedOptionList: []
    };

    this.controlFunc = this.controlFunc.bind(this);
  }

  componentDidMount() {
    if (this.props.locationsList) {
      this.buildValueMappings();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locationsList !== this.props.locationsList) {
      this.buildValueMappings();
    }
  }

  render() {
    const {
      containerStyle,
      locationFormValue,
      hideLabel,
      loading,
      value,
      disabled,
      actionable
    } = this.props;
    const { strippedOptionList, reverseValueMappings } = this.state;

    let defaultText = locationFormValue.defaultValue || 'Select';
    if (loading) {
      defaultText = 'Loading...';
    }
    return (
      <Select
        formValue={locationFormValue}
        startingValue={reverseValueMappings.get(value)}
        controlFunc={this.controlFunc}
        optionList={strippedOptionList}
        defaultOption={defaultText}
        hideLabel={hideLabel}
        containerStyle={containerStyle}
        disabled={disabled}
        actionable={actionable}
      />
    );
  }

  controlFunc(formValue) {
    const option = this.state.valueMappings.get(formValue.value);
    this.props.controlFunc({
      valueId: this.props.locationFormValue.valueId,
      value: (option && option.locationId) || ''
    });
  }

  buildValueMappings() {
    let valueMappings = new Map();
    let reverseValueMappings = new Map();
    let strippedOptionList = [];
    if (!this.props.locationsList) {
      return null;
    }
    this.props.locationsList.forEach(location => {
      if (
        location.id !== this.props.startingValue &&
        location.archived === true
      ) {
        return;
      }

      let locationText = location.name;
      if (location.capacity) {
        locationText += ' | ' + location.capacity;
      }
      valueMappings.set(locationText, {
        locationId: location.id
      });
      reverseValueMappings.set(location.id, locationText);
      strippedOptionList.push(locationText);
    });

    this.setState({
      valueMappings,
      reverseValueMappings,
      strippedOptionList
    });
  }
}

SelectLocation.propTypes = {
  locationFormValue: PropTypes.object.isRequired,
  controlFunc: PropTypes.func.isRequired,
  locationsList: PropTypes.array.isRequired,
  hideLabel: PropTypes.bool,
  containerStyle: PropTypes.object,
  disabled: PropTypes.bool,
  actionable: PropTypes.bool,
  startingValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SelectLocation.defaultProps = {
  disabled: false,
  actionable: false
};

const mapStateToProps = ({ locations }) => {
  return {
    loading: locations.loading
  };
};

export default connect(mapStateToProps, null)(SelectLocation);
