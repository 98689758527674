import React from 'react';
import PropTypes from 'prop-types';
import SelectCourseHTMLSelect from './SelectCourseHTMLSelect';
import SelectCourseOptions from './SelectCourseOptions';
import '../Select/Select.scss';
import './SelectCourse.scss';

class SelectCourseContainer extends React.Component {
  constructor(props) {
    super(props);

    this.selectRef = React.createRef();
    this.optionsWrapperRef = React.createRef();
    this.arrayOfOptionsRefs = [];
    this.state = {
      currentOption: this.props.defaultOption || 'Select',
      openOptions: false,
      focusedOption: undefined,
      hasBeenChanged: false
    };
  }

  isDefaultOption(option) {
    return option === this.props.defaultOption || option === 'Select';
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.openOptions !== this.state.openOptions) {
      if (!this.state.openOptions) {
        this.selectRef.current.focus();
      } else {
        if (!this.arrayOfOptionsRefs || !this.arrayOfOptionsRefs.length) {
          return;
        }
        this.arrayOfOptionsRefs[0].focus();
      }
    }

    if (prevProps.optionList !== this.props.optionList) {
      if (
        !(this.props.optionList && this.props.optionList.length) &&
        !this.isDefaultOption(this.state.currentOption)
      ) {
        this.props.controlFunc({
          valueId: this.props.formValue.valueId,
          value: ''
        });
        this.setState({
          currentOption: this.props.defaultOption || 'Select',
          focusedOption: undefined,
          hasBeenChanged: false
        });
      }
    }
  }

  clearOptionsRefs = () => {
    this.arrayOfOptionsRefs = [];
  };

  handleSubmit = () => {
    this.props.controlFunc({
      valueId: this.props.formValue.valueId,
      value: this.state.currentOption || this.props.optionList[0]
    });
  };

  handleOpenOptions = event => {
    if (this.state.openOptions) {
      this.setState({
        openOptions: false,
        searchString: ''
      });
      return;
    }

    switch (event.type) {
      case 'click':
        this._handleOpenOptions(event);
        break;
      case 'keydown':
        const selectedIndex =
          this.props.optionList.findIndex(option => {
            return Object.keys(option).reduce((result, optionKey) => {
              return (
                result &&
                option[optionKey] === this.state.currentOption[optionKey]
              );
            }, true);
          }) || 0;

        if (event.key === ' ') {
          event.preventDefault();
          this._handleOpenOptions(event);
        } else if (event.key === 'ArrowUp') {
          event.preventDefault();
          const prevOption = this.props.optionList[
            Math.max(0, selectedIndex - 1)
          ];
          this.setState(
            () => ({
              currentOption: prevOption,
              hasBeenChanged: true
            }),
            () => {
              this.handleSubmit();
            }
          );
        } else if (event.key === 'ArrowDown') {
          event.preventDefault();
          const nextOption = this.props.optionList[
            Math.min(this.props.optionList.length - 1, selectedIndex + 1)
          ];
          this.setState(
            () => ({
              currentOption: nextOption,
              hasBeenChanged: true
            }),
            () => {
              this.handleSubmit();
            }
          );
        } else if (event.key.match(/^[\d\w]$/i)) {
          event.preventDefault();

          const { searchString } = this.state;

          let matchingOption;

          if (
            searchString &&
            searchString.length === 1 &&
            event.key.toLowerCase() === searchString.toLowerCase()
          ) {
            matchingOption =
              this.props.optionList.find((option, index) => {
                return (
                  ('' + option.courseNumber)
                    .toLowerCase()
                    .indexOf(searchString.toLowerCase()) !== -1 &&
                  index > selectedIndex
                );
              }) ||
              this.props.optionList.find(option => {
                return (
                  ('' + option.courseNumber)
                    .toLowerCase()
                    .indexOf(searchString.toLowerCase()) !== -1
                );
              });
          } else {
            const newSearchString = (searchString || '') + event.key;
            this.setState(() => ({
              searchString: newSearchString
            }));

            matchingOption = this.props.optionList.find(
              option =>
                ('' + option.courseNumber)
                  .toLowerCase()
                  .indexOf(newSearchString.toLowerCase()) !== -1
            );
          }

          if (matchingOption) {
            this.setState(
              () => ({
                currentOption: matchingOption,
                hasBeenChanged: true
              }),
              () => {
                this.handleSubmit();
              }
            );
          } else {
            this.setState(() => ({
              searchString: ''
            }));
          }

          if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
          }

          this.setState(() => ({
            searchTimeout: setTimeout(() => {
              this.setState(() => ({
                searchString: '',
                searchTimeout: undefined
              }));
            }, 1800)
          }));
        }

        break;
      default:
    }
  };

  _handleOpenOptions = event => {
    this.setState(state => {
      let focusedOption = document.activeElement.id;
      return {
        openOptions: !state.openOptions,
        focusedOption: focusedOption
      };
    });
  };

  handleOptionsEvents = (value, index, event) => {
    switch (event.type) {
      case 'click':
        this.setState(
          () => ({
            currentOption: value,
            openOptions: !this.state.openOptions,
            hasBeenChanged: true
          }),
          () => {
            this.handleSubmit();
          }
        );
        this.selectRef.current.focus();
        break;
      case 'keydown':
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault();
          this.setState(
            () => ({
              currentOption: value,
              openOptions: !this.state.openOptions,
              hasBeenChanged: true
            }),
            () => {
              this.handleSubmit();
            }
          );
          this.selectRef.current.focus();
        }
        if (event.key === 'ArrowUp') {
          event.preventDefault();
          if (!this.arrayOfOptionsRefs || !this.arrayOfOptionsRefs.length) {
            return;
          }
          this.arrayOfOptionsRefs[Math.max(0, index - 1)].focus();
          this.setState(() => ({
            focusedOption: document.activeElement.id
          }));
        }
        if (event.key === 'ArrowDown') {
          event.preventDefault();
          if (!this.arrayOfOptionsRefs || !this.arrayOfOptionsRefs.length) {
            return;
          }
          this.arrayOfOptionsRefs[
            Math.min(this.props.optionList.length - 1, index + 1)
          ].focus();
          this.setState(() => ({
            focusedOption: document.activeElement.id
          }));
        }
        if (String.fromCharCode(event.keyCode).match(/(\w|\s)/g)) {
          event.preventDefault();

          const { searchString } = this.state;

          let matchingOption;
          let newSearchString = searchString || '';

          if (
            newSearchString &&
            newSearchString.length === 1 &&
            event.key.toLowerCase() === newSearchString.toLowerCase()
          ) {
            matchingOption = this.props.optionList.find((option, loopIndex) => {
              return (
                ('' + option.courseNumber)
                  .toLowerCase()
                  .indexOf(newSearchString.toLowerCase()) !== -1 &&
                loopIndex > index
              );
            });
          } else {
            newSearchString = newSearchString + event.key;
            this.setState(() => ({
              searchString: newSearchString
            }));

            matchingOption = this.props.optionList.find(
              option =>
                ('' + option.courseNumber)
                  .toLowerCase()
                  .indexOf(newSearchString.toLowerCase()) !== -1
            );
          }

          if (matchingOption) {
            const matchingOptionIndex = this.props.optionList.findIndex(
              option => option === matchingOption
            );

            try {
              this.optionsWrapperRef.current.scrollTop =
                this.arrayOfOptionsRefs[matchingOptionIndex].offsetTop - 17;
              this.arrayOfOptionsRefs[matchingOptionIndex].focus();
            } catch (error) {
              console.log('refs not set correctly in Select');
            }

            this.setState(() => ({
              focusedOption: matchingOption
            }));
          } else {
            this.setState(() => ({
              searchString: ''
            }));
          }

          if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
          }

          this.setState(() => ({
            searchTimeout: setTimeout(() => {
              this.setState(() => ({
                searchString: '',
                searchTimeout: undefined
              }));
            }, 1000)
          }));
        }
        if (event.key === 'Escape') {
          this.setState(
            () => {
              return { openOptions: !this.state.openOptions };
            },
            () => {
              this.selectRef.current.focus();
            }
          );
        }
        break;
      default:
    }
  };

  setOptionRef = element => {
    if (element !== null) {
      this.arrayOfOptionsRefs.push(element);
    }
  };

  render() {
    const {
      currentOption,
      openOptions,
      focusedOption,
      hasBeenChanged
    } = this.state;
    const {
      formValue,
      optionList,
      hideLabel,
      containerStyle,
      emptyMessage,
      includeEnrollment
    } = this.props;
    const { name, labelTitle } = formValue;

    return (
      <>
        <label htmlFor={name} className={hideLabel ? 'visually-hidden ' : ''}>
          {labelTitle}
        </label>
        <div className="select-container" style={containerStyle}>
          <SelectCourseHTMLSelect
            name={name}
            handleOpenOptions={this.handleOpenOptions}
            openOptions={this.state.openOptions}
            selectRef={this.selectRef}
            currentOption={currentOption}
            hasBeenChanged={hasBeenChanged}
            includeEnrollment={includeEnrollment}
          />
          {openOptions === true ? (
            <SelectCourseOptions
              handleOptionsEvents={this.handleOptionsEvents}
              optionsWrapperRef={this.optionsWrapperRef}
              setOptionRef={this.setOptionRef}
              currentOption={currentOption}
              focusedOption={focusedOption}
              optionList={optionList}
              emptyMessage={emptyMessage}
              includeEnrollment={includeEnrollment}
            />
          ) : (
            [this.clearOptionsRefs(), null]
          )}
        </div>
      </>
    );
  }
}

SelectCourseContainer.propTypes = {
  formValue: PropTypes.object.isRequired,
  controlFunc: PropTypes.func.isRequired,
  optionList: PropTypes.array.isRequired,
  defaultOption: PropTypes.string,
  hideLabel: PropTypes.bool,
  containerStyle: PropTypes.object,
  emptyMessage: PropTypes.string
};

SelectCourseContainer.defaultProps = {
  defaultOption: '',
  hideLabel: false,
  emptyMessage: 'No available options'
};

export default SelectCourseContainer;
