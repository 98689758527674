// Main validation function imported into the schedule reducer
export const validate = (schedule, reqFields) => {
  const { courses } = schedule;

  // Declare validation functions & provide them with the params they need
  let alwaysValid = false;
  if (schedule.state === 'projected' || schedule.state === 'completed') {
    alwaysValid = true;
  }
  let p1 = alwaysValid || scheduleValidation(schedule);
  let p2 = alwaysValid || courseValidation(courses);
  let p3 = alwaysValid || sectionValidation(courses, reqFields);
  let p4 = alwaysValid || instructionalCompValidation(courses);

  // Resolve all validation funcs, get the returned values in a promise array
  return Promise.all([p1, p2, p3, p4]);
  // Expected output: Array [ Array[scheduleValidation], Array[courseValidation], etc.. ]
};

// Schedule level validation
const scheduleValidation = schedule => {
  return new Promise(resolve => {
    let scheduleValidation = [];
    let validationMessages = course => {
      return {
        validationLevel: 'schedule',
        type: 'warning',
        message: `There is projected enrollment for ${course.courseNumber} ${course.name} but course does not appear on the schedule.`
      };
    };

    // Find all student groups where projectedEnrollment is set,
    // compare courses to the schedule courses.
    // If a courseId in the studentGroup does not exist in the schedule, then create the warning.
    let studentGroupCourses = schedule.studentGroups
      .filter(group => group.enrollment)
      .reduce((a, c) => {
        return a.concat(c.courses);
      }, []);

    let missingCourses = studentGroupCourses.filter(c => {
      return !schedule.courses.some(d => d.id === c.id);
    });

    const uniqueMissingCourses = Array.from(
      new Set(missingCourses.map(a => a.id))
    ).map(id => {
      return missingCourses.find(a => a.id === id);
    });

    uniqueMissingCourses.forEach(c =>
      scheduleValidation.push(validationMessages(c))
    );
    resolve(scheduleValidation);
  });
};

// Course level validation
const courseValidation = courses => {
  return new Promise(resolve => {
    let courseValidation = [];

    let validator = course => {
      let validationMessages = [
        {
          type: 'warning',
          message: 'This course has no projected enrollment.',
          condition: !course.projectedEnrollment
        }
      ];

      validationMessages.forEach(vm => {
        if (vm.condition) {
          courseValidation.push({
            type: vm.type,
            message: vm.message,
            validationLevel: 'course',
            courseId: course.id
          });
        }
      });
    };
    courses.forEach(c => validator(c));
    resolve(courseValidation);
  });
};

// Section level validation
const sectionValidation = (courses, reqFields) => {
  return new Promise(resolve => {
    let sectionValidation = [];
    let validator = (section, courseId, locationDups, facultyDups) => {
      const { location, capacity, schedule, perSectionCapacity } = section;
      let locationCapacity;
      if (location) locationCapacity = location.capacity;

      // Determine if a facultyMember or location has a time conflict
      let isTimeConflict = (scheduleA, dups) => {
        // Build a days array with a minute representation for each day
        var days = ['M', 'T', 'W', 'Th', 'F', 'S', 'Su'];
        // Create the base time
        let baseTime = ogTime => {
          let time = ogTime.split(':');
          let hours = time[0];
          let minutes = time[1];
          return { hh: hours, mm: minutes };
        };

        var baseStartA = baseTime(scheduleA.startTime);
        var baseEndA = baseTime(scheduleA.endTime);

        const newTimeFunc = (daysOfWeek, startTime, endTime) => {
          var intervals = [];
          var dayBaseMinutes = [];
          daysOfWeek.forEach(d => {
            dayBaseMinutes.push(parseInt(days.indexOf(d)) * 24 * 60);
            if (d === 'M') {
              dayBaseMinutes.push(7 * 24 * 60);
            }
          });
          dayBaseMinutes.forEach(dbm => {
            var t1 =
              parseInt(dbm) +
              60 * parseInt(startTime.hh) +
              parseInt(startTime.mm);
            var t2 =
              parseInt(dbm) + 60 * parseInt(endTime.hh) + parseInt(endTime.mm);
            if (startTime <= endTime) {
              intervals.push([t1, t2]);
            } else {
              intervals.push([t1, 24 * 60 + parseInt(t2)]);
            }
          });
          return intervals;
        };

        var intervalsA = newTimeFunc(
          scheduleA.daysOfWeek,
          baseStartA,
          baseEndA
        );

        var isDup = [];
        dups.forEach(dup => {
          let baseStartB = baseTime(dup.schedule.startTime);
          let baseEndB = baseTime(dup.schedule.endTime);
          let intervalsB = newTimeFunc(
            dup.schedule.daysOfWeek,
            baseStartB,
            baseEndB
          );

          let compareIntervals = (intervalsA, intervalsB) => {
            const compareResults = [];
            intervalsA.forEach(a =>
              intervalsB.forEach(b => {
                let startA = a[0];
                let endA = a[1];
                let startB = b[0];
                let endB = b[1];
                let result =
                  startB < startA && endB > startA
                    ? true // B1 ... A1 ... B2 .. A2
                    : startB < endA && endB > endA
                    ? true // B1 ... A1 ... A2 ... B2
                    : startB > startA && endB < endA
                    ? true // A1 ... B1 ... B2 ... A2
                    : startB > startA && startA > endB
                    ? true // A1 ... B1 ... A2 ... B2
                    : startB === startA && endB === endA
                    ? true
                    : false;
                compareResults.push(result);
              })
            );

            return compareResults;
          };
          isDup.push(compareIntervals(intervalsA, intervalsB));
        });
        return isDup.flat().some(x => x === true);
      };

      // Determine if a section has missing info, based on component field criteria
      let isMissingInformation = () => {
        let fields = reqFields.find(r => r.id === section.type);
        let isMissing = false;
        Object.entries(fields.fieldRequirements).forEach(([key, value]) => {
          if (value === 'required' && section[key] === null) {
            if (!section[key + 'Tba']) {
              isMissing = true;
            }
            if (key === 'capacity' && value === 'required') {
              isMissing = true;
            }
          }
        });
        return isMissing;
      };

      let validationMessages = [
        {
          type: 'error',
          message: 'This section has missing information.',
          condition: isMissingInformation()
        },
        {
          type: 'error',
          message: `This location can only accommodate ${locationCapacity} students`,
          condition: locationCapacity && capacity && capacity > locationCapacity
        },
        {
          type: 'error',
          message: `The section capacity exceeds the maximum capacity of ${perSectionCapacity} specified for the instructional component.`,
          condition:
            capacity && perSectionCapacity && capacity > perSectionCapacity
        },
        {
          type: 'error',
          message: `The capacity is not set for the section but is specified to be ${perSectionCapacity} for the instructional component.`,
          condition: !capacity && perSectionCapacity
        },
        {
          type: 'error',
          message: `The capacity is not set for the section but is specified to be ${locationCapacity} for the location.`,
          condition: !capacity && locationCapacity
        },
        {
          type: 'warning',
          message:
            'There is more than one course section scheduled for this location and time.',
          condition:
            schedule && locationDups.length > 0
              ? isTimeConflict(schedule, locationDups)
              : false
        },
        {
          type: 'warning',
          message:
            'This faculty member is assigned another section during this time.',
          condition:
            schedule && facultyDups.length > 0
              ? isTimeConflict(schedule, facultyDups)
              : false
        },
        {
          type: 'warning',
          message: "Section has items marked as 'TBA'.",
          condition:
            section.locationTba ||
            section.facultyMemberTba ||
            section.scheduleTba
        },
        {
          type: 'warning',
          message: 'Section has unconfirmed faculty.',
          condition:
            section.facultyMember &&
            !section.facultyMemberTba &&
            !section.confirmed
        }
      ];

      validationMessages.forEach(vm => {
        if (vm.condition) {
          sectionValidation.push({
            type: vm.type,
            message: vm.message,
            validationLevel: 'section',
            sectionId: section.id,
            courseId: courseId
          });
        }
      });
    };
    // Get all sections so we can search for duplicates
    let allSections = courses
      .map(c => c.components.map(cm => cm.sections))
      .flat(2);

    courses.forEach(c => {
      let sections = c.components.reduce((a, cs) => {
        let sectionsType = cs.sections.map(s => {
          return {
            ...s,
            type: cs.type,
            perSectionCapacity: cs.perSectionCapacity
          };
        });
        return a.concat(sectionsType);
      }, []);

      sections.forEach(s => {
        let locationDups = allSections.filter(
          a =>
            a.schedule &&
            s.schedule &&
            a.id !== s.id &&
            a.location &&
            s.location &&
            a.location.id === s.location.id
        );
        let facultyDups = allSections.filter(
          a =>
            a.id !== s.id &&
            a.schedule &&
            s.schedule &&
            a.facultyMember &&
            s.facultyMember &&
            a.facultyMember.id === s.facultyMember.id
        );
        validator(s, c.id, locationDups, facultyDups);
      });
    });
    resolve(sectionValidation);
  });
};

const instructionalCompValidation = courses => {
  return new Promise(resolve => {
    let instructionalCompValidation = [];

    let validator = (component, course) => {
      let { projectedEnrollment } = course;

      // Calculate total component capacity
      let componentTotalCapacity = component.sections.reduce((a, c) => {
        let total;
        if (c.capacity) total = parseInt(a) + parseInt(c.capacity);
        else total = Infinity;
        // TODO: check to make sure this works!
        return total;
      }, 0);

      let validationMessages = [
        {
          type: 'error',
          message: 'There are no sections for this instructional component.',
          condition: component.sections.length === 0
        },
        {
          type: 'error',
          message:
            'There is insufficient capacity for the projected enrollment.',
          condition:
            component.sections.length !== 0 &&
            projectedEnrollment &&
            componentTotalCapacity < projectedEnrollment
        },
        {
          type: 'warning',
          message:
            'The total section capacity is greater than required for the projected enrollment.',
          condition:
            projectedEnrollment &&
            componentTotalCapacity !== Infinity &&
            componentTotalCapacity > projectedEnrollment
        }
      ];

      validationMessages.forEach(vm => {
        if (vm.condition) {
          instructionalCompValidation.push({
            type: vm.type,
            message: vm.message,
            validationLevel: 'instructionalComponent',
            componentType: component.type,
            courseId: course.id
          });
        }
      });
    };
    courses.forEach(c => {
      if (c.components) c.components.forEach(cs => validator(cs, c));
    });
    resolve(instructionalCompValidation);
  });
};
