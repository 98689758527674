import React from 'react';
import { connect } from 'react-redux';
import Input from '../common/Input';
import Select from '../common/Select';
import FormReveal from '../common/FormReveal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';

class SetWorkplanAllocation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDefaultAllocation: '',
      defaultAllocationValues: {},
      allocationInputValues: {},
      isFormInputValid: false,
      resetting: false
    };
    this.formRevealRef = React.createRef();
    this.baseState = { ...this.state };
    this.handleValueChange = this.handleValueChange.bind(this);

    this.formValues = {
      default: {
        name: 'default-allocation',
        valueId: 'defaultAllocation',
        labelTitle: 'Default Allocation',
        defaultValue: 'Select',
        validatorFunc: value => ({
          isValid: value && value !== '' && value !== 'Select',
          errorMessage: 'Invalid default allocation'
        })
      },
      effortValues: {
        validatorFunc: value => ({
          isValid:
            /^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/.test(value) &&
            parseFloat(value) <= 100 &&
            parseFloat(value) >= 0,
          errorMessage: 'Invalid effort'
        })
      }
    };
  }

  componentDidMount() {
    if (this.props.workPlan) {
      let initialAllocationValues = {};
      this.props.workPlan.allocation.forEach(x => {
        initialAllocationValues[x.type] = x.expectedEffort;
      });

      this.setState({
        initialAllocationValues: initialAllocationValues,
        allocationTotal: Object.entries(initialAllocationValues).reduce(
          (result, next) => {
            let theResult;
            let floatPrecision = this.getMaxPrecision(initialAllocationValues);
            if (!Number.isNaN(next[1])) {
              theResult = parseFloat(result) + parseFloat(next[1]);
            } else {
              theResult = result;
            }
            return this.parseToPrecision(floatPrecision, theResult);
          },
          0
        )
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.resetting !== this.state.resetting && this.state.resetting) {
      this.setState({
        resetting: false
      });
    }
    if (prevProps.workPlan !== this.props.workPlan && this.props.workPlan) {
      let initialAllocationValues = {};
      this.props.workPlan.allocation.forEach(x => {
        initialAllocationValues[x.type] = x.expectedEffort;
      });

      this.setState({
        initialAllocationValues: initialAllocationValues
      });
    }
  }

  render() {
    const { effortMode } = this.props;
    const form = this.renderSetAllocationForm();
    if (!form) return null;
    return (
      <FormReveal
        buttonText={effortMode === 'TU' ? 'Edit Allocation' : 'Set Allocation'}
        buttonId="set-allocation-btn"
        form={form}
        isOpening={state => this.isOpening(state)}
        submitFunc={() => this.resetForm()}
        cancelFunc={() => this.resetForm()}
        ref={this.formRevealRef}
        useChevronIcon={effortMode === 'TU'}
      />
    );
  }

  renderSetAllocationForm() {
    const {
      workTypes,
      effortUnit,
      defaultAllocations,
      workPlan,
      effortMode
    } = this.props;
    const {
      allocationTotal,
      defaultAllocationValues,
      selectedDefaultAllocation,
      allocationInputValues,
      resetting,
      initialAllocationValues
    } = this.state;
    if (resetting) {
      return null;
    }
    // Simplified array of allocation options
    let allocationOptions = [];
    if (defaultAllocations) {
      allocationOptions = defaultAllocations.map(d => d.name);
    }

    // Combine original values & any values set in state to pre-populate inputs
    if (!workPlan || !initialAllocationValues || !workTypes) {
      return null;
    }

    let defaultValues = {
      ...initialAllocationValues,
      ...defaultAllocationValues,
      ...allocationInputValues
    };

    // Effort values array
    let effortValues = [];
    if (workTypes) {
      Object.entries(initialAllocationValues).forEach(([key, value]) => {
        let releventWorkType = workTypes.find(x => x.id === key);
        if (releventWorkType.active) {
          effortValues.push({
            name: `${releventWorkType.id}-effort`,
            valueId: `${releventWorkType.id}Effort`,
            typeId: releventWorkType.id,
            labelTitle:
              effortMode === '%'
                ? `${releventWorkType.name} (${effortUnit})`
                : `${releventWorkType.name} effort`,
            defaultValue: defaultValues
              ? defaultValues[releventWorkType.id]
              : undefined,
            validatorFunc: value =>
              this.formValues.effortValues.validatorFunc(value)
          });
        }
      });
    }

    return (
      <form id="set-allocation-form" className="allocation-form">
        {effortMode !== 'TU' && <h1>Set Allocation</h1>}
        <div className="set-default-allocation">
          <Select
            formValue={this.formValues.default}
            controlFunc={this.handleValueChange}
            optionList={allocationOptions}
          />
          <button
            type="button"
            id="apply-default-values"
            onClick={event => this.applyDefaultValues(event)}
            disabled={
              !this.formValues.default.validatorFunc(selectedDefaultAllocation)
                .isValid
            }
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                this.applyDefaultValues(event);
              }
            }}
          >
            Apply Default
          </button>
        </div>
        <div className="set-expected-effort">
          {effortMode === '%' ? (
            <h2>
              {allocationTotal}
              {effortUnit} Expected Effort
            </h2>
          ) : (
            <h2>{allocationTotal} Total Expected Effort</h2>
          )}

          <div className="effort-inputs-container">
            {Object.keys(initialAllocationValues).map((key, i) => {
              return (
                <div key={i} className="allocation-input">
                  <Input
                    formValue={effortValues.find(v => {
                      return v.typeId === key;
                    })}
                    controlFunc={value => this.handleValueChange(value, key)}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div id="form-end-wrapper">
          <div className="buttons">
            <button
              type="button"
              id="cancel-set-allocation"
              onClick={event => this.handleCancelClick(event)}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  this.handleCancelClick(event);
                }
              }}
            >
              Cancel
            </button>
            <button
              id="submit-set-allocation"
              onClick={event => this.handleSubmitClick(event)}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  this.handleSubmitClick(event);
                }
              }}
              disabled={!this.state.isFormInputValid}
            >
              {effortMode === '%' ? 'Set Allocation' : 'Save Allocation'}

              <FontAwesomeIcon
                icon={effortMode === '%' ? faPlusCircle : faCheck}
              />
            </button>
          </div>
        </div>
      </form>
    );
  }

  handleCancelClick(event) {
    event.preventDefault();
    this.formRevealRef.current.handleCancelClick(event);
  }

  getMaxPrecision(valuesObj) {
    let precision = Object.entries(valuesObj).reduce(function(
      precisionAggregator,
      next
    ) {
      let nextStr = next[1].toString().split('.');
      let nextPrecision = nextStr.length > 1 ? nextStr[1].length : 0;
      return Math.max(precisionAggregator, nextPrecision);
    },
    0);
    return Number.parseFloat(precision).toFixed(precision);
  }

  parseToPrecision(allocation, actual) {
    let allocationStr = allocation.toString().split('.');
    let allocationPrecision =
      allocationStr.length > 1 ? allocationStr[1].length : 0;
    return Number.parseFloat(actual).toFixed(allocationPrecision);
  }

  applyDefaultValues(event) {
    event.preventDefault();
    const { defaultAllocations } = this.props;
    const { initialAllocationValues } = this.state;
    document.getElementById('set-allocation-form').reset();

    let defaultEffort = defaultAllocations.find(
      x => x.name === this.state.selectedDefaultAllocation
    );

    let newAllocationValues = {};
    Object.entries(initialAllocationValues).forEach(([key, value]) => {
      let allocationDefault = defaultEffort.expectedEffort[key];
      newAllocationValues[key] = allocationDefault ? allocationDefault : 0;
    });

    this.setState({
      defaultAllocationValues: {
        ...newAllocationValues
      },
      allocationInputValues: {
        ...newAllocationValues
      },
      allocationTotal: Object.entries({
        ...newAllocationValues
      }).reduce((result, next) => {
        let theResult;
        let floatPrecision = this.getMaxPrecision(newAllocationValues);
        if (!Number.isNaN(next[1])) {
          theResult = parseFloat(result) + parseFloat(next[1]);
        } else {
          theResult = result;
        }
        return this.parseToPrecision(floatPrecision, theResult);
      }, 0),
      isFormInputValid: Object.entries({
        ...newAllocationValues
      }).reduce((result, next) => {
        let theResult;
        theResult =
          result && this.formValues.effortValues.validatorFunc(next[1]).isValid;
        return theResult;
      }, true)
    });
  }

  handleSubmitClick(event) {
    event.preventDefault();
    const { allocationInputValues } = this.state;
    let allocationArr = [];

    Object.entries(allocationInputValues).forEach(([key, val]) => {
      allocationArr.push({
        id: key,
        expectedEffort: val
      });
    });

    this.props.setWorkplanAllocation(allocationArr);
    this.formRevealRef.current.handleSubmitClose(event);
  }

  resetForm() {
    this.setState(prevState => ({
      ...this.baseState,
      allocationTotal: Object.entries(prevState.initialAllocationValues).reduce(
        (result, next) => {
          let theResult;
          let floatPrecision = this.getMaxPrecision(
            prevState.initialAllocationValues
          );
          if (!Number.isNaN(parseFloat(next[1]))) {
            theResult = parseFloat(result) + parseFloat(next[1]);
          } else {
            theResult = result;
          }
          return this.parseToPrecision(floatPrecision, theResult);
        },
        0
      ),
      initialAllocationValues: { ...prevState.initialAllocationValues },
      resetting: true
    }));
  }

  isOpening(state) {
    this.props.showSetAllocation(state);
  }

  handleValueChange = (formValue, workType) => {
    if (formValue.valueId === 'defaultAllocation') {
      this.setState({
        selectedDefaultAllocation: formValue.value
      });
    } else {
      let newValue = !Number.isNaN(parseFloat(formValue.value))
        ? parseFloat(formValue.value)
        : formValue.value;

      this.setState(prevState => ({
        allocationInputValues: {
          ...prevState.initialAllocationValues,
          ...prevState.allocationInputValues,
          [workType]: newValue
        },
        allocationTotal: Object.entries({
          ...prevState.initialAllocationValues,
          ...prevState.allocationInputValues,
          [workType]: newValue
        }).reduce((result, next) => {
          let theResult;
          let floatPrecision = this.getMaxPrecision({
            ...prevState.initialAllocationValues,
            ...this.state.allocationInputValues,
            [workType]: newValue
          });
          if (!Number.isNaN(parseFloat(next[1]))) {
            theResult = parseFloat(result) + parseFloat(next[1]);
          } else {
            theResult = result;
          }
          return this.parseToPrecision(floatPrecision, theResult);
        }, 0),
        isFormInputValid: Object.entries({
          ...prevState.initialAllocationValues,
          ...prevState.allocationInputValues,
          [workType]: newValue
        }).reduce((result, next) => {
          let theResult;
          theResult =
            result &&
            this.formValues.effortValues.validatorFunc(next[1]).isValid;
          return theResult;
        }, true)
      }));
    }
  };
}

const mapStateToProps = ({ config, faculty }) => {
  return {
    defaultAllocations:
      config &&
      config.config &&
      config.config.facultyWorkPlanDefaultAllocations,
    workPlan: faculty.viewWorkPlan
  };
};

export default connect(mapStateToProps, null)(SetWorkplanAllocation);
