import React from 'react';
import { connect } from 'react-redux';
import { hideSidePanel } from '../../service/actions/sidePanel';
import './SidePanel.scss';

class SidePanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      renderContent: false
    };
    this.baseState = { ...this.state };

    this.contentRef = React.createRef();
    this.escapeFunction = this.escapeFunction.bind(this);
    this.backFunction = this.backFunction.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escapeFunction, false);
    window.addEventListener('popstate', this.backFunction, false);
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible) {
      if (this.props.visible) {
        this.openPanel();
        this.trapFocus();
      } else {
        this.closePanel();
      }
    }

    if (this.props.updateFocusTrap !== prevProps.updateFocusTrap) {
      if (this.props.updateFocusTrap) {
        this.trapFocus();
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escapeFunction, false);
    window.removeEventListener('popstate', this.backFunction, false);
  }

  render() {
    return (
      <div id="panel-wrapper" className={this.props.visible ? 'visible ' : ''}>
        <div id="panel-bg-overlay" onClick={() => this.closePanel()}></div>
        <section id="panel-content" tabIndex="-1" ref={this.contentRef}>
          {this.props.sidePanelContent}
        </section>
      </div>
    );
  }

  escapeFunction(event) {
    if (event.key === 'Escape') {
      this.closePanel(true);
    }
  }

  backFunction(_) {
    this.closePanel(false);
  }

  closePanel(shouldSendFocus) {
    document.body.classList.remove('noscroll');
    this.props.hideSidePanel({
      sendFocus: shouldSendFocus
    });
  }

  openPanel() {
    setTimeout(() => {
      document.body.classList.add('noscroll');
      if (this.props.keyActivated) {
        let focusItem =
          document.querySelector('#panel-content .first-focus') ||
          this.contentRef.current;
        if (focusItem) {
          focusItem.setAttribute('tabindex', '0');
          focusItem.focus();
        }
      } else {
        this.contentRef.current.setAttribute('tabindex', '0');
        this.contentRef.current.focus();
      }
      this.trapFocus();
    }, 0);
  }

  trapFocus() {
    function trap(e) {
      const focusableEls = this.contentRef.current.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
      );
      const firstFocusableEl = focusableEls[0];
      const lastFocusableEl = focusableEls[focusableEls.length - 1];

      if (e.key === 'Enter') {
        e.preventDefault();
      }

      if (e.key !== 'Tab') {
        return;
      }

      if (e.shiftKey) {
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
          e.preventDefault();
        }
      } else {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
          e.preventDefault();
        }
      }
    }

    this.contentRef.current.removeEventListener('keydown', trap.bind(this));
    this.contentRef.current.addEventListener('keydown', trap.bind(this));
  }
}

const mapStateToProps = ({ sidePanel }) => {
  return {
    visible: sidePanel.sidePanelVisible,
    sidePanelContent: sidePanel.content,
    sidePlanelClosing: sidePanel.sidePanelClosing,
    updateFocusTrap: sidePanel.updateFocusTrap,
    keyActivated: sidePanel.keyActivated
  };
};

export default connect(
  mapStateToProps,
  { hideSidePanel }
)(SidePanel);
