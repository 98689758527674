import {
  GET_COURSES,
  GET_COURSE,
  GET_COURSE_SCHEDULES,
  ADD_COURSE,
  UPDATE_COURSE,
  UPDATE_COURSE_COMPONENTS,
  CLEAR_COURSE_ERRORS,
  ARCHIVE_COURSE
} from '../types';
import {
  getCoursesEndpoint,
  getCourseEndpoint,
  getCourseSchedulesEndpoint,
  addCourseEndpoint,
  updateCourseEndpoint,
  updateCourseComponentsEndpoint,
  archiveCourseEndpoint
} from '../../utils/endpoints';
import requestWrapper from '../requestWrapper';

export const getCourses = () => async dispatch => {
  requestWrapper(dispatch, {
    actionType: GET_COURSES,
    requestObject: {
      url: getCoursesEndpoint(),
      method: 'GET',
      responseType: 'json'
    },
    errorMessage: 'Error retrieving courses',
    responseDataKey: 'courses'
  });
};

export const getCourse = courseId => async dispatch => {
  requestWrapper(dispatch, {
    actionType: GET_COURSE,
    requestObject: {
      url: getCourseEndpoint(courseId),
      method: 'GET',
      responseType: 'json'
    },
    errorMessage: 'Error retrieving course',
    responseDataKey: 'course',
    customResponseStatusMessages: {
      '404': 'Course not found.'
    }
  });
};

export const getCourseSchedules = courseId => async dispatch => {
  requestWrapper(dispatch, {
    actionType: GET_COURSE_SCHEDULES,
    requestObject: {
      url: getCourseSchedulesEndpoint(courseId),
      method: 'GET',
      responseType: 'json'
    },
    errorMessage: 'Error retrieving course schedules',
    responseDataKey: 'schedules'
  });
};

export const addCourse = newCourse => async dispatch => {
  requestWrapper(dispatch, {
    actionType: ADD_COURSE,
    requestObject: {
      url: addCourseEndpoint(),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: newCourse,
      responseType: 'json'
    },
    responseDataKey: 'course'
  });
};

export const updateCourse = (courseId, courseUpdate) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: UPDATE_COURSE,
    requestObject: {
      url: updateCourseEndpoint(courseId),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      data: courseUpdate,
      responseType: 'json'
    },
    responseDataKey: 'course',
    responsePayload: {
      ...courseUpdate,
      id: courseId
    }
  });
};

export const updateCourseComponents = (
  courseId,
  components
) => async dispatch => {
  requestWrapper(dispatch, {
    actionType: UPDATE_COURSE_COMPONENTS,
    requestObject: {
      url: updateCourseComponentsEndpoint(courseId),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      data: components,
      responseType: 'json'
    },
    responseDataKey: 'course',
    responsePayload: {
      id: courseId,
      components: components
    }
  });
};

export const archiveCourse = (courseId, courseData) => dispatch => {
  requestWrapper(dispatch, {
    actionType: ARCHIVE_COURSE,
    requestObject: {
      url: archiveCourseEndpoint(courseId),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      data: { ...courseData, archived: true },
      responseType: 'json'
    },
    responseDataKey: 'course',
    responsePayload: {
      id: courseId
    }
  });
};

export const clearErrors = () => dispatch => {
  dispatch({ type: CLEAR_COURSE_ERRORS });
};
