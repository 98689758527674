import React from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';
import { connect } from 'react-redux';

class SelectDepartment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valueMappings: new Map(),
      reverseValueMappings: new Map(),
      strippedOptionList: []
    };

    this.controlFunc = this.controlFunc.bind(this);
  }

  componentDidMount() {
    if (this.props.departmentsList) {
      this.buildValueMappings();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.departmentsList !== this.props.departmentsList) {
      this.buildValueMappings();
    }
  }

  render() {
    const {
      containerStyle,
      formValue,
      hideLabel,
      loading,
      value,
      disabled,
      actionable,
      optional
    } = this.props;
    const { strippedOptionList, reverseValueMappings } = this.state;

    let defaultText = formValue.defaultValue || 'Select';
    if (loading) {
      defaultText = 'Loading...';
    }
    return (
      <Select
        formValue={formValue}
        startingValue={reverseValueMappings.get(value)}
        controlFunc={this.controlFunc}
        optionList={strippedOptionList}
        defaultOption={defaultText}
        hideLabel={hideLabel}
        containerStyle={containerStyle}
        disabled={disabled}
        actionable={actionable}
        optional={optional}
      />
    );
  }

  controlFunc(formValue) {
    const { departmentId } = this.state.valueMappings.get(formValue.value) || {
      departmentId: null
    };
    this.props.controlFunc({
      valueId: this.props.formValue.valueId,
      value: departmentId
    });
  }

  buildValueMappings() {
    let valueMappings = new Map();
    let reverseValueMappings = new Map();
    let strippedOptionList = [];
    if (!this.props.departmentsList) {
      return null;
    }
    this.props.departmentsList.forEach(department => {
      valueMappings.set(department.name, {
        departmentId: department.id
      });
      reverseValueMappings.set(department.id, department.name);
      strippedOptionList.push(department.name);
    });

    this.setState({
      valueMappings,
      reverseValueMappings,
      strippedOptionList
    });
  }
}

SelectDepartment.propTypes = {
  formValue: PropTypes.object.isRequired,
  controlFunc: PropTypes.func.isRequired,
  value: PropTypes.string,
  hideLabel: PropTypes.bool,
  containerStyle: PropTypes.object,
  disabled: PropTypes.bool,
  actionable: PropTypes.bool,
  optional: PropTypes.bool
};

SelectDepartment.defaultProps = {
  disabled: false,
  actionable: false,
  optional: false
};

const mapStateToProps = ({ config }) => {
  return {
    loading: config.loading,
    departmentsList: config && config.config && config.config.departments
  };
};

export default connect(mapStateToProps, null)(SelectDepartment);
