export const generateRequestActionTypeName = basicActionName =>
  `${basicActionName}_REQUEST`;
export const generateSuccessActionTypeName = basicActionName =>
  `${basicActionName}_SUCCESS`;
export const generateFailureActionTypeName = basicActionName =>
  `${basicActionName}_FAILURE`;
export const generateClearErrorActionTypeName = basicActionName =>
  `${basicActionName}_CLEAR_ERROR`;
export const generateClearItemActionTypeName = basicActionName =>
  `${basicActionName}_CLEAR`;
