import React from 'react';
import './Sidebar.scss';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ReactComponent as IconPrograms } from '../../assets/images/icon-programs.svg';
import { ReactComponent as IconSchedules } from '../../assets/images/icon-schedules.svg';
import { ReactComponent as IconFaculty } from '../../assets/images/icon-faculty.svg';
import { ReactComponent as IconCourses } from '../../assets/images/icon-courses.svg';
import { ReactComponent as IconCourseEffort } from '../../assets/images/icon-course-effort.svg';
import { ReactComponent as IconLocations } from '../../assets/images/icon-locations.svg';
import { ReactComponent as IconStudentGroups } from '../../assets/images/icon-student-groups.svg';
import A11YLink from '../common/A11YLink/';

const Sidebar = props => {
  const scopes = useSelector(state => {
    return state.auth && state.auth.scopes;
  });
  if (!scopes) {
    return null;
  }

  return (
    <nav id="sidebar">
      <ul className="none navigation">
        <li>
          <A11YLink
            path="/schedules"
            unauthorized={!scopes.includes('schedule.read')}
          >
            <span>
              <div className="icon">
                <IconSchedules />
              </div>
              Schedules
            </span>
          </A11YLink>
        </li>
        <li>
          <A11YLink
            path="/faculty-members"
            unauthorized={!scopes.includes('faculty-member.read')}
          >
            <span>
              <div className="icon">
                <IconFaculty />
              </div>
              Faculty
            </span>
          </A11YLink>
        </li>
        <li>
          <A11YLink
            path="/courses"
            unauthorized={!scopes.includes('course.read')}
          >
            <span>
              <div className="icon">
                <IconCourses />
              </div>
              Courses
            </span>
          </A11YLink>
        </li>
        <li>
          <A11YLink
            path="/plans-of-study"
            unauthorized={!scopes.includes('plan-of-study.read')}
          >
            <span>
              <div className="icon">
                <IconPrograms />
              </div>
              Plans of Study
            </span>
          </A11YLink>
        </li>
        <li>
          <A11YLink
            path="/student-groups"
            unauthorized={!scopes.includes('student-group.read')}
          >
            <span>
              <div className="icon">
                <IconStudentGroups />
              </div>
              Student Groups
            </span>
          </A11YLink>
        </li>
        <li>
          <A11YLink
            path="/locations"
            unauthorized={!scopes.includes('location.read')}
          >
            <span>
              <div className="icon">
                <IconLocations />
              </div>
              Locations
            </span>
          </A11YLink>
        </li>
        <li
          className={
            !scopes.includes('teaching-default-work-effort.write')
              ? 'unauthorized '
              : ''
          }
        >
          <A11YLink
            path="/default-course-effort"
            unauthorized={
              !scopes.includes('teaching-default-work-effort.write')
            }
          >
            <span>
              <div className="icon">
                <IconCourseEffort />
              </div>
              Course Effort
            </span>
          </A11YLink>
        </li>
      </ul>
    </nav>
  );
};

export default withRouter(Sidebar);
