import React from 'react';
import './ToggleExpandAll.scss';

class ToggleExpandAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandAll: this.props.startExpanded
    };
  }

  render() {
    return (
      <div className="expand-selector no-print">
        <div
          tabIndex="0"
          onClick={() => this.toggleExpandAll(false)}
          onKeyDown={event => this.toggleExpandAllKeyed(event, false)}
          className={!this.state.expandAll ? 'active' : undefined}
        >
          <span>Collapse All</span>
        </div>
        <div className="pipe-separator"></div>
        <div
          tabIndex="0"
          onClick={() => this.toggleExpandAll(true)}
          onKeyDown={event => this.toggleExpandAllKeyed(event, true)}
          className={this.state.expandAll ? 'active' : undefined}
        >
          <span>Expand All</span>
        </div>
      </div>
    );
  }

  toggleExpandAll(expandState) {
    this.setState({
      expandAll: expandState
    });
    this.props.toggleExpandAll(expandState);
  }

  toggleExpandAllKeyed(event, expandState) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.expandAll(expandState);
    }
  }
}

export default ToggleExpandAll;
